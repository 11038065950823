import { I18N } from '@flyele-nx/i18n'
import React from "react";
import _uniqueId from "lodash/uniqueId";

import { FileCard } from "Component/FileCard";
import styles from "./Detail.module.scss";

const Detail = ({ task, filesList }: any) => {
  return (
    <div className={styles.details}>
      {/** 背景信息 */}
      <div
        className={styles.details_desc}
        dangerouslySetInnerHTML={{
          __html: (task?.detail || "").replace(/\n/g, "<br/>") || I18N.common.noBackgroundInformation,
        }}
      />

      {/** 文件缩略图 */}
      {filesList.length > 0 && (
        <div className={styles.details_files}>
          {filesList.map((file: any) => (
            <div key={_uniqueId()}>
              <FileCard info={file} task={task} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Detail;
