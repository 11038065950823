import React, { useState } from 'react'
import { Files } from '@/service/types/matter'
import { getFileIcon } from "utils_for_pc/file";

import style from './index.module.scss'

type Props = {
  files: Files[] | undefined
}

// 获取文件小图标
// const getSmallFileIcon = (name: string) => {
//
//   const reg = {
//     image: /(jpe?g|png|gif)$/gi,
//     word: /(docx?)$/gi,
//     excel: /(xlsx?)$/gi,
//     ppt: /(pptx?)$/gi,
//     pdf: /(pdf)$/gi,
//     zip: /(zip)$/gi,
//     unknown: /(unknown)$/gi,
//   }
//
//   let type = Object.keys(reg).find((key) => {
//     return reg[key as keyof typeof reg].test(name)
//   })
//
//   type = type || 'unknown'
//   return `icons/file/file-${type}.png`
// }

const FileIconList = (props: Props) => {
  const { files = [] } = props
  const [more, showMore] = useState(false)

  return (
    <div className={style['file-list-container']}>
      {files?.slice(0, more ? files.length : 10).map((item, index) => (
        <img
          key={index}
          alt=""
          src={getFileIcon(item.name)}
          className={style['file-icon']}
        />
      ))}
      {files?.length && files.length > 10 ? (
        <div className={style.more} onClick={() => showMore(!more)}>
          {more ? '收起' : '查看更多'}
        </div>
      ) : null}
    </div>
  )
}

export default FileIconList
