import WeixinSister from "pages/PureH5/WeixinSister/Sister";
import WeixinSisterBind from "pages/PureH5/WeixinSister/Bind";
import BindWxCodeToPhone from "@/pages/BindWxCodeToPhone";

export const ForWeixinRouters = [
  // V17飞项小姐姐关注
  {
    exact: true,
    path: "/wx/sister",
    component: WeixinSister,
    key: "wx_sister",
    verifyLogin: false,
    title: "飞项",
  },
  {
    exact: true,
    path: "/wx/sister/bind",
    component: WeixinSisterBind,
    key: "wx_sister",
    verifyLogin: false,
    title: "绑定飞项账号",
  },
  {
    exact: true,
    path: "/wx/code/custombind",
    component: BindWxCodeToPhone,
    key: "wx_sister",
    verifyLogin: false,
    title: "绑定账号",
  },
];

// {
//   exact: true,
//   path: "/bind-user",
//   component: BindUserPage,
//   key: "bind-user",
//   verifyLogin: true,
//   title: "账号绑定",
// },
// {
//   exact: true,
//   path: "/file-msg/:id",
//   component: FileMsg,
//   key: "file-msg",
//   verifyLogin: true,
//   title: "文件详情",
// },
// {
//   exact: true,
//   path: "/detail/:id",
//   component: Detail,
//   key: "detail",
//   verifyLogin: true,
//   title: "事项详情",
// },
// {
//   exact: true,
//   path: `/invite/task_ans_project`,
//   component: AnserLayoutProject,
//   key: "task_ans_project",
//   verifyLogin: false,
//   children: [],
//   title: "项目邀请页面 - 您的操作结果",
// },

// import BindUserPage from "views/bind-user/index";
// import NotFound from "views/not-found";
// import Agreement from "pages/agreement/Agreement";
// import FileMsg from "views/file-msg";
// import Detail from "views/detail";

// import AnserLayoutProject from "../pages/InviteTask/AnserLayoutProject";

// import TestPage02 from "pages/TestPage/TestPage02";
// import TestPage03 from "pages/TestPage/TestPage03";
