import Http from "service/http";
import { AxiosRequestConfig } from "axios";
import { Hosts } from "./const";
import * as LoginType from "./types/login";

class Login extends Http {
  constructor() {
    super({ host: Hosts.user, version: "v2" });
  }

  beforeSend(request: AxiosRequestConfig): AxiosRequestConfig {
    console.log(this);

    return request;
  }

  getLoginKey(data: LoginType.ILoginKeyParams) {
    const p = this.post({
      // url: '/auth/pc',
      url: "/auth/scanlogin/code",
      data,
      timeout: 3000,
    });

    return this.resFilter<string>(p);
  }

  // 账号登录
  accountLogin(data: LoginType.IAccountLoginParams) {
    const p = this.post({
      url: '/auth/private/login',
      data,
    })

    return this.resFilter<LoginType.IUserInfo & { Token: string }>(p)
  }

  //海外版登录
  OverseasLogin(params: LoginType.IOverseasLogin) {
    const p = this.post({
      url: '/auth/haiwaibanyouxiangdenglujiekou',
      data: params,
    })

    return this.resFilter<any>(p)
  }

  //海外版注册
  OverseasCreateAccount(params: LoginType.IOverseasCreateAccount) {
    return this.post({
      url: '/auth/haiwaibanyouxiangzhucejiekou',
      data: params,
    })
  }

  // 海外版获取验证码
  OverseasGetCode(params: LoginType.IOverseasGetCode) {
    return this.post({
      url: '/auth/haiwaibanyouxiangfasongyoujianjiekou',
      data: params,
    })
  }

  // loginWatch(data: LoginType.IWatchLoginParams) {
  //   const p = this.post({
  //     url: '/auth/pc/watch',
  //     data,
  //     timeout: 300000,
  //   })

  //   return this.resFilter<any>(p)
  // }
}

export default new Login();
