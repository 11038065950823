import React, { useState } from 'react'
import style from './index.module.scss'
import classNames from 'classnames'

interface Props {
    text: string,
    className?: string,
    onClick?(): void
}
const Button: React.FC<Props> = (
    {
        text,
        className,
        onClick = () => {}
}) => {

    return (
        <div className={ classNames([style.btn], [className]) }
             onClick={ onClick }
        >
            { text }
        </div>
    )
}

export default Button
