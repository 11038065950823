import React from 'react'
import { ITask } from '@/service/types/matter'
import ShowCardBox from '../ShowCardBox'
import CardItemBox from '../CardItemBox'
import styles from './index.module.scss'
import {getDate_v15_validity_date} from "@/utils_for_pc/getDateTxt";

interface IProps {
  task: ITask
}

const SubTask = ({ task }: IProps) => {
  const { children } =  task

  if (!children || children.length === 0) return null

  const getDateText = (childrenTask: any) => {
    const dateValue = getDate_v15_validity_date(childrenTask)
    const dateText = `${dateValue.firstPartOutput} ${dateValue.secondPartOutput ? ` — ${dateValue.secondPartOutput}`: ''}`
    return dateText.trim() || '无时间'
  }


  return (
    <ShowCardBox
      title="子事项"
      height="432px"
      allText={children.length > 5 ? `全部 ${children.length} 个` : ''}
    >
      <>
        {children.map(item => (
          <CardItemBox
            key={item.task_id}
            title={item.title}
            icon="/assets/subTask.png"
          >
            <div className={styles.timeBox}>
              <div>{getDateText(item)}</div>
              <div className={styles.iconBox}>
                <img src="/assets/v1_5/task_v15/takers_3x.png" alt=""/>
                { item.taker_total }
              </div>
            </div>
          </CardItemBox>
        ))}
      </>
    </ShowCardBox>
  )
}

export default React.memo(SubTask)