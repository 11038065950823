import { config } from "./config";
import axios from 'axios'

// 当前环境的通用请求域名
var envBaseURL = config.ENV;

// 创建一个axios实例
var http = axios.create({
  baseURL: envBaseURL,
});

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    if (response.data.code !== 0) {
      return Promise.reject(response.data);
    }
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { http };
