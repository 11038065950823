import { getHrefByLocation } from "@/pages/forMobileInvite/utils";
// import { useHistory } from "react-router-dom";

const useBecomeInteracts = () => {
  // const history = useHistory();

  const jumpToRefuse = () => {
    // history.push({ pathname: "/refuse" });
  };

  const hrefSearch: any = getHrefByLocation(jumpToRefuse);

  const isLogined = () => {
    // console.log("hrefSearch", hrefSearch.u);

    if (!localStorage.getItem("fx-token")) {
      return false;
    }
    return true;
  };

  return {
    isLogined,
    hrefSearch,
  };
};

export default useBecomeInteracts;
