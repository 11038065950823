import Http from 'service/http'
import { Hosts } from './const'

class ServiceDate extends Http {
  constructor() {
    super({ host: Hosts.user })
  }

  async getCurrentDate() {
    const res = await this.get<any>({
      url: '/system/now',
    })

    return res.data
  }
}

export default new ServiceDate()
