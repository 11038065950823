

class Storage {

  static _token = ''
  static _uid = ''

  get token() {
    try {
      return Storage._token || localStorage.getItem('fx-token') || ''
    } catch (e) {
      return ''
    }

  }

  set token (val) {

    try {
      localStorage.setItem('fx-token', val)
      
      Storage._token = val

    } catch (e) {
      
    }
  }

  get uid() {
    try {
      return Storage._uid || localStorage.getItem('fx-user_id') || ''
    } catch (e) {
      return ''
    }

  }

  set uid (val) {

    try {
      localStorage.setItem('fx-user_id', val)
      
      Storage._uid = val

    } catch (e) {
      
    }
  }


  // static token = {

  //   get () {

  //     try {
  //       return Storage._token || localStorage.getItem('fx-token') || ''
  //     } catch (e) {
  //       return ''
  //     }
      
  //   },

  //   set (val: string) {

  //     try {
  //       localStorage.setItem('fx-token', val)
        
  //       Storage._token = val

  //     } catch (e) {
        
  //     }
  //   }
  // }

  // static uid = {

  //   get () {

  //     try {

  //       return Storage._uid || localStorage.getItem('fx-user_id') || ''

  //     } catch (e) {
  //       return ''
  //     }
      
  //   },

  //   set (val: string) {

  //      try {
  //       localStorage.setItem('fx-user_id', val)
        
  //       Storage._uid = val

  //     } catch (e) {
        
  //     }
      
  //   }
  // }
}

const storage = new Storage()

export {
  storage,
  Storage
}