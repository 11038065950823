import React, { useState } from "react";
import WxOpenBanner from "@/pages/PureH5/WxOpenBanner";
import styles from "./index.module.scss";
import useHrefSearch from "@/pages/PureHooks/useHrefSearch";

import MaskAlreadyOpenWechat from "@/pages/forMobileInvite/component/MaskAlreadyOpenWechat";

import config from "config";

const WeixinSister = () => {
  const hrefSearch: any = useHrefSearch();

  const wc_id = hrefSearch?.wc_id;
  const robot_id = hrefSearch?.robot_id;

  const path = `/pages/personal/pages/master-bind/index.html?w=${wc_id}&r=${robot_id}`;

  const [visibleMask, set_visibleMask] = useState(false);

  return (
    <div className={styles.wx_back}>
      <MaskAlreadyOpenWechat
        path={path}
        visible={visibleMask}
        set_visibleMask={set_visibleMask}
        mobileClick={() => {}}
      />

      <WxOpenBanner path={path} />
      <div className={styles.wx_back__inner}>
        <img src="/assets/v1_7/bind@3x.png" alt="" />
      </div>
      <div className={styles.wx_back__text}>绑定飞项账号，获取小姐姐能力</div>

      <div className={styles.wx_back__block_btn}>
        <div style={{ padding: "padding: 15px 0 14px 0" }}>
          {/* @ts-ignore*/}
          <wx-open-launch-weapp
            id="launch-btn"
            username={config.miniProgramUsername}
            path={path}
          >
            {/* @ts-ignore*/}
            <script type="text/wxtag-template">
              <span
                style={{
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                }}
              >
                立即绑定
              </span>
            </script>

            {/* @ts-ignore*/}
          </wx-open-launch-weapp>
        </div>
      </div>
    </div>
  );
};

export default WeixinSister;
