/**
 * 空间成员最大值限制
 * basic 9人
 * vip 无限制(免费9人)
 */
export enum SPACE_MEMBER_LIMIT {
  basic = 9,
  vip = 9999, // 专业版无限()
}

/**
 * 空间等级
 */
export enum SPACE_LEVEL {
  basic = 1,
  vip = 2,
}

/**
 * 空间类型
 */
export enum SPACE_TYPE {
  team = 1, // 团队空间
  private = 2, // 个人空间
}

/**
 * 空间状态
 */
export enum SPACE_STATE {
  normal = 1, // 正常
  dissolve = 2, // 解散
  disable = 3, // 禁用(待激活)
}

// 团队空间推荐弹窗ID
export const TeamRecommendModalId = 'project_space_recommend'

// 创建空间弹窗ID
export const CreateSpaceModalId = 'create_space_modal'

// (项目)移动到空间的弹窗ID
export const MoveToSpaceModalId = 'moveTo_space_modal'

// 空间数据节点 (在此时间节点之前的全都算老用户, 反之新用户)
export const SpaceOldUserCreateAt = 1651708800

// 记录团队空间推荐弹窗的 localstorage
export const SPACE_RECOMMEND_TEAM = 'SPACE_RECOMMEND_TEAM'

/**
 * (自创空间)创建数量限制
 */
export enum PRIVATE_SPACE_CREATE_LIMIT {
  basic = 3, // 基础版 3个自创
  vip = 10, // vip版本 10个自创
}

/**
 * 需要同步到全局space的参数!!!
 * 具体参数为 IBasicSpace 所有的键
 *
 *  icon: string // 空间icon
    icon_color: string // 空间icon颜色
    bg_icon: string // 背景图 keyname
    name: string // 空间名称
    workspace_id: string //空间ID
    ws_type: 1 | 2 // 1:团队空间  2:个人空间
    level: 1 | 2 // 空间类型 (1: 基础版  2: 专业版)
    creator_id?: string // 创建人Id
 */

export const NeedSyncToReduxOnSpace = [
  'icon',
  'icon_color',
  'bg_icon',
  'name',
  'workspace_id',
  'ws_type',
  'level',
  'creator_id',
]

/**
 * 空间成员类型
 */
export enum SpaceMemberType {
  SPACE = 1, // 空间成员
  EXTERNAL, // 外部成员
}

/**
 * 空间成员审批状态
 */
export enum SpaceApprovalState {
  UNTREATED = 1, // 未处理
  AGREE, // 同意
  REJECT, // 拒绝
}

/**
 * 空间成员身份 identify
 */
export enum SpaceMemberIdentify {
  CREATOR = 1, // 创建人
  ADMIN, // 管理员
  MEMBER, // 普通成员
}

/**
 * 空间类型
 */
export enum WorkspaceType {
  GROUP = 1, // 团队空间
  PERSONAGE // 个人空间
}

/**
 * 处理结果
 */
export enum DealResult {
  AGREE = 1, // 同意
  REJECT, // 拒绝
  CANCEL, // 撤销
}

export enum SpaceMemberStatusEnum {
  NOT_JOIN = 10901, // 空间未加入状态
  JOINED , // 空间已加入状态
  REFUSE , // 空间已拒绝状态
  REMOVE, // 空间已移除
}

/**
 *
 */
export enum PersonalState {
  NULL, //
  APPROVAL_PENDING, // 待审批
  PERSONAGE_REJECT, // 个人拒绝邀请
  PERSONAGE_ACCESS, // 个人接受邀请
  DELETE, // 被移除
}

export enum Status {
  default, // 没有点击同意、拒绝的情况
  wait,
  refuse,
  adminRefuse,
  join,
  fullMember
}

/**
 * 空间成员类型 中文
 */
export const SpaceMemberTypeLabel: {
  [key: number]: string
} = {
  [SpaceMemberType.SPACE]: '高级成员',
  [SpaceMemberType.EXTERNAL]: '普通成员',
}

/**
 * 空间成员审批状态 中文
 */
export const SpaceApprovalStateLabel: {
  [key: number]: string
} = {
  [SpaceApprovalState.UNTREATED]: '未处理',
  [SpaceApprovalState.AGREE]: '同意',
  [SpaceApprovalState.REJECT]: '拒绝',
}

/**
 * 空间成员身份 中文
 */
export const SpaceMemberIdentifyLabel: {
  [key: number]: string
} = {
  [SpaceMemberIdentify.CREATOR]: '创建人',
  [SpaceMemberIdentify.ADMIN]: '管理员',
  [SpaceMemberIdentify.MEMBER]: '普通成员',
}

/**
 * 空间成员身份 中文
 */
export const WorkspaceTypeLabel: {
  [key: number]: string
} = {
  [WorkspaceType.GROUP]: '团队空间',
  [WorkspaceType.PERSONAGE]: '个人空间',
}
