import React from "react";

import styles from "./index.module.scss";

const FooterBtnAction = () => {
  return (
    <div className={styles.pos}>
      <div className={styles.btn_list}>
        <div className={styles.btn_default}>拒绝</div>

        <div className={styles.btn_attend}>加入</div>
      </div>
    </div>
  );
};

export default FooterBtnAction;
