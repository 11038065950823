import Http from "service/http";
import { Hosts } from "./const";
import * as LoginType from "./types/login";

class LoginWithToken extends Http {
  constructor() {
    super({ host: Hosts.user, version: "v2" });
  }

  /**
   * 选择账号登录
   */
  getSelectLogin(params: LoginType.ISelectLogin) {
    return this.post({
      url: '/auth/select_login',
      data: { ...params },
    })
  }
}

export default new LoginWithToken();
