/* eslint-disable */
// @ts-nocheck
import { I18N, isCN } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";
import { Avatar } from "Component/Avatar/Avatar";

import Layout from "Layout/forH5Auto/Layout";
import { Divider } from "Component/Line/Divider";
import { BigThemeBtn } from "Component/Button";
import matterApi from "service/matter";
import userApi from "service/user";
import { SPACE_LEVEL } from '@/constants/space'

import { downloadBtnClick } from "pages/html5downloader/ts/download";

import "./AnserLayout.scss";
import {curProjectType, PROJECT_ENV} from "@/service/const";

const getPic = (typeName: string) => {
  switch (typeName) {
    case I18N.common.h5_youHaveBeen2:
      return "/assets/banner/banner_beremoved_hd.png";
    case I18N.common.theMatterHasBeenCancelled:
      return "/assets/banner/banner_canceled_hd.png";
    case I18N.common.h5_spaceForMattersToBeAddressed:
      return "/assets/banner/banner_canceled_hd.png";
    case I18N.common.h5_youHaveBeen:
      return "/assets/banner/meeting_beremoved_hd.png";
    case I18N.common.h5_theMeetingHasBeenCancelled:
      return "/assets/banner/meeting_canceled_hd.png";
    case I18N.common.h5_toDoYouHaveBeen:
      return "/assets/banner/taskwait_beremoved_hd.png";
    case I18N.common.h5_toDoCancelled:
      return "/assets/banner/taskwait_canceled_hd.png";
    default:
      return "/assets/banner/taskwait_canceled_hd.png";
  }
};

const AnserLayout = (props: any) => {
  const { history } = props;
  const [searchResult, setSearchResult] = useState(undefined);

  const [ansTitle, setAnsTitle] = useState(I18N.common.canceled);

  // 事项接口数据
  // const { set_task_id, task, task_dispatch } = useGetTask(history);

  const [ansResult, set_ansResult] = useState("");

  const [taskType, set_taskType] = useState("");

  const [task, set_task] = useState<any>({});
  
  const [errCode, setErrCode] = useState("");
  
  useEffect(() => {
    if (!searchResult) return;
    matterApi
      .getDetailIgnore(searchResult.id)
      .then((res: any) => {
        set_task(res.data.task);
        return res;
      })
      .catch(() => {});
    
    if (searchResult?.errCode) {
      setErrCode(searchResult?.errCode)
      setAnsTitle(I18N.common.unableToJoin)
    }

    if (searchResult && searchResult.maxTaker === '1') {
      setAnsTitle(I18N.common.unableToJoin);
    }
  
  }, [searchResult]);

  useEffect(() => {
    if (!task) return;
    
    switch (task?.matter_type) {
      case 10701:
        set_taskType(I18N.common.task);
        break;
      case 10702:
        set_taskType(I18N.common.meeting);
        break;
      case 10703:
        set_taskType(I18N.common.timeCollection);
        break;
      case 10704:
        set_taskType(I18N.common.announcement);
        break;
      case 10705:
        set_taskType(I18N.common.to_do);
        break;
      default:
    }
  
    const { max_taker_total, taker_total, workspace_id, level } = task
    if (errCode === '40011') {
      const isNormalSpace = workspace_id && level === SPACE_LEVEL.normal // 是否基础版空间
      let result =
        max_taker_total && max_taker_total === 20 ||
        taker_total && taker_total === 20 ?
          `${I18N.template(I18N.common.h5_numberOfCollaborators, { val1: 20 })}${isCN ? '。': '.'}${isNormalSpace ? I18N.common.h5_upgradeSpaceToIncreaseNumber : I18N.common.h5_creatorCanIncreaseTheNumber}` :
          `${I18N.template(I18N.common.h5_projectCollaborator, { val1: 100 })}`
      if (!max_taker_total && !(taker_total && taker_total === 20)) {
        result = I18N.common.h5_projectCollaboratorMax
      }
      set_ansResult(result)
    }
  
    if (task.workspace_state === 3) {
      setAnsTitle(I18N.common.h5_spaceToBeActivated)
      return;
    }
  
    if (task.project?.project_state === 10203) {
      setAnsTitle(I18N.common.h5_projectToBeActivated)
      return;
    }
  
    if (
      task?.creator_id === localStorage.getItem("fx-user_id") &&
      !localStorage.getItem("fx-user_id")
    ) {
      setAnsTitle(I18N.common.h5_youAreTheOneResponsibleForThisMatter);
      return;
    }
  }, [task, errCode]);

  useEffect(() => {
    // 从路由中截取入参参数
    if (history.location.search) {
      const searchs = history.location.search.replace("?", "").split("&");

      const result: any = {};

      // 把每个key:value塞入数据数组
      searchs.forEach((str: any) => {
        if (str) {
          const keyValue = str.split("=");

          if (keyValue.length === 2) {
            if (keyValue[0] && keyValue[1]) {
              const ValueOfKey: string = keyValue[0];
              const ValueOfName: string = keyValue[1];

              result[ValueOfKey] = ValueOfName;
            }
          }
        }
      });

      // search参数结果
      console.log("获取参数", result);
      setSearchResult(result);
    }
  }, [props]);

  const getTitleColor = (title: any) => {
    switch (title) {
      case I18N.common.joined: // '已加入':
        return "theme";
      case I18N.common.owner: // '已加入':
        return "theme";
      case I18N.common.inviter: // '已加入':
        return "theme";
      case I18N.common.leaveTaken: // '已请假':
        return "orange";
      case I18N.common.unableToJoin: // '无法加入':
        return "orange";
      case I18N.common.rejected: // '已拒绝':
        return "red";
      default:
        return "black";
    }
  };

  // const ansFrom = '来自陈希希的事项邀请'
  // const ansTaskName = '【Teambition竞品分析】'

  const [avatar_link, set_avatar_link] = useState("");

  useEffect(() => {
    userApi
      .avatar(localStorage.getItem("fx-user_id") || "")
      .then((res: any) => {
        set_avatar_link(res.data.avatar);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  
  return (
    <Layout>
      <div className="fx-layout-anser">
        <div
          className="header"
          style={{
            width:
              ansTitle === I18N.common.canceled ||
              ansTitle === I18N.common.h5_youHaveBeenRemoved ||
              ansTitle === I18N.common.h5_spaceToBeActivated ||
              ansTitle === I18N.common.h5_projectToBeActivated  ? 120 : 56,
          }}
        >
          {ansTitle === I18N.common.canceled ||
          ansTitle === I18N.common.h5_youHaveBeenRemoved ||
          ansTitle === I18N.common.h5_spaceToBeActivated ||
          ansTitle === I18N.common.h5_projectToBeActivated ? (
            <img
              src={getPic(`${taskType}${ansTitle}`)} // "/assets/banner/banner_beremoved_hd.png"
              alt=""
              style={{ width: 120 }}
            />
          ) : (
            <>
              {avatar_link && <Avatar size="large" src={avatar_link} />}
            </>
          )}

          {/* <Avatar size="large" src={avatar_link} /> */}
        </div>
        <div className="content">
          <div className="ans-title">
            <div className={getTitleColor(ansTitle ? ansTitle : "theme")}>
              {ansTitle === I18N.common.canceled && `${taskType}${ansTitle}`}
              {ansTitle === I18N.common.h5_youHaveBeenRemoved && `${ansTitle}${taskType}`}
              {ansTitle === I18N.common.inviter && I18N.template(I18N.common.h5_youAreTheOneWho2, { val1: taskType })}
              {ansTitle === I18N.common.owner && I18N.template(I18N.common.h5_youAreTheOneWho, { val1: taskType })}

              {ansTitle !== I18N.common.canceled &&
                ansTitle !== I18N.common.h5_youHaveBeenRemoved &&
                ansTitle !== I18N.common.inviter &&
                ansTitle !== I18N.common.owner &&
                ansTitle !== I18N.common.h5_spaceToBeActivated &&
                ansTitle !== I18N.common.h5_projectToBeActivated &&
                `${ansTitle}`}
            </div>
            {(ansTitle === I18N.common.h5_spaceToBeActivated || ansTitle === I18N.common.h5_projectToBeActivated) &&
              <div>
                <p>
                  {I18N.template(I18N.common.h5_affiliatedValue, { val1: ansTitle.slice(0, 2) })}
                </p>
                <p>
                  {I18N.template(I18N.common.h5_pleaseContactToActivate, {val1: ansTitle.slice(0, 2)})}
                </p>
              </div>
            }
          </div>
          {ansResult && <div className="ans-result">{ansResult}</div>}
          {ansTitle !== I18N.common.h5_linkExceptionPlease &&
          ansTitle !== I18N.common.h5_spaceToBeActivated &&
          ansTitle !== I18N.common.h5_projectToBeActivated &&
          (
            <>
              <div className="ans-from">
                {
                  I18N.template(I18N.common.h5_fromTas, {
                    val1: task?.nick_name || "加载中",
                    val2: taskType
                  })
                }
              </div>
              <div className="ans-task-name">
                {task?.title
                  ? `【${task?.title}】`
                  : I18N.common.h5_requestDataHas}
              </div>
            </>
          )}
        </div>
        {curProjectType === PROJECT_ENV.normal && (
          <>
            <Divider marginStyle={{ top: 48, bottom: 47 }} />
            <div className="ad">
              <div
                className="info"
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "inline-block",
                }}
              >
                {I18N.common.h5_moreDetails}
              </div>
              <div className="icon">
                <img src="/assets/icons/logo/logo.png" alt="logo" />
              </div>
              <div className="btn-download">
                <BigThemeBtn
                  title={<span style={{ padding: "0px 40px" }}>{I18N.common.h5_downloadNow}</span>}
                  colorType="theme"
                  onClick={downloadBtnClick}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AnserLayout;
