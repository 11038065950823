import React from 'react'
import styles from './index.module.scss'
import dayjs from "dayjs";
import { mapperParsedContent } from "@/utils/mapperParsedContent";
import { FileCard } from "Component/FileCard";

interface IProps {
  data: any[]
  taskId: string
}

/**
 * 判断一个字符串是否为json
 * @param str 需要判断的字符串
 */
function isJsonStr(str: unknown) {
  if (typeof str !== 'string') return false

  try {
    const tempObj = JSON.parse(str)
    return typeof tempObj === 'object'
  } catch (error) {
    return false
  }
  // return true
}

const ChatFrame = ({ data, taskId }: IProps) => {
  const conversion = (item: any) => {
    if (isJsonStr(item.content)) {
        const target = mapperParsedContent({ item })
        if (target.parsedContent) {
          const fileList: any[] = []
          for (const k in target.parsedContent) {
            fileList.push({
              fileId: k,
              id: k,
              name: target.parsedContent[k].file_name,
              size: target.parsedContent[k].file_size
            })
          }
          return fileList.map(file => (
            <FileCard key={file.fileId} info={file} task={{ task_id: taskId }} />
          ))
        }
      }

    // 去掉HTML标签
    return item.content.replaceAll(/<[^>]+>/g, '')
  }


  return (
    <div className={styles.chatFrameRoot}>
      { data.map(item => (
        <div className={styles.chatItem} key={item.comment_id}>
          <div className={styles.avatarBox}>
            <img src={item.avatar} alt="头像" />
          </div>
          <div className={styles.chatItemRight}>
            <div className={styles.nickNameBox}>
              <div className={styles.nickName}>{item.nick_name}</div>
              <div className={styles.chatDate}>{dayjs.unix(item.create_at).format("YYYY.M.D HH:mm")}</div>
              {item.important_at && <div className={styles.important}>重点</div>}
            </div>
            <div className={styles.chatContent}>
              { conversion(item) }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default React.memo(ChatFrame)