import { I18N } from '@flyele-nx/i18n'
import React from "react";
import { message } from "antd";
import userApi from "@/service/user";
import useBecomeInteracts from "./useBecomeInteracts";
import styles from "./BecomeInteracts.module.scss";
import sensors from "@/sensorsDataHook/initSensors";
import { SensorEvent } from "@/sensorsDataHook/const";
import { ChooseLoginType } from "@/components/login/choose-login-type";
import { QrcodeLogin } from "@/components/login/qrcode-login";
import { CorpQrcodeLogin } from "@/components/login/corp-wechat-login";
import { useQrcodeLogin } from "@/components/login/hooks/useQrcodeLogin";
import { useHistory } from "react-router-dom";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {AccountNumberModal} from "@/components/login/account-number-modal";
import {EmailLoginModal} from "@/components/login/email-login-modal";

const BecomeInteracts = (props: any) => {
  const { isLogined, hrefSearch } = useBecomeInteracts();
  const router = useHistory();

  const { history } = props;

  // 登陆弹窗
  const {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    onChoose,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
    onShowModal,
  } = useQrcodeLogin();

  const handleBecomeInteracts = () => {
    if (isLogined() && hrefSearch?.u) {
      userApi
        .addInteracts({
          user_id: hrefSearch?.u,
        })
        .then((res) => {
          console.log("优先处理", res);

          const userId =
            new URLSearchParams(window.location.search).get("u") ?? "";
          const businessId =
            new URLSearchParams(window.location.search).get("dispatch_at") ??
            "";

          const sensorsParams = {
            business_id: businessId,
            business_type: "协作人",
            // 邀请人ID
            invite_people_id: userId,
            // 邀请分享ID
            invitation_share_id: "",
            // 邀请分享类型
            invitation_share_type: "邀请链接",
          };

          console.log("埋点 click_invite_share 10月17日更新", sensorsParams);
          if (sensorsParams.business_id) {
            sensors.sendPoint(SensorEvent.click_invite_share, sensorsParams);
          }

          history.push({
            pathname: `/interacts/ans`,
            search: `?u=${hrefSearch?.u}&lang=${hrefSearch.lang}`,
          });
        })
        .catch((err) => {
          try {
            const response = err || {};

            message.error(response?.message || I18N.common.tokenError);
          } catch (e) {
            console.log(e);
          }

          localStorage.removeItem("fx-token");
          localStorage.removeItem("fx-user_id");
          onShowModal();
        });
    } else {
      onShowModal();
    }
  };

  const handleSuccees = () => {
    const userId = localStorage.getItem("fx-user_id");
    console.log("userId", userId, hrefSearch?.u);
    if (userId === hrefSearch?.u) {
      router.push("/interacts/my");
      return;
    }
    handleBecomeInteracts();
  };

  return (
    <>
      <ChooseLoginType visible={visible} onChoose={onChoose}>
        <div className={styles.btn} onClick={() => handleBecomeInteracts()}>
          {I18N.common.acceptInvitation}</div>
      </ChooseLoginType>

      <QrcodeLogin
        type={qrcodeType}
        visible={qrcodeVisible}
        onClose={() => setQrcodeVisible(false)}
        onSuccess={() => {
          setQrcodeVisible(false);
          handleSuccees();
        }}
      />

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={() => {
            setEmailVisible(false);
            handleSuccees();
          }}
        />
      )}

      {curProjectType !== PROJECT_ENV.normal && (
        <AccountNumberModal
          visible={accountVisible}
          onClose={() => setAccountVisible(false)}
          onSuccess={() => {
            setAccountVisible(false);
            handleSuccees();
          }}
        />
      )}

      {corpWechatVisible && (
        <CorpQrcodeLogin
          visible={corpWechatVisible}
          history={history}
          onSuccess={() => {
            setCorpWechatVisible(false);
            handleSuccees();
          }}
          onError={() => {
            setCorpWechatVisible(false);
          }}
        />
      )}
    </>
  );
};

export default BecomeInteracts;
