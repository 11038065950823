import React, {FC, useState} from 'react'
import styles from "./index.module.scss"
import {WindowType} from "@/pages/PureHooks/usePlatformCheck";
import WinOpenBanner from "@/pages/PureH5/WinOpenBanner";
import MaskModal from "@/pages/forMobileInvite/component/MaskModal";
import {getNowWindowType} from "@/pages/excel/hooks/utils";
import SmallTypeModal from "@/pages/forMobileInvite/component/MaskModal/smallType";
import cs from "classnames";

interface IProps {
  task: any
  inviteId?: string
}

const PageLayout: FC<IProps> = ({ children , task, inviteId = '' }) => {
  const nowWindowType = getNowWindowType()
  const [visibleMask, setVisibleMask] = useState(false);

  return (
    <div className={styles.pageRoot}>
      <div className={styles.mainRoot}>
        {
          nowWindowType === WindowType.PC浏览器 ?
            <WinOpenBanner /> :
            <>
              <MaskModal
                invtiter_id={inviteId}
                task={task}
                visible={visibleMask}
                set_visibleMask={setVisibleMask}
              />
              <SmallTypeModal
                invtiter_id={inviteId}
                task={task}
                visible={false}
                set_visibleMask={setVisibleMask}
              />
            </>

        }
        <div
          className={cs(styles.contentRoot, {[styles.mobileClass]: nowWindowType !== WindowType.PC浏览器})}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageLayout