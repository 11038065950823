import React from "react";
import { Modal } from "antd";
import { Wx2dCode } from "@/pages/Wx2dCode";
import { ModalInnerLayout } from "Component/Modal/ModalInnerLayout";


const _QrcodeLogin = ({
    visible, onSuccess, onClose, type = 'weChat'
}: {
  type?: string
  visible: boolean
  onSuccess: () => void
  onClose: () => void
}) => {
  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable
      onCancel={onClose}
      style={{ top: "20vh" }}
      destroyOnClose
      width="340px"
    >
      <ModalInnerLayout
        component={{
          titleBar: undefined,
          content: (
            <Wx2dCode
              type={type}
              onSuccess={onSuccess}
            />
          ),
          footer: null,
        }}
      />
    </Modal>
  )
}

export const QrcodeLogin = React.memo(_QrcodeLogin)