import * as React from "react";
import { useEffect } from "react";

enum QueryType {
  /** 准备登录 */
  prepareLogin = "prepareLogin",
  /** 登录成功 */
  loginSuccess = "loginSuccess",
}

const EnterpriseWechat: React.FC = () => {
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const type = search.get("type") as QueryType;

    if (type === QueryType.prepareLogin) {
      const url = new URL(
        "https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect"
      );

      url.searchParams.append("appid", "ww84f1bfad54b7281b");
      url.searchParams.append(
        "redirect_uri",
        encodeURI(
          `https://h5-test.flyele.vip/enterprisewechat?type=${QueryType.loginSuccess}`
        )
      );

      console.log("__url.href", url.href);
      location.href = url.href;
    } else if (type === QueryType.loginSuccess) {
      // 再获取  这里暂时不用通信，electron 那边会自动判断 导航的 url ,然后从 url 里面拿参数
      console.log([...search.entries()]);

      // const data = {
      //   auth_code: search.get("auth_code") ?? "",
      //   state: search.get("state") ?? "",
      // };

      // window.parent.postMessage(data, "*");

      // (2) ['type', 'loginSuccess']
      // (2) ['auth_code', 'ou3-lROkVSjK0FbwD4rfQ3RU9q8Kcnb1L3QrYmWbi_SMA_aZlx…AwcMsrjVZLJ7N8okIU0dxdkYtIw2xqjNp85EkI27gG2kQ-tew']
    } else {
      console.log("url_中没有 type 参数");
    }
  }, []);

  // const onClick = () => {
  //   window.parent.postMessage(
  //     {
  //       auth_code:
  //         "ou3-lROkVSjK0FbwD4rfQ3RU9q8Kcnb1L3QrYmWbi_SMA_aZlx…AwcMsrjVZLJ7N8okIU0dxdkYtIw2xqjNp85EkI27gG2kQ-tew",
  //       state: "fakeState",
  //     },
  //     "*"
  //   );
  // };

  return (
    <div className="enterprisewechat">
      {/* <button onClick={onClick}>test</button> */}
    </div>
  );
};

export default EnterpriseWechat;
