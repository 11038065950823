import React from "react";

import styles from "./index.module.scss";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {I18N} from "@flyele-nx/i18n";
import {downloadUrl} from "@/constants/url";

const WinOpenBanner = () => {
  const handleGoDownload = () => {
    location.href = downloadUrl;
  };

  if (curProjectType === PROJECT_ENV.pri_neiMongol) return null

  return (
    <div
      className={styles.win_open}
      style={{ backgroundImage: "url('/assets/v1_7/back_line@3x.png')" }}
    >
      <div className={styles.win_open__logo}>
        <img src="/assets/v1_7/wx_open/logo@3x.png" alt="" />
      </div>
      <div className={styles.win_open__text}>{`${I18N.common.flyele} ${I18N.common.slogan}`}</div>
      <div className={styles.win_open__btn} onClick={handleGoDownload}>
        {I18N.common.open}
      </div>
    </div>
  );
};

export default WinOpenBanner;
