import { domain } from "../service/const";
import { version } from "../../package.json";
import axios, { AxiosRequestConfig } from "axios";
// import { updateToken } from "reducers/user";
// import { store } from 'store'
import wasm, { sign_url, refresh_token } from "./clientsdk";

const signUrl = (url: string) => {
  try {
    console.log("inner sign", sign_url(url, new Date().getTime()));
  } catch (e) {
    console.log("inner sign error -->", e);
  }
  return sign_url(url, new Date().getTime());
};

const VERSION = "v2";

const refreshToken = () => {
  const token = refresh_token(
    `https://${domain}/userc/${VERSION}/auth/refresh`,
    new Date().getTime(),
    localStorage.getItem("fx-token")
    // store.getState().user.token
  );

  const http = axios.create({ responseType: "json" });

  http.interceptors.request.use((req: AxiosRequestConfig) => {
    req.headers.Authorization = localStorage.getItem("fx-token");
    // store.getState().user.token;

    return req;
  });

  http
    .post(token, { platform: "pc", client_version: version })
    .then((res) => {
      console.log("refreshing token", res.data.data.Token);
      // store.dispatch(updateToken(res.data.data.Token))
      localStorage.setItem("fx-token", res.data.data.Token);
    })
    .catch((e) => {
      console.log(e);
    });
};

async function init() {
  await wasm("/assets/clientsdk_bg.wasm");
}

export { signUrl, refreshToken, init };
