// 10701	事项
// 10702	会议
// 10703	时间征集
// 10704	公告
// 10705	待办
// 10706	
// 时间提醒
// 任务id：100000000000001
// 早计划晚总结
// 任务id：100000000000002
// 10707	
// 日历导入

// 事项|待办状态
// state
// 10301	事项已派发
// 10302	事项已完成
// 10303	事项已取消


import { I18N } from '@flyele-nx/i18n'
class TaskTool {

  /**
   * 事项已取消
   * @param data 
   * @returns 
   */
  static isTaskDismiss (data: any) {

    return data.state === 10303
  }

  /**
   * 待接受
   * @param data 
   * @returns 
   */
  static isUnjoined (data: any) {

    return data.personal_state === 10401
  }
  /**
   * 已接受/加入
   * @param data 
   * @returns 
   */
  static isJoined (data: any) {

    return data.personal_state === 10409
  }

  /**
   * 已拒绝
   * @param data 
   * @returns 
   */
  static isRefused (data: any) {

    return data.personal_state === 10403
  }

  static sortTakers (takers: any[]): any[] {

    const res = takers.reduce((acc, cur) => {

      if (TaskTool.isJoined(cur)) {
        Object.assign(cur, { key: 'joined', status: I18N.common.accepted })
        acc[0].push(cur)

      } else if (TaskTool.isRefused(cur)) {

        Object.assign(cur, { key: 'refused', status: I18N.common.rejected })
        acc[1].push(cur)

      } else if (TaskTool.isUnjoined(cur)) {

        Object.assign(cur, { key: 'unjoined', status: I18N.common.h5_toBeAccepted })
        acc[2].push(cur)
      } else {
        acc[3].push(cur)
      }

      return acc

    }, [[], [], [], []])

    const ret: any[] = [].concat.apply([], res)
    
    console.log('sortTakers', res, ret)
    return ret
  }

}

export default TaskTool
