import { domain } from "service/const";
import {getLocation, getOsInfo, getSearch2} from "./utils";

// 初始化 SDK
var sensors = window["sensorsDataAnalytic201505"];
sensors.init({
  sdk_url: "../assets/js/sensorsdata.min.js",
  name: "sensors",
  server_url: `https://datasink.flyele.net:8443/sa?project=${
    domain === "api.flyele.net" || domain === "api.flyele.com" ? "production" : "default"
  }`,
  cross_subdomain: false,
  heatmap: { scroll_notice_map: "not_collect" },
  show_log: false,
});

// 注册公共属性
sensors.registerPage({
  current_url: location.href,
  referrer: document.referrer,
});

// 异步载入
sensors.quick("autoTrack");

// 下载埋点方法
function downloadPoint(type) {
  var clickDownload;
  switch (type) {
    case "appStore":
      clickDownload = "APP store";
      break;
    case "openApp":
      clickDownload = "打开APP";
      break;
    case "goDownload":
      clickDownload = "进入下载流程";
      break;
    default:
  }
  if (clickDownload) {
    sensors.track("SMS_link_download", {
      click_download: clickDownload,
    });
  }
}

/**页面初始化埋点 **/
function loaded(){
  // 埋点 H5_landing_page_View
  const utm_source = getUtmSource();
  const first_traffic_source = getFirstTraffic();
  sensors.track('H5_landing_page_View', {
    $os: getOsInfo(),
    $url_path: window.location.href,
    $utm_source: getUtmSourceValue(utm_source),
    $first_traffic_source_type: getFirstTrafficValue(first_traffic_source),
    $utm_medium: getMedium(utm_source)
  });
}

/** 下载按钮 **/
function downLoad(){
  sensors.track('H5_landing_page_download_button_click', {
    $os: getOsInfo(),
  });
}

/** 离开页面埋点 **/
function pageLeave(time){
  sensors.track('H5_landing_page_Leave', {
    event_duration:time / 1000,
  });
}

/** 地理位置埋点 **/
function sensorsLocation(){
  /** 定位埋点 **/
  getLocation((longitude, latitude) => {
    sensors.track('H5_landing_location', {
      longitude,
      latitude
    });
  });
}


// 获取 utm_source  url上面 code
function getUtmSource() {
  return getSearch2()['utm_source'];
}

function getFirstTraffic() {
  return getSearch2()['first_traffic_source_type'];
}

function getUtmSourceValue(utm_source) {
  // 1：知乎问题:2：知乎专栏 3：知乎信息流 4：百度搜索 5：百度信息流
  const obj = { 1: '知乎问题', 2: '知乎专栏', 3: '知乎信息流', 4: '百度搜索', 5: '百度信息流' };
  return obj[utm_source];
}

function getFirstTrafficValue(first_traffic_source) {
  // 1:付费广告、 2：自然搜索、 3：社交网站、 4：直接流量、 5：引荐流量
  const obj = { 1: '付费广告', 2: '自然搜索', 3: '社交网站', 4: '直接流量', 5: '引荐流量' };
  return obj[first_traffic_source];
}

function getMedium(utm_source) {
  const zhihu = [1, 2, 3];
  const baidu = [4, 5];
  if (zhihu.includes(utm_source)) {
    return '知乎';
  }
  if (baidu.includes(utm_source)) {
    return '百度';
  }
  return '未知';
}



export { downloadPoint,loaded,downLoad,pageLeave,sensorsLocation };
