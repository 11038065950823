import { I18N } from '@flyele-nx/i18n'
import React from 'react';
import Layout from "Layout/forH5Auto/Layout";
import WinOpenBanner from "@/pages/PureH5/WinOpenBanner";
import styles from './index.module.scss'
import {BigThemeBtn} from "@/Component/Button";
import {useGetTarget} from "@/pages/InviteTarget/hooks/useGetTarget";
import targetCancelImg from '@/icons/targetCancel.png'
import targetBgIcon from '@/icons/targetBgIcon.png'
import {QrcodeLogin} from "@/components/login/qrcode-login";
import {CorpQrcodeLogin} from "@/components/login/corp-wechat-login";
import {useQrcodeLogin} from "@/components/login/hooks/useQrcodeLogin";
import {useMemoizedFn} from "ahooks";
import { ChooseLoginType } from "@/components/login/choose-login-type";
import {AccountError} from "@/components/account-error";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {AccountNumberModal} from "@/components/login/account-number-modal";
import {EmailLoginModal} from "@/components/login/email-login-modal";

type TProps = {
  history: History;
}

const InviteTarget = (props: TProps) => {
  const { history } = props;
  const {
    mySelf,
    targetInfo,
    inviter,
    isValidToken,
    isTargetCancelled,
    creatorIsVip,
    hasToken,
    joinToTarget,
    urlParams,
    showAccountError,
    showCanNotAdd,
  } = useGetTarget(history)

  // 登陆弹窗
  const {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    onChoose,
    setVisible,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
  } = useQrcodeLogin()

  /**
   * 打开二维码
   */
  const openQrcode = useMemoizedFn(() => {
    if (curProjectType === PROJECT_ENV.normal) {
      if (!isCN) {
        setEmailVisible(true)
        return
      }
      if (targetInfo?.type === 1) {
        setVisible(true);
      } else {
        if (urlParams?.corp_id) {
          setCorpWechatVisible(true)
        } else {
          setQrcodeVisible(true)
        }
      }
    } else {
      setAccountVisible(true)
    }
  })

  const onClickBtn = useMemoizedFn(async () => {
    // 假如没有token信息
    if (!hasToken()) {
      openQrcode();
      return
    }

    // 检查token是否有效 - 无效切入登陆
    if (!isValidToken) {
      openQrcode();
    } else {
      await joinToTarget()
    }
  })

  return (
    <Layout>
      <WinOpenBanner />

      <div className={styles.inviteTargetRoot}>
        {
          isTargetCancelled ? (
            <div className={styles.cancelRoot}>
              <div className={styles.cancelImg}>
                <img src={targetCancelImg} alt=""/>
              </div>
              <div className={styles.cancelText}>
                {I18N.common.targetHasBeenTaken}</div>
            </div>
          ) : (
            <>
              <div className={styles.bgHeader} />
              <div className={styles.bgIcon}>
                <img src={targetBgIcon} alt="bgIcon"/>
              </div>

              {showAccountError ? (
                <AccountError />
              ) : (
                <div className={styles.content}>
                  <div className={styles.title}>
                    {
                      showCanNotAdd ? (
                        <span className={styles['wait-text']}>{I18N.common.unableToJoin}</span>
                      ) : mySelf ?
                        mySelf.state === 1 ? (
                          <span className={styles['invite-text']}>{I18N.common.joinedTarget}</span>
                        ) : (
                          <span className={styles['wait-text']}>{I18N.common.afterAdministratorApproval}</span>
                        ) : creatorIsVip ? (
                          <>
                            <span className={styles['invite-text']}>{I18N.common.invite}</span>
                            <span>{I18N.common.youJoinedTheTarget}</span>
                          </>
                        ) : targetInfo?.type === 1 ? (
                          <span className={styles['wait-text']}>{I18N.common.targetCreatorNotVip}</span>
                        ) : (
                          <span className={styles['wait-text']}>{I18N.common.unableToJoin}</span>
                        )
                    }
                  </div>
                  <div className={styles.inviteInfo}>
                    <div className={styles.avatarBox}>
                      <img src={inviter.avatar} alt="avatar" />
                    </div>
                    <div className={styles.name}>{inviter.nick_name}</div>
                  </div>
                  <div className={styles.contentBlock}>
                    <div className={styles.contentTitle}>{`【${I18N.common.objectives}】`}</div>
                    <div className={styles.contentDesc}>
                      {targetInfo?.title}
                    </div>
                  </div>

                  <div className={styles.divider} />


                  <div className={styles.contentBlock}>
                    <div className={styles.contentTitle}>{I18N.common.executor}</div>
                    <div className={styles.contentDesc}>
                      <div className={styles.memberList}>
                        {
                          targetInfo?.objective_member?.map(item => {
                            return (
                              <img
                                alt=""
                                src={item.avatar}
                                key={item.user_id}
                                className={styles.avatar}
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!isValidToken && creatorIsVip && !showCanNotAdd && (
                <div className={styles.footer}>
                  <div className={styles.btn}>
                    <ChooseLoginType visible={visible} onChoose={onChoose}>
                      <BigThemeBtn
                        colorType="large"
                        customClass={styles.btnBox}
                        title={showAccountError ? I18N.common.changeAnAccountAndRepeat : I18N.common.immediatelyAfterLogin}
                        onClick={onClickBtn}
                      />
                    </ChooseLoginType>
                  </div>
                </div>
              )}
            </>
          )
        }
      </div>

      <QrcodeLogin
        type={qrcodeType}
        visible={qrcodeVisible}
        onClose={() => setQrcodeVisible(false)}
        onSuccess={async () => {
          setQrcodeVisible(false);
          await joinToTarget()
        }}
      />

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            await joinToTarget()
          }}
        />
      )}

      {curProjectType !== PROJECT_ENV.normal && (
        <AccountNumberModal
          visible={accountVisible}
          onClose={() => setAccountVisible(false)}
          onSuccess={async () => {
            setAccountVisible(false);
            await joinToTarget()
          }}
        />
      )}

      {corpWechatVisible && (
        <CorpQrcodeLogin
          visible={corpWechatVisible}
          history={history}
          onSuccess={async () => {
            setCorpWechatVisible(false);
            await joinToTarget()
          }}
          onError={() => {
            setCorpWechatVisible(false);
          }}
        />
      )}
    </Layout>
  )
}

export default InviteTarget