import { I18N, isCN } from '@flyele-nx/i18n'
import React from "react";
import { getDate_YYYYMMDD_HHmm } from "utils_for_pc/getDateTxt";
import styles from "./Header.module.scss";

const Header = ({ task, inviter_id, inviterName, inviterAvatar }: any) => {
  return (
    <div className={styles.header}>
      <div>
        <div className={styles.header__invite_info}>
          <span>
            <img
              src={inviterAvatar}
              alt=""
              style={{
                width: 32,
                height: 32,
                border: "1px solid #dfdfdf",
                borderRadius: "50%",
                marginRight: "10px",
                position: "relative",
                top: -2,
              }}
            />
          </span>

          {/** 邀请人名称 */}
          <span className="text-blod">
            {inviter_id ? inviterName : null}

            {/* {inviter_id && inviterName.length > 6
              ? inviterName.slice(0, 6).concat("...")
              : inviterName} */}
          </span>

          {/** 事项创建时间 */}
          <span className="text-gray">
            {` `}{I18N.common.createdOn}{isCN ? '' : `: `}{getDate_YYYYMMDD_HHmm(task?.create_at)}
          </span>
        </div>
      </div>

      {(task?.parents || []).length > 0 && (
        <div className={styles.header__right_include}>
          <span className="text-gray">{`${I18N.common.parent_task}:`}</span>

          <span className="text-green">
            {task?.parents[task.parents.length - 1]?.title}
          </span>
        </div>
      )}
    </div>
  );
};

export default Header;
