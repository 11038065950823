import matterApi from "service/matter";

export default function useAuth(task_id: any, history: any, inviter_id: any) {
  // 检查是否有token
  function hasToken() {
    return !!localStorage.getItem("fx-token");
  }

  function check_task_dispatch(
    data_task_dispatch: any,
    firstTime_task_id: any = undefined
  ) {
    // 会议被取消，被移出
    if (data_task_dispatch && data_task_dispatch.operate_state) {
      if ([10609, 10607].includes(data_task_dispatch.operate_state)) {
        history.push({
          pathname: `/invite/task_ans`,
          search: `?result=canceled&id=${
            firstTime_task_id || task_id
          }&u=${inviter_id}`,
        });
        return true;
      }
      if ([10610, 10608].includes(data_task_dispatch.operate_state)) {
        history.push({
          pathname: `/invite/task_ans`,
          search: `?result=removed&id=${
            firstTime_task_id || task_id
          }&u=${inviter_id}`,
        });
        return true;
      }
    }

    if (data_task_dispatch && data_task_dispatch.personal_state) {
      if ([10611, 10409].includes(data_task_dispatch.personal_state)) {
        history.push({
          pathname: `/invite/task_ans`,
          search: `?result=attended&id=${
            firstTime_task_id || task_id
          }&u=${inviter_id}`,
        });
        return true;
      }
      if ([10403, 10603].includes(data_task_dispatch.personal_state)) {
        history.push({
          pathname: `/invite/task_ans`,
          search: `?result=refuse&id=${
            firstTime_task_id || task_id
          }&u=${inviter_id}`,
        });
        return true;
      }
      if ([10604].includes(data_task_dispatch.personal_state)) {
        history.push({
          pathname: `/invite/task_ans`,
          search: `?result=vacate&id=${
            firstTime_task_id || task_id
          }&u=${inviter_id}`,
        });
        return true;
      }
    }

    return false;
  }

  // 检查token是否有效
  function isValidToken(firstTime_task_id: any = undefined) {
    if (!localStorage.getItem("fx-token")) {
      return false;
    }

    return matterApi
      .getDetail(firstTime_task_id || task_id)
      .then((res: any) => {
        // 先拿dispath_id
        console.log("task_dispatch", res?.data.task_dispatch);

        if (check_task_dispatch(res?.data.task_dispatch, firstTime_task_id)) {
          return false;
        }

        return true;
      })
      .catch(() => {
        return false;
      });
  }

  return { hasToken, isValidToken };
}
