import React, { useEffect, useState } from "react";
import cs from "classnames";
import {getCircleTimeText, IRepeatConfig} from "./Loop";
import styles from "./ProjectDisplay.module.scss";
import {useMemoizedFn} from "ahooks";

type TProps = {
  start_time: number
  end_time: number
  create_at: number
  cycle: number;
  repeat_type: number;
  end_repeat_at: number;
  repeat_config?: IRepeatConfig // 循环设置
};

const RepeatDisplay = ({ cycle, start_time, end_time, create_at, repeat_type, end_repeat_at, repeat_config }: TProps) => {
  const [circleTimeText, setCircleTimeText] = useState<string>('')

  const getText = useMemoizedFn(async () => {
    const text = await getCircleTimeText({start_time, end_time, create_at, end_repeat_at, repeat_type, cycle, repeat_config})

    setCircleTimeText(text)
  })

    useEffect(() => {
      getText()
  }, [create_at, cycle, end_repeat_at, end_time, getText, repeat_config, repeat_type, start_time])

  return (
    <div className={styles["time-remind"]}>
      <div
        className={cs(styles["icon-time"], styles["fx-float-left"])}
        style={{ opacity: 1 }}
      >
        <img src="/assets/v1_5/repeat_3x.png" alt="" />
      </div>

      <div className={cs(styles["box-text"], styles["fx-float-left"])}>
        <span>{circleTimeText}</span>
      </div>
    </div>
  );
};

export default RepeatDisplay;
