
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cs from 'classnames'

import styles from './index.module.scss'
import { IMobileFormOutput, IMobileFormProps } from './main'
import Input from '../Input'
import { IInputProps } from '../Input/main'
import { INameFormOutput } from '../NameForm/main'
import { LoginStatus } from '../Button/main'

const mobileReg = /1[0-9]\d{9}$/
const MAX_LENGTH = 11

const MobileForm: ForwardRefRenderFunction<INameFormOutput, IMobileFormProps> = (props, ref) => {

  const {
    show,
    status,
    changeHandler,
  } = props

  const [input, setInput] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const inputRef = useRef(input)
  useEffect(() => {
    inputRef.current = input
  }, [input])

  // useEffect(() => {
  //   const mobile = '18620270129'
  //   setInput(mobile)
  //   changeHandler(true, mobile)
  // }, [])

  const validate: IMobileFormOutput['validate'] = (val) => {

    const pass = mobileReg.test(String(val || inputRef.current))
    
    // console.log('validate', val, inputRef.current, pass)
    return pass

  }

  const onChange: IInputProps['changeHandler'] = (val) => {

    val = val.trim().substring(0, MAX_LENGTH)

    setInput(val)

    const pass = validate(val)
    console.log('onChange', pass)

    // if (pass) {
    //   onPass()

    // }
    changeHandler(pass, val)
    setError(!pass)
  }

  const onBlur: IInputProps['blurHandler'] = () => {

    const pass = validate(inputRef.current)
    console.log('onblur', pass)

    setError(!pass)
  }

  const onClear = () => {
    setInput('')
    changeHandler(false, '')
  }

  useImperativeHandle(ref, () => ({
    input,
    validate,
  }), [input])

  return (
    <div className={cs(styles.form, {
      [styles['form--hidden']]: !show
    })}>

      <Input
        type="number"
        input={input}
        error={error}
        errTips={'输入正确的手机号'}
        placeholder={'输入手机号码'}
        overClass={styles.form__input}
        gap={16}
        readonly={status >= LoginStatus.beforeCheckCode}
        changeHandler={onChange}
        blurHandler={onBlur}
        clearHandler={onClear}
      />
    </div>
  )
}

export default forwardRef(MobileForm)
