import { useState } from "react";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import { isCN } from '@flyele-nx/i18n'

export const useQrcodeLogin = () => {
  const [visible, setVisible] = useState(false);
  const [qrcodeType, setQrcodeType] = useState<string>('')
  const [qrcodeVisible, setQrcodeVisible] = useState(false);
  const [corpWechatVisible, setCorpWechatVisible] = useState(false);
  const [accountVisible, setAccountVisible] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);

  const onShowModal = () => {
    if (!isCN) {
      setEmailVisible(true)
      return
    }
    if (curProjectType === PROJECT_ENV.normal) {
      setVisible(true)
    } else {
      setAccountVisible(true)
    }
  }

  const onChoose = (type: string) => {
    setVisible(false);
    setQrcodeType(type)
    switch (type) {
      case 'corp_weChat':
        // 企业微信登录
        if (corpWechatVisible) return
        if (qrcodeVisible) {
          setQrcodeVisible(false)
        }
        setCorpWechatVisible(true)
        break;
      case 'weChat':
        // 微信登录
        if (qrcodeVisible) return
        if (corpWechatVisible) {
          setCorpWechatVisible(false)
        }
        setQrcodeVisible(true)
        break;
      case 'flyele':
        // 飞项登录
        if (qrcodeVisible) return
        if (corpWechatVisible) {
          setCorpWechatVisible(false)
        }
        setQrcodeVisible(true)
        break;
      default:
        console.log('没有匹配');
    }
  }

  return {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    setVisible,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
    onChoose,
    onShowModal,
  }
}