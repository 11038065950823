import { I18N } from '@flyele-nx/i18n'
import React from "react";

import config from "config";

import { downloadBtnClick } from "./evokeApp";

import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
import { SensorEvent } from "sensorsDataHook/const";
// import { isMobileAns } from "sensorsDataHook/isMobile";

import "./index.scss";
import dayjs from "dayjs";

import styles from "./smallType.module.scss";
import { getHref, HrefReturn } from "../../utils";
import { useLocation } from "react-router-dom";
import {curProjectType, PROJECT_ENV} from "@/service/const";


const _getMiniProgramPath = (id: string, u: string, rid: string): string => {
  return `${config.invite}?taskId=${id}&from=wechat&from_user_id=${u}&shareFrom=2&rid=${rid || ''}`;
};

/**
 * 空间邀请页面
 */
const _getMiniProgramSpacePath = (id: string, u: string, dispatch_at: string,  hrefSearch: HrefReturn): string => {
  // return `/pages/task/pages/transfer-space/index?spaceId=${id}&shareForm=27&from_user_id=${u}&dispatch_at=${dispatch_at}`

  const {
    memberType,
    rid,
  } = hrefSearch

  return `/pages/task/pages/transfer-space/index?spaceId=${id}&shareForm=27&from_user_id=${u}&dispatch_at=${dispatch_at}&member_type=${memberType}&rid=${rid}`
};

/**
 * 项目邀请页面
 */
 const _getMiniProgramProjectPath = (id: string, u: string, dispatch_at: string, hrefSearch: HrefReturn): string => {
  // return ''
  const {
    memberType,
    rid,
  } = hrefSearch
  
  return `/pages/task/pages/transfer-project/index?projectId=${id}&shareForm=25&from_user_id=${u}&dispatch_at=${dispatch_at}&shareFrom=25&member_type=${memberType}&rid=${rid}`
}

type TProps = {
  invtiter_id: string;
  task: any;
  visible: boolean;
  set_visibleMask: React.Dispatch<React.SetStateAction<boolean>>;
  isSpace?: boolean // 是否空间
  isProject?: boolean // 是否项目
  dispatch_at?: string // 邀请时间
};

function isWeiXin() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1
}

const MaskModal = ({ invtiter_id, task, visible, set_visibleMask, isSpace, isProject, dispatch_at }: TProps) => {
  
  const location = useLocation()
  const hrefSearch: any = getHref({history: {location}});
  console.log('hrefSearch', hrefSearch)

  if (curProjectType === PROJECT_ENV.pri_neiMongol) return null

  return (
    <div>
      <div
        className="full-screen-no-mask"
        // style={{ display: visible ? "none" : "block" }}
        style={{
          display: visible ? "none" : "block",
        }}
      >
        <div
          // className="footer-modal-mobile"
          className={styles.top_ad}
          style={{
            backgroundImage: "url('/assets/v1_7/wx_open/back@3x.png')",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.top_ad_left}>
            <img src="/assets/v1_7/wx_open/logo@3x.png" alt="" />
          </div>

          <div className={styles.top_ad_middle}>
            {isWeiXin() ? I18N.common.inFlyeleMiniProgress : I18N.common.inFlyeleApp}
          </div>

          <div className={styles.top_ad_right}>
            {isWeiXin() && (
              <button>
                {/* @ts-ignore*/}
                <wx-open-launch-weapp
                  id="launch-btn"
                  username={config.miniProgramUsername}
                  path={
                    isProject 
                      ? _getMiniProgramProjectPath(task?.project_id, invtiter_id, dispatch_at || '', hrefSearch) 
                      : isSpace 
                        ? _getMiniProgramSpacePath(task?.workspace_id, invtiter_id, dispatch_at || '', hrefSearch) 
                        : _getMiniProgramPath(task?.task_id, invtiter_id, hrefSearch?.rid)
                  }
                >
                  <script type="text/wxtag-template">
                    {/* @ts-ignore*/}
                    {I18N.common.open}
                  </script>
                  {/* @ts-ignore*/}
                </wx-open-launch-weapp>
              </button>
            )}

            {!isWeiXin() && (
              <button
                type="button"
                className="ok"
                onClick={() => {
                  try {
                    sensors.sendPoint(SensorEvent.H5_open_click, {
                      business_type: getTypeCName(task?.matter_type),
                      business_id: task?.task_id,
                      invite_people_id: invtiter_id,
                      invite_time: dayjs().diff(
                        dayjs.unix(task?.create_at),
                        "second"
                      ),
                    });
                  } catch (e) {
                    console.log(e);
                  }

                  downloadBtnClick(task, invtiter_id, {
                    isProject,
                    isSpace,
                    ...hrefSearch
                  })
                }}
              >
                {I18N.common.open}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaskModal;
