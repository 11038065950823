import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useState, useMemo } from "react";
import { Modal, Input, message } from "antd";
// H5基础结构
import Layout from "Layout/forH5Auto/Layout";

import {
  getDate_v15_validity_date,
  // getDate_v15_remind_date,
  getDate_v17_remind_date,
} from "utils_for_pc/getDateTxt";

import OpenAppBtn from "@/pages/PureH5/FooterOpenBtn/OpenAppBtn";

import ChildProcess from "./components/child-process";
// 待办用的样式
import CapsoleTime from "pages/InviteMeeting/components/CapsoleTime";
import { OneCapsule } from "Component/Capsule/OneCapsule";
import { BigThemeBtn } from "Component/Button";
import { PrimaryBtn } from "Component/Button/PrimaryBtn";
import { ModalInnerLayout } from "Component/Modal/ModalInnerLayout";
import Takers from "ComponentRewrite/Takers/Takers";
import RepeatInfo from "pages/forMobileInvite/Task/component/RepeatInfo";
import useGetTask from "./hooks/useGetTask";
import useFileList from "./hooks/useFileList";
import useGetAddress from "./hooks/useGetAddress";
import useAuth from "./hooks/useAuth";
import { ChooseLoginType } from "@/components/login/choose-login-type";
import styles from "../InviteMeeting/index.module.scss";
// 神策埋点
import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
import { SensorEvent } from "sensorsDataHook/const";
import { isMobileAns } from "sensorsDataHook/isMobile";
import InfoLineV15, {
  InfoLineV15TypeName,
  InfoLineV15DisplayType,
} from "./components/InfoLineV15";
// v17
import Header from "./v17/Header";
import Detail from "./v17/Detail";
import WinOpenBanner from "@/pages/PureH5/WinOpenBanner";
import Summary from "./v17/Summary";
import useHrefSearch from "../PureHooks/useHrefSearch";
import InviteTipsPopup from '@/Component/InviteTipsPopup'
import useGetSpace from '@/pages/forMobileInvite/hooks/useGetSpace'
import {PersonalState, WorkspaceType} from "@/constants/space";
import { checkExpire } from "@/utils/checkExpire";
import {QrcodeLogin} from "@/components/login/qrcode-login";
import {CorpQrcodeLogin} from "@/components/login/corp-wechat-login";
import {useQrcodeLogin} from "@/components/login/hooks/useQrcodeLogin";
import {isMobile} from "@/pages/forMobileInvite/hooks/isMobile";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {AccountNumberModal} from "@/components/login/account-number-modal";
import FooterTextBtn from "@/pages/PureH5/FooterTextBtn";
import {useMemoizedFn} from "ahooks";
import {EmailLoginModal} from "@/components/login/email-login-modal";

const { TextArea } = Input;

type InviteState = '' | 'pending' | 'approval' | 'reject'

const InviteTask = (props: any) => {
  const { history } = props;

  const hrefSearch = useHrefSearch();

  const [refuseModalVisible, setRefuseModalVisible] = useState(false);

  // 二维码相关
  const {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    onChoose,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
    onShowModal,
  } = useQrcodeLogin()

  // 事项接口数据
  const {
    set_task_id,
    task,
    task_dispatch,
    goDispatch,
    takers,
    isValidToken,
    sendSensors
  } = useGetTask(history, {
    oSearch: hrefSearch
  });

  // 事项地址
  const [address, addressInput] = useGetAddress(task);

  // 文件列表
  const filesList = useFileList(task);

  // 权限
  const [inviter_id, set_inviter_id] = useState(undefined);
  const { hasToken } = useAuth(task.task_id, history, inviter_id);

  // 上一个操作
  // const [lastAction, setLastAction] = useState("");

  // 请假或者拒绝理由
  const [refuseReason, setRefuseReason] = useState("");
  
  const {
    spaceInfo,
    isOverSpaceMaxNum,
    memberDealState,
    getSpaceDetailInfo,
    getInviteState,
    getSpaceInfoFromProject,
  } = useGetSpace()
  // 邀请处理状态
  const [currentInviteState, setCurrentInviteState] = useState<InviteState>('')

  const beforeSetId = async (expireAt: string, id: string) => {
    const isExpired = await checkExpire(expireAt)

    if (isExpired) {
      // 跳转
      props.history.push({
        pathname: `/expiredPage`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      })
    } else {
      set_task_id(id);
    }
  }

  useEffect(() => {
    beforeSetId(hrefSearch.expire_at, hrefSearch.id)
  }, [hrefSearch.id, hrefSearch.expire_at]);

  const [firstTime, set_firstTime] = useState(true);

  useEffect(() => {
    if (!task?.task_id) return;

    if (firstTime) {
      set_firstTime(false);
    }

    if (task?.creator_id || !hrefSearch.u) {
      set_inviter_id(task?.creator_id);
    }
  
    const { workspace_id } = task.project || { workspace_id: '' }
  
    if (workspace_id) {
      getSpaceDetailInfo(workspace_id)
      if (localStorage.getItem("fx-token")) {
        getInviteState(workspace_id)
      }
    } else {
      const { project_id } = task
      project_id && getSpaceInfoFromProject(project_id)
    }
  
  }, [task]);

  const [needLogin, toggleNeedLogin] = useState(true)

  /** 提交拒绝 */
  async function JumpHrefAfterSubmit(type: string) {
    sendSensors()

    switch (type) {
      case "接受":
        // [神策埋点] 接受
        sensors.sendPoint(SensorEvent.accept_event, {
          business_id: task?.task_id,
          business_type: getTypeCName(task?.matter_type),
          invite_people_id: inviter_id,
          enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
          identity_type: "邀请链接",
          is_click_accept_button: true,
        });

        if (isMobile()) {
          props.history.push({
            pathname: '/feedback/matter',
            search: props.history.location.search,
          })
        } else {
          props.history.push({
            pathname: `/invite/task_ans`,
            search: props.history.location.search,
          });
        }
        break;
      case "拒绝":
        // 显示拒绝窗口
        if (
          await goDispatch(type, {
            reason:
              refuseReason && refuseReason !== "" ? refuseReason : undefined,
          })
        ) {
          // [神策埋点] 拒绝
          sensors.sendPoint(SensorEvent.refuse_event, {
            business_id: task?.task_id,
            business_type: getTypeCName(task?.matter_type),
            invite_people_id: inviter_id,
            enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
            identity_type: "邀请链接",
            is_click_refuse_button: true,
            is_fill_refuse_reason: !!(refuseReason && refuseReason !== ""),
          });

          props.history.push({
            pathname: `/invite/task_ans`,
            search: `?result=refuse&id=${task.task_id}&res=${refuseReason}&u=${inviter_id}`,
          });
        }

        break;
      default:
        console.log("错误的操作");
    }
  }

  /** 点击按钮操作内容 */
  const handleClick = useMemoizedFn(async (type: string) => {
    // 假如没有token信息
    if (!hasToken()) {
      onShowModal();
    }

    // 记录最近的上一个操作
    // setLastAction(type);

    // 检查token是否有效 - 无效切入登陆
    if (!(await isValidToken())) {
      onShowModal();
    } else {
      sendSensors()
      switch (type) {
        case "接受":
          // 提交接受
          if (await goDispatch(type)) {
            if (spaceInfo && spaceInfo.ws_type === WorkspaceType.GROUP && isOverSpaceMaxNum) break
            // 查询一下状态 看看是否需要审批
            if (task.project && task.project.workspace_id) {
              const res = await getInviteState(task.project.workspace_id)
              if (res?.personal_state === PersonalState.APPROVAL_PENDING) return
            }
            JumpHrefAfterSubmit(type);
          }
          break;
        case "拒绝":
          // 显示拒绝窗口
          setRefuseReason("");
          setRefuseModalVisible(true);
          break;
        default:
          console.log("错误的操作");
      }
    }
  })

  const inviterName = (
    takers.find((t: any) => t?.taker_id === inviter_id) || {
      nick_name: "",
    }
  ).nick_name;

  const inviterAvatar = (
    takers.find((t: any) => t?.taker_id === inviter_id) || {
      nick_name: "",
      avatar: "",
    }
  ).avatar;

  const inviteStateText = useMemo(() => {
    let text = ''
    switch (currentInviteState) {
      case 'pending':
        text = I18N.common.waitingForAdministrator
        break
      case 'approval':
        text = I18N.common.theAdministratorHasAlreadyContacted
        break
      case 'reject':
        text = I18N.common.administratorRejected
        break
      default:
        text = I18N.common.unableToJoin
        break
    }
    return text
  }, [currentInviteState])

  useEffect(() => {
    if (!hrefSearch.u) {
      if (task?.matter_type === 10701) {
        document.title = I18N.common.eventDetailsPage;
      } else {
        document.title = I18N.common.toDoDetailsPage;
      }
    }
  }, [task]);

  const Location = useMemoizedFn(
    () => (
      <span>
        <span>{address && `${address}`}</span>

        {address && addressInput && <span className="fx-split-char" />}

        {addressInput && `${addressInput}`}
      </span>
    )
  );

  useEffect(() => {
    if (memberDealState) {
      if (memberDealState.personal_state === PersonalState.APPROVAL_PENDING) {
        setCurrentInviteState('pending')
      }
    } else {

      if (hasToken()) {
        handleClick('接受')
      } else {
        toggleNeedLogin(true)
      }
    }
  }, [memberDealState])

  return (
    <Layout>
      <WinOpenBanner />

      <div className={styles.v17}>
        <div className={styles.v17_center}>
          {/** 邀请人 创建时间 */}
          {/** 所属事项 */}
          <Header
            task={task}
            inviter_id={inviter_id}
            inviterName={inviterName}
            inviterAvatar={inviterAvatar}
          />

          {/** 标题 */}
          <div className={styles.v17_center_content_title}>{task?.title}</div>

          {/** 详情 */}
          <Detail task={task} filesList={filesList} />

          {/** 总结 */}
          <Summary task_id={hrefSearch.id} takers={takers} />

          {task?.project ? (
            <InfoLineV15
              typeName={InfoLineV15TypeName.project}
              mode={InfoLineV15DisplayType.line01}
              info2_bottom_style={{ paddingLeft: 26 }}
            >
              <div style={{ marginBottom: 12 }}>
                {task?.project?.project_name}
              </div>
            </InfoLineV15>
          ) : null}

          {/** 待办的样式 */}
          {!hrefSearch?.u ? (
            <>
              <CapsoleTime typeName="时间区间" task={task} isShowCalandar />

              <CapsoleTime typeName="提醒时间" task={task} isShowCalandar />

              {curProjectType === PROJECT_ENV.normal && (
                <div className="task-time">
                  {(address || addressInput) && (
                    <>
                      <br />
                      <OneCapsule iconType="地址" title={<Location />} />
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div className={"timeline-for-invite"}>
                {/** 开始时间、结束时间 */}
                {task?.start_time || task?.end_time ? (
                  <InfoLineV15 typeName={InfoLineV15TypeName.time}>
                    <div
                      style={{
                        position: "relative",
                        top: 2,
                      }}
                    >
                      {getDate_v15_validity_date(task).output}
                    </div>
                  </InfoLineV15>
                ) : null}

                <InfoLineV15 typeName={InfoLineV15TypeName.remind}>
                  <div
                    style={{
                      position: "relative",
                      top: 2,
                    }}
                  >
                    {getDate_v17_remind_date(task) || I18N.common.doNotRemind}
                  </div>
                </InfoLineV15>

                {/** 地点 */}
                {task?.execute_addr && (address || addressInput) && curProjectType === PROJECT_ENV.normal && (
                  <InfoLineV15 typeName={InfoLineV15TypeName.pos}>
                    <span>{address}</span>
                    {address && addressInput && <span>{` | `}</span>}
                    <span>{addressInput}</span>
                  </InfoLineV15>
                )}
              </div>
            </>
          )}

          {task?.end_repeat_at && task?.repeat_type ? (
            <InfoLineV15
              typeName={InfoLineV15TypeName.loop}
              mode={InfoLineV15DisplayType.line01}
              info2_bottom_style={{ paddingLeft: 26 }}
            >
              <div style={{ marginBottom: 12 }}>
                <RepeatInfo
                  cycle={task?.cycle}
                  start_time={task?.start_time}
                  end_time={task?.end_time}
                  create_at={task?.create_at}
                  end_repeat_at={task?.end_repeat_at}
                  repeat_type={task?.repeat_type}
                  repeat_config={task?.repeat_config}
                />
              </div>
            </InfoLineV15>
          ) : null}

          {takers.length > 0 && (
            <InfoLineV15
              typeName={InfoLineV15TypeName.takers}
              mode={InfoLineV15DisplayType.line02}
              info2_bottom_style={{ paddingLeft: 26 }}
            >
              <div style={{ marginBottom: 12 }}>
                {takers.length > 0 && (
                  <Takers
                    typeName="v15优化事项样式"
                    envTypeName="事项"
                    userId={localStorage.getItem("fx-user_id")}
                    task={task}
                    takers={takers.filter(
                      (t: any) => t.identity !== 10804 && t.identity !== 10811
                    )}
                    disabledEdit
                  />
                )}
              </div>
            </InfoLineV15>
          )}

          {(task?.children || []).length > 0 && (
            <InfoLineV15
              typeName={InfoLineV15TypeName.child}
              mode={InfoLineV15DisplayType.line02}
            >
              <div className="child_process">
                <ChildProcess
                  task={task}
                  task_dispatch={task_dispatch}
                  needHandle={false}
                />
              </div>
            </InfoLineV15>
          )}
        </div>
        <InviteTipsPopup
          spaceInfo={spaceInfo}
          isOverSpaceMaxNum={isOverSpaceMaxNum}
          projectName={task?.project?.project_name || task?.project_name}
          isPc={true}
        />
      </div>

      {!hrefSearch.u || hrefSearch?.status === "accepted" ? (
        <div className={styles.btn_fixed}>
          {curProjectType === PROJECT_ENV.normal ? (
            <OpenAppBtn task={task} />
          ) : (
            <FooterTextBtn />
          )}
        </div>
      ) : (
        <div className={styles.v17_footer} style={{ display: "flex", justifyContent: "center" }}>
          {memberDealState !== null 
            ?
              (
                <div className={currentInviteState === 'pending' ? styles.waitBtn : styles.rejectBtn}>
                  {inviteStateText}
                </div>
              ) 
            : needLogin
              ? 
                (
                  <ChooseLoginType visible={visible} onChoose={onChoose}>
                    <BigThemeBtn
                      colorType="large"
                      title={I18N.common.immediatelyAfterLogin}
                      onClick={() => onShowModal()}
                    />
                  </ChooseLoginType>
                )
              :
                null
          }
        </div>
      )}

      <QrcodeLogin
        type={qrcodeType}
        visible={qrcodeVisible}
        onClose={() => setQrcodeVisible(false)}
        onSuccess={ async () => {
          setQrcodeVisible(false);
          await handleClick('接受');
          await isValidToken();
        }}
      />

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            await handleClick('接受');
            await isValidToken();
          }}
        />
      )}

      {curProjectType !== PROJECT_ENV.normal && (
        <AccountNumberModal
          visible={accountVisible}
          onClose={() => setAccountVisible(false)}
          onSuccess={async () => {
            setAccountVisible(false);
            await handleClick('接受');
            await isValidToken();
          }}
        />
      )}

      {corpWechatVisible && (
        <CorpQrcodeLogin
          visible={corpWechatVisible}
          history={history}
          onSuccess={async () => {
            setCorpWechatVisible(false);
            await handleClick('接受');
            await isValidToken();
          }}
          onError={() => {
            setCorpWechatVisible(false);
          }}
        />
      )}

      <Modal
        visible={refuseModalVisible}
        title={null}
        footer={null}
        closable={false}
        style={{ top: "20vh" }}
        width="420px"
      >
        <ModalInnerLayout
          component={{
            titleBar: undefined,
            content: (
              <div style={{ padding: "8px 8px 28px 8px" }}>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  placeholder="输入拒绝理由"
                  value={refuseReason}
                  onChange={(e) => {
                    if (e.target.value.toString().length > 30) {
                      message.info({
                        content: "理由不能超出30字",
                        key: "info",
                        duration: 1.5,
                      });
                    }
                    setRefuseReason(e.target.value.slice(0, 30));
                  }}
                />
              </div>
            ),
            footer: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PrimaryBtn
                  title={I18N.common.cancel}
                  onClick={() => setRefuseModalVisible(false)}
                  colorType="white"
                />
                <PrimaryBtn
                  title={I18N.common.confirm}
                  onClick={() => {
                    JumpHrefAfterSubmit("拒绝");
                    setRefuseModalVisible(false);
                  }}
                />
              </div>
            ),
          }}
        />
      </Modal>
    </Layout>
  );
};

export default InviteTask;
