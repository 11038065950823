import React from 'react';
import PageLayout from '../components/PageLayout'
import AvatarBox from '../components/AvatarBox'
import TaskTitleBox from '../components/TaskTitleBox'
import TaskBgInfo from '../components/TaskBgInfo'
import SummaryCard from '../components/SummaryCard'
import TaskInfoRow from '../components/TaskInfoRow'
import MemberList from '../components/MemberList'
import SubTask from '../components/SubTask'
import TodoTask from '../components/TodoTask'
import Sediment from '../components/Sediment'
import Importance from '../components/Importance'
import Communication from '../components/Communication'
import AssistProcess from '../components/AssistProcess'
import NoData from '../components/NoData'
import { useInit } from "@/pages/excel/hooks/fetchDetails";

const ExcelTask = () => {
  const { task, takers, errCode } = useInit()

  return (
    <PageLayout task={task} inviteId={task?.creator_id}>
      { task ? (
        <div>
          <AvatarBox avatar={task.avatar} name={task.nick_name} desc="给你分享了这件事~" />

          <TaskTitleBox title={task.title} create_at={task.create_at} name={task.nick_name} />

          <TaskBgInfo task={task} />

          <SummaryCard taskId={task.task_id} takers={takers} />

          <TaskInfoRow task={task} />

          <MemberList takers={takers} />

          <SubTask task={task} />

          <TodoTask task={task} />

          <Sediment taskId={task.task_id} />

          <Importance taskId={task.task_id} />

          <Communication taskId={task.task_id} />

          <AssistProcess taskId={task.task_id} />
        </div>
        ) : (
          <NoData code={errCode} />
      )}
    </PageLayout>
  )
}

export default ExcelTask