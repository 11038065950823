import React from 'react';
import styles from './index.module.scss'

interface IProps {
  avatar: string
  name: string
  desc: string
}

const AvatarBox = ({ avatar, name, desc }: IProps) => {
  return (
    <div className={styles.avatarBoxRoot}>
      <div className={styles.avatar}>
        <img src={avatar} alt=""/>
      </div>
      <div className={styles.userName}>{name}</div>
      <div className={styles.desc}>{desc}</div>
    </div>
  )
}

export default AvatarBox