import { domain, Domain } from "service/const";

interface ConfigInterface {
  invite: string;
  filePath: string;
  //
  bindSister: string;
  //
  miniProgramUsername: string;
  getAppId(): string;
  official: { [prop: string]: string };
}

// const appIdDict = {
//   release: "wx3c0ab106efd630ea",
//   // release: "wxfca9e569e0d02bb2",
//   prod: "wx2edc8ed2729bdddf",
//   // prod: "wx47498aca992164df",
// };

const appIdDict = {
  [Domain.dev]: "wx3c0ab106efd630ea",
  [Domain.test]: "wx3c0ab106efd630ea",
  [Domain.prod]: "wx2edc8ed2729bdddf",
  [Domain.prodEn]: "wx2edc8ed2729bdddf",
  [Domain.pre_prod]: "wx2edc8ed2729bdddf",
  [Domain.test_neiMongol]: "wx3c0ab106efd630ea",
  [Domain.prod_neiMongol]: "wx2edc8ed2729bdddf",
};

// const miniProgramUsernameDict = {
//   release: "gh_929a79b05aa9",
//   prod: "gh_3c646b70ad18",
// };

const miniProgramUsernameDict = {
  [Domain.dev]: "gh_929a79b05aa9",
  [Domain.test]: "gh_929a79b05aa9",
  [Domain.prod]: "gh_3c646b70ad18",
  [Domain.prodEn]: "gh_3c646b70ad18",
  [Domain.pre_prod]: "gh_3c646b70ad18",
  [Domain.test_neiMongol]: "gh_929a79b05aa9",
  [Domain.prod_neiMongol]: "gh_3c646b70ad18",
};
/*
飞项小助手-公众号 AppID wx3c0ab106efd630ea
 微信公众号 APPID wx2edc8ed2729bdddf
 */
const config: ConfigInterface = {
  invite: "/pages/task/pages/tranfer/index.html",
  filePath: "/pages/personal/pages/my-document/index.html",
  // 新
  // * 打开小程序绑定
  bindSister: "#",
  //
  miniProgramUsername: miniProgramUsernameDict[domain],
  // miniProgramUsernameDict[process.env.service],
  getAppId() {
    // return appIdDict[process.env.service];
    return appIdDict[domain];
  },
  /**
   * 关注公众号用的，已经用不上了
   */
  official: {
    release:
      "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg3NjUyMzMzOA==&scene=124#wechat_redirect",
    prod: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU3MTUyNzcwMg%3D%3D&scene=110#wechat_redirect",
  },
};

export default config;
