import { I18N } from '@flyele-nx/i18n'
import React, {useCallback, useEffect, useMemo, useState} from "react";

// import useGetTask from "../../InviteTask/hooks/useGetTask";
// import { getHref } from "../utils";
import useGetTask from "../hooks/useGetTask";
import useFileList from "../hooks/useFileList";
import useAuth from "../hooks/useAuth";

import useGetAddress from "pages/InviteTask/hooks/useGetAddress";

import OpenAppBtn from "@/pages/PureH5/FooterOpenBtn/OpenAppBtn";

import Summary from "pages/InviteTask/v17/Summary";

// //Capsule
import CapsoleTime from "pages/InviteMeeting/components/CapsoleTime";
import {OneCapsule} from "Component/Capsule/OneCapsule";

import AvatarTitle from "../component/AvatarTitle";
import ParentBread from "../component/ParentBread";
import Title from "../component/Title";
import BackGround from "../component/BackGround";
import FileLists from "../component/FileLists";
import DividerLine from "../component/DividerLine";
import TimeRange from "../component/TimeRange";
import TimeRemind from "../component/TimeRemind";
import ChildProcess from "../component/ChildProcess";
import Address from "../component/Address";
import Takers from "ComponentRewrite/Takers/Takers";

import FooterBtnAction from "./FooterBtnAction";

import MaskModal from "../component/MaskModal";
import SmallTypeModal from "../component/MaskModal/smallType";

import styles from "./index.module.scss";
import useHrefSearch from "@/pages/PureHooks/useHrefSearch";
import ProjectDisplay from "./component/ProjectDisplay";
import RepeatDisplay from "./component/RepeatDisplay";
// 神策埋点
// import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
// import { SensorEvent } from "sensorsDataHook/const";
import useGetSpace from "@/pages/forMobileInvite/hooks/useGetSpace";
import {checkExpire} from "@/utils/checkExpire";
import usePlatformCheck, {WindowType} from "@/pages/PureHooks/usePlatformCheck";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import FooterTextBtn from "@/pages/PureH5/FooterTextBtn";
import {useMemoizedFn} from "ahooks";

type TProps = {
  history: any;
};

const defaultInviter = { avatar: "#", nick_name: I18N.common.loading, taker_id: "" };

const InvitePageH5Task = (props: TProps) => {
  const { history } = props;
  const { nowWindowType } = usePlatformCheck();

  // 权限
  const [inviter, set_inviter] = useState(defaultInviter);
  const [visibleMask, set_visibleMask] = useState(false);

  const { spaceInfo, isOverSpaceMaxNum, getSpaceDetailInfo } = useGetSpace();

  // 事项接口数据
  const {
    set_task_id,
    task,
    // task_dispatch,
    goDispatch,
    takers,
    isValidToken,
    sendSensors,
  } = useGetTask(history);

  const hrefSearch: any = useHrefSearch();

  const beforeSetId = useMemoizedFn(async (expireAt: string, id: string) => {
    const isExpired = await checkExpire(expireAt)

    if (isExpired) {
      // 跳转
      props.history.push({
        pathname: `/expiredPage`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      })
    } else {
      set_task_id(id);
    }
  })
  
  useEffect(() => {
    const { workspace_id } = task.project || { workspace_id: "" };

    beforeSetId(hrefSearch?.expire_at, hrefSearch?.id)

    if (task?.creator_id) {
      set_inviter(
        takers.find((t: any) => t?.taker_id === task?.creator_id) ||
          defaultInviter
      );
    }

    if (workspace_id) {
      getSpaceDetailInfo(workspace_id);
    }
  }, [beforeSetId, getSpaceDetailInfo, hrefSearch?.expire_at, hrefSearch?.id, takers, task]);

  // 文件列表
  const filesList = useFileList(task);

  const { hasToken } = useAuth(task.task_id, history, inviter?.taker_id);

  // 事项地址
  const [address, addressInput] = useGetAddress(task);

  const Location = useCallback(
    () => (
      <span>
        <span>{address && `${address}`}</span>

        {address && addressInput && (
          <span className={styles["fx-split-char"]} />
        )}

        {addressInput && `${addressInput}`}
      </span>
    ),
    [address, addressInput, task]
  );

  const isMeeting = useMemo(() => {
    return task.matter_type === 10702
  }, [task.matter_type])

  return (
    <div className={styles["h5-pc-invite"]}>
      {
        nowWindowType !== WindowType.企业微信浏览器 && (
          <>
            <MaskModal
              invtiter_id={inviter?.taker_id}
              task={task}
              visible={visibleMask}
              set_visibleMask={set_visibleMask}
            />

            <SmallTypeModal
              invtiter_id={inviter?.taker_id}
              task={task}
              // visible={visibleMask}
              visible={false}
              set_visibleMask={set_visibleMask}
            />
          </>
        )
      }
      <div
        className={styles["inner"]}
        style={
          nowWindowType === WindowType.企业微信浏览器 ||
          curProjectType === PROJECT_ENV.pri_neiMongol ?
            { paddingTop: '35px' } :
            {}
        }
      >
        <AvatarTitle
          task={task}
          avatarSrc={inviter?.avatar}
          nickName={inviter?.nick_name}
        />

        <div className={styles["white-content"]}>
          {(task?.parents || []).length > 0 && (
            <ParentBread list={task?.parents || []} />
          )}

          <Title title={task?.title} />
          <BackGround detail={task?.detail} />
          <FileLists filesList={filesList} task={task} />

          {/** 总结 */}
          <Summary task_id={hrefSearch.id} takers={takers} />

          {/** 项目 */}
          {task?.project ? (
            <>
              <DividerLine />
              <ProjectDisplay project_name={task?.project?.project_name} />
            </>
          ) : null}

          {/** 循环 */}
          {task?.end_repeat_at && task?.repeat_type ? (
            <>
              <DividerLine />
              <RepeatDisplay
                repeat_type={task?.repeat_type || 0}
                end_repeat_at={task?.end_repeat_at}
                cycle={task?.cycle}
                start_time={task?.start_time}
                end_time={task?.end_time}
                create_at={task?.create_at}
                repeat_config={task?.repeat_config}
              />
            </>
          ) : null}

          {/** 待办的样式 */}
          {!hrefSearch?.u ? (
            <>
              {task?.start_time || task?.end_time ? (
                <CapsoleTime typeName="时间区间" task={task} isShowCalandar />
              ) : null}

              <CapsoleTime typeName="提醒时间" task={task} isShowCalandar />

              {curProjectType === PROJECT_ENV.normal && (
                <div className="task-time">
                  {(address || addressInput) && (
                    <>
                      <br />
                      <OneCapsule iconType="地址" title={<Location />} />
                    </>
                  )}
                </div>
              )}

              {takers.length > 1 && (
                <>
                  <DividerLine />
                  <Takers envTypeName={isMeeting ? '会议' : '事项'} takers={takers} />
                </>
              )}
            </>
          ) : (
            <>
              {task?.start_time || task?.end_time ? (
                <>
                  <DividerLine />
                  <TimeRange task={task} />
                </>
              ) : null}

              {task?.remind_at && (
                <>
                  <DividerLine />
                  <TimeRemind task={task} />
                </>
              )}

              {(address || addressInput) && curProjectType === PROJECT_ENV.normal && (
                <>
                  <DividerLine />
                  <Address Location={Location} />
                </>
              )}

              {takers.length > 1 && (
                <>
                  <DividerLine />
                  <Takers envTypeName={isMeeting ? '会议' : '事项'} takers={takers} />
                </>
              )}
            </>
          )}
        </div>

        {(task?.children || []).length > 0 && (
          <div className={styles["white-content"]}>
            <ChildProcess list={task?.children || []} />
          </div>
        )}

        {/** 假如是新邀请，则显示接受按钮 */}
        {/** 否则显示打开按钮 */}
        {!hrefSearch?.u || hrefSearch?.status === "accepted" ? (
          <div className={styles.btn_fixed}>
            {curProjectType === PROJECT_ENV.normal ? (
              <OpenAppBtn task={task} />
            ) : (
              <FooterTextBtn />
            )}
          </div>
        ) : (
          <div className={styles["btn-list"]}>
            <FooterBtnAction
              isValidToken={isValidToken}
              task={task}
              goDispatch={goDispatch}
              inviter_id={inviter?.taker_id}
              history={history}
              hasToken={hasToken}
              spaceInfo={spaceInfo}
              isOverSpaceMaxNum={isOverSpaceMaxNum}
              sendSensors={sendSensors}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InvitePageH5Task;
