import { GetObjKeyOfType } from 'typings/index'

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'
import { Hosts, domain } from './const'

// type K = keyof Event
// function dataIsType(data: EventSource | null): data is EventSource {
//   if (data?.onmessage) return true
//   return false
// }

class SSe {
  source: EventSource

  static composeParams(params?: { [p: string]: any }) {
    if (!params) return ''
    let str = ''

    for (const [key, value] of Object.entries(params)) {
      str += `${key}=${value}&`
    }
    return str.substring(0, str.length - 1)
  }

  constructor(url: string, params?: { [p: string]: any }) {
    const EventSource = NativeEventSource || EventSourcePolyfill

    this.source = new EventSource(
      `https://${domain}${Hosts.intime}${url}?${SSe.composeParams(params)}`,
      { withCredentials: false }
    )
  }

  decodeData(data: string) {
    console.log(this)

    return data
  }

  commonMessage() {
    // listener: GetObjKeyOfType<EventSource, 'onmessage'>
    console.log(this)

    return (event01: MessageEvent) => {
      const { data } = event01

      console.log('返回的消息', data)
    }
  }

  onmessage(listener: GetObjKeyOfType<EventSource, 'onmessage'>) {
    this.source.onmessage = (event) => {
      const nv: typeof event = { ...event, data: this.decodeData(event.data) }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      listener.bind(this.source)(nv)
    }
  }

  addEventListener(
    event: string,
    listener: (event: Event & { data: any }) => void
  ) {
    console.log(this)
    this.source.addEventListener(
      event,
      (event02: Event & { [p: string]: any }) => {
        console.log(event)
        if (typeof listener === 'function') {
          try {
            const data = JSON.parse(event02.data)

            // const keys = Reflect.ownKeys(event)
            // const nv: { [p: string]: any } = {}
            //
            // for (const key of keys) {
            //   nv[key as K] = event[key as K]
            // }
            // TODO event有太多不可枚举属性，后面再加上
            listener({ ...event02, data: JSON.parse(data.message) })
          } catch (err) {
            console.log(err)
          }
        }
      }
    )
  }
}

export default SSe
