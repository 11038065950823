import {useState, useEffect, RefObject, useRef, useCallback} from "react";
import { IProjectInfo, IProjectMember } from "service/types/project";
import projectApi from "service/project";
import {usePlatformCheck, WindowType} from "@/pages/PureHooks/usePlatformCheck";
import {isMobile} from "@/pages/forMobileInvite/hooks/isMobile";
import { storage } from "@/utils/storage";
import { IFooterBtnExport } from "../Task/main";
import {History} from 'history'
import { checkSpaceDismiss } from "@/utils/join-tools";
import ProjectTool from "@/pages/Feedback/tools/project";
import sensors from "@/sensorsDataHook/initSensors";
import {SensorEvent} from "@/sensorsDataHook/const";
import {checkExpire} from "@/utils/checkExpire";
import {SPACE_LEVEL, WorkspaceType} from "@/constants/space";

const getHref = (history: History) => {
  // 当前的路径参数
  const { search }: any = history.location

  // 抽出参数
  const searchs = search.replace("?", "").split("&");

  console.log(searchs);
  const result: any = {};

  searchs.forEach((str: any) => {
    if (str) {
      const keyValue = str.split("=");

      if (keyValue.length === 2) {
        if (keyValue[0] && keyValue[1]) {
          const ValueOfKey: string = keyValue[0];
          const ValueOfName: string = keyValue[1];

          result[ValueOfKey] = ValueOfName;
        }
      }
    }
  });

  return result;
}

export enum DisplayEnum {
  loading = 0, // 加载
  offLine = 1, // 断线
  normal = 10201, // 正常 对话页 里面一堆东西
  cancel = 10202, // 解散
  invite = 10301, // 邀请
  join = 10302, // 已加入
  refuse = 10303, // 拒绝
  remove = 10304, // 移出页
}

type IParams = {
  footerRef?: RefObject<IFooterBtnExport>
  oSearch?: ISearch
}

const useProjectDetail = (history: any, extra?: IParams) => {
  const {
    footerRef,
    oSearch
  } = extra || {}

  useEffect(() => {
    document.title = "项目邀请";
  }, []);

  // 事项id
  const [project_id, set_project_id] = useState(oSearch?.id || '');

  // 参与人
  const [takers, set_takers] = useState([]);
  const takersRef = useRef(takers)
  const sendSensorsLock = useRef<boolean>(false)
  // 显示账号错误的样式
  const [showAccountError, setShowAccountError] = useState(false)
  // 显示待审批
  const [approvalPending, setApprovalPending] = useState(false)

  useEffect(() => {
    takersRef.current = takers
  }, [takers])

  // 事项
  const [task, set_task] = useState<any>(
    // 事项
    {
      task_id: "",
      title: "错误的邀请链接",
      detail: "未找到该事项，请确认链接是否完整",
      start_time: undefined, // 开始时间
      end_time: undefined, // 结束时间
      remind_at: {
        start_remind: undefined, // 开始提醒
        end_remind: undefined, // 结束提醒
      },
      execute_addr: "",
      files: [],
      matter_type: 10708,
    } as any
  );
  const taskRef = useRef(task)
  useEffect(() => {
    taskRef.current = task
  }, [task])

  const { nowWindowType } = usePlatformCheck();
  const nowWindowTypeRef = useRef<WindowType>(nowWindowType)

  useEffect(() => {
    nowWindowTypeRef.current = nowWindowType
  }, [nowWindowType])

  // 检查token是否有效
  function isValidToken() {
    if (!localStorage.getItem("fx-token")) return false;

    // return true;

    return new Promise((resolve, reject) => {
      projectApi
        .getMembers(project_id)
        .then((res: any) => {
          console.log("成员人", res);

          if (res.code === 0) {
            const _data: Array<any> = res.data;
            // 判断状态显示不同
            set_takers(
              _data.map((t: any) => {
                return { ...t, taker_id: t.user_id };
              }) as any
            );

            resolve(res);
            return;
          }

          // reject(false);
          resolve(false);
        })
        .catch(() => {
          localStorage.removeItem("fx-token");
          localStorage.removeItem("fx-user_id");
          setApprovalPending(false)
          // reject(false);
          resolve(false);
        });
    });
  }

  async function getDetailMain() {
    return projectApi
      .getProjectInfo(project_id)
      .then((res: any) => {
        if (res.code === 0) {
          const _data: IProjectInfo = res.data;
          // 判断状态显示不同
          const setData = {
            ..._data,
            title: _data?.project_name,
            detail: _data?.project_desc,
            // project_members: undefined,
            matter_type: 10708,
          }
          set_task(setData);

          set_takers(
            (_data?.project_members || []).map((t) => {
              return { ...t, taker_id: t.user_id };
            }) as any
          );

          if (_data?.project_members && _data.creator_id) {
            let goToAns = false
            const memberLength = _data?.project_members.filter((item) => item.state === 10302).length
            const creator = _data?.project_members.filter(item => item.user_id === _data.creator_id)
            if (creator.length) {
              const { ws_type, workspace_level } = _data
              const { vip_type } = creator[0]
              if (ws_type === WorkspaceType.GROUP) {
                goToAns = workspace_level === SPACE_LEVEL.basic && memberLength >= 20
              } else {
                if (vip_type === 0) {
                  if (memberLength >= 20) {
                    goToAns = true
                  }
                } else {
                  if (memberLength >= 100) {
                    goToAns = true
                  }
                }
              }

              if (goToAns) {
                history.push({
                  pathname: `/invite/project_ans`,
                  search: `${history.location.search}`.includes("?id=")
                    ? `${history.location.search}&maxTaker=1`
                    : `?id=${history.location.search}&maxTaker=1`,
                });
              }
              console.log('vip_type', vip_type);
            }
          }

          if (isValidToken()) {
            sendSensors(setData)
          }
        } else if (res.code === 40047 && res.dbg_error === '空间待激活'){
          history.push({
            pathname: `/invite/project_ans`,
            search: `${history.location.search}`.includes("?id=")
              ? `${history.location.search}`
              : `?id=${history.location.search}`,
          });
        }

        return res
      })
      .catch((e) => {
        // console.log('getDetailMain_err', e)
        localStorage.removeItem("fx-token");
        localStorage.removeItem("fx-user_id");
        setApprovalPending(false)
        return e
      });
  }

  const checkSpecial = () => {
    const find = takersRef.current.find(
      (t: any) => t.user_id === localStorage.getItem("fx-user_id")
    ) as any;

    if (!find) return;

    if (
      find &&
      (find?.state === 10302 || // 已加入
        find?.state === 10303 || // 已拒绝
        find?.state === 10304) // 被移出
    ) {
      if (find.state === 10302 && find.notice_id && find.is_external_member) {
        // 待审批
        setApprovalPending(true)
      } else {
        history.push({
          pathname: `/invite/project_ans`,
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
      }
    }
  };

  const go_next = (actionType: string, resolve: any, reject: any) => {
    if (actionType === "接受") {
      projectApi
        .projectMemberState({ project_id, state: DisplayEnum.join })
        .then((res) => {
          if (res) {
            // context.projectStateController.notification(DisplayEnum.join);
            resolve(true);
          }
        });
    } else if (actionType === "拒绝") {
      projectApi
        .projectMemberState({ project_id, state: DisplayEnum.refuse })
        .then((res) => {
          if (res) {
            // context.projectStateController.notification(DisplayEnum.refuse);
            resolve(true);
          }
        })
        .catch(() => {
          reject(false);
        });
    }
  };

  const dispatch_state = (
    actionType: string,
    members: any,
    resolve?: any,
    reject?: any
  ) => {
    const inviteUserId = oSearch?.u

    // 先派发，后执行
    if (!inviteUserId) {
      reject?.(false);
      console.log('缺少邀请人id');
      return
    }
    projectApi
      .projectInviteMember(project_id, {
        invite_id: inviteUserId, // 邀请人id
        invite_type: "flyele", // 邀请方式
        [oSearch?.memberType === '2' ? 'external_user_id' : 'user_id']: [localStorage.getItem("fx-user_id") as string],
      })
      .then((res) => {
        if (res) {
          resolve?.(true);
          return;
        }
        reject?.(false);
      })
      .catch((e) => {
        console.log('看看错误 e', e);
        reject?.(false);
        if (e.code === 40011) {
          history.push({
            pathname: `/invite/project_ans`,
            search: `${history.location.search}`.includes("?id=")
              ? `${history.location.search}&maxTaker=1&errCode=${e.code}`
              : `?id=${history.location.search}&maxTaker=1&errCode=${e.code}`,
          })
        }
        if (e.code === 40006) {
          setShowAccountError(true)
          setApprovalPending(false)
          localStorage.removeItem("fx-token");
          localStorage.removeItem("fx-user_id");
        }
      });
  };

  const goDispatch = (actionType = "接受", options: any = {}) => {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem("fx-token")) {
        resolve(false);
      }

      checkSpecial();
      setShowAccountError(false)

      projectApi
        .getMembers(project_id)
        .then((res: any) => {
          if (res.code === 0) {
            const _data: IProjectInfo = res.data;
            // 判断状态显示不同
            dispatch_state(actionType, _data, resolve, reject);
            return;
          }

          reject(false);
        })
        .catch(() => {
          localStorage.removeItem("fx-token");
          localStorage.removeItem("fx-user_id");
          setApprovalPending(false)
          reject(false);
        });
    });
  };

  function toFeedbackPage () {
    if ([
      WindowType.微信浏览器,
      WindowType.手机浏览器
    ].includes(nowWindowTypeRef.current)) {

      history?.push({
        pathname: `/feedback/project`,
        search: history.location.search,
      })

    } else {

      history.push({
        pathname: `/invite/project_ans`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      })

    }

  }


  const beforeSetId = async (expireAt: string) => {
    const isExpired = await checkExpire(expireAt)

    if (isExpired) {
      // 跳转
      history.push({
        pathname: `/expiredPage`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      })
    } else {
      sendSensorsLock.current = false

      getDetailMain()
    }
  }

  useEffect(() => {
    if (!task) return;

    const { pathname }: any = history.location;

    if (task?.state === 10202 && !pathname.includes("/invite/project_ans")) {
      toFeedbackPage()
    }
    
    if (task?.state === 10203 && !pathname.includes("/invite/project_ans")) {
      toFeedbackPage()
    }

    if (task.state === 10201) {
      // return
      // 没有自己则自动加入
      const takers = (task as IProjectInfo).project_members

      if (!storage.uid) {
        return
      }

      const target = takers.find((item) => item.user_id === storage.uid)

      // 注意啊！！项目邀请你为外部成员，然后把对应的空间解散了，你再进来后需提示空间已解散
      if (checkIfSpaceDismiss()) {
        return
      }

      const accept = () => {
        dispatch_state('', '', async () => {
          if (isMobile()) {
            history.push({
              pathname: `/feedback/project`,
              search: history.location.search,
            })

          } else {
            const res = await projectApi.getProjectInfo(project_id)

            if (res.code === 0) {
              const project_members: IProjectMember[] = res.data.project_members || []
              const findTarget = project_members.find((item) => item.user_id === storage.uid)

              if (findTarget && findTarget.is_external_member && findTarget.notice_id) {
                setApprovalPending(true)
                await getDetailMain()
                return
              }
            }

            history.push({
              pathname: `/invite/project_ans`,
              search: `${history.location.search}`.includes("?id=")
                ? `${history.location.search}`
                : `?id=${history.location.search}`,
            });
          }
        })
      }

      console.log('checkIfSpaceDismiss', target, isMobile(), nowWindowTypeRef.current)

      try {
        if (!target) {
          console.log('自动加自己', task, storage.uid, storage.token)

          // 有登录状态则直接调接口接受，有没有其他办法知道token有无效，不然只能通过接口判断。。。
          if (isValidToken()) {
            accept()
          } else {
            footerRef?.current?.accept('接受')
          }
        } else {
          const isLogin = isValidToken()

          if (
            target.is_external_member &&
            !target.notice_id &&
            queryRef.current.memberType === '1' && 
            isLogin
          ) {
            // 之前是外部成员，现在要转为内部成员，就要重新走接受流程
            accept()
  
          } else if (
            ProjectTool.isRemoved(target) &&
            isLogin
          ) {
            // 被移出也可以重新加入
            accept()
            
          } else if (target.is_external_member && target.notice_id) {
            // 待审批
            setApprovalPending(true)
          } else {
            toFeedbackPage()
          }
        } 

      } catch (err) {
        console.error(err)
      }

    }

  }, [task]);

  const checkIfSpaceDismiss = () => {

    const isDismiss = checkSpaceDismiss({
      task: taskRef.current,
      history,
    })

    // 重点关注：项目邀请了你为外部成员，你还没点击之前把空间解散了，这时你才去点击，就直接提示空间已解散
    if (isDismiss) {

      if (isMobile()) {
        history.push({
          pathname: `/feedback/project`,
          search: history.location.search,
        })

      } else {
        history.push({
          pathname: `/invite/project_ans`,
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
      }

      return true
    }

    return false
  }

  const queryRef = useRef<ISearch>({} as ISearch)

  const sendSensors = useCallback((resData?: any) => {
    const rid = new URLSearchParams(window.location.search).get("rid") ?? "";
    const userId = new URLSearchParams(window.location.search).get("u") ?? "";

    const projectId = resData?.project_id || task?.project_id

    const sensorsParams = {
      business_id: projectId,
      business_type: '项目',
      // 邀请人ID
      invite_people_id: userId,
      // 邀请分享ID
      invitation_share_id: rid,
      // 邀请分享类型
      invitation_share_type: "邀请链接",
    };

    if (sensorsParams.business_id && !sendSensorsLock.current) {
      console.log('埋点 项目', sensorsParams);
      sendSensorsLock.current = true

      sensors.sendPoint(SensorEvent.click_invite_share, sensorsParams);
    }
  }, [task?.project_id])

  useEffect(() => {
    const oQuery  = getHref(history)

    queryRef.current = oQuery

    if (!project_id || project_id === "") return;

    beforeSetId(oQuery?.expire_at)

  }, [project_id, history])



  return {
    set_project_id,
    task,
    takers,
    nowWindowType,
    isValidToken,
    goDispatch,
    sendSensors,
    showAccountError,
    approvalPending
  };
};

export default useProjectDetail;
