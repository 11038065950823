import React from "react";

import config from "config";

import { downloadBtnClick } from "./evokeApp";

import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
import { SensorEvent } from "sensorsDataHook/const";
// import { isMobileAns } from "sensorsDataHook/isMobile";

import "./index.scss";
import dayjs from "dayjs";
import { getHref, getMiniProgramPath, getMiniProgramProjectPath, getMiniProgramSpacePath, HrefReturn } from "../../utils";
import { useLocation } from "react-router-dom";
import {curProjectType, PROJECT_ENV} from "@/service/const";

// const random = () => {
//   return parseInt('' + Math.random() * Math.pow(10, 10))
// }

const _getMiniProgramPath = (id: string, u: string): string => {
  return `${config.invite}?taskId=${id}&from=wechat&from_user_id=${u}&shareFrom=2`;
};

/**
 * 空间邀请页面
 */
const _getMiniProgramSpacePath = (id: string, u: string, dispatch_at: string,  hrefSearch: HrefReturn): string => {
  // return ''

  const {
    memberType,
    rid,
  } = hrefSearch

  return `/pages/task/pages/transfer-space/index?spaceId=${id}&shareForm=27&from_user_id=${u}&dispatch_at=${dispatch_at}&member_type=${memberType}&rid=${rid}`
};

/**
 * 项目邀请页面
 */
const _getMiniProgramProjectPath = (id: string, u: string, dispatch_at: string, hrefSearch: HrefReturn): string => {
  // return ''
  const {
    memberType,
    rid,
  } = hrefSearch
  
  return `/pages/task/pages/transfer-project/index?projectId=${id}&shareForm=25&from_user_id=${u}&dispatch_at=${dispatch_at}&shareFrom=25&member_type=${memberType}&rid=${rid}`
}

type TProps = {
  invtiter_id: string;
  task: any;
  visible: boolean;
  set_visibleMask: React.Dispatch<React.SetStateAction<boolean>>;
  isSpace?: boolean // 是否空间
  isProject?: boolean // 是否项目
  dispatch_at?: string // 邀请时间
};

function isWeiXin() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1
}

// 顶部app打开入口
const MaskModal = ({ invtiter_id, task, visible, set_visibleMask, isSpace, isProject, dispatch_at }: TProps) => {
  
  const location = useLocation()
  const hrefSearch: any = getHref({history: {location}});
  console.log('hrefSearch', hrefSearch)

  if (curProjectType === PROJECT_ENV.pri_neiMongol) return null

  return (
    <div>
      <div
        className="full-screen-mask"
        style={{ display: visible ? "block" : "none" }}
        onClick={() => {
          set_visibleMask(false);
        }}
      >
        <div
          className="footer-modal-mobile"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="line-a">
            <div className="pic">
              <img src="/image/logo.png" alt="" />
            </div>

            <div className="word">
              <div className="title">
                {isWeiXin() ? "使用飞项小程序打开" : "使用飞项App打开"}
              </div>
              <div className="desc">可查看更多详细信息</div>
            </div>
          </div>

          <div className="line-b">
            <button
              style={{fontSize:'large'}}
              type="button"
              className="no"
              onClick={() => {
                try {
                  sensors.sendPoint(SensorEvent.H5_cancel_click, {
                    business_type: getTypeCName(task?.matter_type),
                    business_id: task?.task_id,
                    invite_people_id: invtiter_id,
                    invite_time: dayjs().diff(
                      dayjs.unix(task?.create_at),
                      "second"
                    ),
                  });
                } catch (e) {
                  console.log(e);
                }
                set_visibleMask(false);
              }}
            >
              取消
            </button>

            {isWeiXin() && (
              <div className='open-box'>
                {/* @ts-ignore*/}
                <wx-open-launch-weapp
                  id="launch-btn"
                  username={config.miniProgramUsername}
                  path={
                    isProject 
                      ? getMiniProgramProjectPath(task?.project_id, invtiter_id, dispatch_at || '', hrefSearch) 
                      : isSpace 
                        ? getMiniProgramSpacePath(task?.workspace_id, invtiter_id, dispatch_at || '', hrefSearch) 
                        : getMiniProgramPath(task?.task_id, invtiter_id, hrefSearch.rid)
                  }
                >
                  <script type="text/wxtag-template">
                    {/* @ts-ignore*/}
                    <div
                      style={{
                        fontSize:'large',
                        width: 'inherit',
                        height:'inherit',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        color: "#fff",
                        border: "none",
                      }}
                      onClick={() => {
                        try {
                          sensors.sendPoint(SensorEvent.H5_open_click, {
                            business_type: getTypeCName(task?.matter_type),
                            business_id: task?.task_id,
                            invite_people_id: invtiter_id,
                            invite_time: dayjs().diff(
                              dayjs.unix(task?.create_at),
                              "second"
                            ),
                          });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      打开
                    </div>
                  </script>
                  {/* @ts-ignore*/}
                </wx-open-launch-weapp>
              </div>
            )}

            {!isWeiXin() && (
              <button
                type="button"
                className="ok"
                onClick={() => {
                  try {
                    sensors.sendPoint(SensorEvent.H5_open_click, {
                      business_type: getTypeCName(task?.matter_type),
                      business_id: task?.task_id,
                      invite_people_id: invtiter_id,
                      invite_time: dayjs().diff(
                        dayjs.unix(task?.create_at),
                        "second"
                      ),
                    });
                  } catch (e) {
                    console.log(e);
                  }

                  downloadBtnClick(task, invtiter_id, {
                    isProject,
                    isSpace,
                    ...hrefSearch
                  })
                }}
              >
                打开
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaskModal;
