import React from "react";
import cs from "classnames";

import styles from "../Task/index.module.scss";

type TProps = {
  Location: any;
  // task: any;
};

const Address = ({ Location }: TProps) => {
  return (
    <div className={styles["time-remind"]}>
      <div className={cs(styles["icon-time"], styles["fx-float-left"])}>
        <img
          src="/assets/small-tools/timeCapsule/pos_2x.png"
          // src="/assets/icons/position/pos@2x.png"
          alt=""
        />
      </div>

      <div className={cs(styles["box-text"], styles["fx-float-left"])}>
        <span>{Location()}</span>
      </div>
    </div>
  );
};

export default Address;
