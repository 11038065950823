import './config/lang'
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "store/index";

import "@babel/polyfill";
// import fundebug from 'fundebug-javascript'
// fundebug.apikey = '2b16ed6fe2aed4cd55d4be8fcb4e3502f4765d7610dff3f4a55cf4554c4a434b'

import { init as initWasm } from "wasm";

/* <React.StrictMode>
</React.StrictMode> */

initWasm().then(() =>
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
