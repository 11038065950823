import React from "react";
import DividerLine from "./DividerLine";

import { getDate_v15_validity_date } from "utils_for_pc/getDateTxt";

import styles from "../Task/index.module.scss";

type TProps = {
  list: Array<any>;
};

const Title = ({ list }: TProps) => {
  return (
    <div className={styles["child-process"]}>
      <div className={styles["title"]}>子事项</div>
      <DividerLine />
      {/* 之前下面是有个 只显示5个的代码， 但是 https://jira.flyele.vip/browse/WEB-99 这个bug 又说要显示全部 */}
      {list.map((item) => {
        return (
            <div className={styles["title-line"]} key={item.task_id}>
              {item?.title}
              {item?.start_time && (
                <div className={styles["time-spot"]}>
                  {item?.start_time &&
                    `${getDate_v15_validity_date(item)?.output}`.replace(
                      "添加时间",
                      ""
                    )}
                </div>
              )}
            </div>
        );
      })}
    </div>
  );
};

export default Title;
