import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosPromise,
} from "axios";
import ErrorCodes from "./code";
import { signUrl } from "wasm";
import qs from "qs";

import { VERSION, domain } from "./const";
import { getNavLang, getZone } from "@/utils_for_pc/timezone";

type HttpConfig = AxiosRequestConfig &
  Required<Pick<AxiosRequestConfig, "url">>;

type HttpConfigGet = Omit<HttpConfig, "data">;
type HttpConfigPost = Omit<HttpConfig, "params">;
type Response<T> = {
  code: ErrorCodes;
  data: T;
};

const baseConfig: AxiosRequestConfig = {
  // timeout: 1000,
  timeout: 30000,
  responseType: "json",
};

// TODO: 临时使用
// const WINDER_TOKEN =
//   // undefined
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MTYwMzUwODcsImlhdCI6MTYxMzYxMjE1OSwiaXNzIjoiKi5mbHllbGUubmV0IiwiVXNlcklEIjoiNTUxNDQwODMxNDgwMDE3IiwiVG9rZW4iOiIiLCJQbGF0Zm9ybSI6IndlY2hhdCIsIkNsaWVudFZlcnNpb24iOiJ3ZWNoYXQtbXAgMS4wLjAifQ.OG37q_a-cNZOUJV3IdD4pfgeGcQAuQa3UUMfpsemp-M";
// // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MTYwMzc5NDgsImlhdCI6MTYxMzYxNDc3MiwiaXNzIjoiKi5mbHllbGUubmV0IiwiVXNlcklEIjoiNTQyMzk1MTYxMTE2ODgxIiwiVG9rZW4iOiIiLCJQbGF0Zm9ybSI6IndlY2hhdCIsIkNsaWVudFZlcnNpb24iOiJ3ZWNoYXQtbXAgMS4wLjAifQ.Z1pwcoQwleOkXAonz5-FHyn_QL9_QFUJb-AJT3V9Wn0'

class Http {
  host: string;

  http: AxiosInstance;

  constructor(params: any, without_version = false) {
    const { host, version } = params;

    const httpInfo = domain === 'api-intranet.flyele.nm135.cn' ? 'http' : 'https'
    if (without_version) {
      this.host = `${httpInfo}://${domain}`;
      this.host += host ? `/${host}` : "";
    } else {
      this.host = `${httpInfo}://${domain}`;
      this.host += host
        ? `/${host}/${version || VERSION}`
        : `/${version || VERSION}`;
    }
    // this.host = `https://${domain}/${host}`

    this.http = axios.create({ ...baseConfig });

    this.http.interceptors.request.use((req: AxiosRequestConfig) => {
      return this.beforeSend(req);
    });

    this.http.interceptors.response.use(
      (res: AxiosResponse) => {
        return this.receive(res);
      },
      async (err) => {
        return Promise.reject(err?.response?.data || err)
      });
  }

  /**
   * 发起请求前的拦截器，可被子类重写
   * @param request 请求配置
   */
  beforeSend(request: AxiosRequestConfig) {
    // TODO:
    if (localStorage.getItem("fx-token")) {
      request.headers.Authorization = localStorage.getItem("fx-token");
    }

    request.headers['X-Auth-Timezone'] = getZone()

    request.headers['X-Auth-Language'] = getNavLang()

    request.headers['X-Auth-Area'] = (global as any).lang === 'zh-CN' ? 0 : 1

    const query = request.params ? `?${qs.stringify(request.params)}` : "";

    /**
     * 需要在没有token的情况下加密的接口
     */
    const needSignUrl = ['/auth/phonelogin/code'];
    const isUrlWillSign = needSignUrl.some(url => request.url?.includes(url));

    if (localStorage.getItem("fx-token") || isUrlWillSign) {
      request.url = signUrl(`${request.url}${query}`);
      
      delete request.params
    }

    console.log(this);
    return request;
  }

  receive(response: AxiosResponse) {
    console.log(this);
    return response;
  }

  async get<T>(config: HttpConfigGet): Promise<T> {
    const res = (await this.http.get<T>(`${this.host}${config.url}`, config))
      .data;

    return res;
  }

  resFilter<T>(p: AxiosPromise): Promise<Response<T>> {
    console.log(this);
    // 停用了eslint-disable-next-line compat/compat
    return new Promise<Response<T>>((resolve, reject) => {
      p.then((res) => {
        if (res.data.code === ErrorCodes.success) {
          resolve(res.data);
        }
        reject(res.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  post(config: HttpConfigPost): AxiosPromise {
    return this.http.post(`${this.host}${config.url}`, config.data);
  }

  put(config: HttpConfigPost): AxiosPromise {
    return this.http.put(`${this.host}${config.url}`, config.data);
  }

  patch(config: HttpConfigPost): AxiosPromise {
    return this.http.patch(`${this.host}${config.url}`, config.data);
  }

  delete(config: HttpConfigGet): AxiosPromise {
    return this.http.delete(`${this.host}${config.url}`);
  }
}

export default Http;
