import { IWatchLoginParams } from 'service/types/login'
// import { IGetQrCodeParams } from 'api/types/official'
// import os from 'os'
// import { remote } from 'electron'
// import si from 'systeminformation'

class Util {
  async getLoginKeyParams(): Promise<any> {
    console.log(this)
    // const data = await si.system()

    return {
      device: {
        // client_version: remote.app.getVersion(),
        client_version: '0.0.1',
        // os: os.platform(),
        os: 'html5',
        platform: 'web',
        // device_name: data.model,
        device_name: 'browser',
      },
    }
  }

  async getQrCodeParams(loginKey: string): Promise<any> {
    console.log(this)

    return {
      page: 'pages/login/index',
      scene: `k=${loginKey}`,
    }
  }

  async getLoginWatchParams(key: string): Promise<IWatchLoginParams> {
    const params = await this.getLoginKeyParams()

    return {
      device: params.device,
      login_key: key,
    }
  }
}

export default new Util()
