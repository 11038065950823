// 会议状态 state
// 10501	会议待开始
// 10502	会议进行中
// 10503	会议已完成
// 10504	会议已取消

// 会议参与人状态（state字段）
// 10602	会议待开始
// 10605	会议进行中
// 10606	会议已完成
// 10607	会议已确认取消
// 10608	会议已确认撤回

// 会议参与人个人状态（personal_state字段）
// 10601	会议待接受
// 10611	会议已接受
// 10603	会议已拒绝
// 10604	会议已请假

class MeetingTool {

  /**
   * 已取消
   * @param data 
   * @returns 
   */
  static isMeetingDismiss (data: any) {

    return data.state === 10504
  }

  /**
   * 已接受/加入
   * @param data 
   * @returns 
   */
  static isJoined (data: any) {

    return data.personal_state === 10611
  }

  /**
   * 已拒绝
   * @param data 
   * @returns 
   */
  static isRefused (data: any) {

    return data.personal_state === 10603
  }

  /**
   * 已请假
   * @param data 
   * @returns 
   */
  static isAskForLeave (data: any) {

    return data.personal_state === 10604
  }

  static sortTakers (takers: any[]): any[] {

    const res = takers.reduce((acc, cur) => {

      if (MeetingTool.isJoined(cur)) {
        Object.assign(cur, {key: 'joined',  status: '已接受' })
        acc[0].push(cur)
      } else if (MeetingTool.isRefused(cur)) {

        Object.assign(cur, {key: 'refused',  status: '已拒绝' })
        acc[1].push(cur)

      } else if (MeetingTool.isAskForLeave(cur)) {

        Object.assign(cur, {key: 'askForLeave',  status: '已请假' })
        acc[2].push(cur)

      } else if (cur.identity === 10802) {
        Object.assign(cur, {key: 'master',  status: '主持人' })
        acc[3].push(cur)
      } else {
        Object.assign(cur, {key: 'unjoined',  status: '未接受' })
        acc[4].push(cur)
      }

      return acc

    }, [[], [], [], [], []])

    const ret: any[] = [].concat.apply([], res)

    return ret
  }

}

export default MeetingTool