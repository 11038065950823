import Http from "./http";
import { Hosts } from "./const";
import { CommonResponse } from '@/service/types/http';
import {
  ISpace,
  ISpaceMemberDealState,
  SpaceDetailParams,
  SpaceConfigItem,
  UpdateMemberStatusParams,
  AddMemberInSpaceParams,
  SpaceMemberStatus,
} from './types/space'

const host = Hosts.flyele

class SpaceApi extends Http {
  constructor() {
    super({ host, version: "v2" });
  }
  
  /**
   * 获取空间详情 (附带协作人统计....)
   */
  getSpaceDetail(workspace_id: string, params?: SpaceDetailParams) {
    return this.get<CommonResponse<ISpace>>({
      url: `/workspace/${workspace_id}`,
      params,
    })
  }
  
  /**
   * 查询邀请处理状态
   */
  getMemberDealState(workspace_id: string) {
    return this.get<CommonResponse<ISpaceMemberDealState>>({
      url: `/workspace/${workspace_id}/member/deal/state`
    })
  }
  
  /**
   * 获取空间配置列表
   * @param resource_type 配置类型 1、头像 2、背景 3、头像颜色
   */
  getSpaceConfig<T extends '1' | '2' | '3'>(resource_type: T) {
    return this.get<CommonResponse<SpaceConfigItem<T>[]>>({
      url: '/workspace/resources',
      params: { resource_type },
    })
  }
  
  // 审批当前加入状态
  // 2.0版本后 默认接受了 不需要再
  updateSpaceMemberState(workspace_id: string, data: UpdateMemberStatusParams) {
    return this.post({
      url: `/workspace/${workspace_id}/member/state`,
      data,
    })
  }
  
  /**
   * 添加空间成员
   * @param workspace_id 空间ID
   * @param data 成员的信息
   */
  addMemberInSpace(workspace_id: string, data: AddMemberInSpaceParams) {
    return this.post({
      url: `/workspace/${workspace_id}/member`,
      data,
    })
  }
  
  /**
   * 获取空间成员状态信息
   * @param workspace_id 空间id
   * @param member_id    成员Id
   */
  getMemberState(workspace_id: string, member_id: string) {
    return this.get<CommonResponse<SpaceMemberStatus>>({
      url: `/workspace/${workspace_id}/member/${member_id}/state`,
    })
  }
}

export default new SpaceApi();
