import { useEffect, useState } from 'react'
import ProjectApi from 'service/project'
import { IProjectInfo } from "service/types/project";
import { SPACE_TYPE, SPACE_LEVEL } from "@/constants/space";

/** 默认检查事项的对象 */
export const DEFAULT_CHECK_TASK_PROJECT_RES = {
  /** 个人事项 */
  isPriviateMatter: false,
  /** 个人空间的个人项目 */
  isPrivateProject: false,
  /** 基础空间 */
  isSpaceBasic: false,
  /** 个人空间 */
  isSpacePrivate: false,
  /** 专业空间 */
  isSpaceVip: false,
  /** 项目创建人是否团队会员 */
  isProjectCreatorTeamVip: false,
  /** 项目创建人是否个人会员 */
  isProjectCreatorVip: false,
  /** 项目创建人是否我 */
  isProjectCreatorMyself: false,
}

/** 检查项目：
 * 是否个人事项
 * 是否个人空间的个人项目
 * 是否非个人空间的项目
 * 项目创建人是否是我
 * 项目创建人是否是会员
 */
export const checkProjectByApi = async <
  T extends Pick<
    IProjectInfo,
    | 'project_id'
    | 'project_members'
    | 'workspace_level'
    | 'creator_id'
    | 'workspace_id'
    >
  >(
  params: Partial<T>,
  myUserId: string
) => {
  const res = { ...DEFAULT_CHECK_TASK_PROJECT_RES }

  if (!params.project_id || params.project_id === '') {
    res.isPriviateMatter = true
    res.isProjectCreatorMyself = true
    return res
  }

  // const projectInfo = (await ProjectApi.getProjectInfo(params.project_id)).data
  let projectInfo: T

  if (
    params.project_id === undefined ||
    params.project_members === undefined ||
    params.workspace_level === undefined
  ) {
    projectInfo = ((await ProjectApi.getProjectInfo(params.project_id))
      .data as unknown) as T
  } else {
    projectInfo = params as T
  }

  // console.log('__projectInfo', projectInfo)

  if (
    // 上面请求的 有这个字段的
    ((projectInfo as unknown) as IProjectInfo).ws_type === SPACE_TYPE.private
  ) {
    res.isSpacePrivate = true
    res.isPrivateProject = true
  } else if (projectInfo.workspace_level === SPACE_LEVEL.basic) {
    res.isSpaceBasic = true
  } else if (projectInfo.workspace_level === SPACE_LEVEL.vip) {
    res.isSpaceVip = true
  }

  const projectCreator = projectInfo.project_members.find(
    (i) => i.user_id === projectInfo.creator_id
  )

  // 项目创建人应该肯定在 项目的 Member 里面吧？
  if (projectCreator) {
    if (projectCreator?.vip_type === 1) {
      res.isProjectCreatorVip = true
    } else if (projectCreator?.vip_type === 2) {
      res.isProjectCreatorTeamVip = true
    }

    res.isProjectCreatorMyself = myUserId === projectCreator.user_id
  }

  return res
}


export const useTaskProjectCheck = (
  ...args: Parameters<typeof checkProjectByApi>
) => {
  const [projectInfo, userId] = args

  const [checkRes, setCheckRes] = useState(DEFAULT_CHECK_TASK_PROJECT_RES)

  useEffect(() => {
    checkProjectByApi(projectInfo, userId).then(setCheckRes)
  }, [projectInfo, userId])

  return checkRes
}