import { useEffect, useState } from "react";

import { isImage } from "utils/file";
import storageApi from "service/storage";

const useFileList = (task: any) => {
  // 文件列表
  const [filesList, setFilesList] = useState([] as any[]);

  // 读取文件缩略图
  useEffect(() => {
    // 获取图片
    function getImgs(
      fs: any[] = [],
      fileList: any
    ): Promise<Array<string> | any> {
      // 假如没有图片，则不请求osc图片读取接口，直接渲染文件列表
      if (fs.length === 0) {
        setFilesList(fileList);
        return fileList;
      }

      return storageApi
        .getOnlineImage({
          ids: fs.map((f: any) => f.id),
          ossConfig: 'image/resize,m_fill,w_90,h_90',
          flyeleId: task?.task_id,
          grantor: '',
        })
        .then((res: any) => {
          const dict = res.data;
          const arr = task.files.map((f: any, index: number) => {
            if (dict[f.id]) {
              const newFile = {
                ...f,
                thumb: dict[f.id],
              };

              return newFile;
            }
            return fileList[index];
          });

          console.log("arr", arr);
          setFilesList(arr);
        })
        .catch(() => {});
    }

    // 接口的总列表
    const taskFileList = task.files || [];

    // 文件列表
    const fileList: any = [];

    // 图片列表
    const imageList: any[] = taskFileList.filter((file: any) => {
      if (isImage(file.name)) {
        fileList.push(undefined);
        return true;
      }
      fileList.push(file);
      return false;
    });

    console.log("pics", imageList);

    getImgs(imageList, fileList);
  }, [task]);

  return filesList;
};

export default useFileList;
