import Http from "./http";
import { AxiosPromise } from 'axios'
import { Hosts } from "./const";
import { ICorpAuthInfo, IAuthInfo, IUserCorpList } from './types/official.d'

const host = Hosts.official;

class Official extends Http {
  constructor() {
    super({ host, version: "v1" });
  }

  /**
   * 企业微信接入获取url
   */
  getAuthInfo(data: IAuthInfo): AxiosPromise<{ data: ICorpAuthInfo }> {
    return this.post({
      url: '/corp/auth_info',
      data,
    })
  }

  /**
   * 查询用户企业列表
   */
  getUserCorpList(): AxiosPromise<IUserCorpList> {
    return this.get({
      url: '/corp/user_corp/list'
    })
  }
}

export default new Official();

