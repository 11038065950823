import { ajax } from "./index";

// import { domain } from "service/const";

export const wechatAuthLogin = <T extends WechatAuthLoginReq, S>(data: T) => {
  return ajax<T, S>(
    "post",
    `/v1/official_account/wechat_auth_login`,
    {
      data,
      useToken: false,
    }
  );
};

export const getWxConfig = <T, S>(data: T) => {
  return ajax<T, S>(
    "get",
    `/v1/official_account/get_wechat_jssdk_config`,
    {
      data,
      useToken: false,
    }
  );
};

export const wechatCustomizeBind = <T extends WechatAuthLoginReq & { telephone: string }, S>(data: T) =>{
  return ajax<T, S>(
    "post",
    `/v1/official_account/customize/bind`,
    {
      data,
      useToken: false,
    }
  );
}
