import React from "react";
import styles from "./Layout.module.scss";

type Props = {
  children: any;
};

const Layout = ({ children }: Props) => {
  return (
    <div className={styles.body}>
      <div className={styles["html5-auto"]}>{children}</div>
    </div>
  );
};

export default Layout;
