import { HandleStatus } from 'type-define'

export const user = (state = { bind: HandleStatus.fail }, action: any) => {
    switch (action.type) {
        case 'bind':
            return {
                bind: HandleStatus.success
            }
        case 'unbind':
            return {
                bind: HandleStatus.fail
            }
    }

    return state
}
