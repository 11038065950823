import React from "react";
import { Modal } from "antd";
import AccountNumberLogin from "./components/AccountNumberLogin";
import { ModalInnerLayout } from "Component/Modal/ModalInnerLayout";


const _AccountNumberModal = ({
   visible, onSuccess, onClose
}: {
  visible: boolean
  onSuccess: () => void
  onClose: () => void
}) => {
  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable
      onCancel={onClose}
      style={{ top: "20vh" }}
      destroyOnClose
      width="340px"
    >
      <ModalInnerLayout
        component={{
          titleBar: undefined,
          content: (
            <AccountNumberLogin
              onSuccess={onSuccess}
            />
          ),
          footer: null,
        }}
      />
    </Modal>
  )
}

export const AccountNumberModal = React.memo(_AccountNumberModal)