enum Hosts {
  empty = "",
  intime = "",
  user = "userc",
  matter = "flyele",
  storage = "disk",
  notice = "notice",
  auth = "auth",
  ui = "ui",
  official = "official",
  flyele = "flyele",
}

enum Domain {
  dev = "api.flyele.vip",
  test = "api-test.flyele.vip",
  pre_prod = "api.pre.flyele.vip",
  prod = "api.flyele.net",
  prodEn = "api.flyele.com",
  test_neiMongol = 'api-test.nm10086.p.flyele.vip',
  prod_neiMongol = 'api-intranet.flyele.nm135.cn'
}

/**
 * 项目类型
 * normal — 公有
 * pri_neiMongol — 私有化内蒙古
 */
export enum PROJECT_ENV {
  normal = 'normal',
  pri_neiMongol = 'pri_neiMongol'
}

const VERSION = "v2";

enum MatterCat {
  "all",
  "unread",
  "dispatch",
  "nearest",
  "new",
  "unfinished",
}

enum MatterType {
  "事项" = 10701,
  "会议",
  "时间征集",
}

/**
 * 这里不要改动 // 特征点 前的空格数（要用END-DOMAIN和END-DOMAIN）
 * |
 * 中间不要多删除空格
 * |
 * 改动的话，可以直接在根目录运行
 * yarn to_dev
 * yarn to_test
 * yarn to_prod
 */

// START-DOMAIN
const domain: Domain = Domain.prod; // 特征点
// END-DOMAIN

// START-PROJECT
const curProjectType: PROJECT_ENV = PROJECT_ENV.normal; // 项目类型
// END-PROJECT

/**
 *  结束脚本作用区域
 */

export { VERSION, Hosts, Domain, MatterCat, MatterType, domain, curProjectType };
