import React from "react";

import _uniqueId from "lodash/uniqueId";

import { FileCard } from "Component/FileCard";

import styles from "../Task/index.module.scss";

type TProps = {
  filesList: Array<any>;
  task: any;
};

const FileLists: React.FC<TProps> = ({ filesList, task }: TProps) => {
  return (
    <div className={styles.files}>
      {filesList.map((file) => (
        <div key={_uniqueId()} className={styles.file}>
          <FileCard info={file} task={task} />
        </div>
      ))}
    </div>
  );
};

export default FileLists;
