import os from 'os'
import ps from '../../../../../../package.json'
import { uid } from './getUid'
import { IAccountLoginParams, IPCLoginKeyParams, IOverseasLogin } from "service/types/login.d";
import { getOsInfo } from 'pages/html5downloader/ts/utils';

class Util {
  async getLoginKeyParams(key: string): Promise<IPCLoginKeyParams> {
    return {
      device: {
        client_version: ps.version,
        os: os.platform(),
        platform: 'web',
        device_name: getOsInfo(),
        device_id: uid,
      },
      last_login_key: key,
    }
  }

  async getAccountLoginParams(
    account: string,
    password: string
  ): Promise<IAccountLoginParams> {
    return {
      account,
      password,
      device: (await this.getLoginKeyParams('')).device,
    }
  }

  async getEmailLoginParams(
    email: string,
    password?: string
  ): Promise<IOverseasLogin> {
    return {
      mima: password || '',
      youxiang: email,
      device: (await this.getLoginKeyParams('')).device,
    }
  }
}

export default new Util()
