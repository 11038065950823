import React, {useCallback, useEffect, useState} from 'react'
import styles from './index.module.scss'
import MatterApi from "@/service/matter";
import dayjs from "dayjs";
const AppendixIcon = "/assets/appendix.png";

interface IProps {
  taskId: string
  takers?: any[]
}

const SummaryCard = ({ taskId, takers = [] }: IProps) => {
  const [detail, setDetail] = useState<any>({ content: "", files: [] });

  const getDetail = useCallback(async () => {
    const { data } = await MatterApi.getTaskSummary(taskId);

    const updater = takers?.find(
      (item: any) => item.user_id === (data.updater_id || data.creator_id)
    );

    const _detail = {
      ...data,
      name: updater?.nick_name || updater?.original_name || "",
      date: dayjs
        .unix(data.update_at! || data.create_at!)
        .format("M月D日 HH:mm"),
    };

    setDetail(_detail);
  }, [takers, taskId]);

  useEffect(() => {
    taskId && takers.length > 0 && getDetail();
  }, [getDetail, takers, taskId]);

  if (!taskId || !detail || !detail.date || !detail.content) {
    return null;
  }

  return (
    <div className={styles.summaryCardRoot}>
      <div className={styles.colorBar} />
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div>{`${detail.updater_name} 更新于${detail.date}`}</div>
          <div className={styles.tag}>总结</div>
        </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              __html: detail.content
                .replace("↵/g", "\n")
                .replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </div>
      {detail.files && detail.files.length ? (
        <div className={styles.footer}>
          <img alt="" src={AppendixIcon} />
          <span>{`${detail.files.length}个附件`}</span>
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(SummaryCard)