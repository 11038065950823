/* eslint-disable */
// @ts-nocheck
import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";

import usePlatformCheck, {
  WindowType,
} from "@/pages/PureHooks/usePlatformCheck";

import { getHrefByLocation } from "@/pages/forMobileInvite/utils";
import useAvatar from "@/pages/PureHooks/useAvatar";

import Layout from "@/Layout/forH5Auto/Layout";
import styles from "./AnserLayout.module.scss";
// import { useHistory } from "react-router-dom";

// import { Avatar } from "Component/Avatar/Avatar";

// import Layout from "Layout/forH5Auto/Layout";
import { Divider } from "Component/Line/Divider";
import { BigThemeBtn } from "Component/Button";

// import useGetTask from "./hooks/useGetTask";

import { downloadBtnClick } from "pages/html5downloader/ts/download";
import {curProjectType, PROJECT_ENV} from "@/service/const";

// import "./AnserLayout.scss";

// const getPic = (typeName: string) => {
//   switch (typeName) {
//     case "事项你已被移出该":
//       return "/assets/banner/banner_beremoved_hd.png";
//     case "事项已取消":
//       return "/assets/banner/banner_canceled_hd.png";
//     case "会议你已被移出该":
//       return "/assets/banner/meeting_beremoved_hd.png";
//     case "会议已取消":
//       return "/assets/banner/meeting_canceled_hd.png";
//     case "待办你已被移出该":
//       return "/assets/banner/taskwait_beremoved_hd.png";
//     case "待办已取消":
//       return "/assets/banner/taskwait_canceled_hd.png";
//     default:
//       return "";
//   }
// };

const AnserLayout = (props: any) => {
  // const histroy = useHistory();

  // const jumpToRefuse = () => {
  //   history.push({ pathname: "/refuse" });
  // };

  // useEffect(() => {
  //   if (history) {
  //     if (!localStorage.getItem("fx-token")) {
  //       jumpToRefuse();
  //     }
  //   }
  // }, [histroy]);

  const jumpToRefuse = () => {};

  const hrefSearch: any = getHrefByLocation(jumpToRefuse);

  const { nick_name, avatarSrc } = useAvatar({ user_id: hrefSearch?.u });

  /**
   * 主要渲染DOM结构体
   * @returns
   */
  const BuildInner = () => (
    <div className={styles.v17pc}>
      <div className={styles.v17pc_center}>
        {/**  */}
        <div className={styles.v17pc_center_header}>
          <img src={avatarSrc || "/assets/v1_7/wx_open/logo@3x.png"} alt="" />
        </div>

        <div className={styles.v17pc_center_title}>{I18N.common.h5_invitationAccepted}</div>

        <div className={styles.v17pc_center_desc}>
          {nick_name} {I18N.common.h5_hasBecomeMine}</div>

        {curProjectType === PROJECT_ENV.normal && (
          <div className="fx-layout-anser">
            <Divider marginStyle={{ top: 48, bottom: 47 }} />
            <div className="ad">
              <div className="info">
                {I18N.common.h5_moreDetails}
              </div>
              <div>
                <img
                  src="/assets/logo_v17@2x.png"
                  alt="logo"
                  style={{ width: 100, height: 100 }}
                />
              </div>
              <div style={{ width: "100%", textAlign: "center", paddingTop: 49 }}>
                <div
                  className={styles.btn}
                  style={{ width: 314, margin: "0 auto" }}
                  onClick={downloadBtnClick}
                >
                  {I18N.common.h5_downloadNow}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const { nowWindowType } = usePlatformCheck();

  if (nowWindowType === WindowType.PC浏览器) {
    return <Layout>{BuildInner()}</Layout>;
  }

  return <BuildInner />;
};

export default AnserLayout;
