import React, { useCallback, useState, useEffect } from "react";

// import { VERSION } from "service/const";
import sensors from "sensorsDataHook/initSensors";

import loginApi from "service/login";
import userApi from "service/user";
import ErrorCodes from "service/code";
import SSe from "service/sse";

import Qc from "qrcode";
import { domain } from "service/const";
import util from "./util";

let qrCodeTimeoutId: any;
let watchTimeoutId: any;
const timeOut = 300000;

export const Wx2dCode = ({ type = 'weChat', onSuccess }: { type?: string, onSuccess: () => void }) => {
  const [qrCode, setQrCode] = useState("");
  const [loginKey, setLoginKey] = useState("");
  const [isWatchTimeOut, setWatchTimeOut] = useState(false);

  const getLoginKey = useCallback(async () => {
    const params = await util.getLoginKeyParams();
    const res = await loginApi.getLoginKey(params);

    return res.data;
  }, []);

  const getQrCode = useCallback(async (need_loginKey) => {
    const params = await util.getQrCodeParams(need_loginKey);

    console.log("key", `scene=${encodeURIComponent(params.scene)}`);
    console.log("login_key", need_loginKey);
    const data = await Qc.toDataURL(
      `https://${domain}/userc/v1/pc-login?login_key=${need_loginKey}`,
      {
        margin: 0,
        width: 190,
      }
    );

    return data;
  }, []);

  const generateQrCode = useCallback(async () => {
    const temp_loginKey = await getLoginKey();
    const temp_qrCode = await getQrCode(temp_loginKey);

    setLoginKey(temp_loginKey);
    setQrCode(temp_qrCode);
  }, []);

  const refreshQrCode = () => {
    generateQrCode()
      .then(() => {
        setWatchTimeOut(false);
      })
      .catch();
  };

  const getUserInfo = () => {
    userApi
      .getLoginUserInfo()
      .then((res) => {
        if (res.code === ErrorCodes.success) {
          // dispatch(updateUser(res.data))
          console.log("用户信息", res.data);

          sensors.sender.login(res.data.user_id);
          localStorage.setItem("fx-user_id", res.data.user_id);

          if (onSuccess) {
            setTimeout(() => {
              onSuccess();
            }, 233);
          }
        }
      })
      .catch((err) => {
        console.log("获取登录用户失败", err);
      });
  };

  useEffect(() => {
    generateQrCode().then().catch();
  }, []);

  useEffect(() => {
    if (loginKey) {
      const sse = new SSe("/intime/stream", { identity: loginKey });
      const { source } = sse;

      sse.addEventListener("pc_scan_login", (event: { [p: string]: any }) => {
        console.log("see data", event);

        try {
          if (event.data) {
            const { data } = event;

            if (data.scanned) {
              // 已扫码， 5分钟二维码失效
              watchTimeoutId = setTimeout(() => {
                setWatchTimeOut(true);
                clearTimeout(qrCodeTimeoutId);
                source.close();
              }, timeOut);
            }
            if (data.code === 0) {
              // 登录成功
              // dispatch(updateToken(data.token))
              localStorage.setItem("fx-token", data.token);
              getUserInfo();
              source.close();
            }
          }
        } catch (e) {
          console.log("sse data parse err", e);
        }
      });
      source.onopen = (event) => {
        console.log("sse open", event);
      };
      source.onerror = (event) => {
        // 发生错误
        console.log("sse error", event);
        source.close();
      };

      qrCodeTimeoutId = setTimeout(() => {
        generateQrCode().then().catch();
        source.close();
      }, timeOut);
    }
    return () => {
      clearTimeout(qrCodeTimeoutId);
      clearTimeout(watchTimeoutId);
    };
  }, [loginKey]);

  return (
    <div
      style={{
        textAlign: "center",
        paddingBottom: 53,
      }}
    >
      {isWatchTimeOut && <div>二维码已失效</div>}
      <img
        src={qrCode}
        alt="二维码加载中..."
        style={{ width: 190, height: 190, marginTop: 34, marginBottom: 32 }}
      />
      {!isWatchTimeOut ? (
        <div>{type === 'weChat' ? '请使用微信扫描二维码登录' : '请使用飞项扫描二维码登录'}</div>
      ) : (
        <button
          onClick={refreshQrCode}
          // className={style.refresh_btn}
          type="button"
        >
          刷新
        </button>
      )}
    </div>
  );
};
