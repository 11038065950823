import { I18N } from '@flyele-nx/i18n'
import React, { useState, useEffect } from "react";
import { isImage, getFileIcon } from "utils_for_pc/file";
import { Modal, Button } from "antd";

import storageApi from "service/storage";
import "./style.scss";

const styles = {
  "fx-file-card__img": "fx-file-card__img",
  "fx-file-card__info": "fx-file-card__info",
  "fx-file-card__name": "fx-file-card__name",
  "fx-file-card__size": "fx-file-card__size",
};

type Props = {
  info: any; //FileCardDelear.TaskFile;
  task: any;
};

export const FileCard = ({ info, task }: Props) => {
  const [showBig, set_showBig] = useState(false);

  const [pic, setPic] = useState("#");
  const [icon, setIcon] = useState('')

  const readImg = (id: string) => {
    storageApi
      .getOnlineImage({
        ids: [id],
        // ossConfig: "image/resize,m_fill,w_800,h_800",
        flyeleId: task?.task_id,
        grantor: "",
      })
      .then((res: any) => {
        const dict = res.data;
        const pic = res.data[Object.keys(dict)[0]];

        setPic(pic);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (info.thumb || info.icon) {
      setIcon(info.thumb || info.icon)
    } else {
      const id = info.fileId || info.id
      storageApi.getOnlineImage({
        ids: [id],
        ossConfig: 'image/resize,m_fill,w_70,h_70',
        flyeleId: task?.task_id,
        grantor: '',
      }).then(({ data }) => {
        if (data && data[id]) {
          setIcon(data[id])
        }
      })
    }
  }, [info, task])

  if (!info) return null;

  return (
    <div className="fx-file-card">
      <div className="fx-file-card__img" key={info.fileId}>
        {!isImage((info.name || "").toLowerCase()) && (
          <img
            src={getFileIcon(info.name)}
            alt=""
            style={{ border: "0.5px solid rgba(255,255,255,0)" }}
          />
        )}

        {isImage((info.name || "").toLowerCase()) ? (
          <div
            className={styles["fx-file-card__info"]}
            onClick={() => {
              set_showBig(true);
              readImg(info?.id);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              className={styles["fx-file-card__img"]}
              src={icon}
              alt=""
              style={{ border: "1px solid #efefef" }}
            />
          </div>
        ) : (
          <div className={styles["fx-file-card__info"]}>
            <div className={styles["fx-file-card__name"]}>{info.name}</div>
            <div className={styles["fx-file-card__size"]}>{info.size}</div>
          </div>
        )}

        <Modal
          visible={showBig}
          title={I18N.common.picturePreview}
          footer={
            <Button type="primary" block onClick={() => set_showBig(false)}>
              {I18N.common.closeBtn}
            </Button>
          }
          style={{ boxShadow: "none" }}
          closable
          destroyOnClose
          // width="calc(100vw - 124px)"
          mask
          onCancel={() => set_showBig(false)}
        >
          <img
            style={{ width: "100%", height: "auto", maxWidth: "800px" }}
            src={pic}
          />
        </Modal>
      </div>
    </div>
  );
};

export default FileCard;
