import { useState } from 'react'
import {ISpace, ISpaceMemberDealState} from '@/service/types/space'
import SpaceApi from 'service/space'
import projectApi from 'service/project'
import {useMemoizedFn} from "ahooks";

const useGetSpace = () => {
  // 空间详情
  const [spaceInfo, setSpaceInfo] = useState<ISpace | null>(null)
  // 是否超出当前空间人数
  const [isOverSpaceMaxNum, setIsOverSpaceMaxNum] = useState<boolean>(false)
  // 审核状态
  const [memberDealState, setMemberDealState] = useState<ISpaceMemberDealState | null>(null)
  
  /**
   * 获取空间详情信息
   */
  const getSpaceDetailInfo = useMemoizedFn(async (spaceId: string) => {
    const { data } = await SpaceApi.getSpaceDetail(spaceId)
    const { max_taker_count, takers } = data
    // 专业版本人数不限制
    if (takers && max_taker_count !== -1 && takers.length >= max_taker_count) {
      setIsOverSpaceMaxNum(true)
    } else {
      setIsOverSpaceMaxNum(false)
    }
    setSpaceInfo(data)
  })
  
  /**
   * 获取空间成员邀请处理状态
   */
  const getInviteState = async (spaceId: string) => {
    try {
      const { data } = await SpaceApi.getMemberDealState(spaceId)
      setMemberDealState(data)
      
      return data
    } catch (e) {
      console.log('获取空间成员邀请处理状态失败', e)
    }
  }
  
  /**
   * 通过项目id去获取空间信息
   */
  const getSpaceInfoFromProject = async (projectId: string) => {
    try {
      const res = await projectApi.getProjectInfo(projectId)
      const spaceId = res?.data.workspace_id || ''
      getSpaceDetailInfo(spaceId)
      getInviteState(spaceId)
    } catch (e) {
      console.log('获取项目详情接口错误', e)
    }
  }
  
  return {
    spaceInfo,
    isOverSpaceMaxNum,
    memberDealState,
    getSpaceDetailInfo,
    getInviteState,
    getSpaceInfoFromProject,
  }
}

export default useGetSpace
