import React from "react";
import cs from "classnames";
import styles from "../Task/index.module.scss";

const ParentBread = ({ list }: any) => {
  return (
    <div className={styles.parent_bread}>
      {list.map((item: any, index: number) => {
        return (
          <div
            className={cs(styles["sub-title"], styles["fx-float-left"])}
            key={`${item.title}-${index}`}
          >{`${item?.title} >`}</div>
        );
      })}
    </div>
  );
};

export default ParentBread;
