import React from 'react'
import styles from './index.module.scss'
import dayjs from "dayjs";
import {I18N} from "@flyele-nx/i18n";

interface IProps {
  title: string
  name: string
  create_at: number
}

const TaskTitleBox = ({ name, create_at, title }: IProps) => {
  return (
    <div className={styles.titleRoot}>
      <div className={styles.title}>{title}</div>
      <div className={styles.createText}>
        {name}{I18N.common.createdOn} {dayjs.unix(create_at).format("YYYY.M.D HH:mm")}
      </div>
    </div>
  )
}

export default TaskTitleBox