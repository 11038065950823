import React from "react";
import styles from "./index.module.scss";

import config from "config";

type TProps = {
  path: string;
};

const WxOpenBanner = (props: TProps) => {
  const { path } = props;

  const invite_style = {
    width: "100%",
    color: "#1dd2c1",
    textAlign: "center",
    background: "#fff",
  };

  return (
    <div
      className={styles.back}
      style={{ backgroundImage: "url('/assets/v1_7/wx_open/back@3x.png')" }}
    >
      <div className={styles.back_left}>
        <img src="/assets/v1_7/wx_open/logo@3x.png" alt="" />
      </div>
      <div className={styles.back_middle}>在飞项小程序中打开</div>
      <div className={styles.back_right}>
        {/* <button>打开</button> */}
        <button>
          {/* @ts-ignore*/}
          <wx-open-launch-weapp
            id="launch-btn"
            username={config.miniProgramUsername}
            path={path}
          >
            <script type="text/wxtag-template">
              {/* @ts-ignore*/}
              <span style={invite_style}>打开</span>
            </script>
            {/* @ts-ignore*/}
          </wx-open-launch-weapp>
        </button>
      </div>
    </div>
  );
};

export default WxOpenBanner;
