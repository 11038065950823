import { config } from "./config";
import CallApp from "callapp-lib";

import { HrefReturn } from "../../utils";
import {isCN} from "@flyele-nx/i18n";
interface Params extends HrefReturn {
  isProject?: boolean
  isSpace?: boolean
  isTask?: boolean
}

// 下载按钮点击方法
export function downloadBtnClick(task: any, u: string, params: Params = {} as any) {
  evokeApp(task, u, params);
}

function evokeApp(task: any, u: string, params: Params) {

  const {
    project_id,
    workspace_id,
    task_id,
  } = task || {}

  const {
    isProject,
    isSpace,
    memberType,
    dispatch_at,
  } = params

  const id = isProject ? project_id : isSpace ? workspace_id : task_id

  // 检测设备是否为iPad
  const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  // 根据设备类型选择正确的App ID
  const appID = isIPad ? config.iOSIPadId : config.iOSAppId;

  const callLib = new CallApp({
    scheme: {
      protocol: "flyele",
    },
    appstore: `https://itunes.apple.com/app/id${appID}`,
    yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.flyele.flyeleMobile',
    fallback: config.HOMEPAGE,
    logFunc: (status) => {
      console.log("status", status);
      if (status === "failure") {
        download();
      } else if (status === "pending") {
        // downloadPoint("openApp");
      }
    },
  });

  console.log('CallApp', task, isSpace, isProject)

  let path = `operate/task/accept?id=${id}&u=${u}&dispatch_at=${dispatch_at}`

  if (isProject) {
    path = `operate/project/accept?id=${id}&u=${u}&member_type=${memberType}&dispatch_at=${dispatch_at}`
  }

  if (isSpace) {
    path = `operate/space/accept?id=${id}&u=${u}&member_type=${memberType}&dispatch_at=${dispatch_at}`
  }

  callLib.open({
    // path: `accept?id=${id}&u=${u}`,
    path,
    // path: `scan`,
    callback: () => {},
  });
}

function download() {
  // window.location.href = `${domain}`;
  window.location.href = isCN ? `https://pc.flyele.net/download/` : 'https://www.flyele.com/download';
  //   if (isIOS) {
  //     gotoAppStore();
  //   } else {
  //     http
  //       .get("/v1/downloads/upgrade_version", {
  //         params: {
  //           platform: 1,
  //           version_number: "0.0.1",
  //         },
  //       })
  //       .then((res: any) => {
  //         var linkUrl = res.data.downloads.full_version.link_url;
  //         var versionNumber = res.data.version_number;
  //         if (!linkUrl) {
  //           alert("下载地址获取失败");
  //         } else {
  //           console.log("========", linkUrl);
  //           downloadApk(linkUrl, `flyeleV${versionNumber}`);
  //         }
  //       })
  //       .catch(() => {
  //         alert("获取app失败");
  //       });
  //   }
}

// 跳转到appstore
// function gotoAppStore() {
//   var appstore = `itms-apps://itunes.apple.com/app/id${config.iOSAppId}`;
//   executeUrl(appstore);
//   // 神策
//   downloadPoint("appStore");
// }

// // 下载 安卓apk
// function downloadApk(href: any, name: any) {
//   executeUrl(href, name);
//   // 神策
//   downloadPoint("goDownload");
// }

// 执行某个url
// function executeUrl(url: any, downloadUrl = "") {
//   var a = document.createElement("a");
//   a.setAttribute("href", url);
//   if (downloadUrl) {
//     a.setAttribute("download", downloadUrl);
//   }
//   a.style.display = "none";
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
// }
