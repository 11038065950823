// 1、空间成员状态
// state
// 10901	空间未加入状态
// 10902	空间已加入状态
// 10903	空间已拒绝状态
// 10904	空间被移出状态

// 4、空间状态
// state
// 1	正常
// 2	解散
// 3	待激活

// 5、审批状态
// approval_state
// 1	未处理
// 2	同意
// 3	拒绝

type ISpaceToolState = {
  state: number
  approval_state: number
  accept_at: number
}

type ISpaceToolSpaceData = {
  state: number
}

class SpaceTool {

  /**
   * 未加入
   * @param data 
   * @returns 
   */
  static isUnjoined (data: ISpaceToolState) {

    const {
      state,
    } = data

    return state === 10901

  }

  /**
   * 已加入
   * @param data 
   * @returns 
   */
  static isJoined (data: ISpaceToolState) {

    const {
      state,
    } = data

    return state === 10902

  }

  /**
   * 已拒绝
   * @param data 
   * @returns 
   */
  static isRefused (data: ISpaceToolState) {

    const {
      state,
    } = data

    return state === 10903

  }

  /**
   * 被移出
   * @param data 
   * @returns 
   */
  static isRemoved (data: ISpaceToolState) {

    const {
      state,
    } = data

    return state === 10904

  }

  /**
   * 审核中
   * @param data 
   * @returns 
   */
  static isOngoing (data: ISpaceToolState) {

    const {
      state,
      approval_state,
      accept_at,
    } = data

    return state === 10901 && approval_state === 1 && accept_at > 0

  }

  /**
   * 审核不通过
   * @param data 
   * @returns 
   */
  static isUnapproved (data: ISpaceToolState) {

    const {
      state,
      approval_state,
      accept_at,
    } = data

    return state === 10903 && approval_state === 3

  }

  /**
   * 空间解散了
   * @param data 
   * @returns 
   */
  static isSpaceDismiss (data: ISpaceToolSpaceData) {

    const {
      state
    } = data

    return state === 2
  }

  static sortTakers (takers: any[]): any[] {

    return takers

    const res = takers.reduce((acc, cur) => {

      if (SpaceTool.isJoined(cur)) {
        Object.assign(cur, { key: 'joined', status: '已加入' })
        acc[0].push(cur)

      } else if (SpaceTool.isRefused(cur)) {

        Object.assign(cur, { key: 'refused', status: '已拒绝' })
        acc[1].push(cur)

      }  else if (SpaceTool.isRemoved(cur)) {

        // Object.assign(cur, { status: '被移出' })
        // acc[2].push(cur)

      } else if (SpaceTool.isUnjoined(cur)) {
        Object.assign(cur, { key: 'unjoined', status: '未加入' })
        acc[3].push(cur)
      }

      return acc

    }, [[], [], [], []])

    const ret: any[] = Array.prototype.concat.apply([], res)
    
    console.log('sortTakers', res, ret)
    return ret
  }

}

export default SpaceTool

export type {
  ISpaceToolState
}