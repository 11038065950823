import { config } from "@/pages/forMobileInvite/component/MaskModal/config";
import CallApp from "callapp-lib";
import {History} from 'history'
import {curProjectType, PROJECT_ENV} from "@/service/const";
import { isCN } from '@flyele-nx/i18n'

// 下载按钮点击方法
// export function downloadBtnClick(task: any, u: string) {
//   evokeApp(task, u);
// }

function evokeApp(path: string, history?: History, enCallback?: () => void) {
  // 检测设备是否为iPad
  const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  // 根据设备类型选择正确的App ID
  const appID = isIPad ? config.iOSIPadId : config.iOSAppId;

  try {
    const callLib = new CallApp({
      scheme: {
        protocol: "flyele",
      },
      appstore: `https://itunes.apple.com/app/id${appID}`,
      yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.flyele.flyeleMobile',
      fallback: config.HOMEPAGE,
      logFunc: (status) => {
        console.log("logFunc status", status);
        if (status === "failure") {
          // download();
          if (curProjectType !== PROJECT_ENV.pri_neiMongol) {
            toLoginPage(history, false, enCallback)
          }
        } else if (status === "pending") {
          // downloadPoint("openApp");
        }
      },
    });

    callLib.open({
      path,
      // : `accept?id=${task?.task_id}&u=${u}`,
      callback: () => {},
    });
  } catch (e) {
    console.log('打开app失败', e);
    if (curProjectType !== PROJECT_ENV.pri_neiMongol) {
      toLoginPage(history, false, enCallback)
    }
  }
}

function toLoginPage (history?: History, goBack?: boolean, enCallback?: () => void) {
  if (isCN) {
    const query = JSON.stringify(history?.location)
    console.log('进入 toLoginPage', query)

    history?.push({
      pathname: `/login`,
      search: `from=${encodeURIComponent(query)}&goBackOrigin=${goBack}`
    })
  } else {
    enCallback && enCallback()
  }
}

// function download() {
//   window.location.href = `https://pc.flyele.net/download/`;
// }

const useEnvokeApp = () => {
  return { evokeApp, toLoginPage };
};

export default useEnvokeApp;
