import { I18N, isCN } from '@flyele-nx/i18n'
import dayjs from "dayjs";
import timeGetter from "utils_for_pc/timeGetter";
import { runConditions } from "./tool";
import { getEnFormat } from "@/utils/time";

const remindTimeToEn: {
  [key: string]: string
} = {
  '自定义提醒': I18N.common.customReminder2,
  '会前15分钟提醒': I18N.common.minutesBeforeTheMeeting,
  '会前5分钟提醒': I18N.common.minutesBeforeTheMeeting2,
  '准点提醒': I18N.common.onTimeReminder,
  '截止不提醒': I18N.common.deadlineWithoutReminder,
  '截止当天18:00提醒': I18N.common.dueOnTheSameDay,
  '截止当天9:00提醒': I18N.common.dueOnTheSameDay2,
  '截止前一天9:00提醒': I18N.common.theDayBeforeTheDeadline,
  '截止前3小时提醒': I18N.common.hoursBeforeDeadline,
  '截止前1小时提醒': I18N.common.hoursBeforeDeadline2,
  '截止前15分钟提醒': I18N.common.minutesBeforeDeadline,
  '截止时提醒': I18N.common.reminderAtDeadline,
  '开始不提醒': I18N.common.startWithoutReminders,
  '当天18:00提醒': I18N.common.reminderForTheDay,
  '开始当天18:00提醒': I18N.common.startOnTheSameDay,
  '当天9:00提醒': I18N.common.reminderForTheDay2,
  '开始当天9:00提醒': I18N.common.startOnTheSameDay2,
  '提前一天9:00提醒': I18N.common.bringItUpOneDayInAdvance,
  '开始前一天9:00提醒': I18N.common.theDayBeforeTheStart,
  '开始前3小时提醒': I18N.common.hoursBeforeStart,
  '开始前1小时提醒': I18N.common.hoursBeforeStart2,
  '开始前15分钟提醒': I18N.common.minutesBeforeStart,
  '开始时提醒': I18N.common.reminderAtTheBeginning
};

// 常量：星期几 - 映射dayjs.day()
const DAY_DICT = [
  I18N.common.sundayMini,
  I18N.common.mondayMini,
  I18N.common.tuesdayMini,
  I18N.common.wednesdayMini,
  I18N.common.thursdayMini,
  I18N.common.fridayMini,
  I18N.common.saturdayMini,
]
const getDateTxt = async (stamp: number): Promise<string> => {
  const date = dayjs.unix(stamp);
  const curDateStamp = await timeGetter.getDate();
  const today = dayjs.unix(curDateStamp);

  const conditions = new Map([
    [() => date.isSame(today, "date"), () => getEnFormat(date, 'H:mm', 'h:mm A')],
    [() => date.isSame(today.subtract(1, "day"), "date"), () => I18N.common.yesterday],
    [() => date.isSame(today, "week"), () => `${isCN ? '星期' : ''}${DAY_DICT[date.day()]}`],
    [() => date.isSame(today, "year"), () => getEnFormat(date, 'M月D日', 'MMM D')],
    [() => true, () => getEnFormat(date, 'YYYY年M月', 'MMM, YYYY')],
  ]);

  return runConditions(conditions);
};

/** 2021年3月8日 12:34 */
const getDate_YMD__Hm = (stamp: number) => {
  const YMD__Hm = getEnFormat(dayjs.unix(stamp), 'YYYY年M月D日 HH:mm', 'MMM D, YYYY h:mm A');

  return YMD__Hm;
};

/** 12月4日 周四 9:00 */
export const getDate_MD_Week_Hm = (stamp: number) => {
  const Today = dayjs();

  const YYYY = getEnFormat(dayjs.unix(stamp), 'YYYY年', 'YYYY');
  const TodayYYYY = getEnFormat(Today, 'YYYY年', 'YYYY');
  const MD = getEnFormat(dayjs.unix(stamp), 'M月D日', 'MMM D');
  const Week = `${isCN ? '周' : ''}${DAY_DICT[dayjs.unix(stamp).day()]}`;
  const Hm = getEnFormat(dayjs.unix(stamp), 'HH:mm', 'h:mm A');

  return isCN ? `${YYYY !== TodayYYYY ? `${YYYY} ` : ""}${MD} ${Week} ${Hm}` : `${MD},${YYYY !== TodayYYYY ? ` ${YYYY},` : ''} ${Week}, ${Hm}`;
};

/** 12月4日 周四 9:00 - 12:00 */
const getDate_MD_Week_Hm__Hm = (stamp_start: number, stamp_end: number) => {
  const Today = dayjs();

  const YYYY = getEnFormat(dayjs.unix(stamp_start), 'YYYY年', 'YYYY')
  const TodayYYYY = getEnFormat(Today, 'YYYY年', 'YYYY')
  const MD = getEnFormat(dayjs.unix(stamp_start), 'M月D日', 'MMM D')
  const Week = `${isCN ? '周' : ''}${DAY_DICT[dayjs.unix(stamp_start).day()]}`
  const Hm = getEnFormat(dayjs.unix(stamp_start), 'HH:mm', 'h:mm A')
  const Hm_end = getEnFormat(dayjs.unix(stamp_end), 'HH:mm', 'h:mm A')

  return isCN
    ? `${YYYY !== TodayYYYY ? `${YYYY} ` : ''}${MD} ${Week} ${Hm} - ${Hm_end}`
    : `${MD},${
      YYYY !== TodayYYYY ? ` ${YYYY},` : ''
    } ${Week}, ${Hm} - ${Hm_end}`
};

export const getDate_YYYYMMDD_HHmm = (stamp: number) => {
  if (!stamp) {
    return I18N.common.loading;
  }
  return getEnFormat(dayjs.unix(stamp), 'YYYY.MM.DD HH:mm', 'MMM D, YYYY h:mm A')
};

/**
 * 将remint时间戳转换为显示文字
 * @date 2021-03-04
 * @param {any} remindStamp:any 提醒的时间戳
 * @param {any} nowStamp:any 实际的时间戳
 * @returns {any}
 */
export const getRemindTimeTxt = (
  remindStamp: any,
  nowStamp: any,
  isFullday = false
) => {
  const remindTime = nowStamp - remindStamp;
  const isSameDay =
    dayjs.unix(nowStamp).format("YYYY年MM月DD日") ===
    dayjs.unix(remindStamp).format("YYYY年MM月DD日");
  const isBeforeDay = nowStamp > remindStamp;

  switch (remindTime) {
    case 0:
      return I18N.common.punctuality1
    case 5 * 60:
      return I18N.template?.(I18N.common.formerMath, {
        val1: 5,
      })
    case 15 * 60:
      return I18N.template?.(I18N.common.formerMath, {
        val1: 15,
      })
    case 60 * 60:
      return I18N.template?.(I18N.common.formerMath2, {
        val1: 1,
      })
    default:
      if (isFullday && isSameDay) {
        return I18N.template?.(I18N.common.getOnTheSameDay2, {
          val1: getEnFormat(dayjs.unix(remindStamp), 'HH:mm', 'h:mm A'),
        })
      }
      if (isFullday && isBeforeDay) {
        return I18N.template?.(I18N.common.theDayBeforeGe, {
          val1: getEnFormat(dayjs.unix(remindStamp), 'HH:mm', 'h:mm A'),
        });
      }

      return `${getEnFormat(dayjs.unix(remindStamp), 'M月D日 HH:mm', 'MMM D h:mm A')}`;
  }
};

// v1.5版本详情显示规则
export const getDate_v15_validity_date = (params: any = {}) => {
  const { start_time = '', start_time_full_day = '', end_time = '' } = params

  const { end_time_full_day = '' } = params

  let firstStr = ''
  let secondStr = ''
  let output = ''
  // 前半截输出，后半截输出
  let firstPartOutput = ''
  let secondPartOutput = ''

  if (!start_time && !end_time)
    return {
      output: '',
      firstPartOutput,
      secondPartOutput,
      noSetTime: true,
    }

  const today = dayjs()

  function isSameDay(a: any, b: any) {
    const today_YYYYMMMMDD = a.format('YYYYMMDD')
    const time_YYYYMMMMDD = b.format('YYYYMMDD')

    return today_YYYYMMMMDD === time_YYYYMMMMDD
  }

  const start_YYYY = getEnFormat(dayjs.unix(start_time), 'YYYY年', 'YYYY')
  const end_YYYY = getEnFormat(dayjs.unix(end_time), 'YYYY年', 'YYYY')
  const today_YYYY = getEnFormat(today, 'YYYY年', 'YYYY')

  const start_MMDD = getEnFormat(dayjs.unix(start_time), 'M月D日', 'MMM D')
  let end_MMDD = getEnFormat(dayjs.unix(end_time), 'M月D日', 'MMM D')

  const start_Week = `${isCN ? '周' : ''}${DAY_DICT[dayjs.unix(start_time).day()]}`
  const end_Week = `${isCN ? '周' : ''}${DAY_DICT[dayjs.unix(end_time).day()]}`

  // 年份 + 日期
  if (start_YYYY !== today_YYYY) {
    firstStr += start_YYYY
    firstStr += ' '
  }
  firstStr += start_MMDD

  if (end_YYYY !== today_YYYY) {
    secondStr += end_YYYY
    secondStr += ' '
  }
  secondStr += end_MMDD

  // 周几 在最后拼接

  // 01 是开始全天 (单天全天)
  if (
    start_time_full_day === 2 &&
    start_time &&
    isSameDay(dayjs.unix(end_time), dayjs.unix(start_time))
  ) {
    output += firstStr
    output += ' '
    output += isSameDay(today, dayjs.unix(start_time)) ? I18N.common.todayWord : start_Week
    output += ' '
    output += `(${I18N.common.wholeDay})`
    firstPartOutput = output
    return { output, firstPartOutput, secondPartOutput }
  }
  // 02 连续全天
  if (start_time_full_day === 2 && end_time_full_day === 2) {
    output += firstStr
    output += ' '
    output += isSameDay(today, dayjs.unix(start_time)) ? I18N.common.todayWord : start_Week
    firstPartOutput = output

    output += ' - ' // +3 需要被裁掉
    output += secondStr
    output += ' '
    output += isSameDay(today, dayjs.unix(end_time)) ? I18N.common.todayWord : end_Week
    secondPartOutput = output.slice(firstPartOutput.length + 3, output.length)
    return { output, firstPartOutput, secondPartOutput }
  }
  // 03 仅，开始，非全天
  if (start_time_full_day !== 2 && start_time && !end_time) {
    output += firstStr
    output += ' '
    output += isSameDay(today, dayjs.unix(start_time)) ? I18N.common.todayWord : start_Week
    output += ' '
    output += getEnFormat(dayjs.unix(start_time), 'HH:mm', 'h:mm A')
    output = isCN ? `${output} ${I18N.common.start}` : `Start on ${output}`
    firstPartOutput = output
    return { output, firstPartOutput, secondPartOutput }
  }
  // 04 仅，结束，非全天
  if (!start_time && end_time) {
    output += secondStr
    output += ' '
    output += isSameDay(today, dayjs.unix(end_time)) ? I18N.common.todayWord : end_Week
    output += ' '
    output += getEnFormat(dayjs.unix(end_time), 'HH:mm', 'h:mm A')
    output = isCN ? `${output} ${I18N.common.deadline}` : `Due on ${output}`
    firstPartOutput = output
    return { output, firstPartOutput, secondPartOutput }
  }
  // 05有开始、有截止时间
  if (start_time && end_time) {
    output += firstStr
    output += ' '
    output += isSameDay(today, dayjs.unix(start_time)) ? I18N.common.todayWord : start_Week
    output += ' '
    output +=
      start_time_full_day === 2 ? '' : getEnFormat(dayjs.unix(start_time), 'HH:mm', 'h:mm A')
    firstPartOutput = output

    output += ' - '
    output += secondStr
    output += ' '
    output += isSameDay(today, dayjs.unix(end_time)) ? I18N.common.todayWord : end_Week
    output += ' '
    output +=
      end_time_full_day === 2 ? '' : getEnFormat(dayjs.unix(end_time),'HH:mm', 'h:mm A')
    secondPartOutput = output.slice(firstPartOutput.length + 3, output.length)
    return { output, firstPartOutput, secondPartOutput }
  }

  return { output, firstPartOutput, secondPartOutput }
};

export const getDate_v15_remind_date = (params: any) => {
  const {
    remind_at = {},
    start_time,
    end_time,
    start_time_full_day,
    end_time_full_day,

    matter_type = 0,
  } = params;

  let output = "";

  if (!remind_at?.start_remind && remind_at?.end_remind) {
    return I18N.common.doNotRemind;
  }

  if (remind_at?.start_remind) {
    output += I18N.common.start;
    output += getRemindTimeTxt(
      remind_at?.start_remind,
      start_time,
      start_time_full_day === 2
    );
    output += isCN ? "提醒" : '';
  } else {
    output += I18N.common.startWithoutReminders;
  }

  // 会议只有一段
  if (matter_type === 10702) {
    return output
  }

  // if (remind_at?.start_remind && remind_at?.end_remind) {
  output += "、";
  // }

  if (remind_at?.end_remind) {
    output += I18N.common.deadline;
    output += getRemindTimeTxt(
      remind_at?.end_remind,
      end_time,
      end_time_full_day === 2
    );
    output += isCN ? "提醒" : '';
  } else {
    output += I18N.common.deadlineWithoutReminder;
  }



  return output;
};

export const getDate_v17_remind_date = (params: any) => {
  const {
    remind_at = {},
    start_time,
    end_time,
    start_time_full_day,
    end_time_full_day,
  } = params;

  console.log('@@@ getDate_v17_remind_date', params);

  let output = "";

  if (!remind_at?.start_remind && !remind_at?.end_remind && !remind_at?.alone_remind) {
    return I18N.common.doNotRemind;
  }

  if (remind_at?.start_remind) {
    (remind_at?.start_remind || []).forEach((time: number, i: number) => {
      if (i !== 0) output += '、'

      output += `${I18N.common.start} `;
      output += getRemindTimeTxt(
        time, // remind_at?.start_remind,
        start_time,
        start_time_full_day === 2
      );
      output += isCN ? "提醒" : '';
    })

  }

  if (remind_at?.end_remind) {
    if (output) {
      output += '、'
    }

    (remind_at?.end_remind || []).forEach((time: number, i: number) => {
      if (i !== 0) output += '、'

      output += isCN ? "截止" : `Due ${end_time_full_day === 2 ? 'on' : 'at'}`;
      output += getRemindTimeTxt(
        time,
        end_time,
        end_time_full_day === 2
      );
      output += isCN ? "提醒" : '';
    })
  }

  if (remind_at?.alone_remind) {
    if (output) {
      output += '、';
    }

    (remind_at?.alone_remind || []).forEach((time: number, i: number) => {
      if (i !== 0) output += '、'

      if(dayjs.unix(time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')){
        output += getEnFormat(dayjs.unix(time), '今天 HH:mm', 'Today h:mm A')
      }else{
        output += getEnFormat(dayjs.unix(time), 'M月D日 HH:mm', 'MMM D h:mm A')
      }
      
      output += isCN ? "提醒" : '';
    })
  }

  output = remindTimeToEn[output] || output

  return output;
};


export const getLength = (params: any) => {
  const { start_time, end_time } = params;

  let char = isCN ? '约' : 'about';

  try {
    const start_dayjs = dayjs.unix(start_time).valueOf();
    const end_dayjs = dayjs.unix(end_time).valueOf();

    const ms = end_dayjs - start_dayjs;

    console.log(ms);

    if (!isCN) {
      char += ' '
    }

    if (ms < 1000 * 60) {
      char += `${Math.floor(ms / 1000)}${isCN ? '秒' : ' sec'}`;
    } else if (ms < 1000 * 60 * 60) {
      char += `${Math.floor(ms / 1000 / 60)}${isCN ? '分钟' : ' minutes'}`;
    } else if (ms < 1000 * 60 * 60 * 24) {
      char += `${Math.floor(ms / 1000 / 60 / 60)}${isCN ? '小时' : ' hours'}`;
    } else if (ms < 1000 * 60 * 60 * 24 * 31) {
      char += `${Math.floor(ms / 1000 / 60 / 60 / 24)}${isCN ? '天' : ' days'}`;
    } else if (ms < 1000 * 60 * 60 * 24 * 30 * 12) {
      char += `${Math.floor(ms / 1000 / 60 / 60 / 24 / 30)}${isCN ? '月' : ' month'}`;
    } else {
      char += `${Math.floor(ms / 1000 / 60 / 60 / 24 / 365)}${isCN ? '年' : ' year'}`;
    }
  } catch (e) {
    console.error(e);
  }

  return char;
};

export default {
  getDateTxt,
  getDate_YMD__Hm,
  getDate_MD_Week_Hm,
  getDate_MD_Week_Hm__Hm,
  getDate_YYYYMMDD_HHmm,
  //
  getRemindTimeTxt,
  //
};
