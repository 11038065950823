import { config } from "./config";
import CallApp from "callapp-lib";
import { isCN } from '@flyele-nx/i18n'

// 下载按钮点击方法
export function downloadBtnClick(task: any) {
  evokeApp(task);
}

function evokeApp(task: any) {
  // 检测设备是否为iPad
  const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  // 根据设备类型选择正确的App ID
  const appID = isIPad ? config.iOSIPadId : config.iOSAppId;

  const callLib = new CallApp({
    scheme: {
      protocol: "flyele",
    },
    appstore: `https://itunes.apple.com/app/id${appID}`,
    yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=com.flyele.flyeleMobile',
    fallback: config.HOMEPAGE,
    logFunc: (status) => {
      console.log("status", status);
      if (status === "failure") {
        download();
      } else if (status === "pending") {
        // downloadPoint("openApp");
      }
    },
  });

  callLib.open({
    path: `task?id=${task?.task_id}`,
    callback: () => {},
  });
}

function download() {
  window.location.href = isCN ? `https://pc.flyele.net/download/` : 'https://www.flyele.com/download';
}
