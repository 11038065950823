import config from "config/index";
import { wechatAuthLogin } from "api/user";
import store from "store";
import qs from "qs";

export const getCodeUrl = (options?:{appid: string}): string => {
  const href = encodeURIComponent(window.location.href);
  const urlSuffix =
    "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${options?.appid??config.getAppId()}&redirect_uri=${href}${urlSuffix}`;
  return url;
};

export const verifyLogin = (path: string) => {
  const userInfo = window.localStorage.getItem("userInfo");
  console.log("userInfo", userInfo);

  if (process.env.NODE_ENV === "development") return true;
  if (!userInfo) {
    console.log(path);
    if (path.indexOf("bind-user") !== -1) {
      const params = qs.parse(window.location.search.substr(1));
      console.log(params);
      if (!!params.isBind) {
        store.dispatch({ type: params.isBind });
        return true;
      }
    }
    getCode();
    return false;
  } else {
    store.dispatch({ type: "bind" });
    return true;
  }
};

export const getCode = () => {
  if (process.env.NODE_ENV === "development") return;
  const url = getCodeUrl();

  console.log("url", url);
  window.location.href = url;
};

export const codeLogin = async (params: any) => {
  try {
    const data = await wechatAuthLogin<WechatAuthLoginReq, WechatAuthLoginRes>(
      params
    );
    store.dispatch({ type: "bind" });
    if (data && data.token) {
      window.localStorage.setItem("userInfo", JSON.stringify(data));
    }
    return data;
  } catch (e: any) {
    console.log(e);
    // 用户未绑定
    if (e && e.code === 40006) {
      store.dispatch({ type: "unbind" });
    }
    console.log("code login fail", e);
  }
  return false;
};
