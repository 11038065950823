import { Dayjs } from 'dayjs'
import 'dayjs/locale/en'
import { isCN } from '@flyele-nx/i18n'

export const getEnFormat = (
  time: Dayjs,
  cnFormat: string,
  enFormat: string
) => {
  return isCN ? time.format(cnFormat) : time.locale('en').format(enFormat)
}