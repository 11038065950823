
import cs from 'classnames'
import styles from './index.module.scss'

import { IInputProps } from "./main"


const Input = (props: IInputProps) => {

  const {
    input,
    error,
    errTips,
    placeholder,
    blurHandler,
    changeHandler,
    clearHandler,
    readonly = false,
    onComposition,
    overClass,
    type,
    gap = 12,
  } = props

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const val = e.target.value

    changeHandler(val)
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {

    blurHandler?.()
  }

  return (

    <div className={cs(overClass, styles.form__input, {
      [styles['form__input--readonly']]: readonly
    })}>

      <input type={type || 'text'}
        placeholder={placeholder}
        value={input}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readonly}
        onCompositionStart={onComposition}
        onCompositionUpdate={onComposition}
        onCompositionEnd={onComposition}
        style={{
          paddingTop: gap,
          paddingBottom: gap,
        }}
      />

      {
        input ? (
          <i className={styles['form__input-clear']} 
            onClick={clearHandler}
            style={{ background: `no-repeat url('/assets/v2_0/clear.png') center/100%` }}
          />
        ) : null
      }
      

      {
        error 
          ? (
            <div className={styles['form__input-tips']}>
              {errTips}
            </div>
          ) : null
      }

    </div>
  )
}

export default Input