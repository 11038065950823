import SpaceApi from '@/service/space'
import { IBasicSpace } from '@/service/types/space'

/**
 * 返回用户的 (皮肤/背景) 空间配置信息
 */
export const getSpacesSinkConfig = () => {
  SpaceApi.getSpaceConfig('2').then((result) => {
    return result.data
  })
}

/**
 * 返回用户的 (头像icon) 空间配置信息
 */
export const getSpacesAvatarConfig = () => {
  SpaceApi.getSpaceConfig('1').then((result) => {
    return result.data
  })
}

/**
 * 返回用户的 (头像背景) 空间配置信息
 */
export const getSpacesAvatarColorConfig = () => {
  SpaceApi.getSpaceConfig('3').then((result) => {
    return result.data
  })
}

/**
 * 根据(皮肤/背景)图片字段获取图片路径
 * @param key_name 皮肤名
 */
export const getSpaceSinkUrl = (key_name = '') => {
  return {
    base: `/assets/space/sink/pc_${key_name}.png`,
    main: `/assets/space/sink/pc_main_${key_name}.png`,
    select: `/assets/space/sink/pc_select_${key_name}.png`,
  }
}

/**
 * 根据(头像)字段获取图片路径
 * @param key_name 头像名
 */
export const getSpaceAvatarUrl = (key_name = '') => {
  return {
    normal: `/assets/space/avatar/${key_name}.png`,
    active: `/assets/space/avatar/${key_name}_color.png`,
  }
}

/**
 * 获取空间基本信息
 * **/
export const getSpaceInfo = (spaceInfo: IBasicSpace) => {
  return {
    ...spaceInfo,
    c_icon_color: `#${spaceInfo.icon_color}`,
    c_icon: getSpaceAvatarUrl(spaceInfo.icon),
    c_bg_icon: getSpaceSinkUrl(spaceInfo.bg_icon),
    isPro: spaceInfo.level === 2,
  }
}