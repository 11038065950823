import { I18N } from '@flyele-nx/i18n'
import InviteTaskH5 from "../pages/forMobileInvite/Task/index";
import InvitePageH5Project from "pages/forMobileInvite/Project";
import InviteSpaceH5 from "pages/forMobileInvite/Space";
import InviteTargetH5 from "pages/forMobileInvite/Target";

export const ForMobileRouters = [
  //
  {
    exact: true,
    path: `/invite_h5/task`,
    component: InviteTaskH5,
    key: "InviteTask_h5",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_invitationForNewMatters,
  },
  //
  {
    exact: true,
    path: `/invite_h5/meeting`,
    component: InviteTaskH5,
    key: "InviteMeeting_h5",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_newMeetingInvitation,
  },
  {
    exact: true,
    path: `/invite/project`,
    component: InvitePageH5Project,
    key: "InviteProject_h5",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_projectInvitation,
  },
  {
    exact: true,
    path: `/invite_h5/space`,
    component: InviteSpaceH5,
    key: "InviteSpace_h5",
    verifyLogin: false,
    children: [],
    title: I18N.common.spaceInvitation,
  },
  {
    exact: true,
    path: `/invite_h5/target`,
    component: InviteTargetH5,
    key: "InviteTarget_h5",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_targetInvitation,
  },
];
