interface CommentListQuery {
  ref_type: string
  ref_id: string
  page_record: number
  page_number: number
}

export enum MsgFormatType {
  text = 0,
  img = 1,
  file = 2,
  loopSummary,
}

export interface CommentResponse {
  avatar: string
  content: string
  create_at: number
  creator_id: string
  id: string
  msg_type: number
  nick_name: string
  ref_type: string
  system_type: number
  comment_id: string
  msg_format: MsgFormatType
  revoke_at?: number
  parsedContent?: IParsedContent
  ref_id: string
  title?: string
}

export interface ToolCommentContent {
  matter_type: MatterType
  task_id: string
  creator_id: string
  title: string
  content: string
  start_time: number
  is_cancel?: boolean
  takers: ITaker[]
}

export interface FileCommentContent {
  [fileId: string]: CommentFile
}

// TODO: 小工具、文件评论信息都是以JSON存在
export interface IParsedContent extends ToolCommentContent, FileCommentContent {
  [key: string]: any
}

export enum SearchCommentQueryType {
  FILE = 1, // 文件/图片
  IMPORTANT, // 重点
  ALL_TOOLS, // 所有工具
  MEETING, // 会议
  NOTICE, // 公告
  TODO, // 待办
  TIME, // 时间征集
  ALL_SMALL_TOOLS // 所有小工具（不包含已经X的
}
export enum SearchCommentPendStatus {
  ALL, // 全部（默认）
  UNFINISHED, // 未完成
  FINISHED , // 已完成
  NOT_DEAL, // 无需处理
}

export interface ISearchCommentsQuery {
  ref_type: string // task -> 任务，record -> 记录
  ref_id: string // 关联id
  query_type: SearchCommentQueryType // 1->文件/图片，2->重点，3->所有工具，4->会议，5->公告，6->待办，7->时间征集，8->所有小工具（不包含已经X的
  order?: string // 排序，asc->正序，desc->倒序, 默认：desc
  page_number?: number // 查询页数
  page_record?: number // 查询条数
  pend_status?: SearchCommentPendStatus // 待办额外字段 0 全部(默认) 1未完成 2已完成 3无需处理
}

export interface ICommentsQuery {
  ref_type: string // 关联类型 task->任务，record->记录，project->项目，private_chat->私聊，group_chat->群聊，workspace->空间，member->会员
  ref_id: string // 关联id
  msg_type?: number // 消息类型, 1->操作记录，2->结论，3->纪要，4->最近回复消息，默认全部消息（不包含操作记录）
  msg_format?: string // 消息格式，0->txt，1->img，2->file，多个用逗号隔开
  order?: string // 排序，asc->正序，desc->倒序, 默认：desc
  last_comment_id?: string // 加载更多，列表最后一个评论id
  timestamp?: string // 时间戳，查询操作记录需要带上
  page_number?: number // 查询页数
  page_record?: number // 查询条数
}

export interface CommentFile {
  file_id?: string
  file_name: string
  file_size: number | string
}

export type IInitFile = {
  key: string
  path: string
  size: string | number
  icon: string
  thumb: string | ArrayBuffer | undefined | null
  fileId: string
  fileName: string
  fileSize: string
  fileOrigin?: FileUploadOrigin
  downloadStatus?: FileDownloadStatus
  uploadStatus?: FileUploadStatus
  isDownloadSilent?: boolean
}