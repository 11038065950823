import Http from "./http";
import { Hosts } from "./const";
// import { IGetListParams, IGetList } from './types/main'
import { DetailResponse } from './types/matter'
import { CommonResponse } from "@/service/types/http";

const host = Hosts.matter;

enum MatterDict {
  task = "/task",
  tasks = "/tasks",
}

class Matter extends Http {
  constructor() {
    super({ host, version: "v2" });
  }

  getDetail(id: string) {
    return this.get<DetailResponse>({
      url: `${MatterDict.task}/${id}`,
    });
  }

  getDetailIgnore(id: string) {
    return this.get<CommonResponse<DetailResponse>>({
      url: `${MatterDict.task}/${id}/auth_ignore`,
    });
  }

  getTakers(id: string) {
    return this.get<any>({
      url: `${MatterDict.task}/${id}/takers`,
    });
  }

  setTaskDispatchState(id: string, data: any) {
    return this.post({
      url: `/task_dispatch/${id}/state`,
      data,
      timeout: 3000,
    });
  }

  addTaskDispatch(data: any) {
    return this.post({ url: "/task_dispatch", data });
  }

  /**
   * 获取事项总结详情
   */
  getTaskSummary(taskId: string) {
    return this.get<any>({
      url: `/task_conclusion/${taskId}`,
    })
  }

  /**
   * 获取任务关联数据
   */
  getTaskRelation(taskId: string) {
    return this.get<any>({
      url: `${MatterDict.task}/${taskId}/relation`
    })
  }
}

export default new Matter();
