import React, { useState } from 'react';
import { useMemoizedFn } from 'ahooks'
import EmailLogin from './components/EmailLogin'
import CreateAccount from './components/CreateAccount'
import VerificationCode from './components/VerificationCode'
import { IOverseasCreateAccount } from "@/service/types/login.d";
import { Modal } from "antd";

export const EmailLoginModal = ({
  visible,
  onSuccess,
  onClose
}: {
  visible: boolean
  onSuccess: () => void
  onClose: () => void
}) => {
  const [toCreate, setToCreate] = useState(false)
  const [verificationCode, setVerificationCode] = useState(false)
  const [userInfo, setUserInfo] = useState<IOverseasCreateAccount>()

  const createCount = useMemoizedFn(() => {
    setToCreate(true)
  })

  const goToLogin = useMemoizedFn(() => {
    setToCreate(false)
    setVerificationCode(false)
  })

  const goToVerificationCode = useMemoizedFn(() => {
    setVerificationCode(true)
    setToCreate(false)
  })

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable
      onCancel={onClose}
      style={{ top: "20vh" }}
      destroyOnClose
      width="340px"
      mask={true}
      maskClosable={true}
    >
      {!toCreate && !verificationCode && (
        <EmailLogin onSuccess={onSuccess} setToCreate={createCount} />
      )}
      {toCreate && (
        <CreateAccount
          onSuccess={(data) => {
            setUserInfo(data)
          }}
          goToLogin={goToLogin}
          goToVerificationCode={goToVerificationCode}
        />
      )}
      {verificationCode && (
        <VerificationCode
          goToLogin={goToLogin}
          userInfo={userInfo}
          onSuccess={onSuccess}
        />
      )}
    </Modal>
  )
}