

// 登录/注册流程状态
enum LoginStatus {
  beforeCheckMobile = 1,
  mobileError,
  mobileSuccess,

  beforeCheckCode,
  codeError,
  codeSuccess,

  beforeCheckName,
  nameError,
  nameSuccess,
}

type IButtonProps = {
  disabled: boolean
  text: string
  status: LoginStatus
  clicked(): void
}

export type {
  IButtonProps,
}

export {
  LoginStatus,
}

