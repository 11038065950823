import { I18N } from '@flyele-nx/i18n'
import React from "react";

import styles from "../Task/index.module.scss";

type TProps = {
  title: string | undefined;
};

const Title = ({ title }: TProps) => {
  return <div className={styles.title}>{title || I18N.common.loadingData}</div>;
};

export default Title;
