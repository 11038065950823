import { FileType } from "type-define";

export const getFileSize = (length: number): string => {
  let count = 4;
  const suffixs = ["TB", "GB", "MB", "KB"];
  let size = length;
  while (count > 0) {
    size = parseFloat((size / 1024).toFixed(2));
    if (size < 1024) {
      return `${size.toFixed(2)}${suffixs[count - 1]}`;
    }
    count--;
  }
  return "";
};

export const getFileType = (fileName: string): FileType => {
  const suffix = fileName.substring(
    fileName.lastIndexOf(".") + 1,
    fileName.length
  );

  const dict: { [props: string]: RegExp } = {
    [FileType.ppt]: /(pptx)$/,
    [FileType.img]: /(png|jpe?g|PNG|JPE?G|gif)$/,
    [FileType.excel]: /(xlsx?|csv)$/,
    [FileType.word]: /(docx?)$/,
    [FileType.zip]: /(zip)$/,
  };
  for (const key of Object.keys(dict)) {
    if (dict[key].test(suffix)) {
      return key as FileType;
    }
  }
  return FileType.unknown;
};

export const isImage = (path: string): boolean => {
  return /(jpe?g|png|gif)$/.test((path || "").toLocaleLowerCase());
};

export const getFileName = (path: string) => {
  const arr = path.split("/");

  return arr[arr.length - 1];
};
