import { I18N } from '@flyele-nx/i18n'
import Login from "@/pages/Login"


export const LoginRouters = [
  {
    exact: true,
    path: '/login',
    component: Login,
    key: "login",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_login,
  }
]