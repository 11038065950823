import React, { FC } from 'react'
import './ModalInnerLayout.scss'

interface IProps {
  children?: any
  component: any
}

export const ModalInnerLayout: FC<IProps> = ({ children, component }) => {
  return (
    <div className="fx-modal-inner">
      {component.title && (
        <div className="fx-modal-inner__title">{component.title}</div>
      )}
      {component.content && (
        <div className="fx-modal-inner__content">{component.content}</div>
      )}
      {component.footer && (
        <div className="fx-modal-inner__footer">{component.footer}</div>
      )}

      {children}
    </div>
  )
}

export default ModalInnerLayout
