import { History } from 'history'
import { IProjectInfo } from "@/service/types/project"

interface Params {
  task: IProjectInfo
  history: History
}

const getHref = (history: History) => {
  // 当前的路径参数
  const { search }: any = history.location

  // 抽出参数
  const searchs = search.replace("?", "").split("&");

  console.log(searchs);
  const result: any = {};

  searchs.forEach((str: any) => {
    if (str) {
      const keyValue = str.split("=");

      if (keyValue.length === 2) {
        if (keyValue[0] && keyValue[1]) {
          const ValueOfKey: string = keyValue[0];
          const ValueOfName: string = keyValue[1];

          result[ValueOfKey] = ValueOfName;
        }
      }
    }
  });

  return result;
}

const checkSpaceDismiss = (params: Params) => {

  const {
    task,
    history,
  } = params

  const {
    ws_type
  } = task

  const {
    memberType,
  } = getHref(history)

  return ws_type === 2 && memberType === '2'

}

export {
  checkSpaceDismiss
}