import Http from "./http";
import { Hosts } from "./const";
import { ISearchCommentsQuery, ICommentsQuery } from "@/service/types/comment";

const host = Hosts.ui;

class Comment extends Http {
  constructor() {
    super({ host, version: "v2" });
  }

  /**
   * 搜索评论列表
   */
  searchComments(params: ISearchCommentsQuery) {
    return this.get<any>({
      url: `/comments/search`,
      params
    })
  }

  /**
   * 查看评论列表
   */
  getComments(params: ICommentsQuery) {
    return this.get<any>({
      url: `/comments`,
      params
    })
  }
}

export default new Comment();
