
import styles from './index.module.scss'
import { IBgProps } from './main'

const Background = (props: IBgProps) => {

  const {
    text
  } = props

  return (
    <div className={styles.bg}>
      {text}
    </div>
  )
}

export default Background