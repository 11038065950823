import React from 'react';
import PageLayout from '../components/PageLayout'
import AvatarBox from '../components/AvatarBox'
import TaskTitleBox from '../components/TaskTitleBox'
import TaskBgInfo from "@/pages/excel/components/TaskBgInfo";
import TaskInfoRow from "@/pages/excel/components/TaskInfoRow";
import MemberList from "@/pages/excel/components/MemberList";
import MeetingProgress from '../components/MeetingProgress'
import {useInit} from "@/pages/excel/hooks/fetchDetails";
import NoData from "@/pages/excel/components/NoData";

const ExcelMeeting = () => {
  const { task, takers, errCode } = useInit()

  return (
    <PageLayout task={task} inviteId={task?.creator_id}>
      { task ? (
        <div>
          <AvatarBox avatar={task.avatar} name={task.nick_name} desc="给你分享了这个会议~" />

          <TaskTitleBox title={task.title} create_at={task.create_at} name={task.nick_name} />

          <TaskBgInfo task={task} />

          <TaskInfoRow task={task} />

          <MemberList takers={takers} />

          <MeetingProgress taskId={task.task_id} />
        </div>
      ) : (
        <NoData code={errCode} />
      )}
    </PageLayout>
  )
}

export default ExcelMeeting