
import { ITask } from '@/service/types/matter'
import Background from '../Background'
import styles from './index.module.scss'

type IProps = {
  task: ITask
}

const MatterInfo = (props: IProps) => {

  const {
    task,
  } = props

  return (
    <div className={styles.info}>

      <div className={styles.info__title}>
        {(task as any).title}
      </div>

      <Background
        text={(task as any).detail}
      />

    </div>
  )
}

export default MatterInfo