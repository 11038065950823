import {CommentFile, CommentResponse, IInitFile, IParsedContent, MsgFormatType} from '@/service/types/comment.d'
import {convertSize, getFileIcon} from "utils_for_pc/file";

type ICommentFile = IInitFile & CommentFile


export const mapperParsedContent = ({
  item
}: {
  item: CommentResponse
}): CommentResponse & {
  parsedContent?: IParsedContent
  files?: ICommentFile[]
} => {
  try {
    const parsedContent: IParsedContent = JSON.parse(item.content)

    item.parsedContent = parsedContent

    const hasFile = [MsgFormatType.file, MsgFormatType.img].includes(
      item.msg_format
    )

    if (hasFile) {
      const fileIdArr: string[] = []

      item.content.replace(/"(\d)*?"/g, (id: string) => {
        fileIdArr.push(id.replaceAll('"', ''))
        return id
      })

      /**
       * 下面注释的代码，排序上有点问题，没有根据 json 字符串来排
       */
      const files = fileIdArr.map((id) => {
        const o: CommentFile = parsedContent[id]
        return {
          icon: getFileIcon(o.file_name),
          thumb: '',
          fileId: id,
          fileName: o.file_name,
          fileSize: convertSize(o.file_size),
          ...o,
        }
      })

      Object.assign(item, { files })
    }
  } catch (err) {
    console.error(err)
  }

  return item
}