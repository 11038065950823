import React from "react";
import styles from "./index.module.scss";

const FooterTextBtn = () => {
  return (
    <div className={styles.textBtn}>已接受</div>
  )
}

export default FooterTextBtn
