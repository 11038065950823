import React from "react";

import config from "config";

// import { downloadBtnClick } from "./evokeApp";

// import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
// import { SensorEvent } from "sensorsDataHook/const";
// import { isMobileAns } from "sensorsDataHook/isMobile";

import "./index.scss";
// import dayjs from "dayjs";

// const _getMiniProgramPath = (id: string, u: string): string => {
//   return `${config.invite}?taskId=${id}&from=wechat&from_user_id=${u}&shareFrom=2`;
// };

type TProps = {
  path: string;
  visible: boolean;
  set_visibleMask: React.Dispatch<React.SetStateAction<boolean>>;
  mobileClick: any;
};

function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if ((ua.match(/MicroMessenger/i) as unknown as string) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

const MaskModal = ({ path, visible, set_visibleMask, mobileClick }: TProps) => {
  return (
    <div>
      <div
        className="full-screen-mask"
        style={{ display: visible ? "block" : "none", top: 0, left: 0 }}
        onClick={() => {
          set_visibleMask(false);
        }}
      >
        <div
          className="footer-modal-mobile"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="line-a">
            <div className="pic">
              <img src="/image/logo.png" alt="" />
            </div>

            <div className="word">
              <div className="title">
                {/* {isWeiXin() ? "在飞项小程序中查看" : "在飞项App中查看"} */}
                已经打开微信小程序
              </div>
              <div className="desc" />
            </div>
          </div>

          <div className="line-b">
            <button
              style={{ fontSize: "large" }}
              type="button"
              className="no"
              onClick={() => {
                set_visibleMask(false);
              }}
            >
              取消
            </button>

            {isWeiXin() && (
              <div className="open-box">
                {/* @ts-ignore*/}
                <wx-open-launch-weapp
                  id="launch-btn"
                  username={config.miniProgramUsername}
                  path={path}
                >
                  <script type="text/wxtag-template">
                    {/* @ts-ignore*/}
                    <div
                      style={{
                        fontSize: "large",
                        width: "inherit",
                        height: "inherit",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        border: "none",
                      }}
                      onClick={() => {}}
                    >
                      重新打开
                    </div>
                  </script>
                  {/* @ts-ignore*/}
                </wx-open-launch-weapp>
              </div>
            )}

            {!isWeiXin() && (
              <button type="button" className="ok" onClick={mobileClick}>
                打开
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaskModal;
