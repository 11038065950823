import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import codeApi from "@/service/2dcode";

const WeixinSister = () => {
  const [ code ] = useState("");

  useEffect(() => {
    codeApi.get2dCode().then((res) => {
      console.log("@@@@@", res);
      // set_code();
    });
  }, []);

  return (
    <div className={styles.wx_back}>
      <div className={styles.wx_back__inner}>
        <img
          src="/assets/v1_7/sister@3x.png"
          alt=""
          className={styles.wx_back__inner__bannerpic}
        />

        <div className={styles.wx_back__inner__layer}>
          <div className={styles.info_title}>长按二维码，添加小姐姐账号</div>
          <div className={styles.info_2dcode}>
            <img src={code || "/assets/loading3.gif"} alt="" className="img" />
          </div>
          <div className={styles.info_help}>我可以帮你</div>

          <div className={styles.info_line}>
            <div className={styles.info_line_icon}>
              <img
                src="/assets/v1_7/p1.png"
                alt=""
                className={styles.info_line_icon_right_pic}
              />
              <div className={styles.info_line_icon_left_div}>快速创建</div>
            </div>

            <div className={styles.info_line_icon2}>
              <img
                src="/assets/v1_7/p2.png"
                alt=""
                className={styles.info_line_icon2_right_pic}
                style={{ background: "#F0FFFC" }}
              />
              <div
                className={styles.info_line_icon2_left_div}
                style={{ background: "#F0FFFC" }}
              >
                转存文件
              </div>
            </div>
          </div>

          <div className={styles.info_line}>
            <div className={styles.info_line_icon}>
              <img
                src="/assets/v1_7/p3.png"
                alt=""
                className={styles.info_line_icon_right_pic}
                style={{ background: "#FFF8F8" }}
              />
              <div
                className={styles.info_line_icon_left_div}
                style={{ background: "#FFF8F8" }}
              >
                时间提醒
              </div>
            </div>

            <div className={styles.info_line_icon2}>
              <img
                src="/assets/v1_7/p4.png"
                alt=""
                className={styles.info_line_icon2_right_pic}
                style={{ background: "#F1FAFF" }}
              />
              <div
                className={styles.info_line_icon2_left_div}
                style={{ background: "#F1FAFF" }}
              >
                更多功能
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeixinSister;
