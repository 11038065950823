import React from 'react'
import ShowCardBox from '../ShowCardBox'
import CardItemBox from '../CardItemBox'
import styles from "./index.module.scss";
import {ITask} from "@/service/types/matter";
import {getDate_v15_validity_date} from "@/utils_for_pc/getDateTxt";

interface IProps {
  task: ITask
}

const TodoTask = ({ task }: IProps) => {
  const { todo } =  task

  if (!todo || todo.length === 0) return null

  const getDateText = (childrenTask: any) => {
    const dateValue = getDate_v15_validity_date(childrenTask)
    const dateText = `${dateValue.firstPartOutput} ${dateValue.secondPartOutput ? ` — ${dateValue.secondPartOutput}`: ''}`
    return dateText.trim() || '无时间'
  }

  return (
    <ShowCardBox
      title="待办"
      height="521px"
      allText={todo.length > 5 ? `全部${todo.length}个` : ''}
    >
      <>
        {todo.slice(0, 5).map(item => (
          <CardItemBox
            key={item.id}
            title={item.todo_name}
            icon="/assets/todoIcon.png"
          >
            <div className={styles.timeBox}>
              {getDateText(item)}
            </div>
          </CardItemBox>
        ))}
      </>
    </ShowCardBox>
  )
}

export default React.memo(TodoTask)