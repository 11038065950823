export enum HolidayState {
  FURLOUGH = 1, // 放假
  DUTY, // 补班
}

export interface IArrangeTask {
  create_at: number
  dispatch_id: string
  identity: number
  matter_type: number
  state: number
  task_id: string
  title: string
  finish_time?: number
}

export interface IHoliday {
  date: string
  state: HolidayState
}

export interface IViewCount {
  total: number
}
