import React, {useEffect, useState} from 'react'
import ShowCardBox from '../ShowCardBox'
import CommentApi from "@/service/comment";
import { SearchCommentQueryType } from "@/service/types/comment.d";
import ChatFrame from '../ChatFrame'

interface IProps {
  taskId: string
}
const Importance = ({ taskId }: IProps) => {

  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)

  const fetchComments = async (id: string) => {
    const { code, data, total } = await CommentApi.searchComments({
      ref_type: 'task',
      ref_id: id,
      query_type: SearchCommentQueryType.IMPORTANT
    })

    if (code === 0) {
      setTotal(total)
      if (data) setList(data)
    }
  }

  useEffect(() => {
    fetchComments(taskId)
  }, [taskId])

  if (list.length === 0) return null

  return (
    <ShowCardBox
      title="重点"
      height="626px"
      allText={total > 6 ? `全部${total}个` : ''}
    >
      <ChatFrame data={list.slice(0, 6)} taskId={taskId} />
    </ShowCardBox>
  )
}

export default React.memo(Importance)