import dayjs from 'dayjs'
import ServiceDate from '@/service/date'

export const getDate = async () => {
  try {
    return await ServiceDate.getCurrentDate()
  } catch (e) {
    console.error('获取时间戳失败', e)
    // 时间戳获取失败, 暂时读取客户端时间
    return dayjs().unix()
  }
}

export const checkExpire = async (expireAt: string | number | undefined) => {
  const systemTime = await getDate()

  if (!expireAt) return false
  if (typeof expireAt === 'number') {
    return systemTime > expireAt
  } else {
    return systemTime > Number(expireAt)
  }
}