/* eslint-disable */
// @ts-nocheck
import { MatterType } from "@/service/const";
import { concatSearch } from "@/utils/tool";
import {useCallback, useEffect, useRef, useState} from "react";
import matterApi from "service/matter";

import { isMobile } from "./isMobile";
import sensors, {getTypeCName} from "@/sensorsDataHook/initSensors";
import {SensorEvent} from "@/sensorsDataHook/const";

export default function useGetTask(history: any) {

  const isLoginRef = useRef(false)

  // 事项id
  const [task_id, set_task_id] = useState("");

  // 参与人
  const [takers, set_takers] = useState([]);

  // 事项
  const [task, set_task] = useState<any>(
    // 事项
    {
      task_id: "",
      title: "错误的邀请链接",
      detail: "未找到该事项，请确认链接是否完整",
      start_time: undefined, // 开始时间
      end_time: undefined, // 结束时间
      remind_at: {
        start_remind: undefined, // 开始提醒
        end_remind: undefined, // 结束提醒
      },
      execute_addr: "",
      files: [],
    } as any
  );

  const [task_dispatch, set_task_dispatch] = useState<any | undefined>(
    undefined
  );

  const sendSensorsLock = useRef<boolean>(false)

  function getHref() {
    // 当前的路径参数
    const { search }: any = history.location;

    // 检查参数合法性

    // 抽出参数
    const searchs = search.replace("?", "").split("&");

    console.log(searchs);
    const result: any = {};

    searchs.forEach((str: any) => {
      if (str) {
        const keyValue = str.split("=");

        if (keyValue.length === 2) {
          if (keyValue[0] && keyValue[1]) {
            const ValueOfKey: string = keyValue[0];
            const ValueOfName: string = keyValue[1];

            result[ValueOfKey] = ValueOfName;
          }
        }
      }
    });

    // 实际taskId
    if (!search.includes("id=") || !result.id || !result.u) {
      history.push({
        pathname: `/invite/task_ans`,
        search: `?id=`,
      });
    }

    return [result.id, result.u];
  }

  // 检查token是否有效
  function isValidToken() {
    if (!localStorage.getItem("fx-token")) return false;

    return new Promise((resolve, reject) => {
      matterApi
        .getDetail(task_id)
        .then((res: any) => {
          // 先拿dispath_id
          set_task(res.data.task);
          set_task_dispatch(res.data.task_dispatch);
          resolve(true);
        })
        .catch(() => {
          localStorage.removeItem("fx-token");
          localStorage.removeItem("fx-user_id");
          // reject(false);
          resolve(false);
        });
    });
  }

  // 获取参与者
  function getTakers(taskId: string) {
    return matterApi
      .getTakers(taskId)
      .then((res) => {
        const newSorted = res.data.sort((n1: any, n2: any) => {
          console.log(n1.personal_state, n2.personal_state);
          if (!n1.personal_state) {
            return 9999;
          }
          if (!n2.personal_state) {
            return -9999;
          }
          return n1.personal_state - n2.personal_state;
        });

        console.log(newSorted);
        set_takers(newSorted);
        return res;
      })
      .catch(() => {});
  }

  // 获取事项详情
  function getDetail(taskId: string, useIgnore = false) {
    // useIgnore为 true时，则用
    // 无需权限的接口
    if (useIgnore) {
      return matterApi
        .getDetailIgnore(taskId)
        .then((res: any) => {
          set_takers(res.data.takers || []);
          return res;
        })
    }

    return matterApi
      .getDetail(taskId)
      .then((res: any) => {
        getTakers(taskId);

        return res;
      })
      .catch(() => {});
  }

  async function getDetailMain(needSensor?: boolean) {
    //
    let isValid = false;

    // 检查用哪个接口
    if (localStorage.getItem("fx-token")) {
      isValid = await isValidToken();
    } else {
      isValid = false;
    }

    isLoginRef.current = isValid

    // 请求
    // isValid 为 true， 则说明直接使用非auth_ignore的接口
    // isValid 为 false 则说明，token无效，使用auth_ignore的接口（传true激活无权限接口）
    getDetail(task_id, !isValid)
      .then((res: any) => {
        set_task(res.data.task);
        set_task_dispatch(res.data.task_dispatch);

        if (isValid && needSensor) {
          sendSensors(res.data.task)
        }
      })
      .catch((err) => {
        console.log('getDetailIgnore', err)
        
        if (err.code === 40021) {

          const search = concatSearch(history.location.search, {
            cancel: 1,
            // matter_type: history.location.pathname.includes("/invite_h5/meeting") ?  MatterType.会议 : MatterType.事项
            matter_type: err.dbg_error.includes('会议') ? MatterType.会议 : MatterType.事项
          })

          // code: 40021
          // dbg_error: "事项已取消"
          // message: "任务已取消"
          history.push({
            pathname: `/feedback/matter`,
            search,
          })
        }
      });
  }

  useEffect(() => {
    console.log("task_dispatch", task_dispatch);
    // if (!task_dispatch) return;
    // if (
    //   task_dispatch &&
    //   task_dispatch?.personal_state !== 10401 &&
    //   task_dispatch?.personal_state !== 10601
    // ) {
    //   history.push({
    //     pathname: `/invite/task_ans`,
    //     search: `${history.location.search}`.includes("?id=")
    //       ? `${history.location.search}`
    //       : `?id=${history.location.search}`,
    //   });
    // }

    if (!task_dispatch) {
      goDispatch('接受')
    }

    checkSpecial();
  }, [task_dispatch]);

  const checkSpecial = () => {

    console.log("checkSpecial", task_dispatch);

    if (!task_dispatch) return;
    if (
      task_dispatch 
      // &&
      // task_dispatch?.personal_state !== 10401 &&
      // task_dispatch?.personal_state !== 10601
    ) {

      history.push({
        pathname: `/feedback/matter`,
        search: history.location.search,
      })
      // history.push({
      //   pathname: `/invite/task_ans`,
      //   search: `${history.location.search}`.includes("?id=")
      //     ? `${history.location.search}`
      //     : `?id=${history.location.search}`,
      // });
    }
  };

  // 读取task数据
  useEffect(() => {
    if (!task_id || task_id === "") return;

    console.log("props.history", history.location.pathname, isMobile());

    sendSensorsLock.current = false

    if (!isMobile()) {
      if (history.location.pathname.includes("/invite_h5/task")) {
        history.push({
          pathname: "/invite/task",
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
        return;
      }

      if (history.location.pathname.includes("/invite_h5/meeting")) {
        history.push({
          pathname: "/invite/meeting",
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
        return;
      }
    }

    getDetailMain(true);
  }, [task_id]);

  useEffect(() => {
    if (!task) return;

    if (task?.matter_type === 10702 && document) {
      document.title = document.title.replace("事项", "会议");
    }

    const { pathname, search }: any = history.location;

    // if (task?.is_cancel && !pathname.includes("/invite/task_ans_canceled")) {
    console.log('ignore', task)

    if (
      task?.is_cancel && 
      !pathname.includes("/feedback/matter") &&
      isLoginRef.current
    ) {

      // TODO: 跳到新的结果页面
      history.push({
        pathname: `/feedback/matter`,
        search: history.location.search,
      })
      // history.push({
      //   pathname: `/invite/task_ans_canceled`,
      //   search: `${history.location.search}`.includes("?id=")
      //     ? `${history.location.search}`
      //     : `?id=${history.location.search}`,
      // });
    }
  }, [task]);

  // 操作
  async function dispatch_state(
    dispatch_id: string,
    personal_state: number,
    options: any = {}
  ) {
    let new_dispatch_id = undefined;
    if (!dispatch_id) {
      const afterAdd_dispatch_id = () =>
        new Promise((resolve, reject) => {
          matterApi
            .addTaskDispatch({
              from_user_id: getHref()[1],
              invite_type: "flyele",
              taker_id: [localStorage.getItem("fx-user_id")],
              task_id: getHref()[0],
            })
            .then((res) => {
              resolve(res.data);
            })
            .catch((e) => {
              console.log(e);
              localStorage.removeItem("fx-token");
              localStorage.removeItem("fx-user_id");
              location.reload(true);
              // reject(e);
              resolve(false);
            });
        });

      new_dispatch_id = await afterAdd_dispatch_id();

      console.log("我成功的afterAdd_dispatch_id", new_dispatch_id);
      Object.keys(new_dispatch_id.data).forEach((key) => {
        new_dispatch_id = new_dispatch_id.data[key];
      });
    }

    const real_dispatch_id = new_dispatch_id || dispatch_id;

    getDetailMain();

    return Promise.resolve()
    // return matterApi
    //   .setTaskDispatchState(real_dispatch_id, {
    //     personal_state,
    //     ...options,
    //   })
    //   .then((res: any) => {
    //     console.log("接受成功", res);
    //     return true;
    //   })
    //   .catch(() => {
    //     return false;
    //   });
  }

  // 提交修改
  function goDispatch(actionType = "接受", options: any = {}) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem("fx-token")) {
        checkSpecial();
        resolve(false);
      }

      matterApi
        .getDetail(task_id)
        .then(async (res: any) => {
          const dispatch_id = res?.data?.task_dispatch?.dispatch_id;

          const { pathname }: any = history.location;
          let code = -1;

          if (actionType === "接受") {
            // 提交成功
            if (pathname.includes("meeting")) code = 10611;
            if (pathname.includes("task")) {
              if (task_dispatch?.identity === 10804) {
                code = 10402;
              } else {
                code = 10409;
              }
            }

            if (await dispatch_state(dispatch_id, code)) resolve(true);
          }

          if (actionType === "请假") {
            if (pathname.includes("meeting")) code = 10604;

            if (await dispatch_state(dispatch_id, code, options)) resolve(true);
          }

          if (actionType === "拒绝") {
            if (pathname.includes("meeting")) code = 10603;
            if (pathname.includes("task")) code = 10403;

            if (await dispatch_state(dispatch_id, code, options)) resolve(true);
          }

          // reject(false);
          resolve(false);
        })
        .catch((e) => {
          console.log(e);
          // reject(false);
          resolve(false);
        });
    });
  }

  const sendSensors = useCallback((resTask?: any) => {
    const rid = new URLSearchParams(window.location.search).get("rid") ?? "";
    const useTask = resTask || task
    const sensorsParams = {
      business_id: useTask?.task_id,
      business_type: getTypeCName(useTask?.matter_type),
      // 邀请人ID
      invite_people_id: useTask?.creator_id,
      // 邀请分享ID
      invitation_share_id: rid,
      // 邀请分享类型
      invitation_share_type: "邀请链接",
    };

    if (sensorsParams.business_id && !sendSensorsLock.current) {
      console.log('埋点 click_invite_share 10月18日更新222', sensorsParams);
      sendSensorsLock.current = true

      sensors.sendPoint(SensorEvent.click_invite_share, sensorsParams);
    }
  }, [task])

  return { set_task_id, task, task_dispatch, goDispatch, takers, isValidToken, sendSensors };
}
