import React, { useState } from "react";
import RouterComponent from "components/router/index";

import "./App.less";

import "style/init.css";
import qs from "qs";
import "utils/wx_init";
import { codeLogin } from "utils/login";

import "event-source-polyfill/src/eventsource.min";
import { domain, Domain } from "service/const";

const showVConsole = () => {
  const vConsole = require("vconsole");
  new vConsole();
};
process.env.service !== "prod" && showVConsole();
(domain !== Domain.prod && domain !== Domain.prodEn) && showVConsole();

function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if ((ua.match(/MicroMessenger/i) as unknown as string) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

declare global {
  interface Window {
    isWeixin: any;
  }
}

window.isWeixin = isWeiXin;

let inCall = false;
// @ts-ignore
const App: React.FC<{}> = () => {
  const [isCodeLogin, setIsCodeLogin] = useState<boolean>(false);

  const custombind = location.href.includes('wx/code/custombind')
  console.log('custombind', custombind)

  if (!isWeiXin())
    return (
      <div>
        <RouterComponent />
      </div>
    );

  const params = qs.parse(window.location.search.substr(1)) as {
    [prop: string]: string;
  };
  console.log("网页来源:", document.referrer);

  if (params.code && !isCodeLogin && !custombind) {
    console.log("走登录流程", localStorage.getItem("code"));
    if (inCall || params.code === localStorage.getItem("code")) return null;
    setIsCodeLogin(true);

    localStorage.setItem("code", params.code);
    inCall = true;
    codeLogin({
      code: params.code,
      wx_id: params.wx_id || "",
    })
      .then((data) => {
        inCall = false;
        const isBind = !!data ? "bind" : "unbind";
        // 只有绑定页面带上是否绑定
        window.location.replace(
          window.location.href.replace(
            window.location.search,
            `?isBind=${isBind}`
          )
        );
        setTimeout(() => {
          setIsCodeLogin(false);
        }, 300);
        if (data === false) {
          console.log("登录失败了");
        } else {
        }
      })
      .catch((err) => {
        inCall = false;
      });
    return <React.Fragment />;
  }
  return <div>{!isCodeLogin && <RouterComponent />}</div>;
};

export default App;
