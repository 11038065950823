import { cloneDeep } from 'lodash'

/**
 * 获取列表接口返回的所有数据
 */
const fetchApiAllData = async (
  fetchClass: any, // 因为我们很多http请求都是包着个 class 的，所以需要把class传入。
  fetchFnName: any, // 上面class对应的请求
  params: {
    pageRecord?: number // 每次请求的数据量
    queryParams?: any // 列表接口的query参数
    responseHandler: (res: any) => void // 列表接口返回处理。在里面就可以获取到每次返回的列表数据
  }
) => {
  const { queryParams, pageRecord = 10, responseHandler } = params

  const page_record = pageRecord
  const page_number = 1
  let hasMore = true

  let query: any = {
    page_number,
    page_record,
  }

  if (queryParams) {
    const cloneQuery = cloneDeep(queryParams)

    query = {
      ...cloneQuery,
      ...query,
    }
  }

  do {
    const result = await fetchClass[fetchFnName](query)

    responseHandler(result)

    query.page_number++

    if (!result.data || result.data.length !== page_record) {
      hasMore = false
    }
  } while (hasMore)
}

export default fetchApiAllData
