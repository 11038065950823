import {useHistory} from "react-router-dom";
import {getUrlQuery} from "@/pages/excel/hooks/utils";
import {useEffect, useState} from "react";
import {ITask} from "@/service/types/matter";
import matterApi from "@/service/matter";

export const useInit = () => {
  const history = useHistory();
  const { id } = getUrlQuery(history, true)

  const [task, setTask] = useState<ITask>()
  const [takers, setTakers] = useState<TakersItem[]>([])
  const [errCode, setErrCode] = useState<number>(0)

  const fetchTaskDetails = async (taskId: string) => {
    try {
      const { code, data } = await matterApi.getDetailIgnore(taskId)
      if (code === 0) {
        const { task, takers } = data
        setTask(task)
        takers && setTakers(takers)
      } else {
        console.log('请求事项详情失败');
      }
    } catch (e) {
      const { code } = e
      setErrCode(code)
    }

  }

  useEffect(() => {
    fetchTaskDetails(id)
  }, [id])

  return {
    task,
    takers,
    errCode,
  }
}