import React, {useEffect, useState} from 'react'
import styles from './index.module.scss'

interface IProps {
  takers: TakersItem[]
}

const MemberList = ({ takers }: IProps) => {
  const [showTakers, setShowTakers] = useState<TakersItem[]>([])

  useEffect(() => {
    if (takers) {
      const takerArr = takers.filter(taker => {
        return taker.identity !== 10804 && taker.identity !== 10811
      })
      setShowTakers(takerArr)
    }
  }, [takers])

  if (showTakers.length === 0) return null

  return (
    <div className={styles.memberListRoot}>
      <div className={styles.memberTitle}>协作人</div>
      <div className={styles.memberList}>
        {
          showTakers.map(taker => {
            return (
              <div key={taker.taker_id} className={styles.avatarBox}>
                <img className={styles.avatar} src={taker.avatar} alt="头像" />

                { taker.state === 10404 && (
                  <div className={styles.finished}>
                    <img src="/assets/icons/avatar/finished.png" alt="已完成" />
                  </div>
                )}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default React.memo(MemberList)