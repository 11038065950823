import React, { useEffect, useState } from "react";

import { getDate_v15_validity_date, getLength } from "utils_for_pc/getDateTxt";

import styles from "../Task/index.module.scss";

type TProps = {
  task: any;
};

const TimeRange: React.FC<TProps> = ({ task }: TProps) => {
  const [startTime, set_startTime] = useState({
    year: "",
    day: "",
    time: "",
  });

  const [endTime, set_endTime] = useState({
    year: "",
    day: "",
    time: "",
  });

  useEffect(() => {
    if (!task) return;

    const { firstPartOutput, secondPartOutput } =
      getDate_v15_validity_date(task);

    const tempObj_start = {
      year: "",
      day: "",
      time: "",
    };

    const tempObj_end = {
      year: "",
      day: "",
      time: "",
    };

    try {
      if (firstPartOutput) {
        const first = firstPartOutput.split(" ");

        if (firstPartOutput.includes("年")) {
          tempObj_start.year = first[0];
          tempObj_start.day = first[1] + " " + first[2];
          if (first.length > 3) {
            tempObj_start.time = first[3];
          }
        } else {
          tempObj_start.day = first[0] + " " + first[1];
          if (first.length > 2) {
            tempObj_start.time = first[2];
          }
        }
      }

      if (secondPartOutput) {
        const first = secondPartOutput.split(" ");

        if (secondPartOutput.includes("年")) {
          tempObj_end.year = first[0];
          tempObj_end.day = first[1] + " " + first[2];
          if (first.length > 3) {
            tempObj_end.time = first[3];
          }
        } else {
          tempObj_end.day = first[0] + " " + first[1];
          if (first.length > 2) {
            tempObj_end.time = first[2];
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    set_startTime(tempObj_start);
    set_endTime(tempObj_end);
  }, [task]);

  return (
    <div className={styles["time-range"]}>
      <div className={styles["icon-time"]}>
        <img src="/assets/v1_5/time_2x.png" alt="" />
      </div>
      <div
        className={styles["box-start"]}
        style={{ paddingTop: 4 }}
      >
        {!task?.start_time ? (
          <div className={styles["line01"]}>无时间</div>
        ) : (
          <>
            {startTime.year && (
              <div className={styles["line01"]}>{startTime.year}</div>
            )}

            <div className={styles["line01"]}>{startTime.day}</div>
            <div className={styles["line02"]}>{startTime.time}</div>
          </>
        )}
      </div>

      {task?.start_time && task?.end_time && endTime.day && (
        <div className={styles["box-inner-time"]}>
          <span>{getLength(task)}</span>
        </div>
      )}

      <div
        className={styles["box-start"]}
        style={{ paddingTop: 4 }}
      >
        {endTime.year && <div className={styles["line01"]}>{endTime.year}</div>}

        <div className={styles["line01"]}>{endTime.day}</div>
        <div className={styles["line02"]}>{endTime.time}</div>
      </div>
    </div>
  );
};

export default TimeRange;
