import { I18N } from '@flyele-nx/i18n'
const getCName = (matterType: string, taker: any) => {
  const taskType = matterType
  const {
    state = undefined,
    identity = undefined,
    operate_state = undefined,
    personal_state = undefined,
  } = taker

  /**
   * 事项规则与名字
   * 1、派发人不显示，所以在前面10301已过滤
   * 2、已完成 - 已接受 - 未接受 - 已拒绝
   */
  if (taskType === '事项' || taskType === '待办') {
    // 1 已完成
    if ([10302, 10404].includes(state)) {
      return I18N.common.completed
    }

    // 2 已接受
    if (
      [10409].includes(personal_state) ||
      ([10801].includes(identity) && [10402].includes(state)) ||
      ([10810].includes(identity) && [10402].includes(state)) // 发起人的已接受
    ) {
      return I18N.common.accepted
    }

    // 3 未接受
    if ([10401].includes(personal_state)) {
      return I18N.common.notAccepted
    }

    // 4 已拒绝
    if ([10403].includes(personal_state)) {
      return I18N.common.rejected
    }
  }

  /**
   * 会议规则与名字
   * 1、主持人、已接受、已请假、已拒绝、未接受
   */
  if (taskType === '会议') {
    if ([10802].includes(identity)) {
      return I18N.common.compere
    }

    if ([10611].includes(personal_state)) {
      return I18N.common.accepted
    }

    if ([10604].includes(personal_state)) {
      return I18N.common.leaveTaken
    }

    if ([10603].includes(personal_state)) {
      return I18N.common.rejected
    }

    return I18N.common.notAccepted
  }

  /**
   * 会议规则与名字
   * 1、已读、未读
   */
  if (taskType === '公告') {
    if ([10408].includes(personal_state) || [10808].includes(identity)) {
      return I18N.common.read
    }
    return I18N.common.unread
  }

  return ''
}

export default getCName
