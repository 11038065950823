import {isWeiXin} from "@/pages/PureHooks/isMobile";
import {WindowType} from "@/pages/PureHooks/usePlatformCheck";
import {isMobile} from "@/pages/forMobileInvite/hooks/isMobile";

/**
 * 获取url上参数
 * history: History
 * import History from 'history'
 */
export const getUrlQuery = (history: any, justNeedId: boolean = false, idKey: string = 'id') => {
    const { search } = history.location;

    const searchs = search.replace('?', '').split('&');

    const result: any = {};

    searchs.forEach((str: string) => {
        if (str) {
            const keyValue = str.split("=");

            if (keyValue.length === 2) {
                if (keyValue[0] && keyValue[1]) {
                    const ValueOfKey: string = keyValue[0];
                    result[ValueOfKey] = keyValue[1];
                }
            }
        }
    });

    if (justNeedId) {
        return { id: result[idKey] }
    }
    return result
}

export const getNowWindowType = () => {
    let type = WindowType.PC浏览器
    if (isWeiXin()) {
        type = WindowType.微信浏览器;
    } else if (isMobile()) {
        type = WindowType.手机浏览器
    }

    return type
}