import { useEffect, useState } from 'react'

export default (task: any) => {
  // 解析后的Json地址字符串
  const [address, setAddress] = useState(undefined)
  const [addressInput, setAddressInput] = useState(undefined)

  // 更新地址数据
  useEffect(() => {
    if (!task) return
    if (!task.execute_addr || task.execute_addr === '') return
    try {
      console.log(
        'JSON解析后的task.execute_addr',
        JSON.parse(task.execute_addr).select
      )
      const { address: jsonAddress } = JSON.parse(task.execute_addr).select
      const { input: jsonAddressInput } = JSON.parse(task.execute_addr)

      setAddress(jsonAddress)
      setAddressInput(jsonAddressInput)
    } catch (e) {
      console.error(task)
    }
  }, [task])

  return [address, addressInput]
}
