import { I18N } from '@flyele-nx/i18n'
import React from 'react';
import styles from './index.module.scss'

export const AccountError = () => {
  return (
    <div className={styles.accountErrorRoot}>
      <div className={styles.imgBox}>
        <img src="/assets/icons/warmingCircle.svg" alt="warming"/>
      </div>
      <div className={styles.text}>{I18N.common.currentIdentityNone}</div>
    </div>
  )
} 