type ILang = 'zh-CN' | 'en-US'

function initLang(lang: ILang) {
  console.log('lang', lang)
  ;(global as any).lang = lang
}

function getLang() {
  return ((global as any).lang || 'zh-CN') as ILang
}

const lang = new URLSearchParams(window.location.search).get('lang') as ILang ?? 'zh-CN';

// 初始化语言
initLang(lang)

export { initLang, getLang }
