import {getRouteSearch} from '@/utils/tool'
import 'antd-mobile/lib/toast/style/index'
import 'antd-mobile/lib/modal/style/index'
import 'antd-mobile/lib/list/style/index'
import 'antd-mobile/lib/radio/style/index'
import {History} from 'history'
import {useEffect, useRef, useState} from 'react'
import { Modal, List, Radio, Toast } from 'antd-mobile'
import { isWeiXinWork } from '@/pages/PureHooks/isMobile'
import Form from './components/Form'
import styles from './index.module.scss'
import officialApi from "@/service/official";
import {IUserCorp} from "@/service/types/official";
import {useMemoizedFn} from "ahooks";
import loginWithTokenApi from "@/service/loginWithToken";
import util from "@/pages/Wx2dCode/util";

type IProps = {
  children: any
  history: History
}

const RadioItem = Radio.RadioItem

const Login = (props: IProps) => {
  const { history } = props
  const preRouteRef = useRef({} as any)
  const goBackOriginPage = useRef(false)
  const phoneNum = useRef<string>('')
  const [showModal, setShowModal] = useState(false)
  const [personal, setPersonal] = useState<IUserCorp>()
  const [corpList, setCorpList] = useState<IUserCorp[]>([])
  const [selectUser, setSelectUser] = useState<string>('')

  /**
   * 关闭弹窗
   */
  const onCloseModal = () => {
    setShowModal(false)
  }

  /**
   * 选择企业用户
   */
  const onChooseUser = (id: string) => {
    setSelectUser(id)
  }

  /**
   * 普通登录
   */
  const normalNext = () => {
    const {
      pathname,
      search,
    } = preRouteRef.current

    const pn = pathname.includes('invite_h5/task')
      ? 'matter'
      : pathname.includes('invite_h5/meeting')
        ? 'matter'
        : pathname.includes('invite/project')
          ? 'project'
          : pathname.includes('invite_h5/space')
            ? 'space'
            : ''

    if (!pn && !goBackOriginPage.current) {
      return
    }

    let pathNameValue = `/feedback/${pn}`
    if (goBackOriginPage.current) {
      pathNameValue = pathname
    }

    const nextRoute = {
      pathname: pathNameValue,
      search,
    }

    history.push(nextRoute)
  }

  /**
   * 获取用户列表
   * 并
   * 显示选择框
   */
  const fetchUserCorpList = async () => {
    const { data } = await officialApi.getUserCorpList()

    if (data) {
      const { personal, corp_user } = data
      if (personal) {
        setPersonal(personal)
      }
      if (corp_user) {
        setCorpList(corp_user)
      }
      setShowModal(true)
    } else {
      Toast.fail('获取用户企业列表失败')
    }
  }

  /**
   * 选择企业微信登录
   */
  const onSelectLogin = useMemoizedFn(async () => {
    const deviceParams = await util.getLoginKeyParams()
    try {
      const { data: { code, data }} = await loginWithTokenApi.getSelectLogin({
        device: deviceParams.device,
        telephone: phoneNum.current,
        user_id: selectUser,
      })

      if (code === 0) {
        const { Token, user_id } = data
        if (Token) localStorage.setItem("fx-token", Token)
        if (user_id) localStorage.setItem("fx-user_id", user_id)
        onCloseModal()
        normalNext()
      } else {
        Toast.fail('企业微信用户登录失败')
      }
    } catch (e) {
      Toast.fail('企业微信用户登录失败')
    }
  })

  const toNext = ({phone}: { phone: string }) => {
    phoneNum.current = phone
    if (isWeiXinWork()) {
      fetchUserCorpList()
    } else {
      normalNext()
    }
  }

  useEffect(() => {
    const search = getRouteSearch(history.location.search)

    if (search.from) {
      try {
        const str = decodeURIComponent(search.from)

        preRouteRef.current = JSON.parse(str)
      } catch (err) {
        console.error(err)
      }
    }

    // 返回原来页面
    if (search.goBackOrigin === 'true') {
      goBackOriginPage.current = true
    }

  }, [history])

  return (
    <div className={styles.login}>
      <div className={styles.login__banner}>
        <img src='/assets/v2_0/login_banner.png' alt="" />
      </div>

      <Form next={toNext} />

      <Modal
        popup
        visible={showModal}
        maskClosable={false}
        closable={false}
        onClose={onCloseModal}
        animationType="slide-up"
      >
        <div>
          {personal && (
            <List renderHeader={() => <div>个人</div>} className="popup-list">
              <RadioItem key={personal.user_id} checked={selectUser === personal.user_id} onChange={() => onChooseUser(personal.user_id)}>
                {personal.nick_name}
              </RadioItem>
            </List>
          )}
          {corpList && corpList.length > 0 && (
            <List renderHeader={() => <div>企业</div>} className="popup-list">
              {corpList.map(i => (
                <RadioItem key={i.user_id} checked={selectUser === i.user_id} onChange={() => onChooseUser(i.user_id)}>
                  {i.corp_name}
                </RadioItem>
              ))}
            </List>
          )}
          <List.Item>
            <div className={styles.btn} onClick={onSelectLogin}>
              确定
            </div>
          </List.Item>
        </div>
      </Modal>
    </div>
  )

}

export default Login