
import styles from './index.module.scss'

type IProps = {
  children?: any
}

const Card = (props: IProps) => {

  return (
    <div className={styles.card}>
      {props.children}
    </div>
  )
}

export default Card