import { I18N } from '@flyele-nx/i18n'
import Button from "../button"
import styles from './index.module.scss'
import { ILoginBtnProps } from "./main"



const LoginBtn = (props: ILoginBtnProps) => {

  const { onClick, showAccountError = false } = props

  return (
    <Button
      className={styles.btn}
      text={showAccountError ? I18N.common.changeAnAccountAndRepeat : I18N.common.immediatelyAfterLogin}
      onClick={onClick && onClick}
    />
  )

}

export default LoginBtn