import React from "react";

import dayjs from "dayjs";

import "./ListItem.scss";
import { getDate_v15_validity_date } from "utils_for_pc/getDateTxt";

const styles = {
  item: "item",
  item_right: "item_right",
  line_active: "line_active",
  line: "line",
};

const ListItem = ({ item = {}, sort_index_for_line, sort_index }: any) => {
  const getTime = (item: any) => {
    const now_YYYY = dayjs().format("YYYY年");

    // 是不是同年
    const isSameYear_start_time =
      dayjs.unix(item.start_time).format("YYYY年") === now_YYYY;
    const isSameYear_end_time =
      dayjs.unix(item.end_time).format("YYYY年") === now_YYYY;

    // 仅有开始时间
    // MM月DD日 HH:mm 开始
    if (item.start_time && !item.end_time) {
      return dayjs
        .unix(item.start_time)
        .format(
          `${
            isSameYear_start_time
              ? ""
              : dayjs.unix(item.start_time).format("YYYY年 ")
          }MM月DD日 HH:mm 开始`
        );
    }
    // 仅有截止时间
    if (!item.start_time && item.end_time) {
      return dayjs
        .unix(item.end_time)
        .format(
          `${
            isSameYear_end_time
              ? ""
              : dayjs.unix(item.end_time).format("YYYY年 ")
          }MM月DD日 HH:mm 截止`
        );
    }
    // 区间时间
    if (item.start_time && item.end_time) {
      const isSameDay =
        dayjs.unix(item.start_time).format("YYYYMMDD") ===
        dayjs.unix(item.end_time).format("YYYYMMDD");

      const first = dayjs
        .unix(item.start_time)
        .format(
          `${
            isSameYear_start_time
              ? ""
              : dayjs.unix(item.start_time).format("YYYY年 ")
          }MM月DD日 HH:mm - `
        );

      const second = dayjs
        .unix(item.end_time)
        .format(
          `${
            isSameYear_end_time
              ? ""
              : dayjs.unix(item.end_time).format("YYYY年 ")
          }MM月DD日 HH:mm`
        );

      const second_noDay = dayjs.unix(item.end_time).format("HH:mm");

      if (isSameDay) {
        return `${first}${second_noDay}`;
      }

      return `${first}${second}`;
    }

    return "";
  };

  return (
    <div
      style={{
        borderTop:
          sort_index_for_line === sort_index
            ? "2px solid rgba(29, 210, 193, 1)"
            : "2px solid rgba(255, 255, 255, 0)",
        height: 43,
      }}
    >
      <div
        className={styles.item}
        style={{
          opacity: 1,
        }}
      >
        <div>{`${item?.title}`}</div>
        <div className={styles.item_right}>
          {/* <span>{getTime(item)}</span> */}
          <span>
            {`${getDate_v15_validity_date(item)?.output}`.replace(
              "添加时间",
              ""
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
