import React, { FC } from "react";
import "./OneCapsule.scss";

interface IProps {
  title: string | any;
  disableColor?: boolean;
  iconType: string;
  paddingLeft?: any;
}

const styles = {
  "fx-capsule": "fx-capsule",
  "fx-capsule-one": "fx-capsule-one",
  "fx-capsule-one_gray": "fx-capsule-one_gray",
  "fx-capsule-one_default": "fx-capsule-one_default",
};

export const OneCapsule: FC<IProps> = ({
  title,
  disableColor,
  iconType,
  // paddingRight,
}) => {
  const getIconSrc = () => {
    switch (iconType) {
      case "时间区间":
        return "/small-tools/meeting/time.png";
      case "提醒时间":
        return "/small-tools/meeting/alert.png";
      case "地址":
        return "/small-tools/meeting/pos.png";
      default:
        return "";
    }
  };

  return (
    <button
      type="button"
      className={styles["fx-capsule"]}
      // style={{ paddingRight }}
    >
      <div className={styles["fx-capsule-one"]}>
        <img src={getIconSrc()} alt="" style={{ width: 16, marginRight: 5 }} />
        <span
          className={
            disableColor
              ? styles[`fx-capsule-one_gray`]
              : styles[`fx-capsule-one_default`]
          }
        >
          {title}
        </span>
          

      </div>
    </button>
  );
};
