import React, { PropsWithChildren } from 'react';
import { Popover } from 'antd'
import styles from './index.module.scss'

const _ChooseLoginType = ({ visible, children, onChoose }: PropsWithChildren<{ visible: boolean, onChoose: (type: string) => void }>) => {
  const loginType = [
    {
      key: 'corp_weChat',
      title: '企业微信登录',
      icon: '/assets/icons/login/corp_weChat.svg',
    },
    {
      key: 'weChat',
      title: '微信登录',
      icon: '/assets/icons/login/weChat.svg',
    },
    {
      key: 'flyele',
      title: '飞项登录',
      icon: '/assets/icons/login/flyele.svg',
    },
  ]

  return (
    <Popover
      visible={visible}
      content={() => {
        return (
          <div className={styles.loginTypeRoot}>
            <div className={styles.top}>
              <img src="/assets/icons/login/line.svg" alt="line" />
              <div className={styles.topTitle}>登录方式</div>
              <img src="/assets/icons/login/line.svg" alt="line" />
            </div>
            <div className={styles.content}>
              {loginType.map(item => {
                return (
                  <div
                    key={item.key}
                    className={styles.contentItem}
                    onClick={() => onChoose(item.key)}
                  >
                    <div className={styles.itemIcon}>
                      <img src={item.icon} alt=""/>
                    </div>
                    <div className={styles.contentTitle}>{item.title}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }}
    >
      {children}
    </Popover>
  )
}

export const ChooseLoginType = React.memo(_ChooseLoginType)