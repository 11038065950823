import React, {useState, useEffect, useMemo, useCallback} from "react";
import style from './index.module.scss'
import { Form, Input, message } from 'antd'
import cs from "classnames";
import util from './utils'
import loginApi from "service/login";
import sensors from "@/sensorsDataHook/initSensors";
import { debounce } from 'lodash'

interface Props {
  onSuccess?: () => void
}

type ChangeValue = {
  [P in 'account' | 'password']: string
}

const AccountNumberLogin: React.FC<Props> = ({ onSuccess }) => {
  const [form] = Form.useForm()
  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(true) // 是否可以点击提交

  useEffect(() => {
    if (account && password) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [account, password])

  const onChange = (changedValues: ChangeValue) => {
    const entries = Object.entries(changedValues)

    entries.forEach((item) => {
      switch (item[0]) {
        case 'account':
          setAccount(item[1])
          break
        case 'password':
          setPassword(item[1])
          break
        default:
          console.log('未匹配')
      }
    })
  }

  const login = useCallback(async () => {
    const params = await util.getAccountLoginParams(account, password)

    loginApi.accountLogin(params)
      .then(async (res) => {
      localStorage.setItem("fx-token", res.data.Token);
      sensors.sender.login(res.data.user_id);
      localStorage.setItem("fx-user_id", res.data.user_id);

      if (onSuccess) {
        setTimeout(() => {
          onSuccess();
        }, 233);
      }

    })
      .catch((err) => {
        console.log('登录错误', err)
        message.error('用户名或密码错误')
      })
  }, [account, onSuccess, password])

  const onSubmit = useCallback(() => {
    form
      .validateFields()
      .then(async () => {
        login()
      })
      .catch((err) => {
        console.log('校验不通过', err)
      })
  }, [form, login])

  const debounceSubmit = useMemo(() => {
    return debounce(onSubmit, 500)
  }, [onSubmit])

  return (
    <div className={style.phone_number_login}>
      <header className={style.login_header}>
        <h1>登录</h1>
      </header>
      <div className={style.form_wrap}>
        <Form
          form={form}
          name="login"
          layout="vertical"
          onValuesChange={onChange}
          onKeyDown={(e) => e.key === 'Enter' && debounceSubmit()}
        >
          <Form.Item name="account" label="用户名" wrapperCol={{ span: 24 }}>
            <Input
              allowClear
              className={style.form_wrap__custom_input}
              placeholder="请输入用户名"
            />
          </Form.Item>

          <Form.Item name="password" label="密码" wrapperCol={{ span: 24 }}>
            <Input.Password
              className={cs(style.form_wrap__custom_input, {
                [style.passwordInput]: !password,
              })}
              placeholder="请输入密码"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <button disabled={disabled} onClick={debounceSubmit} type="button">
              登录
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default AccountNumberLogin
