import { useMount } from "ahooks";
import css from "./index.module.scss";
import { ReactComponent as FlyEleIcon } from "@/icons/flyele.svg";
import userApi from "@/service/user";
import { useState } from "react";
import { VipMember } from "@/service/types/user";
import { downloadUrl } from '@/constants/url'

export const MyMembers = () => {
  const [list, setList] = useState<VipMember.UserInteracts[]>([]);
  useMount(async () => {
    const res = await userApi.getInteracts({
      add_type: 2,
    });
    setList(res.data);
  });

  const buildEmpty = () => {
    return <span className={css.empty}>暂未有好友接受邀请</span>;
  };

  return (
    <div className={css.container}>
      <div className={css.box_wrap}>
        <div className={css.box}>
          <div className={css.header}>
            <div className={css.icon}>
              <FlyEleIcon />
            </div>
            <span className={css.title}>成为飞项协作人</span>
            <span className={css.desc}>一起用飞项协作吧！</span>
          </div>
          <div className={css.list_wrap}>
            <div className={css.list_title}>以下好友已成为你的协作人</div>
            <div className={css.list}>
              {list.length
                ? list.slice(0, 10).map((item) => (
                    <div className={css.item} key={item.related_with_uid}>
                      <img className={css.avatar} src={item.avatar} alt="" />
                      <span className={css.name}>
                        {item.nick_name || item.original_name || ""}
                      </span>
                    </div>
                  ))
                : buildEmpty()}
            </div>
          </div>
        </div>
        <button
          className={css.btn}
          type="button"
          onClick={() => {
            location.href = downloadUrl;
          }}
        >
          打开飞项
        </button>
      </div>
    </div>
  );
};
