import { I18N } from '@flyele-nx/i18n'
import React, { useState } from 'react';
import {History} from 'history'
import styles from "./index.module.scss";
import MaskModal from "@/pages/forMobileInvite/component/MaskModal";
import SmallTypeModal from "@/pages/forMobileInvite/component/MaskModal/smallType";
import {useGetTarget} from "@/pages/InviteTarget/hooks/useGetTarget";
import targetCancelImg from "@/icons/targetCancel.png";
import targetBgIcon from "@/icons/targetBgIcon.png";
import FooterBtnAction from "./components/FooterBtnAction";
import {WindowType} from "@/pages/PureHooks/usePlatformCheck";
import {AccountError} from "@/components/account-error";
import cs from 'classnames'

type TProps = {
  history: History;
}

const InviteTargetH5 = (props: TProps) => {
  const { history } = props;
  const [visibleMask, set_visibleMask] = useState(false);

  const {
    mySelf,
    targetInfo,
    inviter,
    isValidToken,
    isTargetCancelled,
    urlParams,
    creatorIsVip,
    nowWindowType,
    showAccountError,
    showCanNotAdd,
    joinToTarget,
  } = useGetTarget(history)


  return (
    <div className={styles.targetInviteH5Root}>
      {nowWindowType !== WindowType.企业微信浏览器 && (
        <>
          <MaskModal
            invtiter_id={inviter?.user_id}
            task={targetInfo}
            visible={visibleMask}
            set_visibleMask={set_visibleMask}
            isSpace={true}
            dispatch_at={urlParams?.dispatch_at}
          />

          <SmallTypeModal
            invtiter_id={inviter?.user_id}
            task={targetInfo}
            // visible={visibleMask}
            visible={false}
            set_visibleMask={set_visibleMask}
            isSpace={true}
            dispatch_at={urlParams?.dispatch_at}
          />
        </>
      )}

      {
        isTargetCancelled ? (
          <div className={styles.cancelRoot}>
            <div className={styles.cancelImg}>
              <img src={targetCancelImg} alt=""/>
            </div>
            <div className={styles.cancelText}>
              {I18N.common.targetHasBeenTaken}</div>
          </div>
        ) : (
          <div className={cs(styles.contentRoot, {[styles.mt0]: nowWindowType === WindowType.企业微信浏览器})}>
            <div className={styles.bgHeader} />
            <div className={styles.bgIcon}>
              <img src={targetBgIcon} alt="bgIcon"/>
            </div>

            {showAccountError ? (
              <AccountError />
            ) : (
              <div className={styles.content}>
                <div className={styles.title}>
                  {
                    showCanNotAdd ? (
                      <span className={styles['wait-text']}>{I18N.common.unableToJoin}</span>
                    ) : mySelf ?
                      mySelf.state === 1 ? (
                        <span className={styles['invite-text']}>{I18N.common.joinedTarget}</span>
                      ) : (
                        <span className={styles['wait-text']}>{I18N.common.afterAdministratorApproval}</span>
                      ) : creatorIsVip ? (
                        <>
                          <span className={styles['invite-text']}>{I18N.common.invite}</span>
                          <span>{I18N.common.youJoinedTheTarget}</span>
                        </>
                      ) : targetInfo?.type === 1 ? (
                        <span className={styles['wait-text']}>{I18N.common.targetCreatorNotVip}</span>
                      ) : (
                        <span className={styles['wait-text']}>{I18N.common.unableToJoin}</span>
                      )
                  }
                </div>
                <div className={styles.inviteInfo}>
                  <div className={styles.avatarBox}>
                    <img src={inviter.avatar} alt="avatar" />
                  </div>
                  <div className={styles.name}>{inviter.nick_name}</div>
                </div>
                <div className={styles.contentBlock}>
                  <div className={styles.contentTitle}>{`【${I18N.common.objectives}】`}</div>
                  <div className={styles.contentDesc}>
                    {targetInfo?.title}
                  </div>
                </div>

                <div className={styles.divider} />


                <div className={styles.contentBlock}>
                  <div className={styles.contentTitle}>{I18N.common.executor}</div>
                  <div className={styles.contentDesc}>
                    <div className={styles.memberList}>
                      {
                        targetInfo?.objective_member?.map(item => {
                          return (
                            <img
                              alt=""
                              src={item.avatar}
                              key={item.user_id}
                              className={styles.avatar}
                            />
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isValidToken && creatorIsVip && !showCanNotAdd && (
              <div className={styles.footer}>
                <FooterBtnAction history={history} showAccountError={showAccountError} loginSuccess={joinToTarget} />
              </div>
            )}
          </div>
        )
      }
    </div>
  )
}

export default InviteTargetH5
