import React, { FC } from 'react'
import './Avatar.scss'

interface IProps {
  src?: string | undefined
  size?: string
}

export const Avatar: FC<IProps> = ({ src = undefined, size = 'default' }) => {
  return (
    <div className={`fx-avatar-${size}`}>
      <img src={src} alt="" />
    </div>
  )
}

export default Avatar
