import React from "react";

import styles from "./index.module.scss";

import Takers from "ComponentRewrite/Takers/Takers";
import { Wx2dCode } from "pages/Wx2dCode";

import FooterBtnAction from "./FooterAttendBtn";

const SpaceInvite = () => {
  const nick_name = "Master";
  const title = "研发";
  const creator = "Master";
  const content =
    "团中确年儿可点适正办十并这需其群清完都县由率斗或八安一南精传委或片一力期广写非万老明然下所白太军的正部变土提高。复按强状近广低只立基克般路形厂步斗海提满些己口点气及月员则太样…";

  const hasMore = true;

  const showMore = false;

  const takers: Array<any> = [];

  return (
    <div className={styles.bodylayer}>
      <div className={styles.inner}>
        <div className={styles.pc_title}>
          <span>{nick_name}</span>
          <span className={styles.pc_title_blue}> 邀请</span>
          <span>你加入空间</span>
        </div>

        <div className={styles.pc_icon}>
          <img src="#" alt="" />
          <div className={styles.pc_icon_bandage}>专业版</div>
        </div>

        <div className={styles.pc_space_name}>{title}</div>

        <div className={styles.pc_space_creator}>空间创建人：{creator}</div>

        <div className={styles.pc_space_desc}>
          <div className={styles.pc_space_desc_title}>空间描述</div>
          <div className={styles.pc_space_desc_content}>
            {content}
            <div className={styles.pc_space_files}></div>
            {hasMore && !showMore ? (
              <div className={styles.more}>查看更多</div>
            ) : null}
          </div>
        </div>

        <div className={styles.pc_space_desc}>
          <div className={styles.pc_space_desc_title}>空间成员</div>
          <div className={styles.pc_space_desc_content}>
            <Takers envTypeName="事项" takers={takers} />
          </div>
        </div>
      </div>

      <FooterBtnAction />

      <Wx2dCode
        onSuccess={() => {
          // setAcceptModalVisible(false);
          // handleClick(lastAction);
          // isValidToken();
        }}
      />
    </div>
  );
};

export default SpaceInvite;
