
import { I18N } from '@flyele-nx/i18n'
import { useEffect, useState } from 'react'
import { InviteType } from '../../hooks/useGetMatter'
import styles from './index.module.scss'
import cs from 'classnames'
import { JoinType } from '../../types/main'
import { ITask } from '@/service/types/matter'
import { VipMember } from '@/service/types/user'

type IProps = {
  joinStatus: JoinType
  children?: any
  task: any
  inviteType: InviteType
  vipInfo: VipMember.MemberApi | VipMember.ProjectPower
}

const getStatusText = (data: {joinStatus: JoinType, inviteType: InviteType, task: ITask, vipInfo: IProps['vipInfo']}) => {

  const {
    joinStatus,
    inviteType,
    task,
    vipInfo,
  } = data

  const result = {
    class: joinStatus,

    status: {
      [JoinType.joined]: [10701, 10702].includes(task.matter_type) ? I18N.common.accepted : I18N.common.joined,
      [JoinType.ongoing]: I18N.common.h5_underReview,
      [JoinType.unapproved]: I18N.common.h5_reviewFailed,
      [JoinType.fail]: I18N.common.unableToJoin,
      [JoinType.none]: '',
      [JoinType.askForLeave]: I18N.common.leaveTaken,
      [JoinType.refused]: I18N.common.rejected,
    }[joinStatus],

    get tips () {

      if (joinStatus === JoinType.ongoing) {

        return inviteType === InviteType.matter
          ? I18N.common.h5_afterApproval
          : inviteType === InviteType.project
            ? I18N.common.h5_afterReview
            : inviteType === InviteType.space
              ? I18N.common.h5_afterReview
              : ''
      }

      if (joinStatus === JoinType.fail) {

        if (inviteType === InviteType.matter) {

          const max = (vipInfo as VipMember.MemberApi)?.task_equity?.task_interact_num || 20

          if (max === 20) {

            return `${I18N.template(I18N.common.h5_numberOfCollaborators, { val1: max })}<br />${I18N.common.h5_creatorCanIncreaseTheNumber}`

          } else {

            return I18N.template(I18N.common.h5_projectCollaborator, { val1: max })
          }
        } 
        
        if (inviteType === InviteType.project) {

          // const max = (vipInfo as VipMember.ProjectPower).project_equity.project_member_num
        }
      }

      return ''
    },
  }

  // 无法加入
  // '协作人数量已满20人<br />一级事项创建人升级会员后可提升协作人数'
  // '事项协作人数已达100人'

  switch (inviteType) {

    case InviteType.matter:
      // 事项/会议
      // 已加入/审核中/审核未通过
      // Object.assign(result, { status: '已加入', class: joinType })
      break

    case InviteType.project:
      // 已加入/审核中/审核未通过
      // Object.assign(result, { status: '已加入', class: Type.joined })
      break

    case InviteType.space:
      // 已加入/审核中/审核未通过
      // Object.assign(result, { status: '已加入', class: Type.joined })
      break

    default: break
  }

  return result
}

const Info = (props: IProps) => {

  const {
    joinStatus,
    task,
    inviteType,
    vipInfo,
  } = props

  const [feedback, setFeedback] = useState<ReturnType<typeof getStatusText>>({} as any)


  const nameKey = inviteType === InviteType.space
    ? 'creator_name'
    : inviteType === InviteType.matter
      ? 'nick_name'
      : 'nick_name'

  useEffect(() => {

    setFeedback(getStatusText({joinStatus, inviteType, task, vipInfo}))

  }, [inviteType, joinStatus, task, vipInfo])

  return (
    <div className={styles.info}>
      
      <div>
        <div className={styles["info-avatar"]}>

          <img 
            src={task.avatar} 
            alt=""
          />

        </div>

        <div className={styles["info-name"]}>
          {task[nameKey]}
        </div>
      </div>

        <div className={cs(styles['info-status'], styles[`info-status--${feedback.class}`])}>
          { feedback.status }
        </div>

      <div className={styles['info-tips']} 
        dangerouslySetInnerHTML={{__html: feedback.tips || ''}}
      />

    </div>
  )
}

export default Info