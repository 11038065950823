const isImage = (path: string): boolean => {
  return /(jpe?g|png|gif)$/.test(path)
}

const getFileIcon = (name: string, prefix = 'sm') => {
  const dict: any = {
    sm: '/assets/icons/file/icon-',
    lg: '/assets/icons/file/icon-',
    mask: '/assets/mask/',
  }

  const reg: any = {
    image: /(jpe?g|png|gif)$/g,
    word: /(docx?)$/g,
    excel: /(xlsx?)$/g,
    ppt: /(pptx?)$/g,
    pdf: /(pdf)$/g,
    zip: /(zip)$/g,
    unknown: /(unknown)$/g,
  }

  let type = Object.keys(reg).find((key) => {
    return reg[key].test(name)
  })

  type = type || 'unknown'
  return `${dict[prefix]}${type}.png`
}

/**
 * 获取文件大小
 * @param size
 * @param threshold
 * @param unit
 */
const convertSize = (size: number | string, threshold = 1000, unit = 1): string => {
  const UNIT = ['', 'KB', 'MB', 'GB', 'TB', 'PB']
  // kb > mb > gb > tb
  const _size = Number(size) / 1024 ** unit

  if (_size < 0) {
    if (unit === 1) return `${_size.toFixed(2)} ${UNIT[unit]}`
    return `${_size.toFixed(2)} ${UNIT[unit - 1]}`
  }

  // 阈值，超过的话转换为下一个单位
  if (_size > threshold) return convertSize(size, threshold, unit + 1)

  return `${_size.toFixed(2)} ${UNIT[unit]}`
}

const getFileName = (path: string) => {
  const arr = path.split('/')

  return arr[arr.length - 1]
}

export { isImage, getFileIcon, convertSize, getFileName }
