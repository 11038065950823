import Http from "service/http";
import { Hosts } from "./const";

class ServiceDate extends Http {
  constructor() {
    super({ host: Hosts.empty, version: "v1" });
  }

  async get2dCode() {
    const res = await this.get<any>({
      url: "/work_wechat/​get_robot_qrcode",
    });

    return res.data;
  }
}

export default new ServiceDate();