import React, {useMemo} from 'react'
import styles from './index.module.scss'

const NoData = ({ code }: { code: number }) => {
  const noDataText = useMemo(() => {
    let text = ''

    if (code === 40021) {
      text = '该事项已取消~'
    } else {
      text = '暂无数据'
    }

    return text
  }, [code])

  return (
    <div className={styles.noDataRoot}>
      <div className={styles.noDataImg}>
        <img src="/assets/taskNoData.png" alt="" />
      </div>
      <div className={styles.noDataText}>{noDataText}</div>
    </div>
  )
}

export default React.memo(NoData)