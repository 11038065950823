import React, {useEffect} from "react";

import config from "config";

// import { downloadBtnClick } from "./evokeApp";

// import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
// import { SensorEvent } from "sensorsDataHook/const";
// import { isMobileAns } from "sensorsDataHook/isMobile";

import "./index.scss";
import sensors from "@/sensorsDataHook/initSensors";
import {SensorEvent} from "@/sensorsDataHook/const";
// import dayjs from "dayjs";

type TProps = {
  // invtiter_id: string;
  // task: any;
  path: string;
  visible: boolean;
  set_visibleMask: React.Dispatch<React.SetStateAction<boolean>>;
};

function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if ((ua.match(/MicroMessenger/i) as unknown as string) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

// 顶部app打开入口
const MaskModal = ({ path, visible, set_visibleMask }: TProps) => {
  useEffect(() => {
    const userId = new URLSearchParams(window.location.search).get("u") ?? "";
    const businessId = new URLSearchParams(window.location.search).get("dispatch_at") ?? "";

    const sensorsParams = {
      business_id: businessId,
      business_type: '协作人',
      // 邀请人ID
      invite_people_id: userId,
      // 邀请分享ID
      invitation_share_id: '',
      // 邀请分享类型
      invitation_share_type: "邀请链接",
    };

    console.log('埋点 click_invite_share 10月17日更新', sensorsParams);
    if (sensorsParams.business_id) {
      sensors.sendPoint(SensorEvent.click_invite_share, sensorsParams);
    }
  }, [])

  return (
    <div>
      <div
        className="full-screen-mask"
        style={{ display: visible ? "block" : "none" }}
        onClick={() => {
          set_visibleMask(false);
        }}
      >
        <div
          className="footer-modal-mobile"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="line-a">
            <div className="pic">
              <img src="/image/logo.png" alt="" />
            </div>

            <div className="word">
              <div className="title">
                {isWeiXin() ? "使用飞项小程序打开" : "使用飞项App打开"}
              </div>
              <div className="desc">可查看更多详细信息</div>
            </div>
          </div>

          <div className="line-b">
            <button
              style={{ fontSize: "large" }}
              type="button"
              className="no"
              onClick={() => {
                set_visibleMask(false);
              }}
            >
              取消
            </button>

            {isWeiXin() && (
              <div className="open-box">
                {/* @ts-ignore*/}
                <wx-open-launch-weapp
                  id="launch-btn"
                  username={config.miniProgramUsername}
                  path={path}
                >
                  <script type="text/wxtag-template">
                    {/* @ts-ignore*/}
                    <div
                      style={{
                        fontSize: "large",
                        width: "inherit",
                        height: "inherit",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        border: "none",
                      }}
                      onClick={() => {}}
                    >
                      打开
                    </div>
                  </script>
                  {/* @ts-ignore*/}
                </wx-open-launch-weapp>
              </div>
            )}

            {/* {!isWeiXin() && (
              <button
                type="button"
                className="ok"
                onClick={() => {
                  try {
                    sensors.sendPoint(SensorEvent.H5_open_click, {
                      business_type: getTypeCName(task?.matter_type),
                      business_id: task?.task_id,
                      invite_people_id: invtiter_id,
                      invite_time: dayjs().diff(
                        dayjs.unix(task?.create_at),
                        "second"
                      ),
                    });
                  } catch (e) {
                    console.log(e);
                  }

                  downloadBtnClick(task, invtiter_id);
                }}
              >
                打开
              </button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaskModal;
