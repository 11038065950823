import moment, { Moment } from 'moment'
import {HolidayState, IHoliday} from '@/typings/month.d'
import { DurationInputArg2 } from 'moment/moment'
import { cloneDeep, uniq } from 'lodash'
import MonthApi from '@/service/month'
import { I18N, isCN } from '@flyele-nx/i18n'
import {getEnFormat} from "@/utils/time";
import dayjs from "dayjs";

// 循环类型 （新版）
// 其实和旧版很像 但是 就是少了几个，多了几个
export enum RepeatConfigRepeatType {
    EVERY_DAY = 1, // 每天
    EVERY_WEEK = 2, // 每周
    EVERY_MONTH = 6, // 每月
    EVERY_YEAR = 7, // 每年
}

export interface IRepeatConfig {
    ignore_holiday?: number // 1->跳过节假日
    repeat_date?: number[] | string[] // 循环日期，repeat_type=1时忽略，每周一，二传[1, 2]，每月2号10号传[2, 10]，每年几月几号传["02-14", "05-18"]
    repeat_interval?: number // 循环间隔
    repeat_type?: RepeatConfigRepeatType // 循环类型
}

export interface ILoopParams {
    start_time: number
    end_time: number
    create_at: number
    cycle: number;
    repeat_type: number;
    end_repeat_at: number;
    repeat_config?: IRepeatConfig // 循环设置
}

export interface IChange {
    repeatInterval: number
    repeatType: RepeatConfigRepeatType
    repeatDate: number[] | string[]
}

// 循环事项选项
export enum LOOP_MATTER {
    noLoop = 0, // 不循环
    everDay, // 每天
    weekly, // 每周
    everyFortnight, // 每两周
    weekdays, // 工作日
    nonWork, // 非工作日
    monthly, // 每月
    custom = 999, // 自定义循环
}

export const LOOP_MATTER_CNNAME: { [key: number]: string } = {
    [LOOP_MATTER.noLoop]: I18N.common.no_repeat,
    [LOOP_MATTER.everDay]: I18N.common.daily,
    [LOOP_MATTER.weekly]: I18N.common.weekly,
    [LOOP_MATTER.everyFortnight]: I18N.common.every_2_week,
    [LOOP_MATTER.weekdays]: I18N.common.weekday,
    [LOOP_MATTER.nonWork]: I18N.common.nonWorkingDays,
    [LOOP_MATTER.monthly]: I18N.common.monthly,
    [LOOP_MATTER.custom]: I18N.common.custom,
}

export enum AlwaysFinishTime {
    value = 2114351999,
}

/**
 * 一半的一直循环日期，上面改了 下面记得改
 * 为了避免一直循环导致内存溢出，所以分开2段来控制
 */
export enum HalfAlwaysFinishTime {
    value = 1924963199, // 2030-12-31 23:59:59
}

interface INextLoopRule {
    time: Moment
    startTime: Moment
    firstTime?: Moment
    ignoreHoliday?: boolean
    realHolidays?: IHoliday[]
    dutyHoliday?: IHoliday[]
    repeat_config?: IRepeatConfig
    isFirst?: boolean
    finnishTime: Moment
}
interface ICheckHolidayMini {
    time: Moment
    finnishTime: Moment
    realHolidays: IHoliday[] // 真正的假期，通过总的那边filter出来的，去除补班的
    dutyHoliday: IHoliday[] // 补班的，通过总的那边filter出来的
}

interface ICheckHoliday extends ICheckHolidayMini {
    timeAmount?: number
    timeUnit?: DurationInputArg2
}

interface ICheckDateRepeatConfig extends ICheckHolidayMini {
    repeat_config: IRepeatConfig
    isFirst?: boolean
    ignoreHoliday?: boolean
}

interface IIsDateJump extends ICheckHolidayMini {
    time: Moment
    finnishTime: Moment
    repeat_date: number[] | string[]
    timeAmount: number
    timeUnit: DurationInputArg2
    ignoreHoliday?: boolean
}

// 是否是一直循环
export const isAlwaysRepeat = (finishTime: number) => {
    return finishTime === AlwaysFinishTime.value
}

/**
 * type 转换 timeUnit
 */
const repeatTypeToUnit = (repeat_type: RepeatConfigRepeatType) => {
    let timeUnit: DurationInputArg2 = 'day'

    switch (repeat_type) {
        case RepeatConfigRepeatType.EVERY_DAY:
            timeUnit = 'day'
            break
        case RepeatConfigRepeatType.EVERY_WEEK:
            timeUnit = 'week'
            break
        case RepeatConfigRepeatType.EVERY_MONTH:
            timeUnit = 'month'
            break
        case RepeatConfigRepeatType.EVERY_YEAR:
            timeUnit = 'year'
            break
        default:
            break
    }

    return timeUnit
}

function infoIsMoment(res: any): res is Moment {
    return !('isJumpAmount' in res)
}

/**
 * 检查当前循环日期是否在节假日里面 （周末也算，但是周末要考虑是否 节假日补班的问题，所以引入了 dutyHoliday）
 * 如果是，则加一天，再判断是不是，如果是，继续判断
 * 如果不是，则返回日期
 * realHolidays 、 dutyHoliday 为什么不在函数里面处理呢，因为外层是有个 while 的，我不想在while函数里面重复处理这种数组的问题
 * 所以我就传入来使用
 */
const checkDateIsJumpHoliday = ({time, finnishTime, realHolidays, dutyHoliday}: ICheckHolidayMini): boolean => {
    const timeDate = time.format('YYYY-MM-DD')
    const week = time.get('weekday')
    const nextWeeks = [6, 0, 7] // 关于周日：后端用 7 、 dayjs 用 0
    const isWeekday = nextWeeks.includes(week)
    const isMaxDate = time.isAfter(finnishTime, 'day') // 必须判断是否大于最后日期了，不然会一直循环下去超出日期
    let isJumpHoliday = false

    if (isWeekday) {
        isJumpHoliday =
          dutyHoliday.findIndex((item) => item.date === timeDate) === -1
    } else {
        isJumpHoliday =
          realHolidays.findIndex((item) => item.date === timeDate) !== -1
    }

    return isJumpHoliday && !isMaxDate
}

/**
 * 普通循环的检查节假日
 */
const checkDateAndReturnDate = ({
    time, finnishTime, realHolidays, dutyHoliday, timeAmount = 1, timeUnit = 'day'
}: ICheckHoliday): Moment => {
    const isJump = checkDateIsJumpHoliday({
        time,
        finnishTime,
        realHolidays,
        dutyHoliday,
    })

    if (isJump) {
        return checkDateAndReturnDate({
            time: time.clone().add(timeAmount, timeUnit),
            finnishTime,
            realHolidays,
            dutyHoliday,
            timeAmount,
            timeUnit,
        })
    }

    return time
}

/**
 * 检查日期是否要往后跳
 * 检查是否在循环配置内
 * 检查是否节假日
 */
const isDateJump = ({
                        time,
                        finnishTime,
                        repeat_date,
                        timeAmount,
                        timeUnit,
                        realHolidays,
                        dutyHoliday,
                        ignoreHoliday = false,
                    }: IIsDateJump): { date: Moment; isJumpAmount: boolean } => {
    const repeatDate = repeat_date || []
    const repeatDateLen = repeatDate.length

    let isJumpAmount = false

    if (repeatDateLen) {
        const isMaxDate = time.isAfter(finnishTime, 'day') // 必须判断是否大于最后日期了，不然会一直循环下去超出日期
        let date = time.get('date')
        let isJump = false

        if (timeUnit === 'week') {
            date = time.get('weekday')
            date = date === 0 ? 7 : date

            const lastDay =
              repeatDate[repeatDateLen - 1] === 7 ? 0 : repeatDate[repeatDateLen - 1]
            const targetDate = time.clone().day(lastDay)

            isJump = !(repeatDate as number[]).includes(date)
            isJumpAmount = time.isSameOrAfter(targetDate, 'day') && timeAmount !== 1
        }

        if (timeUnit === 'month') {
            let cloneRepeatDate = cloneDeep(repeatDate) as number[]
            const lastMonthDate = time.clone().endOf('month').get('date')
            const lastRepeatDateValue = Number(repeatDate[repeatDateLen - 1])
            const lastMonthNum = Math.min(lastRepeatDateValue, lastMonthDate)
            const targetDate = time.clone().date(lastMonthNum)

            // 需求要的，如果用户选了31号，这个月又没有31号，那么就取这个月的最后一天
            if (lastRepeatDateValue > lastMonthNum) {
                cloneRepeatDate.push(lastMonthNum)
                cloneRepeatDate = uniq(cloneRepeatDate)
            }

            isJump = !cloneRepeatDate.includes(date)
            isJumpAmount = time.isSameOrAfter(targetDate, 'day') && timeAmount !== 1
        }

        if (ignoreHoliday && !isJump) {
            isJump = checkDateIsJumpHoliday({
                time,
                finnishTime,
                realHolidays,
                dutyHoliday,
            })
        }

        if (!isMaxDate) {
            if (isJump) {
                const newTime = time.clone()

                if (isJumpAmount) {
                    newTime.add(timeAmount, timeUnit)
                    if (timeUnit === 'week') {
                        newTime.weekday(1)
                    } else {
                        newTime.date(1)
                    }
                } else {
                    newTime.add(1, 'day')
                }

                return isDateJump({
                    time: newTime,
                    repeat_date,
                    timeAmount,
                    timeUnit,
                    finnishTime,
                    realHolidays,
                    dutyHoliday,
                    ignoreHoliday,
                })
            }
        }
    }

    return {
        date: time,
        isJumpAmount,
    }}

/**
 * 检查日期是否在自定义循环日期内
 * 如果不是 则 跳过
 */
const checkDateRepeatConfig = ({
    time, repeat_config, finnishTime, isFirst, ignoreHoliday = false, realHolidays, dutyHoliday
}: ICheckDateRepeatConfig) => {
    const { repeat_interval, repeat_type, repeat_date } = repeat_config

    if (!repeat_type) {
        return time
    }

    const timeAmount = repeat_interval || 0
    const timeUnit = repeatTypeToUnit(repeat_type)

    // 每周、每月
    if (
      [
          RepeatConfigRepeatType.EVERY_WEEK,
          RepeatConfigRepeatType.EVERY_MONTH,
      ].includes(repeat_type)
    ) {
        return isDateJump({
            time: isFirst ? time.clone() : time.clone().add(1, 'day'),
            repeat_date: repeat_date || [],
            timeAmount,
            timeUnit,
            finnishTime,
            ignoreHoliday,
            realHolidays,
            dutyHoliday,
        })
    }
    // 每天、每年 不处理
    const jumpStart = isFirst
      ? time.clone()
      : time.clone().add(timeAmount, timeUnit)

    if (ignoreHoliday) {
        return checkDateAndReturnDate({
            time: jumpStart,
            realHolidays,
            dutyHoliday,
            finnishTime,
            timeAmount,
            timeUnit,
        })
    }
    return jumpStart
}

// 根据设定，一周的开始是0
export const nextLoopRule: {
    [k: string]: {
        getNext: (
          params: INextLoopRule
        ) => Moment | { date: Moment; isJumpAmount: boolean }
    }
} = {
    [LOOP_MATTER.noLoop]: {
        getNext: (params: INextLoopRule) => {
            return params.time
        },
    },
    [LOOP_MATTER.everDay]: {
        getNext: (params: INextLoopRule) => {
            const {
                time,
                finnishTime,
                isFirst,
                startTime,
                ignoreHoliday = false,
                realHolidays = [],
                dutyHoliday = [],
            } = params

            // 事项开始时间在历史日
            if (time.isBefore(startTime, 'day')) {
                return startTime.clone()
            }

            const addTime = time.clone()

            if (!isFirst) addTime.add(1, 'day')

            if (ignoreHoliday) {
                return checkDateAndReturnDate({
                    time: addTime,
                    realHolidays,
                    dutyHoliday,
                    finnishTime,
                })
            }
            return addTime
        },
    },
    [LOOP_MATTER.weekly]: {
        getNext: (params: INextLoopRule) => {
            const {
                time,
                finnishTime,
                isFirst,
                startTime,
                ignoreHoliday = false,
                realHolidays = [],
                dutyHoliday = [],
            } = params

            // 事项开始时间在历史日
            if (time.isBefore(startTime, 'day')) {
                const week = time.get('weekday')

                // 事项开始时间与比对的时间为同一个星期则跳到下一个星期
                // 事项开始时间与比对时间不同星期但跳到周几小于比对时间则跳到下一个星期
                return startTime.isSame(time, 'week') ||
                startTime.clone().week(week).isBefore(startTime, 'day')
                  ? startTime.clone().add(1, 'week').weekday(week)
                  : startTime.clone().week(week)
            }

            const addTime = time.clone()

            if (!isFirst) addTime.add(1, 'week')

            if (ignoreHoliday) {
                return checkDateAndReturnDate({
                    time: addTime,
                    finnishTime,
                    realHolidays,
                    dutyHoliday,
                    timeUnit: 'week',
                })
            }
            return addTime
        },
    },
    [LOOP_MATTER.everyFortnight]: {
        getNext: (params: INextLoopRule) => {
            const {
                time,
                finnishTime,
                startTime,
                isFirst,
                ignoreHoliday = false,
                realHolidays = [],
                dutyHoliday = [],
            } = params

            // 事项开始时间在历史日
            if (time.isBefore(startTime, 'day')) {
                return startTime.clone().add(2, 'week').weekday(time.get('weekday'))
            }

            const addTime = time.clone()

            if (!isFirst) addTime.add(2, 'week')

            if (ignoreHoliday) {
                return checkDateAndReturnDate({
                    time: addTime,
                    realHolidays,
                    dutyHoliday,
                    timeAmount: 2,
                    timeUnit: 'week',
                    finnishTime,
                })
            }
            return addTime
        },
    },
    [LOOP_MATTER.weekdays]: {
        // 工作日的规则 从2.2版本p2需求后 废除，改为自定义循环
        getNext: (params: INextLoopRule) => {
            const { time, startTime } = params

            const week = time.get('weekday')
            const nextWeeks = [5, 6]

            // 事项开始时间在历史日
            if (time.isBefore(startTime, 'day')) {
                const startWeek = startTime.get('weekday')

                // 比对时间在周六则跳到下周一
                return [6].includes(startWeek)
                  ? startTime.add(1, 'week').weekday(1)
                  : startTime
            }

            // 如果是周五周六，下一个工作日是下周一
            return nextWeeks.includes(week)
              ? time.clone().add(1, 'week').weekday(1)
              : time.clone().add(1, 'day')
        },
    },
    [LOOP_MATTER.nonWork]: {
        // 非工作日的规则 从2.2版本p2需求后 废除，改为自定义循环
        getNext: (params: INextLoopRule) => {
            const { time, startTime } = params

            const week = time.get('weekday')
            const weekdays = [1, 2, 3, 4, 5]

            // 事项开始时间在历史日
            if (time.isBefore(startTime, 'day')) {
                // 在工作日跳到周六，否则返回该时间
                return weekdays.includes(startTime.get('weekday'))
                  ? startTime.weekday(6)
                  : startTime
            }

            if (weekdays.includes(week)) {
                return time.clone().weekday(6) // 工作日跳转到本周六
            }

            // 如果是周日则跳到下周六
            return week === 0 ? time.clone().weekday(6) : time.clone().add(1, 'day')
        },
    },
    [LOOP_MATTER.monthly]: {
        // 如果是月循环必传firstTime字段
        getNext: (params: INextLoopRule) => {
            const {
                time,
                finnishTime,
                startTime,
                isFirst,
                firstTime,
                ignoreHoliday = false,
                realHolidays = [],
                dutyHoliday = [],
            } = params

            let date: Moment
            const _day = firstTime?.get('date') || 0

            if (time.isBefore(startTime, 'day')) {
                // 同月跳下月
                date = startTime.isSame(time, 'month')
                  ? startTime.add(1, 'month')
                  : startTime.date(_day)
            } else {
                const addTime = time.clone()

                if (!isFirst) addTime.add(1, 'month')

                if (ignoreHoliday) {
                    date = checkDateAndReturnDate({
                        time: addTime,
                        realHolidays,
                        dutyHoliday,
                        timeUnit: 'month',
                        finnishTime,
                    })
                } else {
                    date = addTime
                }
            }

            if (_day > date.daysInMonth()) {
                date.endOf('month')
            } else {
                date.date(_day)
            }

            return date
        },
    },
    [LOOP_MATTER.custom]: {
        getNext: (params: INextLoopRule) => {
            const {
                time,
                finnishTime,
                startTime,
                ignoreHoliday = false,
                realHolidays = [],
                dutyHoliday = [],
                repeat_config,
                isFirst,
            } = params

            // 事项开始时间在历史日
            if (time.isBefore(startTime, 'day')) {
                return startTime.clone()
            }

            let addTime: Moment | { date: Moment; isJumpAmount: boolean } = time

            if (repeat_config) {
                addTime = checkDateRepeatConfig({
                    time: addTime,
                    repeat_config,
                    finnishTime,
                    isFirst,
                    ignoreHoliday,
                    realHolidays,
                    dutyHoliday,
                })
            } else if (!isFirst) addTime = time.clone().add(1, 'day')

            return addTime
        },
    },
}

/**
 * 获取循环的日期和次数
 * @param value
 */
export const getLoopDatesAndCount = async (value: {
    startTime: number
    createAt: number
    finnishTime: number
    loopOpt: LOOP_MATTER
    ignoreHoliday?: boolean
    repeat_config?: IRepeatConfig
}) => {
    const { startTime, createAt, finnishTime, loopOpt, ignoreHoliday, repeat_config } = value
    const firstTime = moment.unix(startTime)
    const res: { count: number; dates: Map<string, Moment> } = {
        count: 0,
        dates: new Map()
    }
    const ignoreHolidayValue = !repeat_config
      ? ignoreHoliday
      : repeat_config.ignore_holiday === 1

    // 返回时间
    const getStartTime = (startTime: number, createAt: number) => {
        const _startTime = moment.unix(startTime)
        const _createAt = moment.unix(createAt)

        return _startTime.isBefore(_createAt, 'day') ? _createAt : _startTime
    }

    const _startTime = getStartTime(startTime, createAt)

    // 结束时间小于循环的开始时间或者是不循环
    if (
      _startTime.isAfter(moment.unix(finnishTime), 'day') ||
      loopOpt === LOOP_MATTER.noLoop
    ) {
        return res
    }

    let time = firstTime.clone()

    let holidays: IHoliday[] = []
    if (isCN) {
        try {
            const year = moment().get('year')
            const { data } = await MonthApi.getHoliday(year)

            holidays = data
        } catch (e) {
            holidays = []
        }
    }

    // 去掉补班的假期, 真放假的时候。
    const realHolidays = holidays.filter(
      (item) => item.state === HolidayState.FURLOUGH
    )
    // 补班
    const dutyHoliday = holidays.filter(
      (item) => item.state === HolidayState.DUTY
    )

    const { getNext } = nextLoopRule[loopOpt]

    const timeAmount = repeat_config?.repeat_interval || 0
    const timeUnit = repeatTypeToUnit(
      repeat_config?.repeat_type || RepeatConfigRepeatType.EVERY_DAY
    )

    let isFirst = true
    const cloneRepeatConfig = cloneDeep(repeat_config)

    /**
     * 是否自定义循环 并且是 一直循环的事项
     * 当你选周六 且 跳过节假日的时候
     * 如果走 下面的 会内存溢出
     * 所以不走了分开2段时间
     */
    const isCustomAndAlwaysRepeat =
      loopOpt === LOOP_MATTER.custom && finnishTime === AlwaysFinishTime.value
    const halfAlwaysFinishTime = HalfAlwaysFinishTime.value
    const _finnishTime = moment.unix(finnishTime)
    let timeArr = [
        {
            startTime: _startTime.clone(),
            finnishTime: _finnishTime,
        },
    ]

    if (isCustomAndAlwaysRepeat) {
        const halfTime = moment.unix(halfAlwaysFinishTime)

        timeArr = [
            {
                startTime: _startTime.clone(),
                finnishTime: halfTime,
            },
            {
                startTime: halfTime.clone(),
                finnishTime: _finnishTime,
            },
        ]
    }

    const timeArrLen = timeArr.length

    for (let i = 0; i < timeArrLen; i++) {
        const timeItem = timeArr[i]

        while (time.isBefore(timeItem.finnishTime, 'day')) {
            const info = getNext({
                time: time.clone(),
                startTime: timeItem.startTime,
                firstTime,
                ignoreHoliday: ignoreHolidayValue,
                realHolidays,
                dutyHoliday,
                isFirst,
                repeat_config: cloneRepeatConfig,
                finnishTime: timeItem.finnishTime,
            })

            isFirst = false

            time = infoIsMoment(info) ? info : info.date

            if (
              time.isBetween(
                timeArr[0].startTime,
                timeArr[timeArrLen - 1].finnishTime,
                'day',
                '[]'
              )
            ) {
                res.dates.set(time.format('YYYY-MM-DD'), time.clone())
            }

            const needGap = !infoIsMoment(info) && info.isJumpAmount && repeat_config

            if (needGap) {
                time =
                  timeUnit === 'week'
                    ? time.clone().add(timeAmount, timeUnit).weekday(1)
                    : time.clone().add(timeAmount, timeUnit).date(1)

                isFirst = true
            }
        }
    }

    return { ...res, count: res.dates.size }
}

export const renderCustomLoopType = (
  customValue: Pick<IChange, 'repeatType' | 'repeatInterval'> | null
) => {
    let text = ''

    if (customValue) {
        const { repeatType, repeatInterval } = customValue
        const repeatIntervalText = repeatInterval === 1 ? '' : repeatInterval

        switch (repeatType) {
            case RepeatConfigRepeatType.EVERY_DAY:
                text = `每${repeatIntervalText}天`
                break
            case RepeatConfigRepeatType.EVERY_WEEK:
                text = `每${repeatIntervalText}周`
                break
            case RepeatConfigRepeatType.EVERY_MONTH:
                text = `每${
                  repeatIntervalText !== ''
                    ? `${repeatIntervalText}个`
                    : repeatIntervalText
                }月`
                break
            case RepeatConfigRepeatType.EVERY_YEAR:
                text = `每${repeatIntervalText}年`
                break
            default:
                break
        }
    }
    return text
}

export const getCircleTimeText = async ({start_time, end_time, create_at, end_repeat_at, repeat_type, cycle, repeat_config}: ILoopParams) => {
    let displayString = ''

    const output = await getLoopDatesAndCount({
        startTime: (start_time || end_time || create_at) as number,
        createAt: create_at,
        finnishTime: end_repeat_at as number,
        loopOpt: (repeat_type || 0) as LOOP_MATTER,
        repeat_config: repeat_config,
    })

    // 当前的事项循环
    const now_cycle = cycle || 1

    const nowTime = cycle || (now_cycle > 0 ? now_cycle : 1)

    displayString = I18N.template?.(I18N.common.nowT, {
        val1: nowTime,
    }) ?? ''

    if (isAlwaysRepeat(end_repeat_at)) {
        displayString += I18N.common.second
    } else {
        displayString += I18N.template?.(I18N.common.conve, {
            val1: output.count,
        }) ?? ''
    }
    let text = LOOP_MATTER_CNNAME[repeat_type]

    const ignoreHoliday = repeat_config?.ignore_holiday === 1
    const repeatInterval = repeat_config?.repeat_interval
    const repeatConfigType = repeat_config?.repeat_type

    if (
      repeat_type === LOOP_MATTER.custom &&
      repeatInterval &&
      repeatConfigType
    ) {
        text = renderCustomLoopType({
            repeatInterval,
            repeatType: repeatConfigType,
        })
    }

    if (ignoreHoliday) {
        text += `（${isCN ? '跳过法定节假日' : 'Skip weekend'}）`
    }

    displayString += text

    if (isAlwaysRepeat(end_repeat_at)) {
        displayString += I18N.common.alwaysLoop
    } else {
        const YYYY =
          moment.unix(end_repeat_at || 0).format('YYYY') !==
          moment().format('YYYY')
        const M_DD = getEnFormat(dayjs(moment.unix(end_repeat_at || 0).toDate()), 'M月DD日', 'MMM D')

        displayString += isCN ? `、${
          YYYY ? moment.unix(end_repeat_at || 0).format('YYYY年') : ''
        }${M_DD}截止` :  `、Due on ${M_DD}${
          YYYY ? moment.unix(end_repeat_at || 0).format(', YYYY') : ''
        }`
    }

    return displayString
}
