import { I18N, isCN } from '@flyele-nx/i18n'
import React, {useState} from "react";
import {Status} from '@/constants/space'
import {usePlatformCheck, WindowType,} from "@/pages/PureHooks/usePlatformCheck";
import {ISpace} from "@/service/types/space";

import styles from './index.module.scss'
import useEnvokeApp from "@/pages/forMobileInvite/Task/BtnForApp/useEnvokeApp";
import useHrefSearch from "@/pages/PureHooks/useHrefSearch";
import config from "@/config";
import MaskAlreadyOpenWechat from "@/pages/forMobileInvite/component/MaskAlreadyOpenWechat";
import {History} from 'history'
import LoginBtn from "@/components/LoginBtn";
import { getMiniProgramSpacePath } from "@/pages/forMobileInvite/utils";
import {EmailLoginModal} from "@/components/login/email-login-modal";

interface TProps {
  spaceInfo: ISpace | null
  history: History
  sendSensors?: () => void
  showAccountError?: boolean
  loginSuccess?: () => void
}

const FooterBtnAction = (props: TProps) => {
  const {history, sendSensors, showAccountError = false, loginSuccess } = props

  const [lastAction, setLastAction] = useState<Status>(Status.default);
  const hrefSearch = useHrefSearch();
  
  const { nowWindowType } = usePlatformCheck();
  const { evokeApp, toLoginPage } = useEnvokeApp();
  
  const [tempMiniProgramPath, set_tempMiniProgramPath] = useState("");
  const [visibleMask, set_visibleMask] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);

  const savePath = (path: string) => {
    set_tempMiniProgramPath(path);
    set_visibleMask(true);

    sendSensors && sendSensors()
  };

  const openEmailLogin = () => {
    setEmailVisible(true);
  }
  
  const onClickBtn = async (type: Status) => {
    console.log('type', type)
    // 记录最近的上一个操作
    setLastAction(type);

    sendSensors && sendSensors()

    if (nowWindowType === WindowType.企业微信浏览器) {
      toLoginPage(history, true, openEmailLogin)
      return
    }

    if (nowWindowType === WindowType.手机浏览器) {
      let path = "";
      const { id, u, dispatch_at, memberType } = hrefSearch;
      
      switch (type) {
        case Status.join:
          path = `operate/space/accept?id=${id}&u=${u}&member_type=${memberType}&dispatch_at=${dispatch_at}`;
          evokeApp(path, history, openEmailLogin);
          break;
        case Status.refuse:
          path = `operate/space/refuse?id=${id}&u=${u}&member_type=${memberType}&dispatch_at=${dispatch_at}`;
          evokeApp(path, history, openEmailLogin);
          break
      }
      
      return;
    }


    if (!isCN) {
      openEmailLogin()
    }
  }
  
  const _getMiniProgramPath = (type: Status): string => {
    const { id, u, dispatch_at, memberType, } = hrefSearch;
    
    const json: any = {
      operator_type: type === Status.join ? 1 : 2,
      invite_id: u,
      space_id: id,
      dispatch_at: dispatch_at,
      member_type: memberType,
    };
    
    const json_string = JSON.stringify(json);
    
    console.log(
      "请求小程序的链接",
      `/pages/tabBar/schedule/index?submitInfo=${json_string}`
    );

    const path = getMiniProgramSpacePath(id, u,dispatch_at,hrefSearch)

    return path
  }
  
  return (
    <div className={styles.footerBtnRoot}>
  
      {
        nowWindowType === WindowType.微信浏览器 ? (
          <>
            <div className={styles.wxAccessBtn}>
              {/* @ts-ignore*/}
              <wx-open-launch-weapp
                id="launch-btn"
                username={config.miniProgramUsername}
                path={_getMiniProgramPath(Status.join)}
              >
                <script type="text/wxtag-template">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "inherit",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                    onClick={() => savePath(_getMiniProgramPath(Status.join))}
                  >
                    {I18N.common.immediatelyAfterLogin}
                  </div>
                </script>
                {/* @ts-ignore*/}
              </wx-open-launch-weapp>
            </div>
          </>
        ) : (
          <LoginBtn onClick={() => onClickBtn(Status.join)} showAccountError={showAccountError} />
        )
      }

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            loginSuccess && loginSuccess()
          }}
        />
      )}
      
      <MaskAlreadyOpenWechat
        path={tempMiniProgramPath}
        visible={visibleMask}
        set_visibleMask={set_visibleMask}
        mobileClick={() => onClickBtn(lastAction)}
      />
    </div>
  )
}

export default FooterBtnAction
