import { I18N } from '@flyele-nx/i18n'
import SpaceInvite from "@/pages/PureH5/SpaceInvite";

export const ForSpaceRouters = [
  //
  {
    exact: true,
    path: "/space/invite",
    component: SpaceInvite,
    key: "wx_sister",
    verifyLogin: false,
    title: I18N.common.invitationSpace,
  },
];
