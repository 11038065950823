import { RouteProps } from "react-router-dom";

import Html5Page from "pages/html5downloader";
import { ForDownloadRouters } from "./ForDownload";
import { ForWeixinRouters } from "./ForWeixin";
import { ForPcRouters } from "./ForPc";
import { ForMobileRouters } from "./ForMobile";
import { ForSpaceRouters } from "./ForSpace";
import { LoginRouters } from "./Login";
import { FeedbackRouters } from "./Feedback";
import { OtherRouters } from './Other'

interface RouterSubProps {
  key: string;
  verifyLogin: boolean;
  title: string;
}

const router: Array<RouteProps & RouterSubProps> = [
  ...ForDownloadRouters,
  ...ForWeixinRouters,
  ...ForPcRouters,
  ...ForMobileRouters,
  ...ForSpaceRouters,
  ...LoginRouters,
  ...FeedbackRouters,
  ...OtherRouters,
  {
    exact: true,
    path: "*",
    // component: NotFound,
    component: Html5Page,
    key: "*",
    verifyLogin: false,
    title: "",
  },
];

export { router };
