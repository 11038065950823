import React, { FC } from 'react'
import './PrimaryBtn.scss'

import { ButtonDeclear } from './constants/ButtonDeclear'

export const PrimaryBtn: FC<ButtonDeclear.IProps> = ({
  title = '确定',
  onClick = () => {},
  colorType = 'default',
  iconSrc = undefined,
}) => {
  return (
    <div className={`btn-primary-${colorType}`}>
      <button type="button" onClick={onClick}>
        {iconSrc && <img src={iconSrc} alt="" />}
        {title}
      </button>
    </div>
  )
}

export default PrimaryBtn
