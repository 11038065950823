import React, { FC, PropsWithChildren } from 'react'
import styles from "./index.module.scss";

interface IProps {
  title: string
  icon: string
}

const CardItemBox: FC<PropsWithChildren<IProps>> = ({ title, icon, children }) => {
  return (
    <div className={styles.subTaskItem}>
      <div className={styles.itemIcon}>
        <img src={icon} alt=""/>
      </div>
      <div className={styles.itemRight}>
        <div className={styles.itemTitle}>{title}</div>
        {children}
      </div>
    </div>
  )
}

export default React.memo(CardItemBox)