import React, { FC, PropsWithChildren } from 'react'
import styles from './index.module.scss'

interface IShowCardBox {
  title: string
  height: string
  allText?: string
}

const ShowCardBox: FC<PropsWithChildren<IShowCardBox>> = ({ title, height, allText, children }) => {
  return (
    <div className={styles.showCardBoxRoot} style={{ maxHeight: height }}>
      <div className={styles.title}>{title}</div>
      {children}
      { allText && <div className={styles.allTextBox}>{allText}</div> }
    </div>
  )
}

export default React.memo(ShowCardBox)