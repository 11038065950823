
// TODO: 兼容旧数据显示拒绝/请假

import { I18N } from '@flyele-nx/i18n'
import { useEffect, useState } from 'react'
import { InviteType } from '../../hooks/useGetMatter'
import MeetingTool from '../../tools/meeting'
import ProjectTool from '../../tools/project'
import SpaceTool from '../../tools/space'
import TaskTool from '../../tools/task'
import styles from './index.module.scss'

const SHOW_TAKERS_NUM = 6

type IProps = {
  inviteType: InviteType
  task: any
  takers: any[]
}

const Takers = (props: IProps) => {

  const {
    inviteType,
    task,
    takers,
  } = props

  const nameKey = inviteType === InviteType.space
    ? 'nickname'
    : inviteType === InviteType.matter
      ? 'nick_name'
      : 'nick_name'

  const [sortedTakers, setSortedTakers] = useState(takers)

  useEffect(() => {

    let tool = inviteType === InviteType.matter
      ? (
          task.matter_type === 10701
          ? TaskTool
          : task.matter_type === 10702
            ? MeetingTool
            : undefined
        )
      : inviteType === InviteType.project
        ? ProjectTool
        : inviteType === InviteType.space
          ? SpaceTool
          : undefined

    if (tool) {
      const data = tool.sortTakers(takers)
      setSortedTakers(data)
      // setSortedTakers([...data, ...data, ...data, ...data])
    }

  }, [task, takers, inviteType])

  return (
    <div className={styles.takers}>

      <div className={styles.takers__wrap}>
        {
          sortedTakers.slice(0, SHOW_TAKERS_NUM).map((item) => {

            return (
              <div className={styles['takers-item']}
                key={item.taker_id || item.user_id}
              >

                <div className={styles['takers-avatar']}>
                  <img className={styles['takers-avatar-avatar']} src={item.avatar} alt="" />

                  {
                    item.key === 'askForLeave' ?
                    (<img className={styles[`takers-avatar-icon`]} src="/assets/icons/avatar/vacate.png" alt={I18N.common.leaveTaken} />) : null
                  }
                  
                  {
                    item.key === 'refused' ?
                    (<img className={styles[`takers-avatar-icon`]} src="/assets/icons/avatar/refused.png" alt={I18N.common.rejected} />) : null
                  }
                  
                </div>

                <div className={styles['takers-name']}>
                  {item[nameKey]}
                </div>

                <div className={styles['takers-status']}>
                  { item.status || I18N.common.accepted }
                </div>

              </div>
            )
          })
        }

      </div>

      {
        takers.length - SHOW_TAKERS_NUM > 0
        ? (
          <div className={styles['takers-sum']}>
            {I18N.template(I18N.common.h5_waitForTake, { val1: takers.length - SHOW_TAKERS_NUM })}
          </div>
        )
        : null
      }

    </div>
  )
}

export default Takers