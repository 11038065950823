import { I18N } from '@flyele-nx/i18n'
import React, { useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import MatterApi from "service/matter";

import style from "./index.module.scss";

const AppendixIcon = "/assets/appendix.png";

const Summary = ({ task_id, takers }: any) => {
  const [detail, setDetail] = useState<any>({ content: "", files: [] });

  const nick_name = "";

  const [line, setLine] = useState(0);
  const maxLine = 3;

  useEffect(() => {
    if (!detail.content) {
      return;
    }
    const el = document.querySelector("#sumarry-text");

    if (el) {
      const { height } = el!.getBoundingClientRect();
      const { lineHeight } = window.getComputedStyle(el!);

      const _line = Math.ceil(height / parseFloat(lineHeight));

      setLine(_line);
    }
  }, [detail.content]);

  const getDetail = useCallback(async () => {
    const { data } = await MatterApi.getTaskSummary(task_id);

    const updater = takers?.find(
      (item: any) => item.user_id === (data.updater_id || data.creator_id)
    );

    const _detail = {
      ...data,
      name: updater?.nick_name || updater?.original_name || "",
      date: dayjs
        .unix(data.update_at! || data.create_at!)
        .format("M月D日 HH:mm"),
    };

    setDetail(_detail);
  }, [takers]);

  useEffect(() => {
    task_id && (takers || []).length > 0 && getDetail();
  }, [takers]);

  if (!task_id || !detail || !detail.date || !detail.content) {
    return null;
  }

  return (
    <div className={style["summary-card-container"]}>
      <div className={style["card-icon"]}>{I18N.common.summary}</div>
      <div className={style.header}>{I18N.template(I18N.common.updat, { val1: nick_name, val2: detail.date })}</div>
      <div className={style.content}>
        <div
          // style={{ maxHeight: "80px", overflow: "hidden" }}
          dangerouslySetInnerHTML={{
            __html: detail.content
              .replace("↵/g", "\n")
              .replace(/\n/g, "<br />"),
          }}
        />

        {line > maxLine && <div className={style.mask}>...</div>}
      </div>

      {detail.files && detail.files.length ? (
        <div className={style.footer}>
          <span>{I18N.template(I18N.common.concl, { val1: detail.files.length })}</span>
          <img alt="" src={AppendixIcon} />
          <span />
        </div>
      ) : null}
    </div>
  );
};

export default Summary;
