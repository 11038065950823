import { I18N } from '@flyele-nx/i18n'
import { useEffect, useState } from 'react';
import {usePlatformCheck, WindowType} from "@/pages/PureHooks/usePlatformCheck";
import {downloadBtnClick} from "pages/html5downloader/ts/download";
import { IFooterBtnProps } from "./main"
import styles from './index.module.scss'

const FooterBtn = (props: IFooterBtnProps) => {
  const { isAccessible } = props
  const [btnText, setBtnText] = useState(I18N.common.checkInTheFlyele);
  const { nowWindowType } = usePlatformCheck();

  useEffect(() => {
    if (nowWindowType === WindowType.微信浏览器) {
      setBtnText(isAccessible ? I18N.common.checkInMiniProgress : I18N.common.h5_inTheSmallDistanceOfTheFlyingEvent);
    } else if (nowWindowType === WindowType.手机浏览器) {
      setBtnText(isAccessible?  I18N.common.checkInFlyeleApp : I18N.common.h5_inFlightAp);
    } else {
      setBtnText(isAccessible ? I18N.common.checkInTheFlyele : I18N.common.h5_checkInTheFlight);
    }
  }, [isAccessible, nowWindowType])

  return (
    <div className={styles.footer}
      onClick={downloadBtnClick}
    >

      <div className={styles.footer__wrapper}>

      <div className={styles.footer__txt}>
        {btnText}
      </div>

      </div>

    </div>
  )
}

export default FooterBtn