// import { getHref } from "@/pages/forMobileInvite/utils"
import matterApi from "@/service/matter"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import {History} from 'history'
import spaceApi from "@/service/space"
import projectApi from "@/service/project"
import { InviteType, JoinType } from "../types/main"
import SpaceTool, { ISpaceToolState } from "../tools/space"
import ProjectTool, { IProjectToolState } from "../tools/project"

import {IProjectInfo, IProjectInviteMember} from '@/service/types/project'
import { ITask, ITaskDispatch } from "@/service/types/matter"
import TaskTool from "../tools/task"
import MeetingTool from "../tools/meeting"
import { MatterType } from "@/service/const"
import userApi from "service/user"
import { VipMember } from "@/service/types/user"

type IParams = {
  history: History
}

const getHref = (history: History) => {
  // 当前的路径参数
  const { search }: any = history.location

  // 抽出参数
  const searchs = search.replace("?", "").split("&");

  console.log(searchs);
  const result: any = {};

  searchs.forEach((str: any) => {
    if (str) {
      const keyValue = str.split("=");

      if (keyValue.length === 2) {
        if (keyValue[0] && keyValue[1]) {
          const ValueOfKey: string = keyValue[0];
          const ValueOfName: string = keyValue[1];

          result[ValueOfKey] = ValueOfName;
        }
      }
    }
  });

  return result;
}

const getInviteType = (pathname: string): InviteType => {

  return pathname.includes('/matter')
    ? InviteType.matter
    : pathname.includes('/project')
      ? InviteType.project
      : pathname.includes('/space')
        ? InviteType.space
        : InviteType.none
}

const useGetMatter = (params: IParams) => {

  const {
    history
  } = params

  const [joinStatus, setJoinStatus] = useState<JoinType>(JoinType.none)
  const [isAccessible, toggleAccessible] = useState<boolean>(true)
  const [task, setTask] = useState<IProjectInfo | any>({})
  const [vipInfo, setVipInfo] = useState<VipMember.MemberApi | VipMember.ProjectPower>({} as any)
  const [takers, setTakers] = useState<any []>([])
  const [inviteType, setInviteType] = useState(InviteType.none)
  const [unaccessibleText, setUnaccessibleText] = useState('')
  const [query, setQuery] = useState<ISearch>({} as any)
  const queryRef = useRef(query)
  const taskRef = useRef(task)

  useLayoutEffect(() => {
    taskRef.current = task
  }, [task])

  const setStatusInMatter = (data: ITask, dispatchData: ITaskDispatch) => {

    const {
      matter_type,
    } = data

    const isMeeting = matter_type === 10702

    const tool = matter_type === 10701
      ? TaskTool
      : matter_type === 10702
        ? MeetingTool
        : undefined

    if (!tool) {
      return
    }

    if (tool.isJoined(dispatchData)) {

      setJoinStatus(JoinType.joined)
      
    } else if (tool.isRefused(dispatchData)) {

      setJoinStatus(JoinType.refused)

    } else if (
      isMeeting &&
      MeetingTool.isAskForLeave(dispatchData)
    ) {

      setJoinStatus(JoinType.askForLeave)
    }

  }
  
  /**
   * 事项取消了
   * 已加入
   * 加入
   * 拒绝了重新加入
   * 被移出了重新加入
   * @param id 
   * @param takers 
   */
  const checkMatter = async (task: ITask, takers: any[]) => {

    console.log('checkMatter', task, takers)

    const uid = localStorage.getItem('fx-user_id')

    if (!uid) {
      return
    }

    const {
      matter_type,
      task_id,
    } = task

    const isTask = matter_type === 10701
    const isMeeting = matter_type === 10702

    if (
      (isTask && TaskTool.isTaskDismiss(task)) ||
      (isMeeting && MeetingTool.isMeetingDismiss(task)) ||
      task.is_cancel
    ) {
      // 事项/会议已取消
      toggleAccessible(false)
      return
    }

    const target = takers.find(item => item.taker_id === uid)

    if (target) {
      // 协作人有我了

      if (
        (isTask && TaskTool.isJoined(target)) ||
        (isMeeting && MeetingTool.isJoined(target))
      ) {
        // 已加入
        setJoinStatus(JoinType.joined)
        return
      }

      if (
        (isTask && TaskTool.isRefused(target)) ||
        (isMeeting && MeetingTool.isRefused(target))
      ) {
        // 已拒绝
        setJoinStatus(JoinType.refused)
        return
      }

      if (isMeeting && MeetingTool.isAskForLeave(target)) {
        // 已请假
        setJoinStatus(JoinType.askForLeave)
        return
      }
    }

    try {

      const params = {
        taker_id: [uid],
        from_user_id: queryRef.current.u,
        invite_type: 'flyele',
        task_id,
      }

      await matterApi.addTaskDispatch(params)

      const res = await matterApi.getDetail(task_id)

      setStatusInMatter(task, (res as any).data.task_dispatch)
      
    } catch (e: any) {
      console.log('checkMatter',e)

      // 超了人数的情况
      if (e.code === 40011) {
        // {
        //   code: number
        //   dbg_error: string = "任务参与人超出限制"
        //   message: string = "数量超出限制"
        // }
        userApi.taskPower(task_id)
          .then(res => {
            console.log('taskPower', res)

            setVipInfo(res.data)
          })

        setJoinStatus(JoinType.fail)
      }
    }

  }

  /**
   * 加入空间成员
   * @param spaceId 
   * @param uid 
   * @param memberType 1: 内部成员 2: 外部成员
   */
  const addMemberInSpace = async (spaceId: string, uid: string) => {

    const {
      u,
      memberType,
    } = queryRef.current

    if (u) {

      const params = {
        invite_id: u,
        invite_type: "flyele",
        // user_id: [uid]
        [memberType === '2' ? 'external_user_id' : 'user_id']: [uid]
      }

      try {
        const res = await spaceApi.addMemberInSpace(spaceId, params)
        
        const { data } = await spaceApi.getMemberState(spaceId, uid)

        console.log('对当前用户发送邀请', res, data)
        
        setStatusInSpace(data as ISpaceToolState)

      } catch (err) {
        console.error(err)
      }
    }
  }

  const setStatusInSpace = (data: ISpaceToolState) => {

    if (SpaceTool.isJoined(data)) {

      setJoinStatus(JoinType.joined)
      
    } else if (SpaceTool.isOngoing(data)) {

      setJoinStatus(JoinType.ongoing)

    } else if (SpaceTool.isUnapproved(data)) {

      setJoinStatus(JoinType.unapproved)
    }

  }

  /**
   * 空间解散了
   * 已加入
   * 加入
   * 被移出了重新加入
   * @param spaceId 
   * @returns 
   */
  const checkSpace = async (spaceId: string) => {
    console.log('checkSpace')

    const uid = localStorage.getItem('fx-user_id')

    if (!uid) {
      return
    }

    try {
      const { data } = await spaceApi.getMemberState(spaceId, uid)
      console.log('checkSpace', data)

      const {
        memberType,
      } = queryRef.current

      // 10904 被移除过的状态,重新申请加入？
      if (
        !data.state || 
        data.state === 10904 ||
        (data.member_type === 2 && memberType === '1')
      ) {

        await addMemberInSpace(spaceId, uid)

      } else {

        setStatusInSpace(data as ISpaceToolState)
      }
    } catch (e) {
      console.log('查看空间是否有对当前对象发出邀请失败', e)
    }

  }

  // const setStatusInProject = (data: IProjectToolState) => {

  //   if (ProjectTool.isJoined(data)) {

  //     setJoinStatus(JoinType.joined)
      
  //   }

  // }

  const checkIfSpaceDismiss = () => {

    const {
      ws_type
    } = (taskRef.current as IProjectInfo)

    const {
      memberType,
    } = queryRef.current

    console.log('checkIfSpaceDismiss', ws_type, memberType)

    // 重点关注：项目邀请了你为外部成员，你还没点击之前把空间解散了，这时你才去点击，就直接提示空间已解散
    if (
      ws_type === 2 &&
      memberType === '2'
    ) {
      toggleAccessible(false)
      setUnaccessibleText('空间已解散')
      return true
    }

    return false
  }

  /**
   * 项目解散了
   * 已加入
   * 加入 → 成功/超员失败/...
   * 被移出了重新加入
   */
  const checkProject = async (projectId: string, takers: any[]) => {

    const uid = localStorage.getItem('fx-user_id')

    if (!uid) {
      return
    }

    const {
        u,
        memberType,
      } = queryRef.current

    const target = takers.find(item => item.user_id === uid)

    console.log('checkProject', takers, target)


    if (checkIfSpaceDismiss()) {
      return
    }

    if (
      target && ProjectTool.isJoined(target) &&
      !target.is_external_member
    ) {
      // 已加入
      setJoinStatus(JoinType.joined)
      return
    }

    try {

      const params: IProjectInviteMember = {
        // user_id: [uid],
        [memberType === '2' ? 'external_user_id' : 'user_id']: [uid],
        invite_id: u,
        invite_type: 'flyele',
      }

      const { data } = await projectApi.projectInviteMember(projectId, params)

      // setStatusInProject(data as IProjectToolState)
      // 不报错就是加入成功
      !data && setJoinStatus(JoinType.joined)
      
    } catch (e: any) {
      console.error(e)
      // 超了人数的情况
      if (e.code === 40011) {
        // {
        //   code: number
        //   dbg_error: string = "任务参与人超出限制"
        //   message: string = "数量超出限制"
        // }

        // userApi.projectPower(projectId)
        //   .then(res => {
        //     console.log('projectPower', res)

        //     setVipInfo(res.data)
        //   })

        setJoinStatus(JoinType.fail)
      }
    }

  }

  const renderMatter = (query: any) => {

    const {
      id,
      cancel,
      matter_type,
    } = query

    if (cancel === '1') {
      // 事项/会议取消了
      setTask({
        matter_type: Number(matter_type) || MatterType.事项
      })
      toggleAccessible(false)
      return
    } 

    Promise.all([
      matterApi.getDetail(id),
      matterApi.getTakers(id),
    ])
    .then(([res1, res2]: any[]) => {

      const {task} = res1.data

      setTask(task)
      setTakers(res2.data)

      checkMatter(task, res2.data)
    })
    // .catch(err => {
    //   checkMatter(task, res2.data)
    // })

  }

  const renderProject = (id: string) => {

    Promise.all([
      projectApi.getProjectInfo(id),
      projectApi.getMemberInProject(id),
    ])
      .then(([res, takerRes]) => {

        // console.log('takerRes', takerRes)

        const creator = res.data.project_members.find((item: any) => item.user_id === res.data.creator_id)

        const task = Object.assign({}, res.data, {
          avatar: creator?.avatar,
          nick_name: creator?.nick_name,
        })

        setTask(task)
        // setTakers(res.data.project_members)
        setTakers((takerRes as any)?.data || res.data.project_members)

        if (ProjectTool.isProjectDismiss(task)) {
          // 项目已解散了
          toggleAccessible(false)

        } else {
          checkProject(id, (takerRes as any).data)

        }

      })
      .catch(console.error)

  }

  const renderSpace = (query: any) => {

    const {
      id,
      cancel,
    } = query

    // if (cancel === '1') {
    //   // 取消了
    //   toggleAccessible(false)
    //   return
    // } 

    spaceApi.getSpaceDetail(id)
      .then((res: any) => {

        const creator = res.data.takers.find((item: any) => item.user_id === res.data.creator_id)

        const task = Object.assign({}, res.data, {
          avatar: creator?.avatar
        })

        const allTakers = [
          ...res.data.takers || [],
          ...res.data.other_takers || []
        ]

        setTask(task)
        setTakers(allTakers)

        if (SpaceTool.isSpaceDismiss(task)) {
          // 空间已解散了
          toggleAccessible(false)

        } else {

          checkSpace(id)
        }
      })

  }

  useEffect(() => {

    const query = getHref(history)

    const {
      id,
    } = query
    
    const it = getInviteType(history.location.pathname)
    // console.log('进来feedback', history, it)

    setInviteType(it)

    switch (it) {

      case InviteType.matter:
        renderMatter(query)
        break

      case InviteType.project:
        renderProject(id)
        break

      case InviteType.space:
        renderSpace(query)
        break

      default: break;
    }

  }, [history])

  useEffect(() => {

    const oQuery  = getHref(history)

    setQuery(oQuery)

    queryRef.current = oQuery

  }, [history])

  return {
    unaccessibleText,
    task,
    setTask,
    takers,
    inviteType,
    query,
    joinStatus,
    isAccessible,
    vipInfo,
  }
}

export default useGetMatter
export {
  InviteType
}