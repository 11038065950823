import { I18N } from '@flyele-nx/i18n'
import React from "react";
import { ISpace } from '@/service/types/space'
import styles from "./index.module.scss";
// import failIcon from "@/icons/icon-fail.png";
import { WorkspaceType } from '@/constants/space'

const InviteTipsPopup = (Props: {
  spaceInfo: ISpace | null,
  isOverSpaceMaxNum: boolean,
  projectName?: string,
  isPc?: boolean,
  isProject?: boolean
  bottom?: string
}) => {
  const { spaceInfo, projectName, isPc, isProject, bottom } = Props

  if (spaceInfo && spaceInfo.ws_type === WorkspaceType.GROUP) {
    return (
      <div className={isPc ? styles["pc-tips-popup-root"] : styles["m-tips-popup-root"]} style={{ bottom }}>
        <div className={styles["tips-text"]}>{`${I18N.common.youWillJoin}：${I18N.common.teamspace}【${spaceInfo.name || '-'}】${projectName ? `、${I18N.common.project}【${projectName}】` : ''}`}</div>
        {/*{ isOverSpaceMaxNum &&*/}
        {/*<div className={`${styles["tips-text"]} ${styles["warning-text"]}`}>*/}
        {/*  <img className={styles["failIcon"]} src={failIcon} alt="警告" />*/}
        {/*  加入人数过多，需要空间管理员审批*/}
        {/*</div>*/}
        {/*}*/}
      </div>
    )
  } else if (isPc && isProject) {
    return (
      <div className={isPc ? styles["pc-tips-popup-root"] : styles["m-tips-popup-root"]}  style={{ bottom }}>
        <div className={styles["tips-text"]}>
          {`${I18N.common.youWillJoinTogether}：${I18N.common.teamspace}【${spaceInfo?.name || '-'}】`}
        </div>
      </div>
    )
  } else {

    return null
  }
}

export default InviteTipsPopup
