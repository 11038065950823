import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import MatterApi from "@/service/matter";
import { WindowType } from "@/pages/PureHooks/usePlatformCheck";
import cs from 'classnames'
import { getNowWindowType } from "@/pages/excel/hooks/utils";

interface IProps {
  taskId: string
}

const AssistProcess = ({ taskId }: IProps) => {
  const nowWindowType = getNowWindowType()

  const [list, setList] = useState<any[]>([])

  const fetchData = async (id: string) => {
    const { data } = await MatterApi.getTaskRelation(id)

    let resList: any = []
    if (data) {
      resList = [
        {
          title: '待办',
          total: data.gadget_todo_total || 0,
          icon: '/assets/process/todo.png',
        },
        {
          title: '会议',
          total: data.gadget_meeting_total || 0,
          icon: '/assets/process/meeting.png',
        },
        {
          title: '公告',
          total: data.gadget_notice_total || 0,
          icon: '/assets/process/notice.png',
        },
        {
          title: '文件',
          total: data.file_total || 0,
          icon: '/assets/process/files.png',
        },
        {
          title: '重点',
          total: data.important_total || 0,
          icon: '/assets/process/important.png',
        },
        {
          title: '沟通记录',
          total: data.comment_total || 0,
          icon: '/assets/process/chat.png',
        },
      ]
    }


    setList(resList)
  }

  useEffect(() => {
    fetchData(taskId)
  }, [taskId])

  return (
    <div className={styles.assistProcessRoot}>
      <div className={styles.title}>协作过程</div>
      <div className={cs(styles.listBox, {[styles.mobileClass]: nowWindowType !== WindowType.PC浏览器})}>
        {list.map(item => (
          <div className={styles.listItem} key={item.title}>
            <div className={styles.left}>
              <div className={styles.num}>{item.total}</div>
              <div className={styles.text}>{item.title}</div>
            </div>
            <div className={styles.right}>
              <img src={item.icon} alt=""/>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(AssistProcess)
