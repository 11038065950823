import { I18N, isCN } from '@flyele-nx/i18n'
import React, { useState } from "react";
import cs from "classnames";

import config from "config";

// 神策埋点
import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
import { SensorEvent } from "sensorsDataHook/const";
import { isMobileAns } from "sensorsDataHook/isMobile";

import MaskAlreadyOpenWechat from "@/pages/forMobileInvite/component/MaskAlreadyOpenWechat";

import {
  usePlatformCheck,
  WindowType,
} from "@/pages/PureHooks/usePlatformCheck";

import useHrefSearch from "@/pages/PureHooks/useHrefSearch";

import ActionModal from "./ActionModal";
import styles from "../Task/index.module.scss";
import useEnvokeApp from "./BtnForApp/useEnvokeApp";
import { ISpace } from '@/service/types/space'
import InviteTipsPopup from '@/Component/InviteTipsPopup'
import LoginBtn from "@/components/LoginBtn";
import { getMiniProgramPath } from "../utils";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {EmailLoginModal} from "@/components/login/email-login-modal";

type TProps = {
  handleRefuse: () => void;
  handleAccept: () => void;
  task: any;
  spaceInfo?: ISpace
  isOverSpaceMaxNum?: boolean
  sendSensors: () => void
};

const Title = (Props: TProps | any) => {
  const {
    task,
    isValidToken,
    hasToken,
    goDispatch,
    inviter_id,
    history,
    spaceInfo,
    isOverSpaceMaxNum,
    sendSensors
  } = Props;

  const hrefSearch = useHrefSearch();

  const [tempMiniProgramPath, set_tempMiniProgramPath] = useState("");
  const [visibleMask, set_visibleMask] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);

  const _getMiniProgramPath = ({
    personal_state,
    invite_id,
    task_id,
    user_id,
    rid,
  }: any): string => {
    const json: any = {
      personal_state,
      invite_id,
      task_id,
    };

    if (user_id) {
      json.user_id = user_id;
    }

    const json_string = JSON.stringify(json);

    console.log(
      "请求小程序的链接",
      `/pages/tabBar/schedule/index?submitInfo=${json_string}`
    );

    // const path = `/pages/tabBar/schedule/index?submitInfo=${json_string}`;
    const path = getMiniProgramPath(task_id, invite_id, rid)

    return path;
  };

  const savePath = (path: string) => {
    sendSensors && sendSensors()

    set_tempMiniProgramPath(path);
    set_visibleMask(true);
  };

  // 接受模态框
  const [acceptModalVisible, setAcceptModalVisible] = useState(false);
  // 请假模态框
  const [vacateModalVisible, setVacateModalVisible] = useState(false);
  // 拒绝模态框
  const [refuseModalVisible, setRefuseModalVisible] = useState(false);

  // 请假或者拒绝理由
  const [refuseReason, setRefuseReason] = useState("");

  // 上一个操作
  const [lastAction, setLastAction] = useState("");

  const { nowWindowType } = usePlatformCheck();

  const { evokeApp, toLoginPage } = useEnvokeApp();

  const openEmailLogin = () => {
    setEmailVisible(true)
  }

  const afterClick = async (type: string) => {
    if (!hasToken()) {
      // 假如没有token信息
      if (isCN) {
        setAcceptModalVisible(true);
      } else {
        openEmailLogin()
      }
      return
    }

    // 检查token是否有效 - 无效切入登陆
    if (!(await isValidToken())) {
      if (isCN) {
        setAcceptModalVisible(true);
      } else {
        openEmailLogin()
      }
    } else {
      sendSensors && sendSensors()

      switch (type) {
        case "接受":
          // 提交接受
          if (await goDispatch(type)) {
            JumpHrefAfterSubmit(type);
          }
          break;
        // case "请假":
        //   // 显示请假窗口
        //   setRefuseReason("");
        //   setVacateModalVisible(true);
        //   break;
        case "拒绝":
          // 显示拒绝窗口
          setRefuseReason("");
          setRefuseModalVisible(true);
          break;
        default:
          console.log("错误的操作");
      }
    }
  }

  /** 点击按钮操作内容 */
  async function handleClick(type: string) {
    /**
     * 微信小程序侧的情况
     */
    // 直接在按钮上

    // 记录最近的上一个操作
    setLastAction(type);

    if (nowWindowType === WindowType.企业微信浏览器) {
      toLoginPage(history, true, openEmailLogin)
      return
    }

    if (nowWindowType === WindowType.手机浏览器) {
      sendSensors && sendSensors()

      let path = "";
      const { id, u, user_id, memberType, dispatch_at } = hrefSearch;
  
      let queryJson = `?id=${id}&u=${u}&member_type=${memberType}&dispatch_at=${dispatch_at}`
      if (user_id) {
        queryJson += `&target_uid=${user_id}`
      }
      
      if (task?.matter_type === 10701) {
        if (type === "接受") {
          path = `operate/task/accept${queryJson}`;
          evokeApp(path, history, openEmailLogin);
        }

        if (type === "拒绝") {
          path = `operate/task/refuse${queryJson}`;
          evokeApp(path, history, openEmailLogin);
        }
      }

      if (task?.matter_type === 10702) {
        if (type === "接受") {
          path = `operate/task/accept${queryJson}`;
          evokeApp(path, history, openEmailLogin);
        }

        if (type === "请假") {
          path = `operate/task/leave${queryJson}`;
          evokeApp(path, history, openEmailLogin);
        }

        if (type === "拒绝") {
          path = `operate/task/refuse${queryJson}`;
          evokeApp(path, history, openEmailLogin);
        }
      }

      if (curProjectType !== PROJECT_ENV.pri_neiMongol) {
        return
      }
    }

    await afterClick(type)
  }

  /** 提交拒绝 */
  async function JumpHrefAfterSubmit(type: string) {
    switch (type) {
      case "接受":
        // 显示请假窗口
        sensors.sendPoint(SensorEvent.accept_event, {
          business_id: task?.task_id,
          business_type: getTypeCName(task?.matter_type),
          invite_people_id: inviter_id,
          enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
          identity_type: "邀请链接",
          is_click_accept_button: true,
        });
        history.push({
          pathname: `/invite/task_ans`,
          search: `?result=attended&id=${task.task_id}&u=${inviter_id}`,
        });
        break;
      case "请假":
        // 显示请假窗口
        if (
          await goDispatch(type, {
            reason:
              refuseReason && refuseReason !== "" ? refuseReason : undefined,
          })
        ) {
          sensors.sendPoint(SensorEvent.ask_for_leave_event, {
            business_id: task?.task_id,
            business_type: getTypeCName(task?.matter_type),
            invite_people_id: inviter_id,
            enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
            identity_type: "邀请链接",
            click_ask_for_leave_button: true,
            is_fill_ask_for_leave_reason: !!(
              refuseReason && refuseReason !== ""
            ),
          });
          history.push({
            pathname: `/invite/task_ans`,
            search: `?result=vacate&id=${task.task_id}&res=${refuseReason}&u=${inviter_id}`,
          });
        }
        break;
      case "拒绝":
        // 显示拒绝窗口
        if (
          await goDispatch(type, {
            reason:
              refuseReason && refuseReason !== "" ? refuseReason : undefined,
          })
        ) {
          sensors.sendPoint(SensorEvent.refuse_event, {
            business_id: task?.task_id,
            business_type: getTypeCName(task?.matter_type),
            invite_people_id: inviter_id,
            enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
            identity_type: "邀请链接",
            is_click_refuse_button: true,
            is_fill_refuse_reason: !!(refuseReason && refuseReason !== ""),
          });
          history.push({
            pathname: `/invite/task_ans`,
            search: `?result=refuse&id=${task.task_id}&res=${refuseReason}&u=${inviter_id}`,
          });
        }
        break;
      default:
        console.log("错误的操作");
    }
  }

  // const toLogin = () => {

  //   console.log('toLogin', history)

  //   const { id, u } = hrefSearch
  //   const queryJson = `?id=${id}&u=${u}`
  //   const path = `operate/task/accept${queryJson}`

  //   evokeApp(path, history)
  // }

  // return (
  //   <div className={styles["footer-btn-task"]}>
  //     <div className={styles['footer-btn']}
  //       onClick={toLogin}
  //     >
  //       登录后立即加入
  //     </div>
  //   </div>
  // )

  return (
    <div className={styles["footer-btn-task"]}>
      <InviteTipsPopup spaceInfo={spaceInfo} isOverSpaceMaxNum={isOverSpaceMaxNum} projectName={task?.project?.project_name} />
      {task?.matter_type === 10701 && (
        <div className={styles["center"]}>
          <div
            className={cs(styles["left-accept-btn"])}
          >
            {nowWindowType === WindowType.微信浏览器 ? (
              <>
                {/* @ts-ignore*/}
                <wx-open-launch-weapp
                  id="launch-btn"
                  username={config.miniProgramUsername}
                  path={_getMiniProgramPath({
                    personal_state: 10409,
                    invite_id: inviter_id,
                    task_id: task?.task_id,
                    user_id: hrefSearch?.user_id,
                    rid: hrefSearch?.rid
                  })}
                >
                  <script type="text/wxtag-template">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "inherit",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                      onClick={() =>
                        savePath(
                          _getMiniProgramPath({
                            personal_state: 10409,
                            invite_id: inviter_id,
                            task_id: task?.task_id,
                            user_id: hrefSearch?.user_id,
                            rid: hrefSearch?.rid
                          })
                        )
                      }
                    >
                      {I18N.common.immediatelyAfterLogin}
                    </div>
                  </script>
                  {/* @ts-ignore*/}
                </wx-open-launch-weapp>
              </>
            ) : (
              <LoginBtn onClick={() => handleClick("接受")} />
            )}
          </div>
        </div>
      )}

      {task?.matter_type === 10702 && (
        <div className={styles["center"]}>
          <div
            className={cs(
              styles["left-accept-meeting-btn"],
            )}
          >
            {nowWindowType === WindowType.微信浏览器 ? (
              <>
                {/* @ts-ignore*/}
                <wx-open-launch-weapp
                  id="launch-btn"
                  username={config.miniProgramUsername}
                  path={_getMiniProgramPath({
                    personal_state: 10611,
                    invite_id: inviter_id,
                    task_id: task?.task_id,
                    user_id: hrefSearch?.user_id,
                    rid: hrefSearch?.rid
                  })}
                >
                  <script type="text/wxtag-template">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "inherit",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                      }}
                      onClick={() => {
                        savePath(
                          _getMiniProgramPath({
                            personal_state: 10611,
                            invite_id: inviter_id,
                            task_id: task?.task_id,
                            user_id: hrefSearch?.user_id,
                            rid: hrefSearch?.rid
                          })
                        );
                      }}
                    >
                      {I18N.common.immediatelyAfterLogin}
                    </div>
                  </script>
                  {/* @ts-ignore*/}
                </wx-open-launch-weapp>
              </>
            ) : (
              <LoginBtn onClick={() => handleClick("接受")} />
            )}
          </div>
        </div>
      )}

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            await afterClick('接受');
          }}
        />
      )}

      <ActionModal
        lastAction={lastAction}
        JumpHrefAfterSubmit={JumpHrefAfterSubmit}
        handleClick={handleClick}
        isValidToken={isValidToken}
        refuseReason={refuseReason}
        setRefuseReason={setRefuseReason}
        acceptModalVisible={acceptModalVisible}
        setAcceptModalVisible={setAcceptModalVisible}
        vacateModalVisible={vacateModalVisible}
        setVacateModalVisible={setVacateModalVisible}
        refuseModalVisible={refuseModalVisible}
        setRefuseModalVisible={setRefuseModalVisible}
      />

      {/** 微信侧提示已经打开了 */}
      <MaskAlreadyOpenWechat
        path={tempMiniProgramPath}
        visible={visibleMask}
        set_visibleMask={set_visibleMask}
        mobileClick={() => handleClick(lastAction)}
      />
    </div>
  );
};

export default Title;
