import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

// import { Avatar } from "Component/Avatar/Avatar";

import BecomeInteracts from "@/pages/PureH5/FooterOpenBtn/BecomeInteracts";

import usePlatformCheck, {
  WindowType,
} from "@/pages/PureHooks/usePlatformCheck";
import Layout from "@/Layout/forH5Auto/Layout";
import userApi from "service/user";

import MaskModal from "./V17MaskModal";
import useHrefSearch from "../PureHooks/useHrefSearch";

const default_src = "/assets/v1_7/wx_open/logo@3x.png";

const InviteInteracts = (props: any) => {
  const { nowWindowType } = usePlatformCheck();

  const hrefSearch = useHrefSearch();

  const [nick_name, set_nick_name] = useState(`${I18N.common.loading}...`);
  const [avatar_src, set_avatar_src] = useState("");

  useEffect(() => {
    userApi
      .avatar(hrefSearch?.u || "")
      .then((res: any) => {
        set_avatar_src(res.data.avatar);
        set_nick_name(res.data.nick_name);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [hrefSearch?.u]);

  const [visible, set_visible] = useState(true);

  const getPath = (): string => {
    return `/pages/tabBar/schedule/index?addPartner=${hrefSearch.u}`;
  };

  if (nowWindowType === WindowType.PC浏览器) {
    return (
      <Layout>
        <div className={styles.v17pc}>
          <div className={styles.v17pc_center} style={{ paddingBottom: 0 }}>
            <div className={styles.v17pc_center_avatar}>
              <img src={avatar_src || default_src} alt="" />
            </div>

            <div className={styles.v17pc_center_nickname}>
              {`【${nick_name}】`}
            </div>
            <div className={styles.v17pc_center_text}>
              {I18N.common.h5_inviteYouToBecome}<span>{I18N.common.assignee}</span>
            </div>

            <div className={styles.v17pc_center_content}>
              <div className={styles.v17pc_center_content_title__img}>
                <div className={styles.v17pc_center_content_title__img__banner}>
                  <div
                    className={
                      styles.v17pc_center_content_title__img__banner__left
                    }
                  />
                  <div>{I18N.common.h5_inTheFutureWeWill}</div>
                  <div
                    className={
                      styles.v17pc_center_content_title__img__banner__right
                    }
                  />
                </div>
              </div>

              <div className={styles.v17pc_center_content_pics}>
                <div className={styles.v17pc_center_content_pics_item}>
                  <img src="/assets/v1_7/interacts/p1.png" alt="" />
                  <div>{I18N.common.h5_distributionOfMatters}</div>
                </div>
                <div className={styles.v17pc_center_content_pics_item}>
                  <img src="/assets/v1_7/interacts/p2.png" alt="" />
                  <div>{I18N.common.h5_processCoordination}</div>
                </div>
                <div className={styles.v17pc_center_content_pics_item}>
                  <img src="/assets/v1_7/interacts/p3.png" alt="" />
                  <div>{I18N.common.h5_whileChattingAssigningAppointments}</div>
                </div>
              </div>
            </div>

            <BecomeInteracts {...props} />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <div className={styles.bodyback}>
      <div
        className="full-screen-mask"
        style={{
          display:
            visible && nowWindowType === WindowType.微信浏览器
              ? "block"
              : "none",
        }}
        onClick={() => {
          set_visible(false);
        }}
      />

      <div className={styles.v17}>
        <div className={styles.v17_center}>
          <div className={styles.v17_center_avatar}>
            <img src={avatar_src || default_src} alt="" />
          </div>

          <div className={styles.v17_center_nickname}>{`【${nick_name}】`}</div>
          <div className={styles.v17_center_text}>
            {I18N.common.h5_inviteYouToBecome}<span>{I18N.common.assignee}</span>
          </div>

          <div className={styles.v17_center_content}>
            <div className={styles.v17_center_content_title__img}>
              <div className={styles.v17_center_content_title__img__banner}>
                <div
                  className={styles.v17_center_content_title__img__banner__left}
                />
                <div>{I18N.common.h5_inTheFutureWeWill}</div>
                <div
                  className={
                    styles.v17_center_content_title__img__banner__right
                  }
                />
              </div>
            </div>

            <div className={styles.v17_center_content_pics}>
              <div className={styles.v17_center_content_pics_item}>
                <img src="/assets/v1_7/interacts/p1.png" alt="" />
                <div>{I18N.common.h5_distributionOfMatters}</div>
              </div>
              <div className={styles.v17_center_content_pics_item}>
                <img src="/assets/v1_7/interacts/p2.png" alt="" />
                <div>{I18N.common.h5_processCoordination}</div>
              </div>
              <div className={styles.v17_center_content_pics_item}>
                <img src="/assets/v1_7/interacts/p3.png" alt="" />
                <div>{I18N.common.h5_whileChattingAssigningAppointments}</div>
              </div>
            </div>
          </div>

          <BecomeInteracts {...props} />

          {nowWindowType === WindowType.微信浏览器 ? (
            <MaskModal
              path={getPath()}
              visible={visible}
              set_visibleMask={set_visible}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InviteInteracts;
