import DateApi from 'service/date'
import dayjs from 'dayjs'

class TimeGetter {
  timeDiff = 0

  isInit = false

  serviceStamp = 0

  isLoading = false

  curTime = ''

  async getDate(): Promise<number> {
    if (this.isLoading) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null)
        }, 50)
      })

      const stamp = await this.getDate()

      return stamp
    }

    if (this.serviceStamp) {
      return dayjs().unix() - this.timeDiff
    }

    try {
      this.isLoading = true
      const stamp = (await DateApi.getCurrentDate()) as number

      this.timeDiff = dayjs().unix() - stamp
      this.serviceStamp = stamp
      this.isLoading = false
      return stamp
    } catch (e) {
      console.error('获取时间戳失败', e)
      this.isLoading = false
      return 0
    }
  }
}

export default new TimeGetter()
