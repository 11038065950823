import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";
import useGetSpace from "@/pages/InviteSpace/hooks/useGetSpace";
import styles from './index.module.scss'
import { getSpaceSinkUrl } from '@/utils/space'
import SpaceAvatar from "@/pages/InviteSpace/components/SpaceAvatar";
import {SPACE_LEVEL} from "@/constants/space";
import FileLists from "@/pages/forMobileInvite/component/FileLists";
import MaskModal from "@/pages/forMobileInvite/component/MaskModal";
import SmallTypeModal from "@/pages/forMobileInvite/component/MaskModal/smallType";
import DividerLine from "@/pages/forMobileInvite/component/DividerLine";
import FooterBtnAction from "./components/FooterBtnAction";
import { History } from 'history'
import {isWeiXinWork} from "@/pages/PureHooks/isMobile";
import { AccountError } from '@/components/account-error'
import {curProjectType, PROJECT_ENV} from "@/service/const";

type TProps = {
  history: History;
}

const InviteSpaceH5 = (props: TProps) => {
  const { history } = props;
  
  const [visibleMask, set_visibleMask] = useState(false);
  
  const { 
    spaceInfo,
    // isOverSpaceMaxNum,
    inviter,
    urlParams,
    isInvited,
    sendSensors,
    spaceTakers,
    showAccountError,
    joinToSpace,
  } = useGetSpace(history)

  useEffect(() => {
    // console.log('isInvited', isInvited)
    if (isInvited) {
      // 已邀请自己了，直接进结果页

      history.push({
        pathname: `/feedback/space`,
        search: history.location.search,
      })
    }

  }, [isInvited])

  return (
    <div className={styles.spaceInviteH5Root}>
      {!isWeiXinWork() && (
        <>
          <MaskModal
            invtiter_id={inviter?.user_id}
            task={spaceInfo}
            visible={visibleMask}
            set_visibleMask={set_visibleMask}
            isSpace={true}
            dispatch_at={urlParams?.dispatch_at}
          />

          <SmallTypeModal
            invtiter_id={inviter?.user_id}
            task={spaceInfo}
            // visible={visibleMask}
            visible={false}
            set_visibleMask={set_visibleMask}
            isSpace={true}
            dispatch_at={urlParams?.dispatch_at}
          />
        </>
      )}
      
      <div
        className={styles.bgHeader}
        style={{
          backgroundImage: `url(${getSpaceSinkUrl(spaceInfo?.bg_icon).base})`,
        }}
      />

      {showAccountError ? (
        <AccountError />
      ) : (
        <>
          <div className={styles.headerBox}>
            <div className={styles.title}>{inviter.nickname} &nbsp;{I18N.common.invitationSpace}</div>
            <div className={styles['avatar-info']}>
              <SpaceAvatar
                classname={styles.avatar}
                icon={spaceInfo?.icon}
                icon_color={spaceInfo?.icon_color}
              />
              {curProjectType === PROJECT_ENV.normal && (
                <div className={styles.version}>
                  {spaceInfo && spaceInfo.level === SPACE_LEVEL.vip ? I18N.common.professionalEdition : I18N.common.basicEdition}
                </div>
              )}
            </div>
            <div className={styles['team-name']}>{spaceInfo?.name}</div>
            <div className={styles.creator}>
              {`${I18N.common.spaceCreator}：${spaceInfo?.creator_name}`}
            </div>
          </div>

          <div className={styles.content}>
            {spaceInfo?.ws_desc ? (
              <div className={styles.desc}>{spaceInfo.ws_desc || I18N.common.noSpatialInformation}</div>
            ) : null}
            <FileLists filesList={spaceInfo?.files || []} task={spaceInfo} />

            <DividerLine />

            <div className={styles.spaceMemberBox}>
              <div className={styles.memberTitle}>
                <div className={styles.memberText}>{I18N.common.space_member}</div>
                {/*{isOverSpaceMaxNum &&*/}
                {/*  <div className={styles.memberTips}>*/}
                {/*    <img className={styles.icon} src="/assets/space/warming.png" alt=""/>*/}
                {/*    加入人数过多，需要空间管理员审批*/}
                {/*  </div>*/}
                {/*}*/}
              </div>
              <div className={styles.memberList}>
                {spaceTakers.map((item, index) => (
                  <img
                    alt=""
                    src={item.avatar}
                    key={index}
                    className={styles.avatar}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      
      {spaceInfo?.state === 3 ?
        <div className={styles.tips}>
          <p>{`${I18N.common.affiliatedSpace}，`}</p>
          <p>{`${I18N.common.pleaseContactTheSpace}。`}</p>
        </div>
        :
        <div className={styles.footerBox}>
          <FooterBtnAction
            history={history}
            spaceInfo={spaceInfo}
            sendSensors={sendSensors}
            showAccountError={showAccountError}
            loginSuccess={joinToSpace}
          />
        </div>
      }
    </div>
  )
}

export default InviteSpaceH5
