

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Toast } from "antd-mobile";
import {load} from '@fingerprintjs/fingerprintjs'
import 'antd-mobile/lib/toast/style/index'
import styles from './index.module.scss'

import MobileForm from '../MobileForm'
import Code from '../Code'
import Button from '../Button'
import Rights from '../Rights'

import {IMobileFormOutput, IMobileFormProps} from '../MobileForm/main'
import {ICodeProps} from '../Code/main'
import {IButtonProps, LoginStatus} from '../Button/main'
import {IFormProps} from './main'

import user from '@/service/user'
import NameForm from '../NameForm';
import { INameFormOutput, INameFormProps } from '../NameForm/main';
import { getUid } from '@/utils/tool';

const WAIT_TIME = 120

const Form = (props: IFormProps) => {

  (window as any).Toast = Toast

  const {
    next,
    skipRegister,
    title = '登录飞项',
    desc = '若未注册，登录后自动注册'
  } = props

  const mobileFormRef = useRef<IMobileFormOutput>(null)
  // const codeRef = useRef(null)
  const nameFormRef = useRef<INameFormOutput>(null)

  const [status, setStatus] = useState<LoginStatus>(LoginStatus.beforeCheckMobile)
  // const [status, setStatus] = useState<LoginStatus>(LoginStatus.beforeCheckName)

  const statusRef = useRef(status)
  useEffect(() => {
    statusRef.current = status
  }, [status])

  const [disabledBtn, toggleDisable] = useState<IButtonProps['disabled']>(true)
  const [btnTxt, setBtnTxt] = useState<IButtonProps['text']>('获取验证码')

  const [codeError, toggleCodeError] = useState<ICodeProps['error']>(false)

  const [code, setCode] = useState<string>('')
  const codeRef = useRef('')

  const [time, setTime] = useState<number>(WAIT_TIME)

  const deviceIdRef = useRef(getUid())
  useEffect(() => {

    load().then(request => {

      request.get().then(res => {
        
        const uuid = res.visitorId
        
        console.log('finger', res, uuid)
        deviceIdRef.current = uuid
      })
    })
  }, [])

  // 控制定时器在组件销毁后clear掉
  const timerRef = useRef(0)
  useEffect(() => {

    return () => {
      timerRef.current && cancelAnimationFrame(timerRef.current)
    }
  }, [])

  /**
   * 验证码定时器
   */
  const setTimer = () => {

    let time = Date.now()

    const cycle = () => {

      timerRef.current = requestAnimationFrame(() => {
  
        const now = Date.now()
  
        if (now - time >= 1000) {
  
          time = now
          setTime((val) => {
  
            const next = val - 1
  
            if (next > 0) {
  
              cycle()
            }
  
            return next
          })
        } else {
  
          cycle()
        }
  
      })
    } 

    setTime(WAIT_TIME)
    cycle()
  }

  /**
   * 获取验证码
   */
  const getCode = () => {
    user.getVertifyCode({
      telephone: mobileFormRef.current?.input,
    })
      .then(res => {
        console.log('getVertifyCode', res)
        // setCode(res.data)

        // if (!res.code) {

          setStatus(LoginStatus.beforeCheckCode)
          setBtnTxt('登录验证')
          toggleDisable(true)
  
          setTimer()

          // setCode(res.data.data)
          console.log('code值：', res.data.data)
        // }

      })
      .catch(err => {
        console.error(err)

      })
  }

  /**
   * 验证码登录
   */
  const toLogin = () => {

    // Toast.info('请稍后...')

    user.loginWithVertifyCode({
      device: {
        client_version: 'h5',
        device_id: deviceIdRef.current,
        platform: 'h5',
      },
      is_mobile_h5: true,
      telephone: mobileFormRef.current!.input,
      verify_code: codeRef.current,
    })
      .then(res => {
        console.log('toLogin', res)

        try {

          const {
            is_new,
          } = res.data.data

          if (is_new&&!skipRegister) {
            setStatus(LoginStatus.beforeCheckName)
            setBtnTxt('下一步')

            const dom = (
              <div className={styles.toast}>
                <div className={styles['toast-tick']} style={{ background: `no-repeat url('/assets/v2_0/tick.png') center/100%` }}></div>
                <div className={styles['toast-txt']} >注册成功</div>
              </div>
            )
            Toast.info(dom, 1.5)

          } else {
            //TODO: 跳到结果页
            // Toast.success('登录成功')
            next({ phone: mobileFormRef.current!.input })
          }

        } catch (err) {

          console.error(err)
        }

        
      })
      .catch(err => {
        console.error(err)

        toggleCodeError(true)
      })
  }

  /**
   * 注册后更新昵称
   */
  const updateNickname = () => {

    const name = nameFormRef.current?.input
    if (!name) {
      return
    }

    user.updateUserName(name)
      .then(res => {

        console.log('updateNickname', res.data.data)

        next({ phone: mobileFormRef.current!.input })
      })
      .catch(console.error)
  }

  const onMobileChange: IMobileFormProps['changeHandler'] = (pass, val) => {

    toggleDisable(!pass)

    pass && setStatus(LoginStatus.mobileSuccess)
  }

  const onCodeChange: ICodeProps['changeHandler'] = useCallback((val) => {

    codeRef.current = val

    if (val.length < 6) {
      toggleDisable(true)
    } else {

      toggleDisable(false)
    }

    if (codeError) {
      toggleCodeError(false)

    }
    
  }, [codeError])

  const onNameChange: INameFormProps['changeHandler'] = (pass, val) => {

    toggleDisable(!pass)
  }

  const onClickBtn = () => {

    console.log('onClickBtn', statusRef.current)

    if (statusRef.current < LoginStatus.beforeCheckCode) {
      // 输入手机号阶段
      const pass = mobileFormRef.current?.validate()
      // const pass = true

      if (pass) {

        getCode()
      } else {
        toggleDisable(true)
      }

    } else if (statusRef.current < LoginStatus.beforeCheckName) {
      // 输入验证码、登录/注册阶段
      toLogin()

    } else {
      // 未注册填写昵称阶段
      updateNickname()
    }

  }

  const titleDom = useMemo(() => {

    if (status < LoginStatus.beforeCheckCode) {

      return (
        <div>
          <h3>{title}</h3>
          <h5>{desc}</h5>
        </div>
      )

    }

    if (status >= LoginStatus.beforeCheckName) {

      return (
        <div>
          <h3>确认你的昵称</h3>
          <h5>后续可在微信小程序中同步微信头像和昵称</h5>
        </div>
      )
    }

  }, [status])

  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>
        {titleDom}
      </div>

      <div className={styles.form}>

        <MobileForm
          ref={mobileFormRef}
          show={status < LoginStatus.beforeCheckName}
          status={status}
          changeHandler={onMobileChange}
        />

        {
          status >= LoginStatus.beforeCheckName
            ? (
              <NameForm
                ref={nameFormRef}
                changeHandler={onNameChange}
              />
            )
            : null
        }

        {
          status >= LoginStatus.beforeCheckCode && status < LoginStatus.beforeCheckName
            ? (
              <Code 
                code={code}
                toggleCodeError={toggleCodeError}
                error={codeError}
                changeHandler={onCodeChange}
              />
            ) 
            : null
        }
        
        <div>
          <Button
            status={status}
            disabled={disabledBtn}
            text={btnTxt}
            clicked={onClickBtn}
          />

          {
            status >= LoginStatus.beforeCheckCode && status < LoginStatus.beforeCheckName
              ? (
                time === 0 
                  ? (
                    <div className={styles['form__code-refresh']}
                      onClick={getCode}
                    >重新发送验证码</div>
                  )
                  : <div className={styles['form__code-timer']}>{time}后可重新发送</div>
              )
              : null
          }
        </div>

        <Rights />

      </div>

    </div>
  )
}

export default Form