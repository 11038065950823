import config from "config"

type HrefReturn = {
  dispatch_at: string
  id: string
  memberType: '1' | '2'  // 1为内部成员，2为外部成员
  rid: string
  u: string
}

// 从路由中获取相关邀请参数
export function getHref(props: any): HrefReturn {
  // 当前的路径参数
  const { search }: any = props.history.location;

  // 检查参数合法性
  if (!search.includes("id=")) {
    props.history.push({ pathname: "/refuse" });
  }

  // 抽出参数
  const searchs = search.replace("?", "").split("&");

  console.log(searchs);
  const result: any = {};

  searchs.forEach((str: any) => {
    if (str) {
      const keyValue = str.split("=");

      if (keyValue.length === 2) {
        if (keyValue[0] && keyValue[1]) {
          const ValueOfKey: string = keyValue[0];
          const ValueOfName: string = keyValue[1];

          result[ValueOfKey] = ValueOfName;
        }
      }
    }
  });

  return result;
}

export function getHrefByLocation(jumpToRefuse: () => void) {
  // 当前的路径参数
  const { search }: any = window.location;

  // 检查参数合法性
  if (!search.includes("u=")) {
    jumpToRefuse();
  }

  // 抽出参数
  const searchs = search.replace("?", "").split("&");

  console.log(searchs);
  const result: any = {};

  searchs.forEach((str: any) => {
    if (str) {
      const keyValue = str.split("=");

      if (keyValue.length === 2) {
        if (keyValue[0] && keyValue[1]) {
          const ValueOfKey: string = keyValue[0];
          const ValueOfName: string = keyValue[1];

          result[ValueOfKey] = ValueOfName;
        }
      }
    }
  });

  return result;
}

/**
 * 事项、会议邀请
 * @param id
 * @param u
 * @param rid
 * @returns
 */
const getMiniProgramPath = (id: string, u: string, rid: string): string => {
  return `${config.invite}?taskId=${id}&from=wechat&from_user_id=${u}&shareFrom=2&rid=${rid || ''}`;
};

/**
 * 目标邀请页面
 */
const getMiniProgramTargetPath = (id: string, u: string, dispatch_at: string,  hrefSearch: HrefReturn): string => {
  const { rid } = hrefSearch

  return `/pages/target/pages/transfer-target/index?objectiveId=${id}&shareForm=30&from_user_id=${u}&dispatch_at=${dispatch_at}&rid=${rid}`
};

/**
 * 空间邀请页面
 */
const getMiniProgramSpacePath = (id: string, u: string, dispatch_at: string,  hrefSearch: HrefReturn): string => {
  // return ''

  const {
    memberType,
    rid,
  } = hrefSearch

  return `/pages/task/pages/transfer-space/index?spaceId=${id}&shareForm=27&from_user_id=${u}&dispatch_at=${dispatch_at}&member_type=${memberType}&rid=${rid}`
};

/**
 * 项目邀请页面
 */
const getMiniProgramProjectPath = (id: string, u: string, dispatch_at: string, hrefSearch: HrefReturn): string => {
  // return ''
  const {
    memberType,
    rid,
  } = hrefSearch
  
  return `/pages/task/pages/transfer-project/index?projectId=${id}&shareForm=25&from_user_id=${u}&dispatch_at=${dispatch_at}&shareFrom=25&member_type=${memberType}&rid=${rid}`
}

export {
  getMiniProgramPath,
  getMiniProgramProjectPath,
  getMiniProgramSpacePath,
  getMiniProgramTargetPath,
}

export type {
  HrefReturn
}