import React from 'react';
import PageLayout from "@/pages/excel/components/PageLayout";
import AvatarBox from "@/pages/excel/components/AvatarBox";
import TaskTitleBox from "@/pages/excel/components/TaskTitleBox";
import TaskBgInfo from "@/pages/excel/components/TaskBgInfo";
import TaskInfoRow from "@/pages/excel/components/TaskInfoRow";
import MemberList from "@/pages/excel/components/MemberList";
import MeetingProgress from "@/pages/excel/components/MeetingProgress";
import {useInit} from "@/pages/excel/hooks/fetchDetails";
import NoData from "@/pages/excel/components/NoData";


const ExcelTodo = () => {
  const { task, takers, errCode } = useInit()

  return (
    <PageLayout task={task} inviteId={task?.creator_id}>
      { task ? (
        <div>
          <AvatarBox avatar={task.avatar} name={task.nick_name} desc="给你分享了这个待办~" />

          <TaskTitleBox title={task.title} create_at={task.create_at} name={task.nick_name} />

          <TaskBgInfo task={task} />

          <TaskInfoRow task={task} />

          <MemberList takers={takers} />

          <MeetingProgress taskId={task.task_id} isTodo />
        </div>
      ) : (
        <NoData code={errCode} />
      )}
    </PageLayout>
  )
}

export default ExcelTodo