import React, {useEffect, useState} from 'react'
import ShowCardBox from '../ShowCardBox'
import CommentApi from "@/service/comment";
import ChatFrame from '../ChatFrame'

interface IProps {
  taskId: string
  isTodo?: boolean
}
const MeetingProgress = ({ taskId, isTodo = false }: IProps) => {

  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)

  const fetchComments = async (id: string) => {
    const { code, data, total } = await CommentApi.getComments({
      ref_type: 'task',
      ref_id: id,
    })

    if (code === 0) {
      setTotal(total)
      if (data) setList(data)
    }
  }

  useEffect(() => {
    fetchComments(taskId)
  }, [taskId])

  if (list.length === 0) return null

  return (
    <ShowCardBox
      title={isTodo ? '待办进展' : '会议进展'}
      height="626px"
      allText={total > 6 ? `全部${total}个` : ''}
    >
      <ChatFrame data={list} taskId={taskId} />
    </ShowCardBox>
  )
}

export default React.memo(MeetingProgress)