import React, {useEffect, useState} from 'react'
import styles from './index.module.scss'
import { Files, ITask } from "@/service/types/matter";
import { FileCard } from "Component/FileCard";
import useFileList from "@/pages/InviteTask/hooks/useFileList";

interface IProps {
  task: ITask
}

const TaskBgInfo = ({ task }: IProps) => {
  const [showTitle, setShowTitle] = useState(false)
  const filesList = useFileList(task);

  useEffect(() => {
    if (task.title.length > 10) setShowTitle(true)
  }, [task.title])

  const TextRow = ({titleText, content}: {titleText: string, content: string}) => (
    <div className={styles.textRow}>
      <span className={styles.title}>{titleText}: </span>
      {content}
    </div>
  )


  return (
    <div className={styles.taskBgInfoRoot}>
      {showTitle && <TextRow titleText="标题" content={task.title} />}
      {task.detail && <TextRow titleText="背景" content={task.detail} />}
      {filesList && filesList.length !== 0 &&
        <div className={styles.fileListBox}>
          {
            filesList.map((file: Files) => (
            <FileCard key={file.id} info={file} task={task} />
            ))
          }
        </div>
      }
    </div>
  )
}

export default React.memo(TaskBgInfo)