import React, { useState, FC } from "react";

import ListItem from "./DND/ListItem";

// import Title from "./DND/Title";

import "./child-process.scss";

const stylesLayout = {
  child: "invite-task-child",
  child__inner: "invite-task-child__inner",
};

const ChildProcess: FC<any> = ({ task, task_dispatch }: any) => {
  // 当前光标的排序序号（用来显示 绿色定位Line线）
  const [sort_index_for_line, set_sort_index_for_line] = useState(-2);

  const debouncedValue = sort_index_for_line;

  return (
    <div className={stylesLayout.child} style={{ display: "block" }}>
      {/* {(task?.children || []).length > 0 && <Title task={task} />} */}

      <div className={stylesLayout.child__inner}>
        {task?.children?.map((t: any, index: number) => {
          return (
              <ListItem
                key={t.task_id}
                item={t}
                sort_index_for_line={debouncedValue}
                set_sort_index_for_line={set_sort_index_for_line}
                sort_index={index}
                length={task?.children.length}
                task_dispatch={task_dispatch}
              />
          );
        })}
      </div>
    </div>
  );
};

export default ChildProcess;
