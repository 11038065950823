import React, {useEffect, useCallback, useState} from 'react'
import ShowCardBox from '../ShowCardBox'
import storageApi from "@/service/storage";
import fetchApiAllData from '@/utils/fetch-api-all-data'
import styles from "./index.module.scss";
import { getFileIcon } from "utils_for_pc/file";
import { isImage } from "utils/file";
import cs from 'classnames'

interface IProps {
  taskId: string
}

const Sediment = ({ taskId }: IProps) => {
  const [fileList, setFilesList] = useState<any[]>([])
  const [showFileList, setShowFileList] = useState<any[]>([])

  const fetchFiles = useCallback(async () => {
    if (!taskId) return

    let resList: any[] = []
    await fetchApiAllData(storageApi, 'getTaskFileList', {
      queryParams: {
        task_id: taskId
      },
      responseHandler: (res) => {
        const { data } = res

        if (data) resList = resList.concat(data)
      },
    })

    setFilesList(resList)
  }, [taskId])

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])


  useEffect(() => {
    let tempFileList: any[] = []
    if (fileList.length > 8) {
      tempFileList = fileList.slice(0, 8)
    } else {
      tempFileList = fileList
    }

    const imgList: string[] = []
    tempFileList.forEach(item => {
      if(isImage(item.file_name)) {
        imgList.push(item.file_id)
      } else {
        item.file_img = getFileIcon(item.file_name)
        item.show_info = true
      }
    })

    if (imgList.length) {
      storageApi.getOnlineImage({
        ids: imgList,
        ossConfig: 'image/resize,m_fill,w_126,h_126',
        flyeleId: taskId,
        grantor: '',
      }).then(({ data }) => {
        if (data) {
          tempFileList.forEach(item => {
            if (data[item.file_id]) {
              item.file_img = data[item.file_id]
              item.show_info = false
            }
          })
          setShowFileList(tempFileList)
        }
      })
    } else {
      setShowFileList(tempFileList)
    }
  }, [fileList, taskId])


  if (fileList.length === 0) return null

  return (
    <ShowCardBox
      title="沉淀"
      height="350px"
      allText={fileList.length > 8 ? `全部${fileList.length}个` : ''}
    >
      <div className={cs(styles.fileListRoot, {[styles.pb30]: fileList.length > 8})}>
        {showFileList.map(file => (
          <div key={file.file_id} className={styles.fileListItem}>
            <img src={file.file_img} alt={file.file_name} />

            {file.show_info && (
              <div className={styles.fileListInfo}>
                <div className={styles.infoName}>{file.file_name}</div>
                <div className={styles.infoSize}>{(file.length / 1024).toFixed(2)} KB</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </ShowCardBox>
  )
}

export default React.memo(Sediment)