import { I18N } from '@flyele-nx/i18n'
import React, { useCallback, useEffect, useState } from "react";
// H5基础结构
import Layout from "Layout/forH5Auto/Layout";
import { BigThemeBtn } from "Component/Button";
import { OneCapsule } from "Component/Capsule/OneCapsule";
import Takers from "ComponentRewrite/Takers/Takers";
import OpenAppBtn from "@/pages/PureH5/FooterOpenBtn/OpenAppBtn";
import { ChooseLoginType } from "@/components/login/choose-login-type";
// 神策埋点
import sensors, { getTypeCName } from "sensorsDataHook/initSensors";
import { SensorEvent } from "sensorsDataHook/const";
import { isMobileAns } from "sensorsDataHook/isMobile";
import InfoLineV15, {
  InfoLineV15TypeName,
  InfoLineV15DisplayType,
} from "../InviteTask/components/InfoLineV15";
import useGetTask from "../InviteTask/hooks/useGetTask";
import useFileList from "../InviteTask/hooks/useFileList";
import useGetAddress from "../InviteTask/hooks/useGetAddress";
import useAuth from "../InviteTask/hooks/useAuth";
import CapsoleTime from "./components/CapsoleTime";
import styles from "./index.module.scss";
// v17
import Header from "../InviteTask/v17/Header";
import Detail from "../InviteTask/v17/Detail";
import WinOpenBanner from "@/pages/PureH5/WinOpenBanner";
import { useHistory } from "react-router-dom";
import useHrefSearch from "../PureHooks/useHrefSearch";
import FooterTextBtn from "@/pages/PureH5/FooterTextBtn";
import {useQrcodeLogin} from "@/components/login/hooks/useQrcodeLogin";
import {QrcodeLogin} from "@/components/login/qrcode-login";
import {AccountNumberModal} from "@/components/login/account-number-modal";
import {CorpQrcodeLogin} from "@/components/login/corp-wechat-login";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {EmailLoginModal} from "@/components/login/email-login-modal";

const InviteMeeting = (props: any) => {
  // const { history } = props;
  const history = useHistory();

  // 二维码相关
  const {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    onChoose,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
    onShowModal,
  } = useQrcodeLogin()

  // 事项接口数据
  const {
    set_task_id,
    task,
    goDispatch,
    takers,
    task_dispatch,
    isValidToken,
    sendSensors
  } = useGetTask(history);

  // 事项地址
  const [address, addressInput] = useGetAddress(task);

  // 文件列表
  const filesList = useFileList(task);

  // 权限
  const [inviter_id, set_inviter_id] = useState(undefined);
  const { hasToken } = useAuth(task.task_id, history, inviter_id);

  const [firstTime, set_firstTime] = useState(true);

  const hrefSearch = useHrefSearch();

  /** 提交拒绝 */
  async function JumpHrefAfterSubmit(type: string) {
    switch (type) {
      case "接受":
        // 显示请假窗口
        //
        sensors.sendPoint(SensorEvent.accept_event, {
          business_id: task?.task_id,
          business_type: getTypeCName(task?.matter_type),
          invite_people_id: inviter_id,
          enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
          identity_type: "邀请链接",
          is_click_accept_button: true,
        });

        history.push({
          pathname: '/feedback/matter',
          search: history.location.search,
        })
        // history.push({
        //   pathname: `/invite/task_ans`,
        //   search: `?result=attended&id=${task.task_id}&u=${inviter_id}`,
        // });
        break;
      default:
        console.log("错误的操作");
    }
  }

  /** 点击按钮操作内容 */
  async function handleClick(type: string) {
    // 假如没有token信息
    if (!hasToken()) {
      onShowModal()
    }

    // 检查token是否有效 - 无效切入登陆
    if (!(await isValidToken())) {
      onShowModal()
    } else {
      sendSensors()
      switch (type) {
        case "接受":
          // 提交接受
          if (await goDispatch(type)) {
            JumpHrefAfterSubmit(type);
          }
          break;
        default:
          console.log("错误的操作");
      }
    }
  }

  const Location = useCallback(
    () => (
      <span>
        <span>{address && `${address}`}</span>

        {address && addressInput && <span className="fx-split-char" />}

        {addressInput && `${addressInput}`}
      </span>
    ),
    [address, addressInput]
  );

  const inviterName = (
    takers.find((t: any) => t?.taker_id === inviter_id) || {
      nick_name: "",
    }
  ).nick_name;

  const inviterAvatar = (
    takers.find((t: any) => t?.taker_id === inviter_id) || {
      nick_name: "",
      avatar: "",
    }
  ).avatar;

  useEffect(() => {
    set_task_id(hrefSearch.id);
  }, [hrefSearch.id]);

  useEffect(() => {
    if (task?.creator_id) {
      set_inviter_id(task?.creator_id);
    }

    if (!task?.task_id) return;

    if (firstTime) {
      set_firstTime(false);
    }
  }, [firstTime, task]);

  useEffect(() => {
    if (!task_dispatch) return;
    if (
      task_dispatch &&
      task_dispatch?.personal_state !== 10401 &&
      task_dispatch?.personal_state !== 10601
    ) {
      history.push({
        pathname: `/invite/task_ans`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      });
    }
  }, [history, task_dispatch]);

  useEffect(() => {
    if (!hrefSearch.u) {
      document.title = I18N.common.meetingDetailsPage;
    }
  }, [hrefSearch.u]);

  return (
    <Layout>
      <WinOpenBanner />

      <div className={styles.v17}>
        <div className={styles.v17_center}>
          {/** 标题 */}
          <div
            className={styles.v17_center_content_title__margin0}
            style={{ marginBottom: 10 }}
          >
            {task?.title}
          </div>

          {/** 邀请人 创建时间 */}
          {/** 所属事项 */}
          <Header
            task={task}
            inviter_id={inviter_id}
            inviterName={inviterName}
            inviterAvatar={inviterAvatar}
          />

          {/** 详情 */}
          <Detail task={task} filesList={filesList} />

          {task?.project ? (
            <InfoLineV15
              typeName={InfoLineV15TypeName.project}
              mode={InfoLineV15DisplayType.line01}
              info2_bottom_style={{ paddingLeft: 26 }}
            >
              <div style={{ marginBottom: 12 }}>
                {task?.project?.project_name}
              </div>
            </InfoLineV15>
          ) : null}

          <CapsoleTime typeName="时间区间" task={task} isShowCalandar />

          <CapsoleTime typeName="提醒时间" task={task} isShowCalandar />

          {curProjectType === PROJECT_ENV.normal && (
            <div className="task-time">
              {(address || addressInput) && (
                <>
                  <br />
                  <OneCapsule iconType="地址" title={<Location />} />
                </>
              )}
            </div>
          )}

          {takers && takers.length > 1 && (
            <>
              {/** 参与者 */}
              <Takers
                envTypeName="会议"
                userId={localStorage.getItem("fx-user_id")}
                task={task}
                takers={takers.filter(
                  (t: any) => t.identity !== 10804 && t.identity !== 10811
                )}
                disabledEdit
              />
            </>
          )}
        </div>
      </div>

      {/** 底部操作按钮 */}

      {!hrefSearch.u || hrefSearch?.status === "accepted" ? (
        <div className={styles.btn_fixed}>
          {curProjectType === PROJECT_ENV.normal ? (
            <OpenAppBtn task={task} />
          ) : (
            <FooterTextBtn />
          )}
        </div>
      ) : (
        <div
          className={styles.v17_footer}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ChooseLoginType visible={visible} onChoose={onChoose}>
            <BigThemeBtn
              title={I18N.common.immediatelyAfterLogin}
              colorType="large"
              onClick={() => handleClick("接受")}
            />
          </ChooseLoginType>
        </div>
      )}

      <QrcodeLogin
        type={qrcodeType}
        visible={qrcodeVisible}
        onClose={() => setQrcodeVisible(false)}
        onSuccess={ async () => {
          setQrcodeVisible(false);
          await handleClick("接受");
          await isValidToken();
        }}
      />

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            await handleClick("接受");
            await isValidToken();
          }}
        />
      )}

      {curProjectType !== PROJECT_ENV.normal && (
        <AccountNumberModal
          visible={accountVisible}
          onClose={() => setAccountVisible(false)}
          onSuccess={ async () => {
            setAccountVisible(false);
            await handleClick("接受");
            await isValidToken();
          }}
        />
      )}

      {corpWechatVisible && (
        <CorpQrcodeLogin
          visible={corpWechatVisible}
          history={history}
          onSuccess={async () => {
            setCorpWechatVisible(false);
            await handleClick("接受");
            await isValidToken();
          }}
          onError={() => {
            setCorpWechatVisible(false);
          }}
        />
      )}
    </Layout>
  );
};

export default InviteMeeting;
