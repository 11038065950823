import Http from "./http";
import { Hosts } from "./const";
import { VipMember } from "./types/user";

const host = Hosts.user;

interface CommonResponse<T = any> {
  code: number;
  message: string;
  data: T;
}

interface IUserInfo {
  avatar: string;
  nick_name: string;
  original_name: string;
  telephone: string;
  user_id: string;
}
class User extends Http {
  constructor() {
    super({ host, version: "v2" });
  }

  taskPower(taskId: string) {
    return this.get<CommonResponse<VipMember.MemberApi>>({
      url: `/member/task/${taskId}`,
    });
  }

  // 项目权限
  projectPower(project_id: string) {
    return this.get<CommonResponse<VipMember.ProjectPower>>({
      url: `/combo/project/${project_id}`,
    });
  }

  getLoginUserInfo() {
    return this.get<CommonResponse<IUserInfo>>({
      url: "/user",
    });
  }

  addInteracts(data: { telephone?: string; user_id: string }) {
    return this.post({
      url: "/user/interacts",
      data,
    });
  }

  getInteracts(params: VipMember.GetInteractsParams) {
    return this.get<CommonResponse<VipMember.UserInteracts[]>>({
      url: "/user/interacts",
      params,
    });
  }

  avatar(user_id: string) {
    return this.get({
      url: `/user/avatar?user_id=${user_id}`,
    });
  }

  updateUserName(name: string) {
    return this.put({
      url: "/user",
      data: {
        nick_name: name,
      },
    });
  }

  getVertifyCode(data = {}) {
    Object.assign(data, {
      is_mobile_h5: true,
    });

    return this.post({
      url: "/auth/phonelogin/code",
      data,
    });
  }

  loginWithVertifyCode(data: {
    device: {
      client_version: string;
      device_id: string;
      platform: string;
    };
    is_mobile_h5: boolean;
    telephone: string;
    verify_code: string;
  }) {
    Object.assign(data, {
      is_mobile_h5: true,
    });

    return this.put({
      url: "/auth/phonelogin/code",
      data,
    }).then((res) => {
      try {
        const { Token, user_id } = res.data.data;

        localStorage.setItem("fx-token", Token);
        localStorage.setItem("fx-user_id", user_id);
      } catch (err) {
        console.error(err);
      }
      return res;
    });
  }

  /** 获取用户会员信息 */
  getMemberCombo(userId: string) {
    return this.get<CommonResponse<VipMember.MemberApi>>({
      url: `/member/${userId}`,
    })
  }
}

export default new User();
