import React from "react";
import dayjs from "dayjs";
import cs from "classnames";

import styles from "../Task/index.module.scss";
import {I18N} from "@flyele-nx/i18n";

type TProps = {
  task: any;
  avatarSrc: string;
  nickName: string;
  nowType?: string;
};

const AvatarTitle = ({
  task = {},
  avatarSrc,
  nickName,
  nowType = "事项",
}: TProps) => {
  const getCreateTime = () => {
    if (!task?.create_at) {
      return null;
    }

    return `${I18N.common.createdOn} ${dayjs.unix(task?.create_at).format("YYYY.M.D HH:mm")}`;
  };

  return (
    <div className={styles["avatar_title"]}>
      <div className={cs(styles["avatar-img"], styles["fx-float-left"])}>
        <img src={avatarSrc} alt="" />
      </div>
      <div
        className={cs(
          styles["nick-name"],
          styles["fx-float-left"],
          styles["left-right-11"]
        )}
      >
        {nickName}
      </div>
      <div
        className={cs(
          styles["desc"],
          styles["fx-float-left"],
          styles["left-right-11"]
        )}
      >
        {nowType === "项目" ? `${I18N.common.invitationProject}` : getCreateTime()}
      </div>
    </div>
  );
};

export default AvatarTitle;
