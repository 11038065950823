import React from 'react'
import styles from './index.module.scss'

const CorpFailurePage = ({ title = '加入失败', desc }: {
  title?: string
  desc?: string
}) => {
  return (
    <div className={styles.corpFailurePage}>
      <div className={styles.errorLogo}>
        <img src="/assets/systemError.png" alt="error" />
      </div>
      <div className={styles.titleBox}>
        <div className={styles.title}>{title}</div>
        {desc && <div className={styles.desc}>{desc}</div>}
      </div>
    </div>
  )
}

export default CorpFailurePage