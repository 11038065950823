import ismobilejs from "ismobilejs";

export function isWeiXin() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') !== -1) {
    const system = {
      win: false,
      mac: false,
    };

    //检测平台
    const p = navigator.platform;
    system.win = p.indexOf("Win") === 0;
    system.mac = p.indexOf("Mac") === 0;
    if (system.win || system.mac) {
      // alert("在微信PC端上打开内置浏览器");
      return false;
    } else {
      return true;
      // alert("非微信PC端上打开内置浏览器");
    }
  } else {
    return false;
  }
}

export function isWeiXinWork() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('wxwork') !== -1) {
    const system = {
      win: false,
      mac: false,
    };

    //检测平台
    const p = navigator.platform;
    system.win = p.indexOf("Win") === 0;
    system.mac = p.indexOf("Mac") === 0;
    if (system.win || system.mac) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function isMobile() {
  return ismobilejs(window.navigator).phone;
}
