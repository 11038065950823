import { useEffect, useState } from "react";
import { isMobile, isWeiXin, isWeiXinWork } from "./isMobile";

export enum WindowType {
  企业微信浏览器 = "wxwork",
  微信浏览器 = "wechat",
  PC浏览器 = "pc",
  手机浏览器 = "mobile",
  none = ''
}

export const usePlatformCheck = () => {
  const [nowWindowType, set_nowWindowType] = useState(WindowType.none);

  useEffect(() => {
    if (isWeiXinWork()) {
      set_nowWindowType(WindowType.企业微信浏览器);
    }else if (isWeiXin()) {
      set_nowWindowType(WindowType.微信浏览器);
    } else if (isMobile()) {
      set_nowWindowType(WindowType.手机浏览器);
    } else {
      set_nowWindowType(WindowType.PC浏览器);
    }
  }, []);

  return { nowWindowType };
};

export default usePlatformCheck;
