import {useEffect, useMemo, useRef, useState} from "react";
import { ITargetDetailParams, IMemberList } from '@/service/types/target'
import TargetApi  from 'service/target'
import {isMobile} from "@/pages/forMobileInvite/hooks/isMobile";
import {usePlatformCheck} from "@/pages/PureHooks/usePlatformCheck";
import {useMemoizedFn} from "ahooks";

interface IUrlParams {
  id: string
  u: string
  dispatch_at: string
  corp_id?: string
}

const defaultInviter: IMemberList = { avatar: "#", nick_name: "加载中", user_id: "", state: 0, objective_id: "" };

interface ICreator {
  creator_id: string
  creator_nick_name: string
  creator_vip_state: number // 0-非会员，1-个人会员，2-团队会员

}

export const useGetTarget = (history: any) => {
  // url参数
  const [urlParams, setUrlParams] = useState<IUrlParams | null>(null)
  // 目标详情
  const [targetInfo, setTargetInfo] = useState<ITargetDetailParams | null>(null)
  // 邀请人
  const [inviter, setInviter] = useState(defaultInviter);
  // 目标创建人信息
  const [targetCreator, setTargetCreator] = useState<ICreator | null>(null)
  const [mySelf, setMySelf] = useState<IMemberList | null>(null)
  const [isValidToken, setIsValidToken] = useState(true)
  const [isTargetCancelled, setIsTargetCancelled] = useState(false)

  const sendSensorsLock = useRef<boolean>(false)
  const { nowWindowType } = usePlatformCheck();
  // 显示账号错误的样式
  const [showAccountError, setShowAccountError] = useState(false)
  const [showCanNotAdd, setShowCanNotAdd] = useState(false)

  /**
   * 检查是否有token
   */
  const hasToken = () => {
    return !!localStorage.getItem("fx-token");
  }

  const clearToken = () => {
    localStorage.removeItem("fx-token");
    localStorage.removeItem("fx-user_id");
    setIsValidToken(false)
  }

  /**
   * 获取路径参数
   */
  const getHref = () => {
    const { search }: any = history.location;

    // 检查参数合法性

    // 抽出参数
    const searchs = search.replace("?", "").split("&");

    const result: any = {};

    searchs.forEach((str: string) => {
      if (str) {
        const keyValue = str.split("=");

        if (keyValue.length === 2) {
          if (keyValue[0] && keyValue[1]) {
            const ValueOfKey: string = keyValue[0];
            result[ValueOfKey] = keyValue[1];
          }
        }
      }
    });

    if (!search.includes("id=") || !result.id || !result.u) {
      console.log('缺失目标id');
    }

    return [result.id, result.u, result.dispatch_at, result.corp_id || ''];
  }

  /**
   * 获取用户邀请状态，没有加入的话 直接加入
   */
  const getUserStatus = async (data: ITargetDetailParams, needSensor?: boolean) => {
    const userId = localStorage.getItem("fx-user_id")
    if (hasToken() && userId) {
      const { objective_member } = data
      const selfFilter = objective_member?.find((t: IMemberList) => t.user_id === userId)

      if (selfFilter) {
        setMySelf(selfFilter)
      } else {
        await joinToTarget()
      }
      if (needSensor) {
        // TODO 埋点
      }
    } else {
      clearToken()
    }
  }

  /**
   * 获取目标详情信息
   */
  const getTargetDetailInfo = async (targetId: string, needAdd?: boolean, needSensor?: boolean) => {
    try {
      const { data, code } = await TargetApi.getTargetDetail(targetId)

      if (code === 0) {
        setShowAccountError(false)
        setShowCanNotAdd(false)
        setTargetInfo(data)

        if (needAdd) {
          await getUserStatus(data, needSensor)
        } else {
          const userId = localStorage.getItem("fx-user_id")
          const { objective_member } = data
          const selfFilter = objective_member?.find((t: IMemberList) => t.user_id === userId)

          if (selfFilter) {
            setMySelf(selfFilter)
          }
        }

        return data
      }
    } catch (e) {
      console.log(e, '获取详情失败')
      setIsTargetCancelled(true)
      return null
    }
  }

  /**
   * 加入目标
   */
  const joinToTarget = useMemoizedFn(async () => {
    try {
      const targetId = urlParams?.id
      const invite_id = urlParams?.u
      const userId = localStorage.getItem("fx-user_id")
      if (!targetId || !invite_id || !userId) return

      const { data: { code } } = await TargetApi.memberAddTarget(targetId, {
        invite_id,
        user_id: [userId],
      })

      if (code === 0) {
        await getTargetDetailInfo(targetId, false, false)
        setIsValidToken(true)
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        clearToken()
        return
      }
      if (e.code === 40008) {
        if (targetInfo?.type === 2 && !urlParams?.corp_id) {
          setShowCanNotAdd(true)
        } else {
          setShowAccountError(true)
        }
        return
      }
      if (e.code === 40001 && urlParams?.corp_id) {
        setShowAccountError(true)
        return
      }
    }
  })

  const creatorIsVip = useMemo(() => {
    if (targetInfo?.type === 1) {
      return targetCreator?.creator_vip_state !== 0
    } else {
      return targetCreator?.creator_vip_state === 2
    }
  }, [targetCreator?.creator_vip_state, targetInfo?.type])

  useEffect(() => {
    const [id, u, dispatch_at, corp_id] = getHref()
    setUrlParams({
      id,
      u,
      dispatch_at,
      corp_id,
    })
  }, [history])

  useEffect(() => {
    if (urlParams?.id) {
      const id = urlParams.id
      const u = urlParams.u
      sendSensorsLock.current = false

      if (isMobile()) {
        if (history.location.pathname.includes("/invite/target")) {
          history.push({
            pathname: `/invite_h5/target/`,
            search: `${history.location.search}`.includes("?id=")
              ? `${history.location.search}`
              : `?id=${history.location.search}`,
          });
          return
        }
      } else {
        if (history.location.pathname.includes("/invite_h5/target/")) {
          history.push({
            pathname: `/invite/target`,
            search: `${history.location.search}`.includes("?id=")
              ? `${history.location.search}`
              : `?id=${history.location.search}`,
          });
          return;
        }
      }

      if (id) {
        getTargetDetailInfo(id, true, true).then((res) => {
          if (res) {
            const { objective_member } = res
            const inviterFinder = objective_member?.find((t: IMemberList) => t.user_id === u) || defaultInviter

            setTargetCreator({
              creator_id: res.creator_id || '',
              creator_nick_name: res.creator_nick_name || '',
              creator_vip_state: res.creator_vip_state || 0
            })
            setInviter(inviterFinder)
          }
        })
      }
    }
  }, [history, urlParams])

  useEffect(() => {
    if (showAccountError || showCanNotAdd) {
      clearToken()
    }
  }, [showAccountError, showCanNotAdd])

  return {
    inviter,
    targetInfo,
    nowWindowType,
    isValidToken,
    mySelf,
    isTargetCancelled,
    urlParams,
    targetCreator,
    getTargetDetailInfo,
    hasToken,
    joinToTarget,
    showAccountError,
    creatorIsVip,
    showCanNotAdd
  }
}