

import { InviteType } from '../../hooks/useGetMatter'
import styles from './index.module.scss'
import { IUnaccessibleProps } from './main'

const Unaccessible = (props: IUnaccessibleProps) => {

  const {
    task,
    inviteType,
    text,
  } = props

  let txt = {
    [InviteType.matter]: '事项已取消',
    [InviteType.project]: '项目已解散',
    [InviteType.space]: '空间已解散',
    [InviteType.none]: '',
  }[inviteType]

  if (task.matter_type === 10702) {
    txt = '会议已取消'
  }

  return (
    <div className={styles.wrap}>

      <div className={styles.wrap__pic}>
        <img src="/assets/banner/banner_removed.png" alt="" />
      </div>

      <div className={styles.wrap__desc}>{text || txt}</div>
    </div>
  )
}

export default Unaccessible