// 一进来获取空间详情
// 本地有token的话，获取当前用户是否已在当前空间（内外部成员、审核中）
//   ？ 在则直接进入结果页
//   : 不在则把自己加进去再进结果页

import { I18N } from '@flyele-nx/i18n'
import {useEffect, useState, useCallback, useMemo, useRef} from 'react'
import {ISpace, ISpaceMemberDealState, ITakers, SpaceMemberStatus} from '@/service/types/space'
import SpaceApi from 'service/space'
import { isMobile } from '@/pages/forMobileInvite/hooks/isMobile'
import {usePlatformCheck, WindowType} from "@/pages/PureHooks/usePlatformCheck";
import { concatSearch } from '@/utils/tool';
import sensors from "@/sensorsDataHook/initSensors";
import {SensorEvent} from "@/sensorsDataHook/const";
import { checkExpire } from "@/utils/checkExpire";
import {useMemoizedFn} from "ahooks";
import {curProjectType, PROJECT_ENV} from "@/service/const";
// import { SpaceMemberType } from '@/constants/space'

interface IUrlParams {
  id: string,
  u: string,
  dispatch_at: string,
  memberType: '1'|'2'
  expire_at: string
  corp_id?: string
}

const defaultInviter: ITakers = { avatar: "#", nickname: I18N.common.loading, user_id: "" };

const useGetSpace = (history: any) => {
  // url参数
  const [urlParams, setUrlParams] = useState<IUrlParams | null>(null)
  // 空间详情
  const [spaceInfo, setSpaceInfo] = useState<ISpace | null>(null)
  // 是否超出当前空间人数
  const [isOverSpaceMaxNum, setIsOverSpaceMaxNum] = useState<boolean>(false)
  // 审核状态
  const [memberDealState, setMemberDealState] = useState<ISpaceMemberDealState | null>(null)
  
  // 邀请人
  const [inviter, setInviter] = useState(defaultInviter);
  // 是否已有发出邀请
  const [isInvited, setIsInvited] = useState(false)
  // 空间成员状态
  const [memberState, setMemberState] = useState<SpaceMemberStatus>()
  
  const [btnText, setBtnText] = useState(I18N.common.checkInTheFlyele);
  const sendSensorsLock = useRef<boolean>(false)
  const { nowWindowType } = usePlatformCheck();
  // 显示账号错误的样式
  const [showAccountError, setShowAccountError] = useState(false)


  // 检查是否有token
  const hasToken = () => {
    return !!localStorage.getItem("fx-token");
  }

  const sendSensors = useCallback((resSpace?: ISpace) => {
    const rid = new URLSearchParams(window.location.search).get("rid") ?? "";
    const userId = new URLSearchParams(window.location.search).get("u") ?? "";

    const spaceId = resSpace?.workspace_id || spaceInfo?.workspace_id

    const sensorsParams = {
      business_id: spaceId,
      business_type: '空间',
      // 邀请人ID
      invite_people_id: userId,
      // 邀请分享ID
      invitation_share_id: rid,
      // 邀请分享类型
      invitation_share_type: "邀请链接",
    };

    if (sensorsParams.business_id && !sendSensorsLock.current) {
      console.log('埋点 空间邀请发送', sensorsParams);
      sendSensorsLock.current = true

      sensors.sendPoint(SensorEvent.click_invite_share, sensorsParams);
    }
  }, [spaceInfo?.workspace_id])
  
  /**
   * 获取路径参数
   */
  const getHref = () => {
    const { search }: any = history.location;
    
    // 检查参数合法性
  
    // 抽出参数
    const searchs = search.replace("?", "").split("&");
    
    const result: any = {};
    
    searchs.forEach((str: string) => {
      if (str) {
        const keyValue = str.split("=");
        
        if (keyValue.length === 2) {
          if (keyValue[0] && keyValue[1]) {
            const ValueOfKey: string = keyValue[0];
            result[ValueOfKey] = keyValue[1];
          }
        }
      }
    });
    
    // 实际spaceId
    if (!search.includes("id=") || !result.id || !result.u) {
      history.push({
        pathname: `/invite/space_ans`,
        search: `?id=`,
      });
    }
    
    return [result.id, result.u, result.dispatch_at, result.memberType, result.expire_at, result.corp_id || ''];
  }
  
  /**
   * 获取空间详情信息
   */
  const getSpaceDetailInfo = async (spaceId: string, needSensor?: boolean) => {
    const { data } = await SpaceApi.getSpaceDetail(spaceId)
    const { max_taker_count, takers, state } = data

    // 解散直接重定向去别的页面
    if (state === 2) {
      history.push({
        pathname: `/feedback/space`,
        search: concatSearch(history.location.search, {cancel: 1})
      })
      return Promise.reject()
    }

    // 专业版本人数不限制
    if (takers && max_taker_count !== -1 && takers.length >= max_taker_count) {
      setIsOverSpaceMaxNum(true)
    } else {
      setIsOverSpaceMaxNum(false)
    }

    setSpaceInfo(data)
    
    if (hasToken()) {
      await getCurrentSpaceState(spaceId)
      if (needSensor) {
        sendSensors(data)
      }
    }
    
    return data
  }
  
  /**
   * 获取当前空间状态
   */
  const getCurrentSpaceState = async (spaceId: string) => {
    await getMemberState(spaceId)
    await getInviteState(spaceId)
  }
  
  /**
   * 查看空间是否有对当前对象发出邀请
   */
  const getMemberState = async (spaceId: string) => {
    const userId = localStorage.getItem("fx-user_id")
    if (userId) {
      try {
        const { data } = await SpaceApi.getMemberState(spaceId, userId)
        setShowAccountError(false)
        console.log('getMemberState', data)

        const {
          member_type
        } = data

        const { memberType } = urlParams || {}

        // 10904 被移除过的状态
        if (
          !data.state || 
          data.state === 10904 || 
          // data.member_type === SpaceMemberType.EXTERNAL || 
          (member_type === 2 && memberType === '1') // 之前是外部成员，现在要转为内部成员，重新走接受流程
        ) {

          await addMemberInSpace(spaceId, userId)

        } else {
          setIsInvited(true)
          setMemberState(data)
        }
      } catch (e) {
        console.log('查看空间是否有对当前对象发出邀请失败', e)
      }
    }
  }
  
  /**
   * 对当前用户发送邀请
   */
  const addMemberInSpace = async (spaceId: string, userId: string) => {
    if (urlParams) {
      try {
        const { u, memberType } = urlParams
        const params = {
          invite_id: u,
          invite_type: "flyele",
          // user_id: [userId],
          [memberType === '2' ? 'external_user_id' : 'user_id']: [userId]
        }
        const res = await SpaceApi.addMemberInSpace(spaceId, params)
        console.log('对当前用户发送邀请', res)
        setIsInvited(true)
      } catch (e) {
        setShowAccountError(true)
        console.log('对当前用户发送邀请失败', e);
      }
    }
  }
  
  /**
   * 获取空间成员邀请处理状态
   */
  const getInviteState = async (spaceId: string) => {
    try {
      const { data } = await SpaceApi.getMemberDealState(spaceId)
      setMemberDealState(data)
    } catch (e) {
      console.log('获取空间成员邀请处理状态失败', e)
    }
  }
  
  /**
   * 查看空间是否有对当前对象发出邀请
   * 有 则直接添加
   * 无 则先发出 再添加
   */
  const joinToSpace = async () => {
    if (isInvited) {
      await addSpaceMemberState()
    } else {
      if (urlParams) {
        const { id } = urlParams
        await getMemberState(id)
        await addSpaceMemberState()
      }
    }
  }
  
  const refuseToSpace = async () => {
    if (isInvited) {
      await refuseSpaceMemberState()
    } else {
      if (urlParams) {
        const { id } = urlParams
        await getMemberState(id)
        await refuseSpaceMemberState()
      }
    }
  }
  
  /**
   * 加入空间
   */
  const addSpaceMemberState = async () => {
    if (urlParams) {
      const { id } = urlParams
      try {
        console.log('默认接受')
        // const { data: { code }} = await SpaceApi.updateSpaceMemberState(id, {
        //   is_approval: false, // 是否审批，审批列表AND通知里面审批填是, 个人同意填否
        //   operator_type: 1
        // })
        // console.log('joinToSpace', code);
      } finally {
        await getCurrentSpaceState(id)
        setTimeout(async () => {
          await getSpaceDetailInfo(id)
        }, 500)
      }
    }
  }
  
  /**
   * 拒绝加入空间
   */
  const refuseSpaceMemberState = async () => {
    if (urlParams) {
      const { id } = urlParams
      try {
        // const { data: { code }} = await SpaceApi.updateSpaceMemberState(id, {
        //   is_approval: false, // 是否审批，审批列表AND通知里面审批填是, 个人同意填否
        //   operator_type: 2
        // })
        // console.log('refuseToSpace', code)
      } finally {
        await getCurrentSpaceState(id)
        setTimeout(async () => {
          await getSpaceDetailInfo(id)
        }, 500)
      }
    }
  }
  
  const isValidToken = async () => {
    if (!localStorage.getItem("fx-token")) return false;
    
    return new Promise((resolve, reject) => {
      if (urlParams) {
        const { id } = urlParams
        SpaceApi.getMemberDealState(id)
          .then((res) => {
            if (res.code === 0) {
              setMemberDealState(res.data)
              resolve(res);
              return;
            }
            
            resolve(false);
          })
          .catch(() => {
            localStorage.removeItem("fx-token");
            localStorage.removeItem("fx-user_id");
            resolve(false);
          })
      }
    })
  }

  const checkIsExpired = useMemoizedFn(async () => {
    const [id, u, dispatch_at, memberType, expire_at, corp_id] = getHref()
    const isExpired = await checkExpire(expire_at)

    if (isExpired) {
      // 跳转
      history.push({
        pathname: `/expiredPage`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      })
    } else {
      setUrlParams({
        id,
        u,
        dispatch_at,
        memberType,
        expire_at,
        corp_id,
      })
    }
  })
  
  useEffect(() => {
    checkIsExpired()
  }, [checkIsExpired, history])
  
  useEffect(() => {
    if (urlParams?.id) {
      const id = urlParams.id
      const u = urlParams.u

      sendSensorsLock.current = false

      if (isMobile()) {
        if (history.location.pathname.includes("/invite/space")) {
          history.push({
            pathname: `/invite_h5/space/`,
            search: `${history.location.search}`.includes("?id=")
              ? `${history.location.search}`
              : `?id=${history.location.search}`,
          });
          return
        }
      } else {
        if (history.location.pathname.includes("/invite_h5/space/")) {
          history.push({
            pathname: `/invite/space`,
            search: `${history.location.search}`.includes("?id=")
              ? `${history.location.search}`
              : `?id=${history.location.search}`,
          });
          return;
        }
      }
      
      if (id) {
        getSpaceDetailInfo(id, true).then((res) => {
          const { takers } = res
          const inviterFinder = takers?.find((t: ITakers) => t.user_id === u) || defaultInviter
          setInviter(inviterFinder)
        })
      }
      
    }
  }, [urlParams?.id])
  
  useEffect(() => {
    if (nowWindowType === WindowType.微信浏览器 && curProjectType === PROJECT_ENV.normal) {
      setBtnText(I18N.common.checkInMiniProgress);
    } else if (nowWindowType === WindowType.手机浏览器) {
      setBtnText(I18N.common.checkInFlyeleApp);
    } else {
      setBtnText(I18N.common.checkInTheFlyele);
    }
  }, [nowWindowType]);

  useEffect(() => {
    if (showAccountError) {
      localStorage.removeItem("fx-token");
      localStorage.removeItem("fx-user_id");
    }
  }, [showAccountError])

  const spaceTakers = useMemo(() => {
    let takersArr: ITakers[] = []

    if (spaceInfo?.takers && spaceInfo.takers.length) {
      takersArr = spaceInfo.takers
    }

    if (spaceInfo?.other_takers && spaceInfo.other_takers.length) {
      takersArr = takersArr.concat(spaceInfo.other_takers)
    }

    return takersArr

  }, [spaceInfo])

  /**
   * 是否要显示三种登录方式
   * 外部成员、个人空间、免费空间、都要显示
   */
  const isShowManyLogin = useMemo(() => {
    const { memberType } = urlParams || {}
    return memberType === '2' || spaceInfo?.ws_type === 2 || spaceInfo?.level === 1
  }, [urlParams, spaceInfo])

  return {
    spaceInfo,
    isOverSpaceMaxNum,
    memberDealState,
    inviter,
    btnText,
    memberState,
    urlParams,
    hasToken,
    getSpaceDetailInfo,
    getInviteState,
    refuseToSpace,
    joinToSpace,
    isValidToken,
    isInvited,
    sendSensors,
    spaceTakers,
    isShowManyLogin,
    showAccountError,
  }
}

export default useGetSpace
