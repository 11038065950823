import Layout from "Layout/forH5Auto/Layout"
import {useState} from "react";
import Card from "./components/Card";
import Info from "./components/Info";
import useGetMatter, {InviteType} from "./hooks/useGetMatter";
import {History} from 'history'
import Detail from "./components/Detail";
import FooterBtn from "./components/FooterBtn";
import {IFooterBtnProps} from "./components/FooterBtn/main";
import SmallTypeModal from "@/pages/forMobileInvite/component/MaskModal/smallType"
import Unaccessible from "./components/Unaccessible";
import {usePlatformCheck, WindowType} from "@/pages/PureHooks/usePlatformCheck";

type TProps = {
  history: History;
}


// const defaultInviter = { avatar: "#", nick_name: "加载中", taker_id: "" };

const Feedback = (props: TProps) => {
  const { nowWindowType } = usePlatformCheck();
  const { history } = props

  // 权限
  // const [inviter, setInviter] = useState(defaultInviter);
  const [visibleMask, setVisibleMask] = useState(false);
  
  // 事项接口数据
  const {
    task,
    takers,
    inviteType,
    query,
    joinStatus,
    isAccessible,
    vipInfo,
    unaccessibleText,
  } = useGetMatter({history})

  const toView: IFooterBtnProps['clickHandler'] = () => {
    console.log('toView')
  }

  return (
    <Layout>
      {nowWindowType !== WindowType.企业微信浏览器 && (
        <SmallTypeModal
          invtiter_id={query?.u}
          task={task}
          visible={false}
          set_visibleMask={setVisibleMask}
          isProject={inviteType === InviteType.project}
          isSpace={inviteType === InviteType.space}
          dispatch_at={query?.dispatch_at}
        />
      )}

      {
        isAccessible
          ? (
            <div style={{ paddingTop: 70 }}>

              <Card>
                <Info
                  joinStatus={joinStatus}
                  inviteType={inviteType}
                  task={task}
                  vipInfo={vipInfo}
                />
              </Card>

              <div style={{
                marginTop: 12
              }}></div>

              <Card>
                <Detail
                  inviteType={inviteType}
                  task={task}
                  takers={takers}
                />
              </Card>

              <div style={{ marginBottom: 70 }} />
            </div>
          )
          : (
            <Unaccessible 
              text={unaccessibleText}
              task={task}
              inviteType={inviteType}
            />
          )
      }

      <FooterBtn
        inviteType={inviteType}
        isAccessible={isAccessible}
        clickHandler={toView}
        toHost={!isAccessible}
      />

    </Layout>
  )
}

export default Feedback