// 项目成员状态state
// 10301	未加入
// 10302	已加入
// 10303	已拒绝
// 10304	被移出

// 项目state
// 10201	正常项目
// 10202	解散项目
// 10203	待激活项目

type IProjectToolState = {
  state: number
}

type IProjectToolProjectData = {
  state: number
}

class ProjectTool {

  static isUnjoined (data: IProjectToolState) {

    const {
      state,
    } = data

    return state === 10301

  }

  static isJoined (data: IProjectToolState) {

    const {
      state,
    } = data

    return state === 10302

  }

  static isRefused (data: IProjectToolState) {

    const {
      state,
    } = data

    return state === 10303

  }

  static isRemoved (data: IProjectToolState) {

    const {
      state,
    } = data

    return state === 10304

  }

  static isProjectDismiss (data: IProjectToolProjectData) {

    const {
      state
    } = data

    return state === 10202
  }

  static sortTakers (takers: any[]): any[] {
    const res = takers.reduce((acc, cur) => {

      if (ProjectTool.isJoined(cur)) {
        Object.assign(cur, { key: 'joined', status: '已加入' })
        acc[0].push(cur)

      } else if (ProjectTool.isRefused(cur)) {

        Object.assign(cur, { key: 'refused', status: '已拒绝' })
        acc[1].push(cur)

      }  else if (ProjectTool.isRemoved(cur)) {

        // Object.assign(cur, { status: '被移出' })
        // acc[2].push(cur)

      } else if (ProjectTool.isUnjoined(cur)) {
        Object.assign(cur, { key: 'unjoined', status: '未加入' })
        acc[3].push(cur)
      }

      return acc

    }, [[], [], [], []])

    const ret: any[] = Array.prototype.concat.apply([], res)
    
    console.log('sortTakers', res, ret)
    return ret
  }
}

export default ProjectTool

export type {
  IProjectToolState
}