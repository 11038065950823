import { useState, useEffect } from "react";

import userApi from "service/user";

const useAvatar = ({ user_id }: any) => {
  const [nick_name, set_nick_name] = useState("加载中...");
  const [avatarSrc, set_avatarSrc] = useState("");

  useEffect(() => {
    userApi
      .avatar(user_id || "")
      .then((res: any) => {
        set_nick_name(res.data.nick_name);
        set_avatarSrc(res.data.avatar);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user_id]);

  return { nick_name, avatarSrc };
};

export default useAvatar;
