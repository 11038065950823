import {I18N, isCN} from '@flyele-nx/i18n'
import React, {useState} from "react";
import {usePlatformCheck, WindowType,} from "@/pages/PureHooks/usePlatformCheck";

import styles from './index.module.scss'
import useEnvokeApp from "@/pages/forMobileInvite/Task/BtnForApp/useEnvokeApp";
import useHrefSearch from "@/pages/PureHooks/useHrefSearch";
import config from "@/config";
import MaskAlreadyOpenWechat from "@/pages/forMobileInvite/component/MaskAlreadyOpenWechat";
import {History} from 'history'
import LoginBtn from "@/components/LoginBtn";
import { getMiniProgramTargetPath } from "@/pages/forMobileInvite/utils";
import {EmailLoginModal} from "@/components/login/email-login-modal";

interface TProps {
  history: History
  sendSensors?: () => void
  showAccountError?: boolean
  loginSuccess?: () => void
}

const FooterBtnAction = (props: TProps) => {
  const {history, sendSensors, showAccountError, loginSuccess} = props

  const hrefSearch = useHrefSearch();
  
  const { nowWindowType } = usePlatformCheck();
  const { evokeApp, toLoginPage } = useEnvokeApp();
  
  const [tempMiniProgramPath, set_tempMiniProgramPath] = useState("");
  const [visibleMask, set_visibleMask] = useState(false);
  const [emailVisible, setEmailVisible] = useState(false);

  const savePath = (path: string) => {
    set_tempMiniProgramPath(path);
    set_visibleMask(true);

    sendSensors && sendSensors()
  };

  const openEmailLogin = () => {
    setEmailVisible(true);
  }

  const onClickBtn = async () => {
    sendSensors && sendSensors()

    if (nowWindowType === WindowType.企业微信浏览器) {
      toLoginPage(history, true, openEmailLogin)
      return
    }

    if (nowWindowType === WindowType.手机浏览器) {
      let path = "";
      const { id, u, dispatch_at } = hrefSearch;

      path = `operate/target/accept?id=${id}&u=${u}&dispatch_at=${dispatch_at}`;
      evokeApp(path, history, openEmailLogin);
      
      return;
    }

    if (!isCN) {
      openEmailLogin()
    }
  }
  
  const _getMiniProgramPath = (): string => {
    const { id, u, dispatch_at } = hrefSearch;

    return getMiniProgramTargetPath(id, u,dispatch_at,hrefSearch)
  }

  return (
    <div className={styles.footerBtnRoot}>
  
      {
        nowWindowType === WindowType.微信浏览器 ? (
          <>
            <div className={styles.wxAccessBtn}>
              {/* @ts-ignore*/}
              <wx-open-launch-weapp
                id="launch-btn"
                username={config.miniProgramUsername}
                path={_getMiniProgramPath()}
              >
                <script type="text/wxtag-template">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "inherit",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                    onClick={() => savePath(_getMiniProgramPath())}
                  >
                    {I18N.common.immediatelyAfterLogin}
                  </div>
                </script>
                {/* @ts-ignore*/}
              </wx-open-launch-weapp>
            </div>
          </>
        ) : (
          <LoginBtn onClick={() => onClickBtn()} showAccountError={showAccountError} />
        )
      }

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            loginSuccess && loginSuccess()
          }}
        />
      )}
      
      <MaskAlreadyOpenWechat
        path={tempMiniProgramPath}
        visible={visibleMask}
        set_visibleMask={set_visibleMask}
        mobileClick={() => onClickBtn()}
      />
    </div>
  )
}

export default FooterBtnAction
