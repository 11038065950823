/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Avatar } from "Component/Avatar/Avatar";
import Layout from "Layout/forH5Auto/Layout";
import { Divider } from "Component/Line/Divider";
import { BigThemeBtn } from "Component/Button";
import userApi from "service/user";
import useGetTask from "./hooks/useGetTask";
import { downloadBtnClick } from "pages/html5downloader/ts/download";
import "./AnserLayout.scss";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import { isCN, I18N } from '@flyele-nx/i18n'

// const pic = {
//   已取消: "/assets/banner/banner_beremoved_hd.png",
//   你已被移出该: "/assets/banner/banner_beremoved_hd.png",
// };

const getPic = (typeName: string) => {
  switch (typeName) {
    case I18N.common.h5_youHaveBeen2:
      return "/assets/banner/banner_beremoved_hd.png";
    case I18N.common.h5_youHaveReturnedTheItem:
      return "/assets/banner/banner_beremoved_hd.png";
    case I18N.common.theMatterHasBeenCancelled:
      return "/assets/banner/banner_canceled_hd.png";
    case I18N.common.h5_youHaveBeen:
      return "/assets/banner/meeting_beremoved_hd.png";
    case I18N.common.h5_youHaveWithdrawnFromTheMeeting:
      return "/assets/banner/meeting_beremoved_hd.png";
    case I18N.common.h5_theMeetingHasBeenCancelled:
      return "/assets/banner/meeting_canceled_hd.png";
    case I18N.common.h5_toDoYouHaveBeen:
      return "/assets/banner/taskwait_beremoved_hd.png";
    case I18N.common.h5_toDoYouHaveReturned:
      return "/assets/banner/taskwait_beremoved_hd.png";
    case I18N.common.h5_toDoCancelled:
      return "/assets/banner/taskwait_canceled_hd.png";
    default:
      return "";
  }
};

const AnserLayout = (props: any) => {
  const { history } = props;
  const [searchResult, setSearchResult] = useState(undefined);

  const [ansTitle, setAnsTitle] = useState("");

  // 事项接口数据
  const { set_task_id, task, task_dispatch } = useGetTask(history, { disableSensors: true });

  const [ansResult, set_ansResult] = useState("");

  const [taskType, set_taskType] = useState("");

  useEffect(() => {
    if (
      !localStorage.getItem("fx-user_id") ||
      !localStorage.getItem("fx-token")
    ) {
      if (searchResult?.id?.length > 6) {
        history.push({
          pathname: `/invite/task`,
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
      } else {
        setAnsTitle(I18N.common.h5_linkExceptionPlease);
        return;
      }
    }
  }, [searchResult]);

  useEffect(() => {
    if (!task) return;

    if (
      task?.creator_id === localStorage.getItem("fx-user_id") &&
      !localStorage.getItem("fx-user_id") &&
      task?.matter_type
    ) {
      setAnsTitle(I18N.common.h5_youAreTheOneResponsibleForThisMatter);
      return;
    }

    switch (task?.matter_type) {
      case 10701:
        set_taskType(I18N.common.task);
        return;
      case 10702:
        set_taskType(I18N.common.meeting);
        return;
      case 10703:
        set_taskType(I18N.common.timeCollection);
        return;
      case 10704:
        set_taskType(I18N.common.announcement);
        return;
      case 10705:
        set_taskType(I18N.common.to_do);
        return;
      default:
        set_taskType(I18N.common.project);
        return;
    }
  }, [task]);

  useEffect(() => {
    if (!task_dispatch) return;
    switch (task_dispatch?.operate_state) {
      // 会议
      case 10609:
        setAnsTitle(I18N.common.canceled);
        return;
      case 10607:
        setAnsTitle(I18N.common.canceled);
        return;
      case 10610:
        if (task_dispatch?.exit_at) {
          setAnsTitle(I18N.common.h5_youHaveExitedThe);
        } else {
          setAnsTitle(I18N.common.h5_youHaveBeenRemoved);
        }

        return;
      case 10608:
        if (task_dispatch?.exit_at) {
          setAnsTitle(I18N.common.h5_youHaveExitedThe);
        } else {
          setAnsTitle(I18N.common.h5_youHaveBeenRemoved);
        }

        return;
      // 事项，待办
      case 10407:
        setAnsTitle(I18N.common.canceled);
        return;
      case 10405:
        setAnsTitle(I18N.common.canceled);
        return;
      case 10408:
        if (task_dispatch?.exit_at) {
          setAnsTitle(I18N.common.h5_youHaveExitedThe);
        } else {
          setAnsTitle(I18N.common.h5_youHaveBeenRemoved);
        }

        return;
      case 10406:
        if (task_dispatch?.exit_at) {
          setAnsTitle(I18N.common.h5_youHaveExitedThe);
        } else {
          setAnsTitle(I18N.common.h5_youHaveBeenRemoved);
        }

        return;
    }

    switch (task_dispatch?.personal_state) {
      // 事项
      case 10409:
        setAnsTitle(I18N.common.joined);
        return;
      case 10403:
        setAnsTitle(I18N.common.rejected);
        if (searchResult.res) {
          set_ansResult(I18N.template(I18N.common.h5_reasonForRejectionD, { val1: decodeURI(searchResult.res) }));
        }
        return;
      // 会议
      case 10611:
        setAnsTitle(I18N.common.joined);
        return;
      case 10603:
        setAnsTitle(I18N.common.rejected);
        if (searchResult.res) {
          set_ansResult(I18N.template(I18N.common.h5_reasonForRejectionD, { val1: decodeURI(searchResult.res) }));
        }
        return;
      case 10604:
        setAnsTitle(I18N.common.leaveTaken);
        if (searchResult.res) {
          set_ansResult(I18N.template(I18N.common.h5_reasonForLeaveD, { val1: decodeURI(searchResult.res) }));
        }
        return;
    }

    switch (task_dispatch?.identity) {
      case 10802:
        setAnsTitle(I18N.common.owner);
        return;
      case 10804:
        setAnsTitle(I18N.common.owner);
        return;
      case 10810:
        setAnsTitle(I18N.common.owner);
        return;
      case 10811:
        setAnsTitle(I18N.common.owner);
        return;
    }

    if (
      task_dispatch?.invite_id === localStorage.getItem("fx-user_id") &&
      localStorage.getItem("fx-user_id") !== ""
    ) {
      setAnsTitle(I18N.common.inviter);
      return;
    }
  }, [task_dispatch]);

  useEffect(() => {
    // 从路由中截取入参参数
    if (props.history.location.search) {
      const searchs = props.history.location.search.replace("?", "").split("&");

      const result: any = {};

      // 把每个key:value塞入数据数组
      searchs.forEach((str: any) => {
        if (str) {
          const keyValue = str.split("=");

          if (keyValue.length === 2) {
            if (keyValue[0] && keyValue[1]) {
              const ValueOfKey: string = keyValue[0];
              const ValueOfName: string = keyValue[1];

              result[ValueOfKey] = ValueOfName;
            }
          }
        }
      });

      // search参数结果
      console.log("获取参数", result);
      setSearchResult(result);
      set_task_id(result.id);
    }
  }, [props]);

  const getTitleColor = (title: any) => {
    switch (title) {
      case I18N.common.joined: // '已加入':
        return "theme";
      case I18N.common.owner: // '已加入':
        return "theme";
      case I18N.common.inviter: // '已加入':
        return "theme";
      case I18N.common.leaveTaken: // '已请假':
        return "orange";
      case I18N.common.rejected: // '已拒绝':
        return "red";
      default:
        return "black";
    }
  };

  // const ansFrom = '来自陈希希的事项邀请'
  // const ansTaskName = '【Teambition竞品分析】'

  const [avatar_link, set_avatar_link] = useState("");

  useEffect(() => {
    userApi
      .avatar(localStorage.getItem("fx-user_id") || "")
      .then((res: any) => {
        set_avatar_link(res.data.avatar);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Layout>
      <div className="fx-layout-anser">
        <div
          className="header"
          style={{
            width:
              ansTitle === I18N.common.canceled ||
              ansTitle === I18N.common.h5_youHaveBeenRemoved ||
              ansTitle === I18N.common.h5_youHaveExitedThe
                ? 120
                : 56,
          }}
        >
          {ansTitle === I18N.common.canceled ||
          ansTitle === I18N.common.h5_youHaveBeenRemoved ||
          ansTitle === I18N.common.h5_youHaveExitedThe ? (
            <img
              src={getPic(`${taskType}${ansTitle}`)} // "/assets/banner/banner_beremoved_hd.png"
              alt=""
              style={{ width: 120 }}
            />
          ) : (
            <Avatar size="large" src={avatar_link} />
          )}

          {/* <Avatar size="large" src={avatar_link} /> */}
        </div>
        <div className="content">
          <div className="ans-title">
            <div className={getTitleColor(ansTitle ? ansTitle : "theme")}>
              {ansTitle === I18N.common.canceled && `${taskType}${isCN ? ansTitle : ' has benn deleted'}`}
              {ansTitle === I18N.common.h5_youHaveBeenRemoved && `${I18N.template?.(I18N.common.youHaveBeenRemovedVal, {
                val1: taskType
              })}`}
              {ansTitle === I18N.common.h5_youHaveExitedThe && `${isCN ? ansTitle : 'You have quited '}${taskType}`}
              {ansTitle === I18N.common.inviter && I18N.template(I18N.common.h5_youAreTheOneWho2, { val1: taskType })}
              {ansTitle === I18N.common.owner && I18N.template(I18N.common.h5_youAreTheOneWho, { val1: taskType })}

              {ansTitle !== I18N.common.canceled &&
                ansTitle !== I18N.common.h5_youHaveBeenRemoved &&
                ansTitle !== I18N.common.h5_youHaveExitedThe &&
                ansTitle !== I18N.common.inviter &&
                ansTitle !== I18N.common.owner &&
                `${ansTitle}`}
            </div>
          </div>
          {ansResult && <div className="ans-result">{ansResult}</div>}
          {ansTitle !== I18N.common.h5_linkExceptionPlease && (
            <>
              <div className="ans-from">{I18N.template(I18N.common.h5_fromTas, {
                    val1: task?.nick_name || "加载中",
                    val2: taskType
                  }
                )}
              </div>
              <div className="ans-task-name">{`【${task?.title}】`}</div>
            </>
          )}
        </div>
        {curProjectType === PROJECT_ENV.normal && (
          <>
            <Divider marginStyle={{ top: 48, bottom: 47 }} />
            <div className="ad">
              <div className="info">
                {I18N.common.h5_moreDetails}</div>
              <div className="icon">
                <img src="/assets/icons/logo/logo.png" alt="logo" />
              </div>
              <div
                className="btn-download"
                // style={{ width: 204, margin: "0px atuo", background: "pink" }}
              >
                <BigThemeBtn
                  title={<span style={{ padding: "0px 40px" }}>{I18N.common.h5_downloadNow}</span>}
                  colorType="theme-sp"
                  onClick={downloadBtnClick}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AnserLayout;
