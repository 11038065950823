import { getSpaceAvatarUrl } from '@/utils/space'
import React from 'react'
import cs from 'classnames'
import styles from './index.module.scss'

export type SpaceAvatarProps = {
  icon?: string
  icon_color?: string
  size?: string | number
  classname?: string
  style?: React.CSSProperties
}

const SpaceAvatar: React.FC<SpaceAvatarProps> = ({
  icon,
  icon_color,
  classname,
  style,
  size,
}) => {
  return (
    <div
      className={cs(styles.avatar, classname)}
      style={{
        backgroundColor: `#${icon_color}`,
        width: size,
        height: size,
        ...style,
      }}
    >
      <img
        className={styles['avatar-img']}
        src={getSpaceAvatarUrl(icon).active}
        alt=""
      />
    </div>
  )
}

export default React.memo(SpaceAvatar)
