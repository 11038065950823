import React, { useEffect, useState } from "react";

import Taker from "./Taker";
import TakerV15 from './TakerV15'
import "./style.scss";

import getCName from "./tools/getStateCName";

const styles = {
  taker: "takers-taker",
};

const Takers = ({ typeName = "", envTypeName, takers }: any) => {
  // 排序显示规则
  const [sorted_takers, set_sorted_takers] = useState<any[]>([]);

  useEffect(() => {
    const sort_dict: any = {
      已读: [],
      未读: [],

      主持人: [],
      已完成: [],
      已接受: [],
      已请假: [],
      未接受: [],
      已拒绝: [],
      其他: [],
    };
    const new_takers: any[] = [];

    // 排序
    takers.forEach((t: any) => {
      // 状态名称
      const CName = getCName(envTypeName, t);

      if (sort_dict[CName]) {
        sort_dict[CName].push(t);
      } else {
        sort_dict["其他"].push(t);
      }
    });

    Object.keys(sort_dict).forEach((key: any) => {
      sort_dict[key].forEach((t: any) => {
        new_takers.push(t);
      });
    });

    set_sorted_takers(
      new_takers.filter((t) => t.identity !== 10811 && t.identity !== 10804)
    );
  }, [envTypeName, takers]);

  return (
    <div className={styles.taker}>
      {sorted_takers
        .filter((t: any) => t.identity !== 10804 && t.identity !== 10811)
        .map((t) => {
          if (typeName === "v15优化事项样式") {
            return (
              <TakerV15 taker={t} key={t?.taker_id} envTypeName={envTypeName} />
            );
          }
          return (
            <Taker taker={t} key={t?.taker_id} envTypeName={envTypeName} />
          );
        })}
    </div>
  );
};

export default Takers;
