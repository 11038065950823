export enum HandleStatus {
    init,
    fail,
    success
}

export enum FileType {
    pdf= 'pdf',
    word = 'word',
    excel = 'excel',
    ppt = 'ppt',
    zip = 'zip',
    img = 'img',
    unknown = 'unknown'
}
export enum TaskStatus {
    completed= 'completed',
    accepted = 'accepted',
    wait = 'wait',
    refused = 'refused',
    unknown = 'unknown'
}
export interface RichContent {
    html: string,
    tempId: number
}


export type ResponseData<T> = {
    data: {
        code: number,
        data: T
    }
}

export interface AjaxOptions<T> {
    useToken?: boolean,
    data?: T
}

export type SortList = Array<TakersItem & { status: TaskStatus, isCreator: boolean }>
export type FilesOriginList = Array<FilesOriginItem & {file_type: FileType,file_url:string}>
