import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
// import { TakersContext } from 'eventCenter'

// import IMG_FINISHED from "assets/icons/avatar/finished.png";
// import IMG_REFUSED from "assets/icons/avatar/refused.png";
// import IMG_VACATE from "assets/icons/avatar/vacate.png";

// import { MatterType } from '@/service/const'
import "./style.scss";
import getCName from "./tools/getStateCName";

import ClickerUserInfo from "./ClickerUserInfo";

const styles = {
  active: "takers-active",
  custom_popover: "takers-custom_popover",
  focus: "takers-focus",
  taker: "takers-taker",
  taker__cell: "takers-taker__cell",
  taker__icon: "takers-taker__icon",
  taker__icon_CanNotAdd: "takers-taker__icon_CanNotAdd",
  taker__img: "takers-taker__img",
  taker__img__spicon: "takers-taker__img__spicon",
  taker__name: "takers-taker__name",
  taker__right: "takers-taker__right",
  taker__right__list: "takers-taker__right__list",
  taker__state: "takers-taker__state",
};

const Taker = ({ taker, envTypeName }: any) => {
  const [stateName, setStateName] = useState<string | undefined>(undefined);

  // 透传 源头在./Takers.tsx
  // const { envTypeName } = useContext(TakersContext)

  useEffect(() => {
    setStateName(getCName(envTypeName, taker));
  }, [taker]);

  return (
    <div className={styles.taker__cell}>
      {/** 角标 */}
      {stateName === I18N.common.rejected && (
        <img
          className={styles.taker__img__spicon}
          src="/assets/icons/avatar/refused.png"
          alt={I18N.common.rejected}
        />
      )}
      {stateName === I18N.common.leaveTaken && (
        <img
          className={styles.taker__img__spicon}
          src="/assets/icons/avatar/vacate.png"
          alt={I18N.common.leaveTaken}
        />
      )}
      {stateName === I18N.common.completed && (
        <img
          className={styles.taker__img__spicon}
          src="/assets/icons/avatar/finished.png"
          alt={I18N.common.completed}
        />
      )}

      {/** 点击头像出现查看卡片 */}
      <Tooltip title={taker?.nick_name} placement="top">
        <div
          className={styles.taker__img}
          style={{
            opacity:
              stateName === I18N.common.unread || stateName === I18N.common.notAccepted ? 0.3 : undefined,
          }}
        >
          <ClickerUserInfo taker={taker} />
        </div>
      </Tooltip>
    </div>
  );
};

export default Taker;
