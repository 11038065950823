import { I18N } from '@flyele-nx/i18n'
import React, {useEffect, useMemo, useState} from "react";
import cs from "classnames";
import Layout from "Layout/forH5Auto/Layout";
import WinOpenBanner from "@/pages/PureH5/WinOpenBanner";
import useGetSpace from './hooks/useGetSpace'
import {DealResult, PersonalState, SPACE_LEVEL, SpaceMemberStatusEnum, Status} from '@/constants/space'
import SpaceAvatar from './components/SpaceAvatar'
import FileIconList from './components/FileIconList'
import {downloadBtnClick} from "pages/html5downloader/ts/download";
import dayjs from "dayjs";
import {getSpaceSinkUrl} from '@/utils/space'
import style from "./index.module.scss";
import {BigThemeBtn} from "@/Component/Button";
import {QrcodeLogin} from "@/components/login/qrcode-login";
import {CorpQrcodeLogin} from "@/components/login/corp-wechat-login";
import {useQrcodeLogin} from "@/components/login/hooks/useQrcodeLogin";
import { ChooseLoginType } from "@/components/login/choose-login-type";
import {useMemoizedFn} from "ahooks";
import { AccountError } from '@/components/account-error'
import {AccountNumberModal} from "@/components/login/account-number-modal";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {EmailLoginModal} from "@/components/login/email-login-modal";

type TProps = {
  history: History;
}

const InviteSpace = (props: TProps) => {
  const { history } = props;
  const [status, setStatus] = useState<Status>(Status.default)
  const [lastAction, setLastAction] = useState<Status>(Status.default);
  const {
    spaceInfo,
    memberDealState,
    inviter,
    btnText,
    memberState,
    hasToken,
    refuseToSpace,
    joinToSpace,
    isValidToken,
    sendSensors,
    spaceTakers,
    isShowManyLogin,
    urlParams,
    showAccountError,
  } = useGetSpace(history)

  // 登陆弹窗
  const {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    onChoose,
    setVisible,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
  } = useQrcodeLogin()

  const onLoginSuccess = useMemoizedFn(async () => {
    switch (lastAction) {
      case Status.join:
        // 加入
        await joinToSpace()
        break;
      case Status.refuse:
        // 拒绝
        await refuseToSpace()
        break;
    }
    sendSensors()
  })

  /**
   * 打开二维码
   */
  const openQrcode = useMemoizedFn(() => {
    if (curProjectType === PROJECT_ENV.normal) {
      if (!isCN) {
        setEmailVisible(true)
        return
      }
      if (isShowManyLogin) {
        setVisible(true);
      } else {
        if (urlParams?.corp_id) {
          setCorpWechatVisible(true)
        } else {
          setQrcodeVisible(true)
        }
      }
    } else {
      setAccountVisible(true)
    }
  })

  /**
   * 点击按钮
   */
  const onClickBtn = useMemoizedFn(async (btnType: Status) => {
    // 记录最近的上一个操作
    setLastAction(btnType)

    // 假如没有token信息
    if (!hasToken()) {
      openQrcode()
      return
    }

    // 检查token是否有效 - 无效切入登陆
    if (!(await isValidToken())) {
      openQrcode()
    } else {
      switch (btnType) {
        case Status.join:
          // 加入
          await joinToSpace()
          break;
        case Status.refuse:
          // 拒绝
          await refuseToSpace()
          break;
      }
    }
  })

  /**
   * status的状态改变
   */
  useEffect(() => {
    if (spaceInfo?.level === SPACE_LEVEL.basic) {
      const takerNum = spaceInfo.takers ? spaceInfo.takers.length : 0
      if (takerNum >= spaceInfo.max_taker_count) {
        setStatus(Status.fullMember)
      }
    }
    if (memberDealState) {
      if (memberDealState.deal_result === DealResult.AGREE || (memberState?.state === SpaceMemberStatusEnum.JOINED && memberState.approval_state === 2)) setStatus(Status.join)
      if (memberDealState.deal_result === DealResult.REJECT) setStatus(Status.adminRefuse)
      if (memberDealState.personal_state === PersonalState.APPROVAL_PENDING) setStatus(Status.wait)
      if (memberDealState.personal_state === PersonalState.PERSONAGE_REJECT) setStatus(Status.refuse)
      if (memberDealState.personal_state === PersonalState.PERSONAGE_REJECT) setStatus(Status.refuse)
      if (memberDealState.personal_state === PersonalState.NULL && memberState?.member_type === 2) setStatus(Status.join)
    }
  }, [memberDealState, memberState, spaceInfo])

  const renderFooter = useMemo(() => {
    switch (status) {
      case Status.default:
        return (
          <div className={style.btn}>
            <ChooseLoginType visible={visible} onChoose={onChoose}>
              <BigThemeBtn
                colorType="large"
                customClass={style.btn__box}
                title={showAccountError ? I18N.common.changeAnAccountAndRepeat : I18N.common.immediatelyAfterLogin}
                onClick={() => onClickBtn(Status.join)}
              />
            </ChooseLoginType>
          </div>
        )
      case Status.join:
        return (
          <>
            <div className={style.text}>
              {(spaceInfo?.deal_detail?.deal_user_name && spaceInfo?.deal_detail?.deal_at ?
                <>
                  <span>{`${spaceInfo?.deal_detail?.deal_user_name} ${I18N.common.detai2} | `}</span>
                  <span className={style.time}>
                  {`${I18N.common.processingTime}：${dayjs
                    .unix(spaceInfo?.deal_detail?.deal_at || 0)
                    .format('M月D日 HH:mm')}`}
                  </span>
                </>
                :
                <span>{I18N.common.joinedSpace}</span>
              )}
            </div>
            {curProjectType === PROJECT_ENV.normal && (
              <div className={style['jump-space-btn']} onClick={downloadBtnClick}>
                { btnText }
              </div>
            )}
          </>
        )
      case Status.adminRefuse:
        return (
          <>
            <div className={style.text}>
              <span>{`${spaceInfo?.deal_detail?.deal_user_name} ${I18N.common.requestRejected} | `}</span>
              <span className={style.time}>
                {`${I18N.common.processingTime}：${dayjs
                  .unix(spaceInfo?.deal_detail?.deal_at || 0)
                  .format('M月D日 HH:mm')}`}
              </span>
            </div>
          </>
        )
      case Status.refuse:
        return (
          <>
            <div className={style.text}>
              <span>{I18N.common.refusedToJoin}</span>
            </div>
          </>
        )
      case Status.wait:
        return <div className={style['jump-space-btn']}>{I18N.common.waitingForAdministrator}</div>
      case Status.fullMember:
        return <div className={style.text}>{I18N.common.unableToJoinEmpty}</div>
      default:
        return null
    }
  }, [status, visible, onChoose, spaceInfo, btnText, onClickBtn])
  
  return (
    <Layout>
      <WinOpenBanner />
      
      <div className={style.inviteSpaceRoot}>
        <div
          className={style.bgHeader}
          style={{
            backgroundImage: `url(${getSpaceSinkUrl(spaceInfo?.bg_icon).base})`,
          }}
        />
        {showAccountError ? (
          <AccountError />
        ): (
          <div className={style.content}>
            <div className={style.title}>
              <span>{inviter.nickname}</span>
              <span className={style['invite-text']}>{` ${I18N.common.invite} `}</span>
              <span>{I18N.common.youJoinedTheSpace}</span>
            </div>
            <div className={style['avatar-info']}>
              <SpaceAvatar
                classname={style.avatar}
                icon={spaceInfo?.icon}
                icon_color={spaceInfo?.icon_color}
              />
              {curProjectType === PROJECT_ENV.normal && (
                <div className={cs(style.version, {
                  [style.pro]: spaceInfo && spaceInfo.level === SPACE_LEVEL.vip
                })}>
                  {spaceInfo && spaceInfo.level === SPACE_LEVEL.vip ? I18N.common.professionalEdition : I18N.common.basicEdition}
                </div>
              )}
            </div>
            <div className={style['team-name']}>{spaceInfo?.name}</div>
            <div className={style.creator}>
              {`${I18N.common.spaceCreator}：${spaceInfo?.creator_name}`}
            </div>
            {spaceInfo?.ws_desc ? (
              <>
                <div className={style['sub-title']}>{I18N.common.spatialDescription}</div>
                <div className={style.desc}>{spaceInfo.ws_desc}</div>
              </>
            ) : null}
            <FileIconList files={spaceInfo?.files} />
            {spaceTakers.length ? (
              <>
                <div className={style['sub-title']}>{I18N.common.space_member}</div>
                <div className={style['member-list']}>
                  {spaceTakers.map((item, index) => (
                    <img
                      alt=""
                      src={item.avatar}
                      key={index}
                      className={style.avatar}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </div>
        )}
        {spaceInfo?.state === 3 ?
          <div className={style.tips}>
            <p>{`${I18N.common.affiliatedSpace}，`}</p>
            <p>{`${I18N.common.pleaseContactTheSpace}。`}</p>
          </div>
          :
          <div className={style.footer}>{renderFooter}</div>
        }
      </div>

      <QrcodeLogin
        type={qrcodeType}
        visible={qrcodeVisible}
        onClose={() => setQrcodeVisible(false)}
        onSuccess={async () => {
          setQrcodeVisible(false);
          await onLoginSuccess()
        }}
      />

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            await onLoginSuccess()
          }}
        />
      )}

      {curProjectType !== PROJECT_ENV.normal && (
        <AccountNumberModal
          visible={accountVisible}
          onClose={() => setAccountVisible(false)}
          onSuccess={async () => {
            setAccountVisible(false);
            await onLoginSuccess()
          }}
        />
      )}

      {corpWechatVisible && (
        <CorpQrcodeLogin
          visible={corpWechatVisible}
          history={history}
          onSuccess={async () => {
            setCorpWechatVisible(false);
            await onLoginSuccess()
          }}
          onError={() => {
            setCorpWechatVisible(false);
          }}
        />
      )}
    </Layout>
  )
}

export default InviteSpace
