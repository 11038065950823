import React, { FC } from 'react'
import cs from 'classnames'
import './BigThemeBtn.scss'
import { ButtonDeclear } from './constants/ButtonDeclear'

export const BigThemeBtn: FC<ButtonDeclear.IProps> = ({
  title = '取消',
  onClick = () => {},
  colorType = 'red',
  customClass = '',
}) => {
  return (
    <div className={cs(`btn-theme-big-${colorType}`, customClass)}>
      <button type="button" onClick={onClick}>
        {title}
      </button>
    </div>
  )
}

export default BigThemeBtn
