import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React  from 'react'
import { router } from 'router/index'
import { RouteComponentProps } from 'react-router'
import { verifyLogin } from 'utils/login'


const RouterComponent: React.FC<{}> = () => {
    return (
        <BrowserRouter>
            <Switch>
                {
                     router.map(r => {
                        const Component = (r.component) as React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
                        return (
                            <Route path={ r.path } exact={ r.exact } key={ r.key } render={ props => {
                                document.title = r.title
                                if (r.verifyLogin) {
                                    // 需要返回值判断一下，否则会导致用户先看到绑定失败的页面
                                    const res =  verifyLogin(r.path as string)
                                    if (!res) return null
                                }
                                return <Component { ...props } />
                            }}
                            />
                        )
                    })
                }
            </Switch>
        </BrowserRouter>
    )
}

export default RouterComponent
