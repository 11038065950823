import {I18N} from '@flyele-nx/i18n'
import React, {forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState,} from "react";
import config from "config";
import cs from "classnames";
import useEnvokeApp from "./BtnForApp/useEnvokeApp";
// 神策埋点
import sensors, {getTypeCName} from "sensorsDataHook/initSensors";
import {SensorEvent} from "sensorsDataHook/const";
import {isMobileAns} from "sensorsDataHook/isMobile";
import useHrefSearch from "@/pages/PureHooks/useHrefSearch";
import MaskAlreadyOpenWechat from "@/pages/forMobileInvite/component/MaskAlreadyOpenWechat";
import {usePlatformCheck, WindowType,} from "@/pages/PureHooks/usePlatformCheck";
import styles from "./index.module.scss";
import {ISpace} from "@/service/types/space";
import InviteTipsPopup from "@/Component/InviteTipsPopup";
import SpaceApi from "@/service/space";
import {PersonalState} from "@/constants/space";
import {IFooterBtnExport} from "./main";
import {getMiniProgramProjectPath} from "../utils";
import {checkSpaceDismiss} from "@/utils/join-tools";
import {ChooseLoginType} from "@/components/login/choose-login-type";
import {BigThemeBtn} from "@/Component/Button";
import style from "@/pages/InviteSpace/index.module.scss";
import {useQrcodeLogin} from "@/components/login/hooks/useQrcodeLogin";
import {QrcodeLogin} from "@/components/login/qrcode-login";
import {CorpQrcodeLogin} from "@/components/login/corp-wechat-login";
import {useMemoizedFn} from "ahooks";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {AccountNumberModal} from "@/components/login/account-number-modal";
import projectApi from "@/service/project";
import {IProjectMember} from "@/service/types/project";
import {EmailLoginModal} from "@/components/login/email-login-modal";

type TProps = {
  handleRefuse: () => void;
  handleAccept: () => void;
  task: any;
  spaceInfo?: ISpace;
  isOverSpaceMaxNum?: boolean;
  isPc?: boolean;
  sendSensors?: () => void;
  showAccountError?: boolean
  approvalPending?: boolean // 是否待审批
};

const Title: ForwardRefRenderFunction<IFooterBtnExport, TProps | any> = (
  Props,
  ref
) => {
  const {
    task,
    isValidToken,
    hasToken,
    goDispatch,
    inviter_id,
    history,
    spaceInfo,
    isOverSpaceMaxNum,
    isPc,
    isProject,
    sendSensors,
    showAccountError = false,
    approvalPending = false
  } = Props;

  const [tempMiniProgramPath, set_tempMiniProgramPath] = useState("");
  const [visibleMask, set_visibleMask] = useState(false);
  const [spacePersonalState, setSpacePersonalState] = useState<PersonalState>();

  // 登陆弹窗
  const {
    visible,
    qrcodeType,
    qrcodeVisible,
    corpWechatVisible,
    accountVisible,
    emailVisible,
    isCN,
    onChoose,
    setQrcodeVisible,
    setCorpWechatVisible,
    setAccountVisible,
    setEmailVisible,
    onShowModal,
  } = useQrcodeLogin();

  const savePath = (path: string) => {
    sendSensors && sendSensors();
    set_tempMiniProgramPath(path);
    set_visibleMask(true);
  };

  const hrefSearch = useHrefSearch();
  const { nowWindowType } = usePlatformCheck();
  const nowWindowTypeRef = useRef(nowWindowType);
  // 请假或者拒绝理由
  const [refuseReason, setRefuseReason] = useState("");
  // 上一个操作
  const [lastAction, setLastAction] = useState("接受");
  const { evokeApp, toLoginPage } = useEnvokeApp();

  useEffect(() => {
    nowWindowTypeRef.current = nowWindowType;
  }, [nowWindowType]);

  const _getMiniProgramPath = ({
    state,
    invite_id,
    project_id,
    user_id,
    memberType,
  }: any): string => {
    const json: any = {
      state,
      invite_id,
      project_id,
      member_type: memberType,
    };

    if (user_id) {
      json.user_id = user_id;
    }

    const json_string = JSON.stringify(json);

    console.log(
      "请求小程序的链接",
      `/pages/tabBar/schedule/index?submitInfo=${json_string}`
    );

    // const path = `/pages/tabBar/schedule/index?submitInfo=${json_string}`;
    const path = getMiniProgramProjectPath(
      project_id,
      invite_id,
      hrefSearch.dispatch_at,
      hrefSearch
    );

    return path;
  };

  /**
   * 打开二维码
   * 外部成员 或者 个人空间的 或者空间为免费版本 都展示三种登录方式
   */
  const openQrcode = useMemoizedFn(() => {
    const isExternalUrl = hrefSearch?.memberType === "2" || task.ws_type === 2 || task.workspace_level === 1;
    if (isExternalUrl) {
      onShowModal()
    } else {
      if (curProjectType === PROJECT_ENV.normal) {
        if (!isCN) {
          setEmailVisible(true)
          return
        }

        if (hrefSearch?.corp_id) {
          setCorpWechatVisible(true);
        } else {
          setQrcodeVisible(true);
        }
      } else {
        setAccountVisible(true)
      }
    }
  });

  /**
   * 获取空间成员邀请处理状态
   */
  const getInviteState = async (spaceId: string) => {
    try {
      const { data } = await SpaceApi.getMemberDealState(spaceId);
      return data;
    } catch (e) {
      console.log("获取空间成员邀请处理状态失败", e);
    }
  };

  const openEmailLogin = () => {
    setEmailVisible(true);
  }

  const afterClick = async (type: string) => {
    // 假如没有token信息
    if (!hasToken()) {
      openQrcode();
    }

    // 检查token是否有效 - 无效切入登陆
    if (!(await isValidToken())) {
      openQrcode();
    } else {
      switch (type) {
        case "接受":
          // 提交接受
          console.log("project_accept", task, history);

          if (checkSpaceDismiss({ task, history })) {
            history.push({
              pathname: `/invite/project_ans`,
              search: `${history.location.search}`.includes("?id=")
                ? `${history.location.search}`
                : `?id=${history.location.search}`,
            });
            return;
          }

          if (await goDispatch(type)) {
            const projectRes = await projectApi.getProjectInfo(task?.project_id)

            if (projectRes.code === 0) {
              const project_members: IProjectMember[] = projectRes.data.project_members || []
              const findTarget = project_members.find((item) => item.user_id === localStorage.getItem("fx-user_id"))

              if (findTarget && findTarget.is_external_member && findTarget.notice_id) {
                setSpacePersonalState(PersonalState.APPROVAL_PENDING)
                return
              }
            }

            const res = await getInviteState(task.workspace_id);
            setSpacePersonalState(res?.personal_state);
            if (res?.personal_state !== PersonalState.APPROVAL_PENDING) {
              JumpHrefAfterSubmit(type);
            }
          }
          break;
        case "拒绝":
          // 显示拒绝窗口
          setRefuseReason("");
          // setRefuseModalVisible(true);
          break;
        default:
          console.log("错误的操作");
      }
    }
  }

  /** 点击按钮操作内容 */
  async function handleClick(type: string) {
    // 记录最近的上一个操作
    setLastAction(type);

    if (nowWindowTypeRef.current === WindowType.企业微信浏览器) {
      toLoginPage(history, true, openEmailLogin)
      return
    }

    if (nowWindowTypeRef.current === WindowType.手机浏览器) {
      let path = "";
      const { id, u, user_id, dispatch_at, memberType } = hrefSearch;

      let queryJson = `?id=${id}&u=${u}&member_type=${memberType}&dispatch_at=${dispatch_at}`;
      if (user_id) {
        queryJson += `&target_uid=${user_id}`;
      }

      sendSensors && sendSensors();

      if (type === "接受") {
        path = `operate/project/accept${queryJson}`;

        sensors.sendPoint(SensorEvent.accept_join_project, {
          project_id: task?.project_id,
          participants_id: "",
          project_invitation: hrefSearch?.u,
          enter_page: "手机H5链接",
          invite_people_is_new_user: false,
        });

        evokeApp(path, history, openEmailLogin);
      }

      if (type === "拒绝") {
        path = `operate/project/refuse${queryJson}`;
        evokeApp(path, history, openEmailLogin);
      }

      return;
    }

    await afterClick(type)
  }

  /** 提交拒绝 */
  async function JumpHrefAfterSubmit(type: string) {
    sendSensors && sendSensors();
    switch (type) {
      case "接受":
        // 显示请假窗口
        sensors.sendPoint(SensorEvent.accept_event, {
          business_id: task?.project_id,
          business_type: getTypeCName(task?.matter_type),
          invite_people_id: inviter_id,
          enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
          identity_type: "邀请链接",
          is_click_accept_button: true,
        });

        sensors.sendPoint(SensorEvent.accept_join_project, {
          project_id: task?.project_id,
          participants_id: localStorage.getItem("fx-user_id") || "",
          project_invitation: hrefSearch?.u,
          enter_page: "PCweb链接",
          invite_people_is_new_user: false,
        });

        if (
          [WindowType.微信浏览器, WindowType.手机浏览器].includes(nowWindowType)
        ) {
          history.push({
            pathname: `/feedback/${"project"}`,
            search: history.location.search,
          });
        } else {
          history.push({
            pathname: `/invite/project_ans`,
            search: `?result=attended&id=${task.project_id}&u=${inviter_id}`,
          });
        }
        break;
      case "请假":
        // 显示请假窗口
        if (
          await goDispatch(type, {
            reason:
              refuseReason && refuseReason !== "" ? refuseReason : undefined,
          })
        ) {
          sensors.sendPoint(SensorEvent.ask_for_leave_event, {
            business_id: task?.project_id,
            business_type: getTypeCName(task?.matter_type),
            invite_people_id: inviter_id,
            enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
            identity_type: "邀请链接",
            click_ask_for_leave_button: true,
            is_fill_ask_for_leave_reason: !!(
              refuseReason && refuseReason !== ""
            ),
          });
          history.push({
            pathname: `/invite/project_ans`,
            search: `?result=vacate&id=${task?.project_id}&res=${refuseReason}&u=${inviter_id}`,
          });
        }
        break;
      case "拒绝":
        // 显示拒绝窗口
        if (
          await goDispatch(type, {
            reason:
              refuseReason && refuseReason !== "" ? refuseReason : undefined,
          })
        ) {
          sensors.sendPoint(SensorEvent.refuse_event, {
            business_id: task?.project_id,
            business_type: getTypeCName(task?.matter_type),
            invite_people_id: inviter_id,
            enter_page: isMobileAns ? "手机H5链接" : "PCweb链接",
            identity_type: "邀请链接",
            is_click_refuse_button: true,
            is_fill_refuse_reason: !!(refuseReason && refuseReason !== ""),
          });
          history.push({
            pathname: `/invite/project_ans`,
            search: `?result=refuse&id=${task?.project_id}&res=${refuseReason}&u=${inviter_id}`,
          });
        }
        break;
      default:
        console.log("错误的操作");
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      accept: handleClick,
    }),
    []
  );

  return (
    <div
      className={styles["footer-btn-task"]}
      style={isPc ? { bottom: "32vh" || "80px" } : {}}
    >
      <InviteTipsPopup
        bottom="60px"
        isProject={isProject}
        isPc={isPc}
        spaceInfo={spaceInfo}
        isOverSpaceMaxNum={isOverSpaceMaxNum}
      />
      {/* 项目 */}
      {task?.matter_type === 10708 && (
        <div className={styles["center"]}>
          {spacePersonalState === PersonalState.APPROVAL_PENDING || approvalPending ? (
            <div className={styles["waitBtn"]}>{I18N.common.waitingForAdministrator}</div>
          ) : (
            <div
              className={cs(styles["project-footer"], {
                [styles["m-project-footer"]]: !isPc,
              })}
            >
              {nowWindowType === WindowType.微信浏览器 ? (
                <div className={cs(styles.btn, styles["confirm-btn"])}>
                  {/* @ts-ignore*/}
                  <wx-open-launch-weapp
                    id="launch-btn"
                    username={config.miniProgramUsername}
                    path={_getMiniProgramPath({
                      state: 10302,
                      invite_id: inviter_id,
                      project_id: task?.project_id,
                      user_id: hrefSearch?.user_id,
                      memberType: hrefSearch?.memberType || "",
                    })}
                  >
                    <script type="text/wxtag-template">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "300px",
                          height: "48px",
                          color: "#fff",
                          border: "none",
                        }}
                        onClick={() => {
                          savePath(
                            _getMiniProgramPath({
                              state: 10302,
                              invite_id: inviter_id,
                              project_id: task?.project_id,
                              user_id: hrefSearch?.user_id,
                              memberType: hrefSearch?.memberType || "",
                            })
                          );

                          sensors.sendPoint(SensorEvent.accept_join_project, {
                            project_id: task?.project_id,
                            participants_id: "",
                            project_invitation: hrefSearch?.u,
                            enter_page: "手机H5链接",
                            invite_people_is_new_user: false,
                          });
                        }}
                      >
                        {I18N.common.joinNow}</div>
                    </script>
                    {/* @ts-ignore*/}
                  </wx-open-launch-weapp>
                </div>
              ) : (
                <ChooseLoginType visible={visible} onChoose={onChoose}>
                  <BigThemeBtn
                    colorType="large"
                    customClass={style.btn__box}
                    title={showAccountError ? I18N.common.changeAnAccountAndRepeat : I18N.common.immediatelyAfterLogin}
                    onClick={() => handleClick("接受")}
                  />
                </ChooseLoginType>
              )}
            </div>
          )}
        </div>
      )}

      {/** 微信侧提示已经打开了 */}
      <MaskAlreadyOpenWechat
        path={tempMiniProgramPath}
        visible={visibleMask}
        set_visibleMask={set_visibleMask}
        mobileClick={() => handleClick(lastAction)}
      />

      <QrcodeLogin
        type={qrcodeType}
        visible={qrcodeVisible}
        onClose={() => setQrcodeVisible(false)}
        onSuccess={async () => {
          setQrcodeVisible(false);
          handleClick(lastAction);
          isValidToken();
        }}
      />

      {!isCN && (
        <EmailLoginModal
          visible={emailVisible}
          onClose={() => setEmailVisible(false)}
          onSuccess={async () => {
            setEmailVisible(false);
            afterClick(lastAction);
            isValidToken();
          }}
        />
      )}

      {curProjectType !== PROJECT_ENV.normal && (
        <AccountNumberModal
          visible={accountVisible}
          onClose={() => setAccountVisible(false)}
          onSuccess={async () => {
            setAccountVisible(false);
            handleClick(lastAction);
            isValidToken();
          }}
        />
      )}

      {corpWechatVisible && (
        <CorpQrcodeLogin
          visible={corpWechatVisible}
          history={history}
          onSuccess={async () => {
            setCorpWechatVisible(false);
            handleClick(lastAction);
            isValidToken();
          }}
          onError={() => {
            setCorpWechatVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default forwardRef(Title);
