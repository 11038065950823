import React, { useEffect } from "react";

import "./css/common.css";
import {downloadUrl} from "@/constants/url";
// import "./css/index.rem.css";

const PCWeb = () => {
  useEffect(() => {
    document.title = "版本下载-飞项科技（广州）有限公司";
  }, []);

  function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.includes("MicroMessenger") == true) {
      return true;
    } else {
      return false;
    }
  }

  const download = () => {
    if (isWeiXin()) {
      alert("请点击右上角，选择浏览器打开");
      return;
    }
    location.href = downloadUrl
      // "https://download.flyele.net/v1/downloads/application?platform=android";
  };

  return (
    <div className="page flex-col">
      <div className="mod1 flex-col">
        <div className="wrap1 flex-col">
          <div
            className="layer7 flex-col"
            style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
          >
            <img src="./images/header.png" alt="" width="60%" />
          </div>
          <div className="layer7 flex-col" style={{ marginTop: "0.6rem" }}>
            <img src="./images/bg.png" alt="" width="65%" />
          </div>
          <div className="layer8 flex-col">
            <div className="outer5 flex-col">
              <div className="outer6 flex-row">
                <div className="group1 flex-col">
                  <div className="section1 flex-col"></div>
                </div>
                <div className="group2 flex-col">
                  <span className="txt1">下载（飞项）客户端</span>
                  <span className="txt2">把每件事干的漂漂亮亮～</span>
                </div>
                <button className="group3 flex-col" onClick={download}>
                  <span className="info2">立即下载</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCWeb;
