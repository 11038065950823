export enum SensorEvent {
  // 刘奇, Han
  enter_invite_page = "enter_invite_page", // 进入邀请页面
  accept_event = "accept_event", // 接受事件
  refuse_event = "refuse_event", // 拒绝事件
  ask_for_leave_event = "ask_for_leave_event", // 请假事件

  accept_join_project = "accept_join_project",

  H5_open_click = "H5_open_click",
  H5_cancel_click = "H5_cancel_click",

  /** 点击邀请分享 */
  click_invite_share = "click_invite_share",
}
