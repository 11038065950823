import Http from "./http";
import { Hosts } from "./const";
import { CommonResponse } from '@/service/types/http';
import { ITargetDetailParams, IAddMemberParams } from './types/target'

const host = Hosts.flyele

class TargetApi extends Http {
  constructor() {
    super({ host, version: "v2" });
  }

  /**
   * 获取目标详情
   */
  getTargetDetail(objective_id: string) {
    return this.get<CommonResponse<ITargetDetailParams>>({
      url: `/objective/${objective_id}`,
    })
  }

  /**
   * 邀请执行人
   */
  memberAddTarget(objective_id: string, params: IAddMemberParams) {
    return this.post({
      url: `/objective/${objective_id}/member/create`,
      data: params,
    })
  }
}

export default new TargetApi();
