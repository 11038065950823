import { I18N } from '@flyele-nx/i18n'
import Feedback from "@/pages/Feedback";

export const FeedbackRouters = [
  {
    exact: true,
    path: '/feedback/:type',
    component: Feedback,
    key: "feedback",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_resultPage,
  }
]