import React, { FC } from 'react'
import './Divider.scss'

interface IProps {
  marginStyle?:
    | {
        top: number
        bottom: number
      }
    | undefined
}

export const Divider: FC<IProps> = ({ marginStyle = undefined }) => {
  return (
    <div
      className="fx-line-divider"
      style={
        marginStyle
          ? { marginTop: marginStyle.top, marginBottom: marginStyle.bottom }
          : undefined
      }
    />
  )
}

export default Divider
