import { I18N } from '@flyele-nx/i18n'
import { ExpiredPage } from "@/pages/ExpiredPage";

export const OtherRouters = [
  {
    exact: true,
    path: '/expiredPage',
    component: ExpiredPage,
    key: "ExpiredPage",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_qrCodeHasPassed,
  }
]