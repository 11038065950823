// 项目配置

import { domain } from "service/const";

export const config = {
  // appstore中的应用id IPhone
  iOSAppId: "1554845287",
  // appstore中的应用id IPad
  iOSIPadId: "6503137322",
  // 各环境的通用头部链接
  ENV: `https://${domain}`.replace("api", "download"),
  // 上面的download页面不可用
  HOMEPAGE: `https://${domain}?noDownload=true`.replace("api", "h5"),
};
