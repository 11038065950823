// pc/h5项目邀请共用该组件文件
import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useRef, useState } from "react";
import { getHref } from "../utils";
import useProjectDetail from "./useProjectDetail";
import FooterBtnAction from "../Task/FooterBtnActionProject";
import styles from "./index.module.scss";
import useGetSpace from "@/pages/forMobileInvite/hooks/useGetSpace";
import {WindowType} from "@/pages/PureHooks/usePlatformCheck";
import WinOpenBanner from "@/pages/PureH5/WinOpenBanner";
import MaskModal from "@/pages/forMobileInvite/component/MaskModal";
import SmallTypeModal from "@/pages/forMobileInvite/component/MaskModal/smallType";
import cs from "classnames";
import { AccountError } from "@/components/account-error";

type TProps = {
  history: History;
};

const defaultInviter = { avatar: "#", nick_name: I18N.common.loading, taker_id: "" };

const InvitePageH5Project = (props: TProps) => {
  const { history } = props;
  const hrefSearch: any = getHref(props)

  const footerRef = useRef(null)

  const {
    task,
    takers,
    set_project_id,
    isValidToken,
    nowWindowType,
    goDispatch,
    sendSensors,
    showAccountError,
    approvalPending
  } = useProjectDetail(history, {
      footerRef,
      oSearch: hrefSearch,
    });

  // 权限
  const [inviter, set_inviter] = useState(defaultInviter);
  
  const { spaceInfo, isOverSpaceMaxNum, getSpaceDetailInfo } = useGetSpace()
  
  const [visibleMask, set_visibleMask] = useState(false);
  
  useEffect(() => {
    const hrefSearch: any = getHref(props);
  
    set_project_id(hrefSearch.id);
    if (hrefSearch.u) {
      console.log(takers);

      set_inviter(
        takers.find((t: any) => t?.taker_id === hrefSearch.u) || defaultInviter
      );
    }
  }, [takers]);
  
  useEffect(() => {
    const { workspace_id } = task || { workspace_id: '' }
    
    if (workspace_id) {
      getSpaceDetailInfo(workspace_id)
    }
  
  }, [task])

  function hasToken() {
    return !!localStorage.getItem("fx-token");
  }

  const buildTakers = () => {
    return takers
      .filter((item: any) => item.state === 10302)
      .map((item: any, index) => {
        return (
          <div
            key={index}
            className={cs(styles["alt"], styles["join"])}
            style={{ order: 1 }}
          >
            <img src={item.avatar} alt="头像" />
          </div>
        );
    });
  };

  let desc = task?.project_desc ?? "";

  desc = desc.toString().replace("/\n/g", "<br/>");

  return (
    <section>
      <div className={styles["h5-pc-root"]}>
        {
          nowWindowType === WindowType.PC浏览器 ?
            <WinOpenBanner /> :
            nowWindowType !== WindowType.企业微信浏览器 ? (
              <>
                <MaskModal
                  isProject
                  invtiter_id={inviter?.taker_id}
                  // task={spaceInfo}
                  task={task}
                  visible={visibleMask}
                  set_visibleMask={set_visibleMask}
                />

                <SmallTypeModal
                  isProject
                  invtiter_id={inviter?.taker_id}
                  // task={spaceInfo}
                  task={task}
                  // visible={visibleMask}
                  visible={false}
                  set_visibleMask={set_visibleMask}
                />
              </>
            ): null
        }
        <div className={cs(styles["h5-pc-invite"], {
          [styles["pc-page"]]: nowWindowType === WindowType.PC浏览器,
          [styles["h5-pc-wxwork"]]: nowWindowType === WindowType.企业微信浏览器
        })}>
          <div className={styles["invite-box"]}>
            {
              showAccountError ? (
                <AccountError />
              ) : (
                <>
                  <div className={styles["in-header"]}>
                    <img src={inviter?.avatar} alt="头像" />
                    <p>
                      {inviter?.nick_name ?? ""}
                      <>{I18N.common.invitationProject}</>
                    </p>
                  </div>

                  <div className={styles["in-body"]}>
                    <div className={styles["projectName"]}>{task?.project_name ?? ""}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: desc,
                      }}
                    />
                    <div className={styles["takers"]}>{buildTakers()}</div>
                  </div>
                </>
              )
            }
            <div className={styles["btn-list"]}>
              <FooterBtnAction
                ref={footerRef}
                isValidToken={isValidToken}
                task={task}
                goDispatch={goDispatch}
                inviter_id={inviter?.taker_id}
                history={history}
                hasToken={hasToken}
                spaceInfo={spaceInfo}
                isOverSpaceMaxNum={isOverSpaceMaxNum}
                isPc={nowWindowType === WindowType.PC浏览器}
                isProject
                sendSensors={sendSensors}
                showAccountError={showAccountError}
                approvalPending={approvalPending}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvitePageH5Project;
