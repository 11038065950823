// @ts-nocheck
import {curProjectType, domain, PROJECT_ENV} from "service/const";

class Sensor {
  sender: any;

  constructor() {
    // 初始化 SDK（html5这端是把sdk的min.js加进去了）
    // @ts-ignore
    this.sender = window["sensorsDataAnalytic201505"];

    if (!this.sender) {
      console.log("神策初始化失败");
      return;
    }

    if (curProjectType !== PROJECT_ENV.normal) {
      console.log("私有化项目无需埋点初始化");
      return;
    }

    this.sender.init({
      sdk_url: "../assets/js/sensorsdata.min.js",
      name: "sensors",
      server_url: `https://datasink.flyele.net:8443/sa?project=${
        domain === "api.flyele.net" || domain === "api.flyele.com" ? "production" : "default"
      }`,
      cross_subdomain: false,
      heatmap: {
        // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: "not_collect",
        // 是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: "not_collect",
      },
      show_log: true,
    });

    // 注册公共属性
    this.sender.registerPage({
      current_url: location.href,
      referrer: document.referrer,

      platform_type: "pc",
      is_login: true, // 是否登陆
      client_version: "1.3.0", // 当前版本

      // channel_origin 来源渠道
      channel_origin: '官网'
      // 官网、知乎、百度
    });

    const fx_user_id = localStorage.getItem("fx-user_id");

    if (!!fx_user_id) {
      this.sender.login(fx_user_id);
    }

    // 异步载入
    this.sender.quick("autoTrack");
  }

  sendPoint(typeName: string = "", params: object) {
    if (curProjectType !== PROJECT_ENV.normal) return
    this.sender.track(typeName, {
      ...params,
    });
  }
}

export function getTypeCName(matter_type: number) {
  if (matter_type === 10701) {
    return "事项";
  } else if (matter_type === 10702) {
    return "会议";
  } else if (matter_type === 10705) {
    return "待办";
  }

  return undefined;
}

const sensors = new Sensor();

export default sensors;
