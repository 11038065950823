
import { I18N } from '@flyele-nx/i18n'
import { getSpaceAvatarUrl } from '@/utils/space'
import Background from '../Background'
import styles from './index.module.scss'

type IProps = {
  task: any
}

const Info = (props: IProps) => {

  const {
    task,
  } = props

  return (
    <div className={styles.info}>

      <div className={styles.info__header}>

        <div className={styles.info__left}>

          <div className={styles.info__avatar}
            style={{
              backgroundColor: `#${task?.icon_color}`,
            }}
          >

            <img
              className={styles['avatar-img']}
              src={getSpaceAvatarUrl(task?.icon).active}
              alt=''
            />
            
            <div className={styles['info__avatar-tool']}
              style={{
                backgroundColor: task.level === 2 ? '#777bfb' : '#9899A2'
              }}
            >{ task.level === 2 ? I18N.common.professionalEdition : I18N.common.basicEdition}</div>
          </div>

        </div>

        <div className={styles.info__right}>

          <div className={styles.info__name}>{task.name}</div>

          <div className={styles.info__creator}>{`${I18N.common.spaceCreator}:`}{task.creator_name}</div>

        </div>

      </div>

      {/* <div className={styles.info__bg}></div> */}
      <Background
        text={task.ws_desc}
      />

    </div>
  )
}

export default Info