
import { I18N, isCN } from '@flyele-nx/i18n'
import dj from 'dayjs'
import { useEffect, useState } from 'react'
import Background from '../Background'
import styles from './index.module.scss'
import {getEnFormat} from "@/utils/time";

type IProps = {
  task: any
}

const Info = (props: IProps) => {

  const {
    task,
  } = props

  const [targetTime, setTargetTime] = useState('')
  const [soFar, setSoFar] = useState<number|string>('')

  useEffect(() => {

    const {
      create_at,
      target_time,
    } = task

    const day = dj().diff(dj.unix(create_at), 'day')

    setSoFar(day)

    if (target_time) {
      setTargetTime(getEnFormat(dj.unix(target_time), 'YYYY年M月D日', 'MMM D, YYYY'))
    }

  }, [task])

  return (
    <div className={styles.info}>

      <div className={styles['info__title']}>
        {task.project_name}
      </div>

      <div className={styles['info__desc']}>
        {I18N.template(I18N.common.h5_theProjectHasBeenEstablished, { val1: soFar })}
      </div>

      <div className={styles['info__desc']}>
        {`${I18N.common.targetTime}：${targetTime || isCN ? '无目标时间' : 'Null'}`}
      </div>

      <Background
        text={task.project_desc}
      />

    </div>
  )
}

export default Info