// import axios from 'axios'
// import fs from 'fs'
// import path from 'path'
// // import { ipcRenderer } from 'electron'
// import ipcEventNames from 'constants/ipc'
import Http from "./http";
import { Hosts, domain } from "./const";

const host = Hosts.storage;

// enum StorageDict {
//   storage = '/storages',
// }

class Storage extends Http {
  constructor() {
    super({ host });
  }

  getUploadToken() {
    return this.get({
      url: `https://${domain}/storage/token`,
    }).then((res) => {
      return res;
    });
    // .catch((err) => {
    //   return err
    // })
  }

  getOnlineImage({
    ids = [],
    ossConfig = "",
    grantor = "",
    flyeleId = "",
  }: any) {
    const sIds = ids.join(",");

    return this.get<any>({
      url: `/storages/batch?file_id=${sIds}${
        ossConfig ? `&x-oss-process=${ossConfig}` : ""
      }`,
    });
  }

  getTaskFileList({task_id, page_number = 1, page_record = 10}: {
    task_id: string
    page_record?: number
    page_number?: number
  }) {
    const params = {
      page_number,
      page_record
    }
    return this.get({
      url: `/storages/flyele/${task_id}`,
      params,
    });
  }
}

export default new Storage();
