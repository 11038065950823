import React from "react";
import cs from "classnames";

import { getDate_v17_remind_date } from "utils_for_pc/getDateTxt";
import styles from "../Task/index.module.scss";

type TProps = {
  task: any;
};

const TimeRemind = ({ task }: TProps) => {
  const result = getDate_v17_remind_date(task) || "";

  return (
    <div className={styles["time-remind"]}>
      <div className={cs(styles["icon-time"], styles["fx-float-left"])}>
        <img src="/assets/v1_5/remind_2x.png" alt="" />
      </div>

      <div className={cs(styles["box-text"], styles["fx-float-left"])}>
        <span>
          {result.split("、").map((line: any, index: number) => {
            return (
              <>
                <>{line}</>
                {index !== result.split("、").length - 1 ? <br /> : null}
              </>
            );
          })}
        </span>
      </div>
    </div>
  );
};

export default TimeRemind;
