


import styles from './index.module.scss'
import cs from 'classnames'

import {LoginStatus, IButtonProps} from './main'
import { useEffect, useRef } from 'react'

const Button = (props: IButtonProps) => {

  const {
    disabled,
    text,
    status,
    clicked,
  } = props

  // console.log(status, LoginStatus.beforeCheckCode, LoginStatus)

  // const disabled = [
  //   LoginStatus.beforeCheckMobile, 
  //   LoginStatus.mobileError,
  //   LoginStatus.beforeCheckCode,
  //   LoginStatus.codeError,
  // ].includes(status)

  const disabledRef = useRef(disabled)
  useEffect(() => {
    disabledRef.current = disabled
  }, [disabled])

  const onClick = (e: React.MouseEvent<HTMLElement>) => {

    const disabled = disabledRef.current

    console.log('onClick', disabled)

    if (disabled) {
      return
    }

    clicked()
  }

  return (
    <div className={styles.button__wrapper}
      // style={{ marginTop: status }}
    >

      <div className={cs(styles.button, {
        [styles['button--disabled']]: disabled
      })}
        onClick={onClick}
      >

        <div className={styles['button-txt']}>
          {text}
        </div>

      </div>
    </div>
  )
}

export default Button