import React from 'react';
import styles from './index.module.scss'

export const ExpiredPage = () => {
  return (
    <div className={styles.ExpiredPageRoot}>
      <div className={styles.icon}>
        <img src="./assets/warmingBlue.png" alt=""/>
      </div>
      <div className={styles.text}>二维码已过期，请与分享者联系</div>
    </div>
  )
}