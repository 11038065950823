// eslint-disable-next-line import/no-cycle
import {IGetTaskList} from "service/types/main";
import Http from "./http";
import {Hosts} from "./const";

import {
  getRankParams,
  getTaskListInProjectParams,
  ICreateProjectParams,
  IGetMemberScheduleParams,
  IGetProjectList,
  IMatterInProject,
  IProjectInfo,
  IProjectInviteMember,
  IProjectMember,
  IProjectRankList,
  IStatistics,
  setTaskInfoParams,
} from "./types/project";
import {CommonResponse} from "./types/http";

const host = Hosts.flyele;

enum ProjectDict {
  project = "project",
  projects = "projects",
}
// const VERSION = 'v2'

class Project extends Http {
  constructor() {
    super({ host, version: "v2" });
  }

  /**
   * 查询项目列表
   * http://api.flyele.vip/flyele/v2/projects
   * @returns
   */
  getProjectList(page_number: number) {
    return this.get<CommonResponse<IStatistics>>({
      url: `/projects?page_number=${page_number}&page_record=20`,
    });
  }

  /**
   * 获取项目信息
   * @param projectId 项目ID
   * @returns
   */
  getProjectInfo(projectId: string) {
    return this.get<CommonResponse<IProjectInfo>>({
      url: `/${ProjectDict.project}/${projectId}`,
    })
      .then((res) => {
        // 过滤掉所有被撤回的人
        // const _res = {
        //   ...res,
        //   data: {
        //     ...res.data,
        //     project_members: (res.data as any).project_members.filter(
        //       (t: any) => t.state !== 10304
        //     ),
        //   },
        // };
        return res as CommonResponse<IProjectInfo>;
      })
      .catch((e) => {
        return e
      });
  }

  /**
   * 修改项目信息
   * @param projectId 项目ID
   * @returns
   */
  setProjectInfo({ project_id, ...data }: setTaskInfoParams) {
    return this.post({
      url: `/${ProjectDict.project}/${project_id}`,
      data,
    });
  }

  /**
   * 获取项目关联信息(获取概览统计数据)
   * @param projectId  项目ID
   * @returns  概览统计数据的信息 IStatistics
   */
  getStatistics(projectId: string) {
    return this.get<CommonResponse<IStatistics>>({
      url: `/${ProjectDict.project}/${projectId}/relation`,
    });
  }

  /**
   * 获取项目关联信息(获取排行榜数据)
   * @param projectId  项目ID
   * @returns  概览统计数据的信息 IStatistics
   */
  getRank({ project_id, ...params }: getRankParams) {
    return this.get<CommonResponse<IProjectRankList>>({
      url: `/${ProjectDict.project}/${project_id}/ranking_list`,
      params,
    });
  }

  /**
   * 获取项目中的事项列表
   * @param projectId
   * @returns
   */
  getMatterListInProject({
    project_id,
    ...params
  }: getTaskListInProjectParams) {
    return this.get<CommonResponse<IMatterInProject[]>>({
      url: `/${ProjectDict.project}/${project_id}/task_list`,
      params,
    });
  }

  /**
   * 获取项目中的协作人列表
   * @param projectId
   * @returns
   */
  getMemberInProject(projectId: string) {
    return this.get<CommonResponse<IProjectMember[]>>({
      url: `/${ProjectDict.project}/${projectId}/members`,
    })
      .then((res) => {
        // 过滤掉所有被撤回的人
        const _res = {
          ...res,
          ...res.data.filter(
            (t: any) => t.state !== 10304
          ),
          // 这里的数据结构跟后台的不一致，就改了
          // data: {
          //   ...res.data,
          //   project_members: (res.data as any).project_members.filter(
          //     (t: any) => t.state !== 10304
          //   ),
          // },
        };

        return _res as CommonResponse<IProjectMember[]>;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // 项目创建
  createProject(params: ICreateProjectParams) {
    const p = this.post({
      url: `/${ProjectDict.project}`,
      data: params,
    });

    return this.resFilter<{ project_id: string }>(p);
  }

  /***
   * 解散项目
   * @param projectId
   * */
  deleteProject(projectId: string) {
    const res = this.post({
      url: `/${ProjectDict.project}/${projectId}/cancel`,
    });

    return this.resFilter(res);
  }

  // 项目邀请成员
  projectInviteMember(projectId: string, data: IProjectInviteMember) {
    const p = this.post({
      url: `/${ProjectDict.project}/${projectId}/member`,
      data,
    });

    return this.resFilter(p);
  }

  // 项目移除成员
  // projectDeleteMember(projectId: string, data: IProjectInviteMember) {
  //   const p = this.delete({
  //     url: `/${ProjectDict.project}/${projectId}/member`,
  //     data,
  //   })

  //   return this.resFilter(p)
  // }

  // 查询用户创建项目总数
  getUserCreateProjectTotal() {
    return this.get<CommonResponse<{ create_total: number }>>({
      url: `/${ProjectDict.projects}/user_create_project_total`,
    });
  }

  // 查询项目列表
  queryProjectList(params: { page_number: number; page_record: number }) {
    return this.get<IGetProjectList>({
      url: `/${ProjectDict.projects}`,
      params,
    });
  }

  // 查询项目成员列表
  getMembers(projectId: string) {
    return this.get<CommonResponse<IProjectMember[]>>({
      url: `/${ProjectDict.project}/${projectId}/members`,
    });
  }

  //查询项目成员日程列表
  getMemberSchedule({
    projectId,
    memberId,
    day,
    queryType,
    pageNumber = 1,
    pageRecord = 20,
  }: IGetMemberScheduleParams) {
    return this.get<IGetTaskList>({
      url: `/${ProjectDict.project}/${projectId}/member/${memberId}/schedule`,
      params: {
        project_id: projectId,
        member_id: memberId,
        day,
        query_type: queryType,
        page_number: pageNumber,
        page_record: pageRecord,
      },
    });
  }

  /**
   * 项目批量导入事项
   * **/
  importTask({ projectId, taskIds }: { projectId: string; taskIds: string[] }) {
    const res = this.post({
      url: `/${ProjectDict.project}/${projectId}/import_task`,
      data: {
        tasks_id: taskIds,
      },
    });

    return this.resFilter(res);
  }

  /***
   * 项目所属事项
   * states 状态，多个用,隔开，1->待开始，2->进行中，3->已延期，4->已完成
   *
   * ***/
  projectTaskList(
    projectId: string,
    params?: {
      task_id?: string;
      parent_id?: number;
      start_time?: number;
      end_time?: number;
      takers_id?: string;
      states?: "1" | "2" | "3" | "4";
      page_number?: number;
      page_record?: number;
    }
  ) {
    return this.get<any>({
      url: `/${ProjectDict.project}/${projectId}/task_list`,
      params,
    });
  }

  /**
   * 修改项目成员状态
   *  join = 10302, // 已加入
   *  refuse = 10303, // 拒绝
   *  remove = 10304, // 移出页
   ***/
  projectMemberState({
    project_id,
    state,
  }: {
    project_id: string;
    state: 10302 | 10303 | 10304;
  }) {
    const res = this.post({
      url: `/${ProjectDict.project}/${project_id}/member/state`,
      data: {
        state,
      },
    });

    return this.resFilter(res);
  }
}

export default new Project();
