import React, { useMemo } from 'react'
import styles from './index.module.scss'
import { getDate_v15_validity_date } from "@/utils_for_pc/getDateTxt";
import { ITask } from "@/service/types/matter";

interface IProps {
  task: ITask
}

const TaskInfoRow = ({ task }: IProps) => {

  const renderList = useMemo(() => {
    const project = [
      {
        id: 'projectName',
        icon: '/assets/project_icon.png',
        text: task.project ? task.project.project_name : ''
      },
    ]


    const dateValue = getDate_v15_validity_date(task)
    const dateText = `${dateValue.firstPartOutput} ${dateValue.secondPartOutput ? ` — ${dateValue.secondPartOutput}`: ''}`
    const date = [
      {
        id: 'date',
        icon: '/assets/v1_5/task_v15/time_3x.png',
        text: dateText
      },
    ]

    const addressInfo = task.execute_addr ? JSON.parse(task.execute_addr) : ''
    const addressText = addressInfo ?
      addressInfo.input || `${addressInfo.select.address} ${addressInfo.select.name}` :
      ''
    const address = [
      {
        id: 'address',
        icon: '/assets/v1_5/task_v15/pos_3x.png',
        text: addressText
      },
    ]

    let list = []

    if (project[0].text){
      list.push(...project)
    }

    if (dateText.trim() !== '') {
      list.push(...date)
    }
    if (task.widget.execute_addr) {
      list.push(...address)
    }

    return list
  }, [task])


  return (
    <div className={styles.infoRowRoot}>
      {renderList.map(item => (
        <div className={styles.infoItem} key={item.id}>
          <div className={styles.icon}>
            <img src={item.icon} alt=""/>
          </div>
          <div className={styles.text}>{item.text}</div>
        </div>
      ))}
    </div>
  )
}

export default React.memo(TaskInfoRow)