/* eslint-disable */
// @ts-nocheck
import {useCallback, useEffect, useRef, useState} from "react";
import matterApi from "service/matter";
import { isMobile } from "../../forMobileInvite/hooks/isMobile";
import sensors, {getTypeCName} from "@/sensorsDataHook/initSensors";
import {SensorEvent} from "@/sensorsDataHook/const";
import { checkProjectByApi } from '@/utils/checkProjectByApi.ts'
import {useMemoizedFn} from "ahooks";
import userApi from "@/service/user";

export default function useGetTask(history: any, extra?: {
  oSearch: ISearch
  disableSensors?: boolean
}) {
  // 事项id
  const [task_id, set_task_id] = useState(extra?.oSearch?.id || '');

  // 参与人
  const [takers, set_takers] = useState([]);

  // 事项创建人
  const [creator, setCreator] = useState();

  // 事项
  const [task, set_task] = useState<any>(
    // 事项
    {
      task_id: "",
      title: "错误的事项邀请链接",
      detail: "未找到该事项，请确认链接是否正常",
      start_time: undefined, // 开始时间
      end_time: undefined, // 结束时间
      remind_at: {
        start_remind: undefined, // 开始提醒
        end_remind: undefined, // 结束提醒
      },
      execute_addr: "",
      files: [],
    } as any
  );

  const isRedictRef = useRef(false)

  const [task_dispatch, set_task_dispatch] = useState<any | undefined>(
    undefined
  );

  const sendSensorsLock = useRef<boolean>(false)

  function getHref() {
    // 当前的路径参数
    const { search }: any = history.location;

    // 检查参数合法性

    // 抽出参数
    const searchs = search.replace("?", "").split("&");

    console.log(searchs);
    const result: any = {};

    searchs.forEach((str: any) => {
      if (str) {
        const keyValue = str.split("=");

        if (keyValue.length === 2) {
          if (keyValue[0] && keyValue[1]) {
            const ValueOfKey: string = keyValue[0];
            const ValueOfName: string = keyValue[1];

            result[ValueOfKey] = ValueOfName;
          }
        }
      }
    });

    // 实际taskId
    if (!search.includes("id=") || !result.id || !result.u) {
      history.push({
        pathname: `/invite/task_ans`,
        search: `?id=`,
      });
    }

    return [result.id, result.u];
  }

  // 检查token是否有效
  const isValidToken = async () => {
    try {
      const res = await matterApi.getDetail(taskIdRef.current)
      // 先拿dispath_id
      set_task(res.data.task);
      set_task_dispatch(res.data.task_dispatch);
      return true
    } catch (e) {
      localStorage.removeItem("fx-token");
      localStorage.removeItem("fx-user_id");
      return false
    }
  }

  const getMaxSelectedTotal = (res: any) => {
    const {
      isPriviateMatter,
      isPrivateProject,
      isProjectCreatorMyself,
      isProjectCreatorVip,
      isProjectCreatorTeamVip,
      isSpaceBasic,
      isSpaceVip,
      isTaskTopLevelCreatorVip,
      isTaskCreatorMyself,
    } = res
    const MAX = Number.POSITIVE_INFINITY

    // 个人事项
    if (isPriviateMatter) {
      // 如果顶级事项创建人已经是 vip
      if (isTaskTopLevelCreatorVip) {
        return 100
      }

      if (!isTaskCreatorMyself) {
        return 20
      }
      return MAX
    }

    // 个人项目
    if (isPrivateProject) {
      // 跟项目创建人走
      if (isProjectCreatorVip || isProjectCreatorTeamVip) {
        return 100
      }

      if (!isProjectCreatorMyself) {
        return 20
      }
      return MAX
    } // 企业 没有个人项目 100

    // 空间下的
    if (isSpaceBasic || isSpaceVip) {
      if (isSpaceBasic) {
        return 20
      }
      return MAX
    }

    return MAX
  }

  // 获取参与者
  function getTakers(taskId: string) {
    return matterApi
      .getTakers(taskId)
      .then((res) => {
        console.log("getTakers", res);

        const newSorted = res.data.sort((n1: any, n2: any) => {
          console.log(n1.personal_state, n2.personal_state);
          if (!n1.personal_state) {
            return 9999;
          }
          if (!n2.personal_state) {
            return -9999;
          }
          return n1.personal_state - n2.personal_state;
        });

        console.log(newSorted);
        set_takers(newSorted);
        return res;
      })
      .catch(() => {});
  }

  // 获取事项详情
  function getDetail(taskId: string, useIgnore = false) {
    // useIgnore为 true时，则用
    // 无需权限的接口
    if (useIgnore) {
      return matterApi
        .getDetailIgnore(taskId)
        .then((res: any) => {
          const takers = res.data.takers || []
          const { creator_id } = res.data.task
          const creator = takers.find(item => item.taker_id === creator_id)
          set_takers(takers);
          setCreator(creator)
          return res;
        })
        .catch((e) => {
          console.log('getDetailIgnore错误！！！', e);
        });
    }

    return matterApi
      .getDetail(taskId)
      .then((res: any) => {
        getTakers(taskId);

        return res;
      })
      .catch((e) => {
        console.log('getDetail错误！！', e);
      });
  }

  async function getDetailMain(needSensor?: boolean) {
    //
    let isValid = false;

    // 检查用哪个接口
    if (localStorage.getItem("fx-token")) {
      isValid = await isValidToken();
    } else {
      isValid = false;
    }

    // 请求
    // isValid 为 true， 则说明直接使用非auth_ignore的接口
    // isValid 为 false 则说明，token无效，使用auth_ignore的接口（传true激活无权限接口）
    getDetail(task_id, !isValid)
      .then((res: any) => {
        set_task(res.data.task);
        set_task_dispatch(res.data.task_dispatch);

        if (isValid && needSensor && !extra?.disableSensors) {
          sendSensors(res.data.task)
        }
      })
      .catch((e) => {
        console.log(e.code);
      })
  }

  useEffect(() => {
    if (!task_dispatch) {
      goDispatch('接受')
    }

    checkSpecial();
  }, [task_dispatch]);

  const checkSpecial = () => {
    if (!task_dispatch) return;
    if (
      task_dispatch &&
      task_dispatch?.personal_state !== 10401 &&
      task_dispatch?.personal_state !== 10601
    ) {

      if (isMobile()) {

        history.push({
          pathname: `/feedback/matter`,
          search: history.location.search,
        })

      } else {

        history.push({
          pathname: `/invite/task_ans`,
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
      }

    }
    // 如果自己是创建人
    // if (
    //   task?.creator_id &&
    //   localStorage.getItem("fx-user_id") &&
    //   localStorage.getItem("fx-user_id") !== "undefined" &&
    //   task?.creator_id === localStorage.getItem("fx-user_id")
    // ) {
    //   history.push({
    //     pathname: `/invite/task_ans`,
    //     search: `?result=iscreate&id=${getHref()[0]}&u=${getHref()[1]}`,
    //   });
    // }

    // 如果是自己创建的邀请链接
    // if (
    //   getHref()[1] &&
    //   localStorage.getItem("fx-user_id") &&
    //   localStorage.getItem("fx-user_id") !== "undefined" &&
    //   getHref()[1] === localStorage.getItem("fx-user_id")
    // ) {
    //   history.push({
    //     pathname: `/invite/task_ans`,
    //     search: `?result=isself&id=${getHref()[0]}&u=${getHref()[1]}`,
    //   });
    // }

    // 如果是被取消的事项
    // if (task?.is_cancel === true) {
    //   history.push({
    //     pathname: `/invite/task_ans`,
    //     search: `?id=${getHref()[0]}&u=${getHref()[1]}`,
    //   });
    // }

    // 被取消的事项

    // 被取消
    // if (
    //   // 事项
    //   task_dispatch?.operate_state === 10407 ||
    //   task_dispatch?.operate_state === 10405 ||
    //   // 会议
    //   task_dispatch?.operate_state === 10609 ||
    //   task_dispatch?.operate_state === 10607
    // ) {
    //   history.push({
    //     pathname: `/invite/task_ans`,
    //     search: `?result=canceled&id=${getHref()[0]}`,
    //   });
    // }

    // 被移出撤回
    // if (
    //   // 事项
    //   task_dispatch?.operate_state === 10408 ||
    //   task_dispatch?.operate_state === 10406 ||
    //   // 会议
    //   task_dispatch?.operate_state === 10610 ||
    //   task_dispatch?.operate_state === 10608
    // ) {
    //   history.push({
    //     pathname: `/invite/task_ans`,
    //     search: `?result=removed&id=${getHref()[0]}`,
    //   });
    // }

    //
    // if (
    //   // 事项
    //   (task_dispatch?.operate_state === 10401 ||
    //     // 会议
    //     task_dispatch?.personal_state === 10601) &&
    //   history.location.pathname.includes("/task_ans")
    // ) {
    //   history.push({
    //     pathname: `/invite/task`,
    //     search: `${history.location.search}`,
    //   });
    // }
  };

  // 读取task数据
  const taskIdRef = useRef(task_id)
  useEffect(() => {
    if (!task_id || task_id === "") return;

    taskIdRef.current = task_id
    sendSensorsLock.current = false

    if (isMobile()) {
      if (
        history.location.pathname.includes("/invite/task") ||
        history.location.pathname.includes("/invite/meeting")
      ) {

        isRedictRef.current = true
        history.push({
          pathname: `/invite_h5/task`,
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
        return
      }
    }

    getDetailMain(true);

  }, [task_id]);

  useEffect(() => {
    if (!task) return;

    const { pathname, search }: any = history.location;

    if (
      task?.matter_type === 10702 &&
      !pathname.includes("meeting") &&
      !pathname.includes("/invite/task_ans")
    ) {
      // 事项没有显示在事项页面
      history.push({
        pathname: "/invite/meeting",
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      });
    } else if (
      (task?.matter_type === 10701 || task?.matter_type === 10705) &&
      !pathname.includes("task") &&
      !pathname.includes("/invite/task_ans")
    ) {
      // 会议没有显示在会议页面
      history.push({
        pathname: "/invite/task",
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      });
    } else if (
      !localStorage.getItem("fx-token") &&
      !pathname.includes("/invite/task") &&
      !pathname.includes("/invite/meeting") &&
      !search.includes("result=incorrect")
    ) {
      if (isRedictRef.current) {
        return
      }
      history.push({
        pathname: `/invite/task`,
        search: `${history.location.search}`.includes("?id=")
          ? `${history.location.search}`
          : `?id=${history.location.search}`,
      });
    }
    
    // workspace_state === 3 为 空间待激活的状态
    // task.project?.project_state === 10203 为 项目待激活的状态
    if (
      (task?.is_cancel && !pathname.includes("/invite/task_ans_canceled")) ||
      (task.workspace_state === 3 || task.project?.project_state === 10203)
    ) {

      if (isMobile()) {
        history.push({
          pathname: `/feedback/matter`,
          search: history.location.search,
        })
      } else {
        history.push({
          pathname: `/invite/task_ans_canceled`,
          search: `${history.location.search}`.includes("?id=")
            ? `${history.location.search}`
            : `?id=${history.location.search}`,
        });
      }
    }
  }, [task, creator, takers]);

  // 操作
  async function dispatch_state(
    dispatch_id: string,
    personal_state: number,
    options: any = {}
  ) {
    let new_dispatch_id = undefined;
    if (!dispatch_id) {
      const afterAdd_dispatch_id = () =>
        new Promise((resolve, reject) => {
          matterApi
            .addTaskDispatch({
              from_user_id: getHref()[1],
              invite_type: "flyele",
              taker_id: [localStorage.getItem("fx-user_id")],
              task_id: getHref()[0],
            })
            .then((res) => {
              if (res.data && res.data.data && res.data.data.message) {
                if (res.data.data.message === '协作人无法加入步骤') {
                  // 400111 假的一个code，为了做出是工作流事项无法加入的辨识而已
                  if (isMobile()) {
                    history.push({
                      pathname: `/feedback/matter`,
                      search: history.location.search,
                    })
                  } else {
                    history.push({
                      pathname: `/invite/task_ans_canceled`,
                      search: `${history.location.search}`.includes("?id=")
                        ? `${history.location.search}&errCode=${400111}`
                        : `?id=${history.location.search}&errCode=${400111}`,
                    });
                  }
                  reject(res.data)
                  return
                }
              }
              resolve(res.data);
            })
            .catch((e) => {
              if (e.code === 40011) {

                if (isMobile()) {

                  history.push({
                    pathname: `/feedback/matter`,
                    search: history.location.search,
                  })
                } else {

                  history.push({
                    pathname: `/invite/task_ans_canceled`,
                    search: `${history.location.search}`.includes("?id=")
                      ? `${history.location.search}&errCode=${e.code}`
                      : `?id=${history.location.search}&errCode=${e.code}`,
                  });
                }
              } else {
                localStorage.removeItem("fx-token");
                localStorage.removeItem("fx-user_id");
                location.reload(true);
              }
              reject(e);
            });
        });

      new_dispatch_id = await afterAdd_dispatch_id();

      console.log("我成功的afterAdd_dispatch_id", new_dispatch_id);
      Object.keys(new_dispatch_id.data).forEach((key) => {
        if (key !== 'message') {
          new_dispatch_id = new_dispatch_id.data[key];
        }
      });
    }

    const real_dispatch_id = new_dispatch_id || dispatch_id;

    getDetailMain();

    return matterApi
      .setTaskDispatchState(real_dispatch_id, {
        personal_state,
        ...options,
      })
      .then((res: any) => {
        console.log("接受成功", res);
        return true;
      })
      .catch(() => {
        // message.info('操作')
        return false;
      });
  }

  // 提交修改
  const goDispatch = useMemoizedFn((actionType = "接受", options: any = {}) => {
    return new Promise(async (resolve, reject) => {
      const { top_creator_id, creator_id } = task
      const creatorId = top_creator_id ?? creator_id
      if (!localStorage.getItem("fx-token") || !localStorage.getItem("fx-user_id") || !creatorId) {
        checkSpecial();
        resolve(false);
        return
      }

      const userId  = localStorage.getItem("fx-user_id")
      const { data: memberInfo } = await userApi.getMemberCombo(creatorId)
      const isVip =
        memberInfo.member.state !== undefined
          ? memberInfo.member.state !== 0
          : false
      const creatorInfo = {
        isTaskTopLevelCreatorVip: isVip,
        isTaskCreatorMyself: userId === creatorId
      }
      let maxTakerTotal = creatorInfo.isTaskTopLevelCreatorVip ? 100 : !creatorInfo.isTaskCreatorMyself ? 20 : Number.POSITIVE_INFINITY
      if (task.project || task.project_id) {
        const res = await checkProjectByApi(task.project ?? task, userId)
        maxTakerTotal = getMaxSelectedTotal({...res, ...creatorInfo})
      }
      let goToAns = false
      const memberLength = takers.length
      if (memberLength >= maxTakerTotal) {
        goToAns = true
      }

      if (goToAns) {
        let searchParams = `${history.location.search}`.includes("?id=")
          ? `${history.location.search}&maxTaker=1`
          : `?id=${history.location.search}&maxTaker=1`

        if (!searchParams.includes("errCode=")) {
          searchParams += `&errCode=${40011}`
        }

        history.push({
          pathname: `/invite/task_ans_canceled`,
          search: searchParams,
        });
        resolve(false);
        return
      }

      matterApi
        .getDetail(task_id)
        .then(async (res: any) => {
          const dispatch_id = res?.data?.task_dispatch?.dispatch_id;

          const { pathname }: any = history.location;
          let code = -1;

          if (actionType === "接受") {
            // 提交成功
            if (pathname.includes("meeting")) code = 10611;
            if (pathname.includes("task")) {
              if (task_dispatch?.identity === 10804) {
                code = 10402;
              } else {
                code = 10409;
              }
            }

            if (await dispatch_state(dispatch_id, code)) resolve(true);
          }

          if (actionType === "请假") {
            if (pathname.includes("meeting")) code = 10604;

            if (await dispatch_state(dispatch_id, code, options)) resolve(true);
          }

          if (actionType === "拒绝") {
            if (pathname.includes("meeting")) code = 10603;
            if (pathname.includes("task")) code = 10403;

            if (await dispatch_state(dispatch_id, code, options)) resolve(true);
          }

          reject(false);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    });
  })

  const sendSensors = useCallback((resTask?: any) => {
    const rid = new URLSearchParams(window.location.search).get("rid") ?? "";
    const useTask = resTask || task
    const sensorsParams = {
      business_id: useTask?.task_id,
      business_type: getTypeCName(useTask?.matter_type),
      // 邀请人ID
      invite_people_id: useTask?.creator_id,
      // 邀请分享ID
      invitation_share_id: rid,
      // 邀请分享类型
      invitation_share_type: "邀请链接",
    };

    if (sensorsParams.business_id && !sendSensorsLock.current) {
      console.log('埋点 click_invite_share 10月18日更新111', sensorsParams);
      sendSensorsLock.current = true

      sensors.sendPoint(SensorEvent.click_invite_share, sensorsParams);
    }
  }, [task])

  return { set_task_id, task, task_dispatch, goDispatch, takers, isValidToken, sendSensors, creator };
}
