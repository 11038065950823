import React from "react";

import {
  // getDate_MD_Week_Hm,
  getDate_v15_validity_date,
  getDate_v17_remind_date,
} from "utils_for_pc/getDateTxt";
// import { getRemindTimeTxt } from "utils_for_pc/getRemindTimeTxt";

import style from "./CapsoleTime.module.scss";

const styles = {
  calendar_icon_btn: style["calendar_icon_btn"],
  line: style["line"],
  line__center: style["line__center"],
  line__center_green: style["line__center_green"],
  line__left: style["line__left"],
  line__right: style["line__right"],
  line__right_dot: style["line__right_dot"],
  line__right_remind: style["line__right_remind"],
};

const TimeLine = ({ typeName = "开始时间", task }: any) => {
  // const charList = getDate_v15_remind_date(task);

  return (
    <div className={styles.line}>
      <span className={styles.line__left}>
        <span>
          {typeName === "时间区间" && (
            <img
              alt=""
              className={style.icon_top_1}
              src="/assets/small-tools/timeCapsule/time_2x.png"
              // style={{ position: "relative", top: 1 }}
            />
          )}

          {typeName === "提醒时间" && (
            <img
              alt=""
              className={style.icon_top_1}
              src="/assets/small-tools/timeCapsule/remind_2x.png"
            />
          )}
        </span>
      </span>
      <span className={styles.line__center}>
        <span>
          {typeName === "时间区间"
            ? getDate_v15_validity_date(task).output || "无时间"
            : null}

          {typeName === "提醒时间" && getDate_v17_remind_date(task)}
        </span>
      </span>
    </div>
  );
};

export default TimeLine;
