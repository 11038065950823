import PCDownload from "pages/html5downloader_v2/pc";

import Html5Page from "pages/html5downloader";
import EnterpriseWechat from "pages/EnterpriseWechat";
import EnterpriseWechatSdk from "@/pages/EnterpriseWechatSdk";

export const ForDownloadRouters = [
  // h5引流
  {
    exact: true,
    path: "/index.html",
    component: Html5Page,
    key: "bind-user",
    verifyLogin: false,
    title: "App下载",
  },

  {
    exact: true,
    path: "/d/pc",
    component: PCDownload,
    key: "bind-user",
    verifyLogin: false,
    title: "App下载",
  },

  {
    exact: true,
    path: "/enterprisewechat",
    component: EnterpriseWechat,
    key: "EnterpriseWechat",
    verifyLogin: false,
    title: "企业微信",
  },
  {
    exact: true,
    path: "/enterpriseWechatSdk",
    component: EnterpriseWechatSdk,
    key: "EnterpriseWechatSdk",
    verifyLogin: false,
    title: "企业微信",
  },
];
