// 获取url参数
// function getQueryVariable(variable) {
//   var query = window.location.search.substring(1);
//   var vars = query.split("&");
//   for (var i = 0; i < vars.length; i++) {
//     var pair = vars[i].split("=");
//     if (pair[0] == variable) {
//       return pair[1];
//     }
//   }
//   return false;
// }

// 判断当前是否ios系统
const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

function determineAndroidPlatform() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /android/.test(userAgent) && !/mobile/.test(userAgent);
}

export { isIOS, isIPad, determineAndroidPlatform };
