
import React, { useEffect, useRef, useState } from 'react'
import cs from 'classnames'
import styles from './index.module.scss'
import {ICodeProps} from './main'

const DefaultLength = 6

const Code = (props: ICodeProps) => {

  const {
    code,
    error,
    length = DefaultLength,
    changeHandler,
  } = props

  const [activeIndex, setActiveIndex] = useState<string|undefined>(undefined)

  const [codes, setCodes] = useState<Array<string>>([])
  const [inputVal, setInputVal] = useState<string>('')

  const inputRef = useRef<HTMLInputElement|null>(null)


  useEffect(() => {
    if (!code) {
      return
    }
    setCodes(code.split(''))
  }, [code])

  const onClickCodeBox = (e: React.MouseEvent<HTMLDivElement>) => {
    
    console.log('onClickCodeBox', e, e.currentTarget.dataset.index)

    const {
      currentTarget: {
        dataset: {
          index
        }
      }
    } = e

    inputRef.current?.focus()
    setActiveIndex(index)
  }

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    console.log('inputChange', e,)

    const {
      target: {
        value,
      }
    } = e

    const filter = value.replaceAll(/[^0-9]/g, '').substring(0, length)

    const arr = filter.split('')

    setInputVal(filter)
    setCodes(arr)
    changeHandler(filter)
  }

  const inputBlur = () => {

  }

  return (
    <div className={styles.code}>

      <div className={styles.code__tips}>请输入验证码</div>

      <div className={styles['code__box-wrapper']}>

        {
          Array.from({length}).map((_, idx) => {

            return (
              <div key={idx} className={cs(styles.code__box, {
                [styles['code__box--active']]: activeIndex === String(idx)
              })} 
                data-index={idx}
                onClick={onClickCodeBox}
              >

                <div className={styles['code__box-txt']}>
                  {codes[idx]}
                </div>

                <div className={styles['code__box-cursor']} />

              </div>
            )
          })
        }

      </div>

      <div className={styles.code__input}>

        <input type="text"
          value={inputVal}
          ref={inputRef}
          onChange={inputChange}
          onBlur={inputBlur}
        />

      </div>

      {
        error
          ? (
            <div className={styles.code__error}>
              输入正确的验证码
            </div>
          )
          : null
      }
      

    </div>
  )
}

export default Code