import axios, { AxiosInstance, Method, AxiosRequestConfig } from "axios";
import qs from "qs";
import { getBindId } from "../components/editor/tools";
import { Toast } from "antd-mobile";
import { getCode } from "utils/login";
import { AjaxOptions } from "type-define";

import { domain } from "service/const";

// import fundebug from "fundebug-javascript";
const { token } = qs.parse(window.location.search.substr(1)) as {
  token: string;
  env: "release" | "prod" | "develop";
};

// const envDict: { [prop: string]: string } = {
//   develop: "api.flyele.vip",
//   release: "api.flyele.vip",
//   prod: "api.flyele.net",
// };

// const officialDict = {
//   // release: "http://vx.flyele.net",
//   // release: "http://vx.flyele.vip",
//   // release: 'https://api-test.flyele.vip/official',
//   // prod: "https://api.flyele.net/official",

//   // release: 'https://api1.flyele.vip/official',
//   release: "https://api-test.flyele.vip/official/",
//   prod: "https://api.flyele.net/official/",
// };

const _getFullUrl = () => {
  // (url: string): string
  // if (process.env.NODE_ENV === "development") return "";
  // if (url.indexOf("official") !== -1) {
  //   return officialDict[process.env.service];
  // } else {
  // return `https://${envDict[process.env.service]}`;

  // return envDict[process.env.service];
  return `https://${domain}/official`;
  // }
};

const defaultToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzE2Nzc5MDUsImlhdCI6MTYyOTI1NjY2MSwiaXNzIjoiKi5mbHllbGUubmV0IiwiVXNlcklEIjoiODEwMjA4MTMxMDIyOTI1IiwiVG9rZW4iOiIiLCJEZXZpY2VJRCI6IiIsIlBsYXRmb3JtIjoid2ViIiwiQ2xpZW50VmVyc2lvbiI6IjAuMC4xIn0.wlYO0r2IWh7EOxucXCtXahPufA0C1Csw2e1dcKLp91A";
export const http = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "" : ``,
  headers: {
    Authorization:
      token ||
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NTkxNjcyNzAsImlhdCI6MTY1ODMwMTc4NiwiaXNzIjoiYXBpLmZseWVsZS5uZXQiLCJVc2VySUQiOiIxODI0MzUxNzU3MDA5MDQzIiwiRGV2aWNlSUQiOiIxMzI0MzQiLCJQbGF0Zm9ybSI6Img1IiwiQ2xpZW50VmVyc2lvbiI6Img1In0.KZdwHNviQCkwDCC7x6GmOirMpsWLVO1NJKObqstbnWw",
  },
}) as { [props: string]: any } & AxiosInstance;

export const ajax = <T, S>(
  method: Method,
  url: string,
  options?: AjaxOptions<T>
): Promise<S> => {
  // const fullUrl = _getFullUrl(url) + url;
  const fullUrl = _getFullUrl() + url;

  console.log("fullUrl", fullUrl, url);

  return new Promise<S>((resolve, reject) => {
    const userInfo = window.localStorage.getItem("userInfo");
    const token = (userInfo && JSON.parse(userInfo).token) || defaultToken;
    if (!options) {
      options = {
        useToken: true,
      };
    }
    if (typeof options.useToken === "undefined") {
      options.useToken = true;
    }
    const config: AxiosRequestConfig = {
      method,
      url: fullUrl,
    };
    if (options.useToken) {
      config.headers = { Authorization: token };
    }
    if (method.toLocaleLowerCase() === "get") {
      config.params = options.data;
    } else {
      config.data = options.data;
    }
    return http(config)
      .then((res) => {
        console.log(url + "success response", res.data);
        // fundebug.notify(url + 'success response', res.data)
        if (res.data.code === 0) {
          return resolve(res.data.data);
        }
        reject(res);
      })
      .catch((err) => {
        console.log(url + "err response", err.response);
        // fundebug.notify(url + 'err response', err.response)
        try {
          if (err.response.status === 401) {
            getCode(); // token失效重新登录
          }
        } catch (e) {
          console.log(e);
        }

        reject(err?.response?.data);
      });
  });
};

export const getOnlineImgs = (ids: string[], ossConfig = "") => {
  const sIds = ids.join(",");
  let url = `/disk/v1/storages/batch`;
  // if (ossConfig) {
  //   url = url.concat(`&x-oss-process=${ossConfig}`)
  // }
  // return http.get(url)
  return ajax("get", url, { data: { file_id: sIds } });
};

export const publish = (params: PublishParams) =>
  http.post("/ui/v1/comment", {
    ref_type: "task",
    msg_type: 2, // 结论
    ...params,
  });

const bindFile = async (data: {
  file_id: string[];
  flyele_id: string;
  flyele_type: "task" | "record";
}) => await http.post(`/disk/v1/storages/bind`, data);

async function changeFileState(
  id: number,
  fileDict: React.MutableRefObject<FileDict>,
  fileId?: string
) {
  const queryId = `#blot_${id}`,
    loadingDom = document.querySelector(`${queryId} .loading`),
    container = document.querySelector(`${queryId} span`);

  console.log("state change -->", loadingDom, container);

  if (!loadingDom || !container) return;
  container.removeChild(loadingDom);

  // upload fail
  if (!fileId) {
    fileDict.current[id].state = "fail";
    const failDom = document.createElement("div");
    failDom.innerText = "上传失败";
    failDom.classList.add("fail-dom");
    const retryDom = document.createElement("img");
    retryDom.src = "./images/icons/retry.png";
    failDom.appendChild(retryDom);
    container.appendChild(failDom);
    return;
  }

  // upload success
  try {
    // bind file
    const bindId = getBindId();
    if (bindId) {
      const [k, v] = bindId;
      await bindFile({
        flyele_type: k,
        flyele_id: v,
        file_id: [fileId],
      });
    }

    const maskDom = container.querySelector(".mask");
    if (maskDom) {
      container.removeChild(maskDom);
    }

    fileDict.current[id] = {
      ...fileDict.current[id],
      state: "success",
      id: fileId,
    };

    const wrapper = document.querySelector(queryId) as HTMLDivElement;
    wrapper.style.color = "white";
    wrapper.classList.add("blot-uploaded");
    wrapper.setAttribute("data-file", fileId);

    const bgDom = wrapper.querySelector(".bg-file") as HTMLImageElement | null;
    if (bgDom) bgDom.src = bgDom.src.replace("/mask", "");
  } catch (e) {
    console.log("bind file error", e);
    Toast.fail("文件绑定失败");
  }
}
