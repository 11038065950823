import { WWLoginInstance } from "@wecom/jssdk"
import * as React from "react"
import { useEffect } from "react"
import * as ww from "@wecom/jssdk"

// const appId = "wwde9fcdf25dfbf58f";
// const redirect_uri = encodeURI("https://h5-test.flyele.vip/");
const EnterpriseWechatSdk: React.FC = () => {
  const wwLogin = React.useRef<WWLoginInstance>()

  // console.log(
  //   "(传入参数)",
  //   "redirect_uri:",
  //   window.location.href,
  //   "appId:",
  //   appId,
  //   "login_type:ServiceApp"
  // );

  useEffect(() => {
    wwLogin.current = ww.createWWLoginPanel({
      el: "#ww_login",

      params: {
        login_type: "ServiceApp" as any,
        // appid: "ww84f1bfad54b7281b",
        appid: "wwde9fcdf25dfbf58f",
        redirect_uri: encodeURI(window.location.href),
        redirect_type: "callback" as any,
        lang: "zh" as any,
      },
      onCheckWeComLogin({ isWeComLogin }) {
        console.log(isWeComLogin)
      },
      onLoginSuccess(e) {
        console.log("____SUCCESS", e, e.code)
        window.parent.postMessage(
          {
            type: "weCom", // 自定义事件名
            code: e.code, // 数据，只能是字符串
          },
          "*"
        )
      },
      onLoginFail(err) {
        console.log("____err", err)
      },
    })
    return () => {
      wwLogin.current?.unmount()
    }
  }, [])

  return (
    <div
      id="ww_login"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  )
}

export default EnterpriseWechatSdk
