import { IRightProps } from "./main"

import styles from './index.module.scss'

const Rights = (props: IRightProps) => {

  return (
    <div className={styles.rights}>

      <div className={styles.rights__wrapper}>

        <div>登录即同意</div>
        <div>
          <a href="https://cdn.flyele.net/agreements/software-license-agreement.html" target="_blank">《服务协议》</a>
        </div>
        <div>和</div>
        <div>
          <a href="https://cdn.flyele.net/agreements/privacy-policy.html" target="_blank">《隐私政策》</a>
        </div>
      </div>

    </div>
  )

}

export default Rights