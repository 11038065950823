import Http from "./http";
import { Hosts } from "./const";
import { IHoliday } from "@/typings/month";

const host = Hosts.flyele

const PREFIX = '/tasks/monthly_view'

class Month extends Http {
  constructor() {
    super({ host, version: 'v2' })
  }

  getHoliday(year: number) {
    return this.get<{ data: IHoliday[] }>({
      url: `${PREFIX}/holiday`,
      params: {
        year,
      },
    })
  }
}

export default new Month()
