// import React, { useState } from "react";

import { ModalInnerLayout } from "Component/Modal/ModalInnerLayout";
import { PrimaryBtn } from "Component/Button/PrimaryBtn";
import { Modal, Input, message } from "antd";
import { Wx2dCode } from "pages/Wx2dCode";
import {curProjectType, PROJECT_ENV} from "@/service/const";
import {AccountNumberModal} from "@/components/login/account-number-modal";
import React from "react";

const { TextArea } = Input;

const ActionModal = (Props: any) => {
  const {
    JumpHrefAfterSubmit,
    handleClick,
    lastAction,
    isValidToken,
    refuseReason,
    setRefuseReason,
    acceptModalVisible,
    setAcceptModalVisible,
    vacateModalVisible,
    setVacateModalVisible,
    refuseModalVisible,
    setRefuseModalVisible,
  } = Props;

  const handlhangeVacate = (e: any) => {
    if (e.target.value.toString().length > 30) {
      message.info({
        content: "理由不能超出30字",
        key: "info",
        duration: 1.5,
      });
    }
    setRefuseReason(e.target.value.slice(0, 30));
  };

  const handlhangeRefuse = (e: any) => {
    if (e.target.value.toString().length > 30) {
      message.info({
        content: "理由不能超出30字",
        key: "info",
        duration: 1.5,
      });
    }
    setRefuseReason(e.target.value.slice(0, 30));
  };

  return (
    <>
      <Modal
        visible={vacateModalVisible}
        title={null}
        footer={null}
        closable={false}
        style={{ top: "20vh" }}
        width="420px"
      >
        <ModalInnerLayout
          component={{
            titleBar: undefined,
            content: (
              <div style={{ padding: "8px 8px 28px 8px" }}>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  placeholder="输入请假理由"
                  value={refuseReason}
                  onChange={handlhangeVacate}
                />
              </div>
            ),
            footer: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: 24,
                  // paddingBottom: 28,
                }}
              >
                <PrimaryBtn
                  title="取消"
                  onClick={() => setVacateModalVisible(false)}
                  colorType="white"
                />
                <PrimaryBtn
                  title="确定"
                  onClick={() => {
                    JumpHrefAfterSubmit("请假");
                    setVacateModalVisible(false);
                  }}
                />
              </div>
            ),
          }}
        />
      </Modal>

      <Modal
        visible={refuseModalVisible}
        title={null}
        footer={null}
        closable={false}
        style={{ top: "20vh" }}
        width="420px"
      >
        <ModalInnerLayout
          component={{
            titleBar: undefined,
            content: (
              <div style={{ padding: "8px 8px 28px 8px" }}>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  placeholder="输入拒绝理由"
                  value={refuseReason}
                  onChange={handlhangeRefuse}
                />
              </div>
            ),
            footer: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PrimaryBtn
                  title="取消"
                  onClick={() => setRefuseModalVisible(false)}
                  colorType="white"
                />
                <PrimaryBtn
                  title="确定"
                  onClick={() => {
                    JumpHrefAfterSubmit("拒绝");
                    setRefuseModalVisible(false);
                  }}
                />
              </div>
            ),
          }}
        />
      </Modal>

      {curProjectType === PROJECT_ENV.normal ? (
        <Modal
          visible={acceptModalVisible}
          title={null}
          footer={null}
          closable
          onCancel={() => setAcceptModalVisible(false)}
          style={{ top: "20vh" }}
          destroyOnClose
          width="340px"
        >
          <ModalInnerLayout
            component={{
              titleBar: undefined,
              content: (
                <Wx2dCode
                  onSuccess={() => {
                    setAcceptModalVisible(false);
                    handleClick(lastAction);
                    isValidToken();
                  }}
                />
              ),
              footer: null,
            }}
          />
        </Modal>
        ) : (
        <AccountNumberModal
          visible={acceptModalVisible}
          onClose={() => setAcceptModalVisible(false)}
          onSuccess={ async () => {
            setAcceptModalVisible(false);
            handleClick(lastAction);
            isValidToken();
          }}
        />
      )}
    </>
  );
};

export default ActionModal;
