import { I18N } from '@flyele-nx/i18n'
import React from "react";

import styles from "../Task/index.module.scss";

type TProps = {
  detail: string | undefined;
};

const BackGround = ({ detail }: TProps) => {
  return (
    <div className={styles.detail}>
      <div
        dangerouslySetInnerHTML={{
          __html: (detail || "").replace(/\n/g, "<br/>") || I18N.common.noBackgroundInformation,
        }}
      />
    </div>
  );
};

export default BackGround;
