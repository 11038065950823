


import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cs from 'classnames'

import Input from '../Input'
import { IInputProps } from '../Input/main'
import styles from './index.module.scss'
import { INameFormOutput, INameFormProps } from './main'
import { Toast } from 'antd-mobile'
import 'antd-mobile/lib/toast/style/index'

const MAX_LENGTH = 30

const NameForm: ForwardRefRenderFunction<INameFormOutput, INameFormProps> = (props, ref) => {

  const {
    changeHandler,
  } = props

  const [input, setInput] = useState<string>('')

  const isCompositionRef = useRef(false)

  const inputRef = useRef(input)
  useEffect(() => {
    inputRef.current = input
  }, [input])

  const validate: INameFormOutput['validate'] = (val) => {

    if (val === undefined) {

      val = inputRef.current
    }

    return !!String(val).trim()

  }

  const checkValidate =(val: string) => {

    if (val?.trim().length > MAX_LENGTH) {

      Toast.info(`最多支持${MAX_LENGTH}个字符`)
      setInput(val.substring(0, MAX_LENGTH))
      return
    }

    setInput(val)

    const pass = validate(val)
    // console.log('onChange', pass)

    changeHandler(pass, val)
  }

  const onComposition: IInputProps['onComposition'] = (e) => {
    // console.log('handleComposition', e)
    // console.log(e.type + ": " + e.data)

    if (e.type === 'compositionend') {
      
      isCompositionRef.current = false

      // console.log('compositionend', e)
      checkValidate((e.target as any).value)

    } else {
      // in composition
      isCompositionRef.current = true
    }
  }

  const onChange: IInputProps['changeHandler'] = (val) => {

    const exclude = /[@#\*\/\\<>↑↓←→]/g
    // console.log('onChange', val, isCompositionRef.current)

    if (exclude.test(val)) {

      Toast.info('不支持特殊字符@ # * / \\ < > ↑↓ → ← ')
      // setInput(inputRef.current)
      setInput(val.replaceAll(exclude, ''))
      return
    }

    if (isCompositionRef.current) {
      setInput(val)
    }

    if (!isCompositionRef.current) {
      // 非键盘输入法输入（普通英文字符输入）
      // console.log('compositionend', val)
      checkValidate(val)
    }
  }

  // const onBlur: IInputProps['blurHandler'] = () => {

  //   const pass = validate(inputRef.current)
  //   // console.log('onblur', pass)

  // }

  const onClear = () => {
    setInput('')
    changeHandler(false, '')
  }

  useImperativeHandle(ref, () => ({
    input,
    validate,
  }), [input])

  return (
    <div className={cs(styles.form, {
      // [styles['form--hidden']]: !show
    })}>

      <Input
        overClass={styles.form__input}
        input={input}
        placeholder={'请输入昵称'}
        changeHandler={onChange}
        onComposition={onComposition}
        // blurHandler={onBlur}
        clearHandler={onClear}
      />
    </div>
  )
}

export default forwardRef(NameForm)