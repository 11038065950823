/**
 author: william   email:362661044@qq.com
 create_at:2021/9/13 上午 11:54
 **/
import queryString from "query-string";

// 获取操作系统信息
export function getOsInfo() {
  const userAgent = navigator.userAgent.toLowerCase();
  let name = "Unknown";
  if (userAgent.indexOf("win") > -1) {
    name = "Windows";
  } else if (userAgent.indexOf("iphone") > -1) {
    name = "Iphone";
  } else if (userAgent.indexOf("mac") > -1) {
    name = "Mac";
  } else if (
    userAgent.indexOf("x11") > -1 ||
    userAgent.indexOf("unix") > -1 ||
    userAgent.indexOf("sunname") > -1 ||
    userAgent.indexOf("bsd") > -1
  ) {
    name = "Unix";
  } else if (userAgent.indexOf("linux") > -1) {
    if (userAgent.indexOf("android") > -1) {
      name = "Android";
    } else {
      name = "Linux";
    }
  } else {
    name = "Unknown";
  }
  return name;
}

export function getLocation(fn) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      console.warn(position);
      if (fn) fn(position.coords.longitude, position.coords.latitude);
    });
  } else {
    console.log("该浏览器不支持获取地理位置。");
  }
}

// 获取search 参数
export function getSearch() {
  const params = window.location.search;
  if (params && params.indexOf("?") > -1) {
    const _params = params.slice(1);
    const _paramsList = _params.split("&");
    const _listParams = [];
    if (Array.isArray(_paramsList)) {
      for (let item of _paramsList) {
        _listParams.push(item.split("="));
      }

      const obj = {};

      try {
        _listParams.forEach((p) => {
          if (p.length > 1) {
            obj[p[0]] = p[1];
          }
        });
      } catch (e) {
        console.log("obj错误", e);
      }

      console.log("obj", obj);
      return obj;

      // return Object.fromEntries(_listParams);
    }
    return {};
  }
  return {};
}

export function getSearch2() {
  const params = window.location.search;
  const res = queryString.parse(params);
  console.log(res);
  return res;
}
