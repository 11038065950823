import { I18N } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";
import { downloadBtnClick } from "@/pages/forMobileInvite/component/MaskModal/evokeAppOpenDetail";
import config from "config";

import {
  usePlatformCheck,
  WindowType,
} from "@/pages/PureHooks/usePlatformCheck";
import styles from "./OpenAppBtn.module.scss";
import {downloadUrl} from "@/constants/url";

// type TProps = {
//   windowType: WindowType;
// };

const OpenAppBtn = ({ task, invtiter_id }: any) =>
  // props: TProps
  {
    const [btnText, set_btnText] = useState(I18N.common.checkInTheFlyele);

    const handleGoDownload = () => {
      location.href = downloadUrl;
    };

    const _getMiniProgramPath = ({ task }: any): string => {
      let str = "";
      const { task_id } = task;

      switch (task?.matter_type) {
        case 10701:
          // 事项：/pages/task/pages/detail/pages/task/index?taskId=${ref_task_id}&id=${item.dispatch_id}&from=flyele&sycleState=${sycleState}&repeatId=${repeat_id}`)
          str = `/pages/task/pages/detail/pages/task/index?taskId=${task_id}`;
          // str += `&id=${item.dispatch_id}`;
          str += "&from=flyele";
          // str += `&sycleState=${sycleState}&repeatId=${repeat_id}`;
          return str;

        case 10702:
          // 会议：/pages/task/pages/detail/pages/meeting/index?taskId=${ref_task_id}&id=${item.dispatch_id}&from=${isFrom}
          str = `/pages/task/pages/detail/pages/meeting/index?taskId=${task_id}`;
          // str = `&id=${item.dispatch_id}`
          // str += `&from=${isFrom}`

          return str;
        case 10705:
          // 代办 : /pages/task/pages/detail/pages/todo/index?taskId=${ref_task_id}&id=${item.dispatch_id}&from=${isFrom}
          str = `/pages/task/pages/detail/pages/todo/index?taskId=${task_id}`;
          // str = `&id=${item.dispatch_id}`
          // str += `&from=${isFrom}`

          return str;
        default:
      }

      return "";

      // return `${config.invite}?taskId=${id}&from=wechat&from_user_id=${u}&shareFrom=2`;
    };

    const { nowWindowType } = usePlatformCheck();

    useEffect(() => {
      if (nowWindowType === WindowType.微信浏览器) {
        set_btnText(I18N.common.checkInMiniProgress);
      }

      if (nowWindowType === WindowType.手机浏览器) {
        set_btnText(I18N.common.checkInFlyeleApp);
      }

      if (nowWindowType === WindowType.PC浏览器) {
        set_btnText(I18N.common.checkInTheFlyele);
      }
    }, [nowWindowType]);

    return (
      <>
        {nowWindowType === WindowType.微信浏览器 ? (
          <div className={styles.btn_wx}>
            {/* @ts-ignore*/}
            <wx-open-launch-weapp
              id="launch-btn"
              username={config.miniProgramUsername}
              path={_getMiniProgramPath({ task })}
            >
              {" "}
              <script type="text/wxtag-template">
                {/* @ts-ignore*/}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "inherit",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                  }}
                >
                  {btnText}
                </div>
              </script>
              {/* @ts-ignore*/}
            </wx-open-launch-weapp>
          </div>
        ) : null}

        {nowWindowType === WindowType.手机浏览器 ? (
          <div
            className={styles.btn}
            onClick={() => {
              downloadBtnClick(task);
            }}
          >
            {btnText}
          </div>
        ) : null}

        {nowWindowType === WindowType.PC浏览器 ? (
          <div
            className={styles.btn}
            onClick={() => {
              handleGoDownload();
            }}
          >
            {btnText}
          </div>
        ) : null}
      </>
    );
  };

export default OpenAppBtn;
