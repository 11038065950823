import { I18N } from '@flyele-nx/i18n'
import React from "react";

import "./index.scss";
const styles = {
  info: "info",
  info2: "info2",
  info2_bottom: "info2_bottom",
  info2_top: "info2_top",
  info_left: "info_left",
  info_right: "info_right",
};

export enum InfoLineV15TypeName {
  time = "time",
  remind = "remind",
  myStartTime = "myStartTime",
  pos = "pos",
  takers = "takers",
  child = "child",
  tools = "tools",
  project = "project",
  loop = "loop",
}

export enum InfoLineV15DisplayType {
  line01 = "line01",
  line02 = "line02",
}

const picAndCNameDict = {
  project: {
    src: "/assets/v1_5/project_black_3x.png",
    txt: I18N.common.project,
  },
  loop: {
    src: "/assets/v1_5/repeat_3x.png",
    txt: I18N.common.repeats,
  },
  //
  time: {
    src: "/assets/v1_5/task_v15/time_3x.png",
    txt: I18N.common.time,
  },
  remind: {
    src: "/assets/v1_5/task_v15/remind_3x.png",
    txt: I18N.common.remind,
  },
  myStartTime: {
    src: "/assets/v1_5/task_v15/mystart_3x.png",
    txt: I18N.common.executeTime,
  },
  pos: {
    src: "/assets/v1_5/task_v15/takers_3x.png",
    txt: I18N.common.address,
  },
  takers: {
    src: "/assets/v1_5/task_v15/takers_3x.png",
    txt: I18N.common.assignee,
  },
  child: {
    src: "/assets/v1_5/task_v15/child_3x.png",
    txt: I18N.common.subtask,
  },
  tools: {
    src: "/assets/v1_5/task_v15/tools_3x.png",
    txt: I18N.common.collaborativeProcess,
  },
};

type TPorps = {
  typeName: InfoLineV15TypeName;
  mode?: InfoLineV15DisplayType;
  children: React.ReactChildren | any;
  rightBtn?: any;
  info2_bottom_style?: React.CSSProperties; // 自定义的info2_bottom的样式
};

const InfoLineV15 = ({
  typeName,
  mode = InfoLineV15DisplayType.line01,
  children,
  rightBtn,
  info2_bottom_style,
}: TPorps) => {
  /** 单行显示 */
  if (mode === InfoLineV15DisplayType.line01) {
    return (
      <div className={styles.info}>
        <div className={styles.info_left}>
          <img
            src={picAndCNameDict[typeName].src}
            alt=""
            style={{
              opacity: typeName === InfoLineV15TypeName.project  ? 0.52 : 1,
            }}
          />
          <span>{picAndCNameDict[typeName].txt}</span>
        </div>
        <div className={styles.info_right}>{children}</div>
      </div>
    );
  }

  /** 双行显示 */
  return (
    <div className={styles.info2}>
      <div className={styles.info2_top}>
        <div>
          <img src={picAndCNameDict[typeName].src} alt="" />
          <span>{picAndCNameDict[typeName].txt}</span>
        </div>
        {/** 右边的脉络图按钮 */}
        {typeName === InfoLineV15TypeName.child && rightBtn ? (
          <div>{rightBtn}</div>
        ) : null}
      </div>
      <div className={styles.info2_bottom} style={info2_bottom_style}>
        {children}
      </div>
    </div>
  );
};

export default InfoLineV15;
