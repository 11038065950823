import React, { useState, useEffect } from "react";
import {getCircleTimeText, IRepeatConfig} from "./Loop";
import {useMemoizedFn} from "ahooks";

const RepeatInfo = ({
  cycle,
  start_time,
  end_time,
  create_at,
  repeat_type,
  end_repeat_at,
  repeat_config,
}: {
  start_time: number
  end_time: number
  create_at: number
  cycle: number;
  repeat_type: number;
  end_repeat_at: number;
  repeat_config?: IRepeatConfig // 循环设置
}) => {

  const [circleTimeText, setCircleTimeText] = useState<string>('')

  const getText = useMemoizedFn(async () => {
    const text = await getCircleTimeText({start_time, end_time, create_at, end_repeat_at, repeat_type, cycle, repeat_config})

    setCircleTimeText(text)
  })

  useEffect(() => {
    getText()
  }, [create_at, cycle, end_repeat_at, end_time, getText, repeat_config, repeat_type, start_time])


  return (
    <span>{circleTimeText}</span>
  )
};

export default RepeatInfo;
