import { I18N } from '@flyele-nx/i18n'
import AnserLayoutV17 from "@/pages/PureH5/AnserTemplate/AnserLayout";

import InviteMeeting from "../pages/InviteMeeting";
import InviteTask from "../pages/InviteTask";
import InviteInteracts from "../pages/InviteInteracts";
import InviteSpace from "../pages/InviteSpace";

import AnserLayout from "../pages/InviteTask/AnserLayout";
import AnserLayoutCanceled from "../pages/InviteTask/AnserLayoutCanceled";

import AnserLayoutCanceledProject from "../pages/InviteTask/AnserLayoutCanceledProject";

import ExcelTask from "../pages/excel/ExcelTask";
import ExcelMeeting from "../pages/excel/ExcelMeeting";
import ExcelTodo from "../pages/excel/ExcelTodo";

import InviteTarget from "../pages/InviteTarget";

import CorpFailurePage from "../pages/CorpFailurePage";
import { MyMembers } from "../pages/MyMembers";

export const ForPcRouters = [
  // 协作人邀请，创建协作关系
  {
    exact: true,
    path: "/interacts/bind",
    component: InviteInteracts,
    key: "wx_sister",
    verifyLogin: false,
    title: I18N.common.h5_collaboratorInvitation2,
  },
  // 我的协作人
  {
    exact: true,
    path: "/interacts/my",
    component: MyMembers,
    key: "wx_sister",
    verifyLogin: false,
    title: I18N.common.my_interact,
  },
  {
    exact: true,
    path: `/interacts/ans`,
    component: AnserLayoutV17,
    key: "InviteMeetting_ans",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_collaboratorInvitation,
  },
  // pc端的web邀请
  {
    exact: true,
    path: `/invite/task`,
    component: InviteTask,
    key: "InviteTask",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_invitationForNewMatters,
  },
  {
    exact: true,
    path: `/invite/task_ans`,
    component: AnserLayout,
    key: "InviteMeetting_ans",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_invitationPageForYou,
  },
  {
    exact: true,
    path: `/invite/task_ans_canceled`,
    component: AnserLayoutCanceled,
    key: "InviteMeetting_ans",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_invitationPageForYou,
  },
  {
    exact: true,
    path: `/invite/project_ans`,
    component: AnserLayoutCanceledProject,
    key: "InviteMeetting_ans",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_invitationPageForYou,
  },
  {
    exact: true,
    path: `/invite/meeting`,
    key: "InviteMeetting",
    component: InviteMeeting,
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_meetingInvitationPage,
  },
  {
    exact: true,
    path: `/invite/space`,
    component: InviteSpace,
    key: "InviteSpace",
    verifyLogin: false,
    children: [],
    title: I18N.common.spaceInvitation,
  },
  // H5 用同个页面
  {
    exact: true,
    path: `/excel/task`,
    component: ExcelTask,
    key: "ExcelTask",
    verifyLogin: false,
    children: [],
    title: I18N.common.detailsOfTheMatter,
  },
  // H5 用同个页面
  {
    exact: true,
    path: `/excel/meeting`,
    component: ExcelMeeting,
    key: "ExcelMeeting",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_meetingDetails,
  },
  // H5 用同个页面
  {
    exact: true,
    path: `/excel/todo`,
    component: ExcelTodo,
    key: "ExcelTodo",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_toDoDetails,
  },
  {
    exact: true,
    path: `/invite/target`,
    component: InviteTarget,
    key: "InviteTarget",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_targetInvitation,
  },
  {
    exact: true,
    path: `/corpWechat/loginFailure`,
    component: CorpFailurePage,
    key: "CorpFailurePage",
    verifyLogin: false,
    children: [],
    title: I18N.common.h5_resultPage,
  },
];
