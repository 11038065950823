import { useMount } from "ahooks"
import Form from "../Login/components/Form"
import styles from "./index.module.scss"
import { ReactComponent as SuccessIcon} from "@/icons/icon-success.svg"
import qs from "qs"
import { useRef, useState } from "react"
import { wechatCustomizeBind } from "@/api/user"
import { message } from "antd"

const getCodeUrl = (): string => {
  const appid = "wx9ba23742f497d4c3" // 轻派助手
  const href = encodeURIComponent(window.location.href)
  const urlSuffix = "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${href}${urlSuffix}`
  return url
}

/**
 * 轻派专用页面
 */
export default function BindWxCodeToPhone() {
  const paramesRef = useRef<Record<string, string>>({})
  const [hasBind, setHasBind] = useState(false)
  const toNext = ({ phone }: { phone: string }) => {
    if (hasBind) {
      message.error("已经绑定过了")
      return
    }
    const { code, wx_id } = paramesRef.current
    wechatCustomizeBind({ telephone: phone, code, wx_id })
      .then((res) => {
        message.success("绑定成功")
        setHasBind(true)
      })
      .catch((err) => {
        message.error("绑定失败", err.message)
      })
  }

  useMount(() => {
    const params = qs.parse(window.location.href) as {
      [prop: string]: string
    }

    if (!params.code) {
      const url = getCodeUrl()
      window.location.href = url
    } else {
      paramesRef.current = params
    }
  })

  return (
    <div className={styles.login}>
      {hasBind ? (
        <div className={styles.success}>
          <SuccessIcon className={styles.icon} />
          <span className={styles.text}>绑定成功</span>
        </div>
      ) : (
        <>
          <div className={styles.login__banner}>
            <img src="/assets/v2_0/login_banner.png" alt="" />
          </div>

          <Form next={toNext} title="手机账号" desc="" />
        </>
      )}
    </div>
  )
}
