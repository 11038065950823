import React from "react";

import cs from "classnames";

import styles from "./ProjectDisplay.module.scss";

type TProps = {
  project_name: string;
};

const ProjectDisplay = ({ project_name }: TProps) => {
  return (
    <div className={styles["time-remind"]}>
      <div
        className={cs(styles["icon-time"], styles["fx-float-left"])}
        style={{ opacity: 0.66 }}
      >
        <img src="/assets/v1_5/project_black_3x.png" alt="" />
      </div>

      <div className={cs(styles["box-text"], styles["fx-float-left"])}>
        <span>{project_name}</span>
      </div>
    </div>
  );
};

export default ProjectDisplay;
