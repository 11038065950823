
import { InviteType } from '../../hooks/useGetMatter'
import MatterInfo from '../MatterInfo'
import SpaceInfo from '../SpaceInfo'
import ProjectInfo from '../ProjectInfo'
import Takers from '../Takers'
import styles from './index.module.scss'

type IProps = {
  task: any
  takers: any[]
  inviteType: InviteType
}

const Detail = (props: IProps) => {

  const {
    task,
    takers,
    inviteType,
  } = props

  const Component = inviteType === InviteType.matter
    ? MatterInfo
    : inviteType === InviteType.space
      ? SpaceInfo
      : ProjectInfo

  return (
    <div className={styles.detail}>

      <Component 
        task={task}
      />

      <Takers
        inviteType={inviteType}
        task={task}
        takers={takers}
      />

    </div>
  )
}

export default Detail