import React, { useRef, useCallback } from "react";
import * as ww from "@wecom/jssdk";
import { WWLoginInstance } from "@wecom/jssdk";
import userApi from "@/service/user";
import officialApi from "@/service/official";
import ErrorCodes from "@/service/code";
import sensors from "@/sensorsDataHook/initSensors";
import util from "@/pages/Wx2dCode/util";
import { useMount ,useUnmount } from 'ahooks'
import { Toast } from 'antd-mobile'
import 'antd-mobile/lib/toast/style/index'

const _CorpQrcodeLogin = ({
  visible,
  onSuccess,
  onError,
  history,
}: {
  visible: boolean;
  onSuccess: () => void;
  onError?: () => void;
  history?: any
}) => {
  const wwLogin = useRef<WWLoginInstance>();

  const getUserInfo = useCallback(() => {
    userApi
      .getLoginUserInfo()
      .then((res) => {
        if (res.code === ErrorCodes.success) {
          console.log("用户信息", res.data);
          sensors.sender.login(res.data.user_id);
          localStorage.setItem("fx-user_id", res.data.user_id);

          if (onSuccess) {
            setTimeout(() => {
              onSuccess();
            }, 233);
          }
        }
      })
      .catch((err) => {
        console.log("获取登录用户失败", err);
      });
  }, [onSuccess])

  /**
   * 登录后拿企业微信code 换取 token
   */
  const corpLogin = useCallback(async (code: string) => {
    try {
      const params = await util.getLoginKeyParams()
      const { data: { data: { token } }} = await officialApi.getAuthInfo({
        ...params,
        auth_code: code,
      })
      localStorage.setItem("fx-token", token);
      getUserInfo();
    } catch (e) {
      console.log('code更换token失败', e);
      Toast.fail('企业微信登录失败，请稍后重试')
      onError && onError()
    }
  }, [getUserInfo, onError])

  useMount(() => {
    console.log('进入 corp useMount', wwLogin.current);
    wwLogin.current = ww.createWWLoginPanel({
      el: "#ww_login",
      params: {
        login_type: "ServiceApp" as any,
        appid: "wwde9fcdf25dfbf58f",
        redirect_uri: encodeURI(window.location.href),
        redirect_type: "callback" as any,
      },
      onCheckWeComLogin({ isWeComLogin }) {
        console.log(isWeComLogin)
      },
      onLoginSuccess({ code }) {
        corpLogin(code)
      },
      onLoginFail(err) {
        if (history) {
          history.push({
            pathname: `/corpWechat/loginFailure`,
          });
        }
        onError && onError()
      },
    });
  })

  useUnmount(() => {
    console.log('进入 corp useUnmount', wwLogin.current);
    if (wwLogin.current) {
      wwLogin.current?.unmount()
    }
  })

  return (
    <div id="ww_login"  style={{
        display: visible ? 'block' : 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    />
  )
};

export const CorpQrcodeLogin = React.memo(_CorpQrcodeLogin);
