import React, {useEffect, useMemo, useState} from 'react';
import ShowCardBox from '../ShowCardBox'
import ChatFrame from "@/pages/excel/components/ChatFrame";
import CommentApi from "@/service/comment";

const Communication = ({ taskId }: { taskId: string }) => {
  const [list, setList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)

  const fetchComments = async (id: string) => {
    const { code, total, data } = await CommentApi.getComments({
      ref_type: 'task',
      ref_id: id,
      msg_type: 4,
    })

    if (code === 0) {
      setTotal(total)
      setList(data)
    }
  }

  useEffect(() => {
    fetchComments(taskId)
  }, [taskId])

  if (list.length === 0) return null

  return (
    <ShowCardBox
      title="沟通记录"
      height="626px"
      allText={total > 5 ? `全部${total}个` : ''}
    >
      <ChatFrame data={list.slice(0, 5)} taskId={taskId} />
    </ShowCardBox>
  )

}

export default React.memo(Communication)