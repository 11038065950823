const useHrefSearch = () => {
  // 从路由中获取相关邀请参数
  function getHref() {
    // 当前的路径参数
    const { search }: any = window.location;

    // 抽出参数
    const searchs = search.replace("?", "").split("&");

    console.log(searchs);
    const result: any = {};

    searchs.forEach((str: any) => {
      if (str) {
        const keyValue = str.split("=");

        if (keyValue.length === 2) {
          if (keyValue[0] && keyValue[1]) {
            const ValueOfKey: string = keyValue[0];
            const ValueOfName: string = keyValue[1];

            result[ValueOfKey] = ValueOfName;
          }
        }
      }
    });

    console.log("RRREEESSSULT", result)

    return result;
  }

  return getHref();
};

export default useHrefSearch;
