/* eslint-disable */
// @ts-nocheck
import { I18N, isCN } from '@flyele-nx/i18n'
import React, { useEffect, useState } from "react";
import { Avatar } from "Component/Avatar/Avatar";

import Layout from "Layout/forH5Auto/Layout";
import { Divider } from "Component/Line/Divider";
import { BigThemeBtn } from "Component/Button";

import projectApi from "service/project";

import userApi from "service/user";

// import useGetTask from "./hooks/useGetTask";

import { downloadBtnClick } from "pages/html5downloader/ts/download";

import "./AnserLayout.scss";
import { isEmpty } from "lodash";
import { checkSpaceDismiss } from "@/utils/join-tools";
import {curProjectType, PROJECT_ENV} from "@/service/const";

const getPic = (typeName: string) => {
  switch (typeName) {
    case I18N.common.h5_youHaveBeen2:
      return "/assets/banner/banner_beremoved_hd.png";
    case I18N.common.theMatterHasBeenCancelled:
      return "/assets/banner/banner_canceled_hd.png";
    case I18N.common.h5_projectToBeCompleted:
      return "/assets/banner/banner_canceled_hd.png";
    case I18N.common.h5_projectSpaceWaiting:
      return "/assets/banner/banner_canceled_hd.png";
    case I18N.common.h5_youHaveBeen:
      return "/assets/banner/meeting_beremoved_hd.png";
    case I18N.common.h5_theMeetingHasBeenCancelled:
      return "/assets/banner/meeting_canceled_hd.png";
    case I18N.common.h5_toDoYouHaveBeen:
      return "/assets/banner/taskwait_beremoved_hd.png";
    case I18N.common.h5_toDoCancelled:
      return "/assets/banner/taskwait_canceled_hd.png";
    default:
      return "/assets/banner/taskwait_canceled_hd.png";
  }
};

const AnserLayout = (props: any) => {
  const { history } = props;
  const [searchResult, setSearchResult] = useState(undefined);
  const [ansTitle, setAnsTitle] = useState(I18N.common.canceled);
  const [ansResult, set_ansResult] = useState("");
  const [taskType, set_taskType] = useState("");
  const [task, set_task] = useState<any>({});
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!searchResult) return;
    setLoading(true)
    projectApi
      .getProjectInfo(searchResult.id)
      .then((res: any) => {
        if (res.code === 40047 && res.dbg_error === I18N.common.h5_spaceToBeActivated) {
          set_taskType(I18N.common.project);
          setAnsTitle(I18N.common.h5_spaceToBeActivated);
        } else {
          set_task(res.data);
        }
        return res;
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      });
  }, [searchResult]);

  useEffect(() => {
    if (!task || isEmpty(task)) return;

    set_taskType(I18N.common.project);
    setLoading(false)

    if (
      task?.creator_id === localStorage.getItem("fx-user_id") &&
      !localStorage.getItem("fx-user_id")
    ) {
      setAnsTitle(I18N.common.h5_youAreTheOneResponsibleForThisMatter);
      return;
    }

    if (task?.state === 10202) {
      setAnsTitle(I18N.common.h5_theProjectHasBeenCreated);
      return;
    }

    if (task?.state === 10203) {
      setAnsTitle(I18N.common.h5_projectToBeActivated);
      return;
    }

    if (searchResult && searchResult.maxTaker === '1') {
      setAnsTitle(I18N.common.unableToJoin);
      return;
    }

    const find = (task?.project_members || []).find(
      (t) => t.user_id === localStorage.getItem("fx-user_id")
    );

    if (checkSpaceDismiss({
      task,
      history
    })) {
      setAnsTitle(I18N.common.canceled)
      set_taskType(I18N.common.teamspace)
      return
    }

    if (!find) return;

    switch (find?.state) {
      // 项目
      case 10302:
        setAnsTitle(I18N.common.joined);
        return;
      case 10303:
        setAnsTitle(I18N.common.rejected);
        if (searchResult.res) {
          set_ansResult(I18N.template(I18N.common.h5_reasonForRejectionD, { val1: decodeURI(searchResult.res) }));
        }
        return;
      case 10304:
        setAnsTitle(I18N.common.h5_youHaveBeenRemoved);
        return;
    }

    if (task?.creator_id === localStorage.getItem("fx-user_id")) {
      setAnsTitle(I18N.common.owner);
    }
  }, [task, searchResult]);

  useEffect(() => {
    // 从路由中截取入参参数
    if (props.history.location.search) {
      const searchs = props.history.location.search.replace("?", "").split("&");

      const result: any = {};

      // 把每个key:value塞入数据数组
      searchs.forEach((str: any) => {
        if (str) {
          const keyValue = str.split("=");

          if (keyValue.length === 2) {
            if (keyValue[0] && keyValue[1]) {
              const ValueOfKey: string = keyValue[0];
              const ValueOfName: string = keyValue[1];

              result[ValueOfKey] = ValueOfName;
            }
          }
        }
      });

      // search参数结果
      console.log("获取参数", result);
      setSearchResult(result);
    }
  }, [props]);

  const getTitleColor = (title: any) => {
    switch (title) {
      case I18N.common.joined: // '已加入':
        return "theme";
      case I18N.common.owner: // '已加入':
        return "theme";
      case I18N.common.inviter: // '已加入':
        return "theme";
      case I18N.common.leaveTaken: // '已请假':
        return "orange";
      case I18N.common.unableToJoin: // '已请假':
        return "orange";
      case I18N.common.rejected: // '已拒绝':
        return "red";
      default:
        return "black";
    }
  };

  const [avatar_link, set_avatar_link] = useState("");
  const [inviter_nick_name, set_nick_name] = useState("");

  useEffect(() => {
    userApi
      .avatar(localStorage.getItem("fx-user_id") || "")
      .then((res: any) => {
        set_avatar_link(res.data.avatar);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    console.log(searchResult);
    if (!searchResult?.u) return;

    userApi
      .avatar(searchResult.u)
      .then((res: any) => {
        set_nick_name(res.data.nick_name);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [searchResult]);

  return (
    <Layout>
      <div className="fx-layout-anser">
        {loading ? (
          <div>{I18N.common.loading}</div>
        ) : (
          <>
            <div
              className="header"
              style={{
                width:
                  ansTitle === I18N.common.canceled ||
                  ansTitle === I18N.common.h5_youHaveBeenRemoved ||
                  ansTitle === I18N.common.h5_theProjectHasBeenCreated ||
                  ansTitle === I18N.common.h5_spaceToBeActivated||
                  ansTitle === I18N.common.h5_projectToBeActivated  ? 120
                    : 56,
              }}
            >
              {ansTitle === I18N.common.canceled ||
              ansTitle === I18N.common.h5_youHaveBeenRemoved ||
              ansTitle === I18N.common.h5_theProjectHasBeenCreated ||
              ansTitle === I18N.common.h5_spaceToBeActivated||
              ansTitle === I18N.common.h5_projectToBeActivated ? (
                <img
                  src={getPic(`${taskType}${ansTitle}`)} // "/assets/banner/project_canceled_hd.png"
                  alt=""
                  style={{ width: 120 }}
                />
              ) : (
                <>
                  {avatar_link && <Avatar size="large" src={avatar_link} />}
                </>

              )}

              {/* <Avatar size="large" src={avatar_link} /> */}
            </div>
            <div className="content">
              <div className="ans-title">
                <div className={getTitleColor(ansTitle ? ansTitle : "theme")}>
                  {ansTitle === I18N.common.canceled && `${taskType}${ansTitle}`}
                  {ansTitle === I18N.common.h5_youHaveBeenRemoved && `${ansTitle}${taskType}`}
                  {ansTitle === I18N.common.inviter && I18N.template(I18N.common.h5_youAreTheOneWho2, { val1: taskType })}
                  {ansTitle === I18N.common.owner && I18N.template(I18N.common.h5_youAreTheOneWho, { val1: taskType })}

                  {ansTitle !== I18N.common.canceled &&
                    ansTitle !== I18N.common.h5_youHaveBeenRemoved &&
                    ansTitle !== I18N.common.inviter &&
                    ansTitle !== I18N.common.owner &&
                    ansTitle !== I18N.common.h5_spaceToBeActivated &&
                    ansTitle !== I18N.common.h5_projectToBeActivated &&
                    `${ansTitle}`}
                </div>
                {(ansTitle === I18N.common.h5_spaceToBeActivated || ansTitle === I18N.common.h5_projectToBeActivated) &&
                  <div>
                    <p>
                      {I18N.template(I18N.common.h5_affiliatedValue, { val1: ansTitle.slice(0, 2) })}
                    </p>
                    <p>
                      {I18N.template(I18N.common.h5_pleaseContactToActivate, {val1: ansTitle.slice(0, 2)})}
                    </p>
                  </div>
                }
              </div>
              {ansResult && <div className="ans-result">{ansResult}</div>}
              {ansTitle !== I18N.common.h5_linkExceptionPlease &&
                ansTitle !== I18N.common.h5_spaceToBeActivated &&
                ansTitle !== I18N.common.h5_projectToBeActivated && (
                  <>
                    <div className="ans-from">
                      {I18N.template(I18N.common.h5_inviteFrom,
                        {
                          val1: `${
                            inviter_nick_name ? `${isCN ? '' : 'From '}${inviter_nick_name}${isCN ? '的' : ''}` : ""
                          }`,
                          val2: taskType
                        }
                      )}
                    </div>
                    <div className="ans-task-name">
                      {task?.project_name
                        ? `【${task?.project_name}】`
                        : I18N.common.h5_requestDataHas}
                    </div>
                  </>
                )}
            </div>
          </>
        )}
        {curProjectType === PROJECT_ENV.normal && (
          <>
            <Divider marginStyle={{ top: 48, bottom: 47 }} />
            <div className="ad">
              <div className="info" style={{textAlign:'center', width:'100%', display:'inline-block'}}>
                {I18N.common.h5_moreDetails}</div>
              <div className="icon">
                <img src="/assets/icons/logo/logo.png" alt="logo" />
              </div>
              <div className="btn-download">
                <BigThemeBtn
                  title={<span style={{ padding: "0px 40px" }}>{I18N.common.h5_downloadNow}</span>}
                  colorType="theme"
                  onClick={downloadBtnClick}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AnserLayout;
