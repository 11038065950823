

const getRouteSearch = (search: string) => {

  // 抽出参数
  const searchs = search.replace("?", "").split("&");

  console.log(searchs);
  const result: any = {};

  searchs.forEach((str: any) => {
    if (str) {
      const keyValue = str.split("=");

      if (keyValue.length === 2) {
        if (keyValue[0] && keyValue[1]) {
          const ValueOfKey: string = keyValue[0];
          const ValueOfName: string = keyValue[1];

          result[ValueOfKey] = ValueOfName;
        }
      }
    }
  });

  return result;
}

const concatSearch = (search: string, obj: Record<any, any>) => {

  const str: string = Object.keys(obj).reduce((acc, key) => {
     acc.push(`${key}=${obj[key]}`)
     return acc
  }, [] as string[]).join('&')

  if (search.includes('?')) {
    return search.concat(`&${str}`)
  } else {
    return str
  }
}

const getUid = () => {
  const s = []
  const hexDigits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const random = Math.floor(Math.random() * 10 + 1)

  s[0] = hexDigits.slice(10)[random]

  for (let i = 1; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'
  s[19] = hexDigits.substr((s[19] as any & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = ''
  const uuid = s.join('')

  return uuid
}

export {
  getRouteSearch,
  concatSearch,
  getUid,
}