


enum JoinType {
  joined = 'joined',
  ongoing = 'ongoing',
  unapproved = 'unapproved',
  fail = 'fail',
  none = '',
  refused = 'refused',
  askForLeave = 'askForLeave',
}


enum InviteType {
  matter = 'matter',
  project = 'project',
  space = 'space',
  none = ''
}

export {
  JoinType,
  InviteType
}